import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "components/MDTable/tableToolbar";
import TableHeader from "components/MDTable/tableHeader";
import { Order } from "components/MDTable/helper";
import { ITableHeader } from "types/Table";
import TablePaginationActions from "components/MDTable/tablePagination";
import MDBox from "components/MDBox";
import { useSelector } from "react-redux";

import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { Icon, IconButton } from "@mui/material";
import { getDriverStatus, getLoadingStatus } from "redux/slices/driverSlice";
import { CareFreeBooking } from "types/Carefree";
import ConfirmationDialog from "components/ConfirmationDialog";
import { cancelCareFreeBooking } from "services/api/carefreeApi";
import { TransactionActionsEnum } from "types/enum/transactions";

type TableType = {
  header: ITableHeader[];
  rows: CareFreeBooking[];
  onReload: () => void;
};

const BookingTable: React.FC<TableType> = (props) => {
  const { header, rows, onReload } = props;
  const accessToken = localStorage.getItem("carching-token");
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState<number>(0);
  const isLoading = useSelector(getLoadingStatus);
  const [selectedRow, setSelectedRow] = useState<CareFreeBooking | undefined>();
  const [isDialogOpenWithAction, openDialogConfirmation] = useState<
    string | undefined
  >();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleActionButtons = async (value?: string): Promise<void> => {
    if (value === "Confirm") {
      if (accessToken && !!selectedRow) {
        await cancelCareFreeBooking(selectedRow.id, accessToken);
      }
      openDialogConfirmation(undefined);
      setSelectedRow(undefined);
      onReload();
    } else {
      openDialogConfirmation(undefined);
      setSelectedRow(undefined);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {!!isDialogOpenWithAction && (
        <ConfirmationDialog
          title={`Are you sure you want to cancel this booking?`}
          open={!!isDialogOpenWithAction}
          onClose={(value) => handleActionButtons(value)}
          action={isDialogOpenWithAction}
          submitButtonTitle={"Confirm"}
        />
      )}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          numSelected={selected.length}
          tableName={"Carefree Bookings"}
          onReload={onReload}
        />
        {isLoading ? (
          <MDBox display={"flex"} justifyContent={"center"} mt={3}>
            <CircularProgress color={"primary"} />
          </MDBox>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeader
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={() => {}}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={header}
                disableCheckbox={true}
              />
              <TableBody>
                {rows.slice(page * 10, page * 10 + 10).map((row, index) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell align="right">{row.id}</TableCell>
                      <TableCell align="right">{row.home_owner_id}</TableCell>
                      <TableCell align="right">{`${row.start_date}`}</TableCell>
                      <TableCell align="right">{`${row.end_date}`}</TableCell>
                      <TableCell align="right">{`${row.duration}`}</TableCell>
                      <TableCell align="right">
                        {row.status ? row.status.name : row.status_id}
                      </TableCell>
                      <TableCell align="right">
                        {row.user ? `${row.user.fname} ${row.user.lname}` : ""}
                      </TableCell>
                      <TableCell align="right">
                        {row.car
                          ? `${row.car.plate_number} - ${row.car.car_brand.name} ${row.car.car_model.name}`
                          : ""}
                      </TableCell>
                      <TableCell align="right">
                        {row.status_id !== 70 && (
                          <IconButton
                            onClick={(e) => {
                              //Prevent whole  row is being selected
                              e.stopPropagation();
                              e.preventDefault();
                              setSelectedRow(row);
                              openDialogConfirmation("Confirm");
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={rows.length}
          rowsPerPage={10}
          page={rows.length <= 0 ? 0 : page}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </Box>
  );
};
export default BookingTable;

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import { Ledger } from "types/Overview";
import LedgerTable from "./table";
import MDTypography from "components/MDTypography";
import { LedgerEnum } from "types/enum/transactions";
import { useSelector } from "react-redux";
import { getLoadingStatus } from "redux/slices/transactionSlice";

type Props = {
  rows: Ledger;
  onDateChange: (date: string) => void;
};
const LedgerComponent: React.FC<Props> = (props) => {
  const { rows, onDateChange } = props;
  const isLoading = useSelector(getLoadingStatus);
  const [dateFilter, setDateFilter] = useState<string>(
    new Date().toLocaleDateString("en-CA")
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value).toLocaleDateString();
    setDateFilter(e.target.value);
    onDateChange(date);
  };

  return (
    <MDBox>
      <MDBox mt={5} sx={{ background: "white", width: "60%" }}>
        <TextField
          label="Start Date"
          type="date"
          name={"start_date"}
          onChange={handleChange}
          value={dateFilter}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </MDBox>

      {!isLoading && (
        <MDBox>
          <MDBox mt={5} mb={5}>
            <MDTypography variant="p" color="dark" fontWeight={"bold"}>
              {LedgerEnum.MAIN_LEDGER}
            </MDTypography>
            <LedgerTable
              header={MainLedgerHeader}
              rows={rows.main_ledger}
              tableTitle={LedgerEnum.MAIN_LEDGER}
            />
          </MDBox>

          <MDTypography variant="p" color="dark" fontWeight={"bold"}>
            {LedgerEnum.FLOAT_LEDGER}
          </MDTypography>
          <LedgerTable
            header={FloatLedgerHeader}
            rows={rows.float_ledger}
            tableTitle={LedgerEnum.FLOAT_LEDGER}
          />
        </MDBox>
      )}
    </MDBox>
  );
};

export default LedgerComponent;

const MainLedgerHeader = [
  { id: "id", name: "Transaction Id" },
  { id: "created_at", name: "Date" },
  { id: "created_at_time", name: "Time" },
  { id: "updated_at", name: "Cleared Date" },
  { id: "updated_at_time", name: "Cleared Time" },
  { id: "user_id", name: "User ID" },
  { id: "type", name: "Type" },
  { id: "transaction_type", name: "Transaction Type" },
  { id: "amount", name: "Amount" },
];

const FloatLedgerHeader = [
  { id: "id", name: "Transaction Id" },
  { id: "created_at", name: "Date" },
  { id: "created_at_time", name: "Time" },
  { id: "user_id", name: "User ID" },
  { id: "type", name: "Type" },
  { id: "transaction_type", name: "Transaction Type" },
  { id: "amount", name: "Amount" },
];

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CampaignState, StoreState } from "types/state/StoreState";
import {
  Campaign,
  CampaignAppointment,
  CampaignAssets,
  CampaignParticipatns,
  CampaignRequestFilter,
  CampaignSummary,
  Segregation,
} from "types/Campaign";
import {
  createCampaignAppointment,
  createCampaignAsset,
  createCampaigns,
  getAvailablesZones,
  getCampaignAssets,
  getCampaignSummary,
  getCampaigns,
  getSegregations,
  getSpecificCampaign,
  updateCampaignAsset,
  updateCampaigns,
} from "services/api/campaignApi";
import { PaginationCommonObject, Status } from "types/common";
import { getAppointmentsByCampaignId } from "services/api/appointmentsApi";
import { getWorkshops } from "services/api/workshopsApi";
import { CampaignWorkshopAppointment } from "types/AppointmentsSubmission";
import { Workshop } from "types/Workshop";
import { getAllBrandsWithoutPagination } from "services/api/brandsApi";
import { setAllBrands } from "./brandSlice";
import { getStatuses } from "services/api/commonApi";

const campaignSlice = createSlice({
  name: "campaign",
  initialState: {
    campaigns: {},
    campaign: {},
    campaignSummary: {},
    loading: true,
    campaignAppointments: {},
    campaignAssets: {},
    workshops: {},
    zones: {},
    campaignStatuses: [] as Status[],
    segregations: [] as Segregation[],
  } as CampaignState,
  reducers: {
    setAllCampaigns(state, action) {
      state.campaigns = action.payload;
    },
    setLoadingStatus(state, action) {
      state.loading = action.payload;
    },
    setCampaign(state, action) {
      state.campaign = action.payload;
    },
    setCampaignAppointments(state, action) {
      state.campaignAppointments = action.payload;
    },
    setCampaignAssets(state, action) {
      state.campaignAssets = action.payload;
    },
    setCampaignWorkshops(state, action) {
      state.workshops = action.payload;
    },
    setZones(state, action) {
      state.zones = action.payload;
    },
    setCampaignStatuses(state, action) {
      state.campaignStatuses = action.payload;
    },
    setCampaignSummary(state, action) {
      state.campaignSummary = action.payload;
    },
    setSegregation(state, action) {
      state.segregations = action.payload;
    },
  },
});
export const getLoadingStatus = (state: StoreState): boolean =>
  state.campaigns.loading;
export const getAllCampaings = (
  state: StoreState
): PaginationCommonObject<Campaign> => state.campaigns.campaigns;
export const getCampaignAppointments = (
  state: StoreState
): PaginationCommonObject<CampaignWorkshopAppointment> =>
  state.campaigns.campaignAppointments;
export const getAllCampaignAssets = (
  state: StoreState
): PaginationCommonObject<CampaignAssets> => state.campaigns.campaignAssets;
export const getCampaignWorkshops = (
  state: StoreState
): PaginationCommonObject<Workshop> => state.campaigns.workshops;

export const getCmapaignStatuses = (state: StoreState): Status[] =>
  state.campaigns.campaignStatuses;

export const getZones = (state: StoreState): PaginationCommonObject<Status> =>
  state.campaigns.zones;

export const getCampaignDetails = (state: StoreState): Campaign =>
  state.campaigns.campaign;
export const getCampaignParticipants = (
  state: StoreState
): CampaignParticipatns[] =>
  state.campaigns.campaign ? state.campaigns.campaign.participants : [];

export const getCampaignSummaryReducer = (state: StoreState): CampaignSummary =>
  state.campaigns.campaignSummary;
export const getAllSegregationns = (state: StoreState): Segregation[] =>
  state.campaigns.segregations;

export const getAllCampaignsThunk = createAsyncThunk(
  "campaign/getAllCampaignsThunk",
  async (request: CampaignRequestFilter, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(campaignSlice.actions.setLoadingStatus(true));
    const campaigns = await getCampaigns(request);
    dispatch(campaignSlice.actions.setAllCampaigns(campaigns));
    dispatch(campaignSlice.actions.setLoadingStatus(false));
  }
);
type CampaignRequest = {
  campaignId: number;
  accessToken: string;
  page?: number;
};
export const getCampaign = createAsyncThunk(
  "campaign/getCampaign",
  async (campaignRequest: CampaignRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { campaignId, accessToken } = campaignRequest;
    dispatch(campaignSlice.actions.setLoadingStatus(true));
    const campaigns = await getSpecificCampaign(accessToken, campaignId);
    dispatch(campaignSlice.actions.setCampaign(campaigns));
    dispatch(campaignSlice.actions.setLoadingStatus(false));
  }
);

export const getCampaignAppointmentThunk = createAsyncThunk(
  "campaign/getCampaignAppointmentThunk",
  async (request: CampaignRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { campaignId, accessToken, page = 1 } = request;

    dispatch(campaignSlice.actions.setLoadingStatus(true));
    const campaignAppointments = await getAppointmentsByCampaignId(
      accessToken,
      page,
      campaignId
    );
    dispatch(
      campaignSlice.actions.setCampaignAppointments(campaignAppointments)
    );
    dispatch(campaignSlice.actions.setLoadingStatus(false));
  }
);

export const getCampaignAssetsThunk = createAsyncThunk(
  "campaign/getCampaignAssetsThunk",
  async (request: CampaignRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, page, campaignId } = request;
    dispatch(campaignSlice.actions.setLoadingStatus(true));
    const campaignAssets = await getCampaignAssets(
      accessToken,
      page ?? 1,
      campaignId
    );
    dispatch(campaignSlice.actions.setCampaignAssets(campaignAssets));
    dispatch(campaignSlice.actions.setLoadingStatus(false));
  }
);
export const getZonesAndBrandsThunk = createAsyncThunk(
  "campaign/getZonesAndBrandsThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(campaignSlice.actions.setLoadingStatus(true));
    const [zones, brands, segregations] = await Promise.all([
      getAvailablesZones(accessToken),
      getAllBrandsWithoutPagination(accessToken),
      getSegregations(accessToken),
    ]);
    dispatch(campaignSlice.actions.setZones(zones));
    dispatch(setAllBrands(brands));
    dispatch(campaignSlice.actions.setSegregation(segregations));
    dispatch(campaignSlice.actions.setLoadingStatus(false));
  }
);

export const getCampaignAllDetails = createAsyncThunk(
  "campaign/getCampaignAllDetails",
  async (request: CampaignRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { campaignId, accessToken, page = 1 } = request;
    const [
      appointments,
      assets,
      campaign,
      workshops,
      statuses,
      campaignSummary,
      segregations,
    ] = await Promise.all([
      getAppointmentsByCampaignId(accessToken, page, campaignId),
      getCampaignAssets(accessToken, page, campaignId),
      getSpecificCampaign(accessToken, campaignId),
      getWorkshops(accessToken, page, ""),
      getStatuses(accessToken, "DriverCampaign"),
      getCampaignSummary(accessToken, campaignId),
      getSegregations(accessToken),
    ]);
    dispatch(campaignSlice.actions.setLoadingStatus(true));
    dispatch(campaignSlice.actions.setCampaignAppointments(appointments));
    dispatch(campaignSlice.actions.setCampaignAssets(assets));
    dispatch(campaignSlice.actions.setCampaign(campaign));
    dispatch(campaignSlice.actions.setCampaignWorkshops(workshops));
    dispatch(campaignSlice.actions.setCampaignStatuses(statuses));
    dispatch(campaignSlice.actions.setCampaignSummary(campaignSummary));
    dispatch(campaignSlice.actions.setSegregation(segregations));
    dispatch(campaignSlice.actions.setLoadingStatus(false));
  }
);

type CreateCampaign = {
  accessToken: string;
  campaigns: Campaign;
  isEdit: boolean;
};
export const createOrEditCampaignThunk = createAsyncThunk(
  "campaigns/createOrEditCampaignThunk",
  async (request: CreateCampaign, thunkApi) => {
    const { dispatch } = thunkApi;
    const { campaigns, accessToken, isEdit } = request;
    dispatch(campaignSlice.actions.setLoadingStatus(true));
    const isCreatedUpdatedSuccess = isEdit
      ? await updateCampaigns(campaigns, accessToken)
      : await createCampaigns(campaigns, accessToken);
    if (isCreatedUpdatedSuccess) {
      dispatch(
        getAllCampaignsThunk({
          accessToken,
          page: 1,
        })
      );
    }

    dispatch(campaignSlice.actions.setLoadingStatus(false));
    return isCreatedUpdatedSuccess;
  }
);

export const createOrEditCampaignAssetsThunk = createAsyncThunk(
  "campaigns/createOrEditCampaignAssetsThunk",
  async (request: any, _) => {
    const { campaignAssets, accessToken, isEdit } = request;

    const isCreatedUpdatedSuccess = isEdit
      ? await updateCampaignAsset(campaignAssets, accessToken)
      : await createCampaignAsset(campaignAssets, accessToken);
    if (isCreatedUpdatedSuccess) {
      alert("Success! need to refresh the page to reflect the data");
    }
    return isCreatedUpdatedSuccess;
  }
);

type CreateCampaignAppointment = {
  accessToken: string;
  appointments: CampaignAppointment;
  currentPage: number;
};
export const createCampaignAppointmentThunk = createAsyncThunk(
  "campaigns/createCampaignAppointmentThunk",
  async (request: CreateCampaignAppointment, thunkApi) => {
    const { dispatch } = thunkApi;
    const { appointments, accessToken, currentPage } = request;
    dispatch(campaignSlice.actions.setLoadingStatus(true));
    const isCreatedUpdatedSuccess = await createCampaignAppointment(
      appointments,
      accessToken
    );
    if (isCreatedUpdatedSuccess) {
      const campaignAppointments = await getAppointmentsByCampaignId(
        accessToken,
        currentPage,
        appointments.campaign_id
      );
      dispatch(
        campaignSlice.actions.setCampaignAppointments(campaignAppointments)
      );
    }

    dispatch(campaignSlice.actions.setLoadingStatus(false));
    return isCreatedUpdatedSuccess;
  }
);

export const { setAllCampaigns } = campaignSlice.actions;
export default campaignSlice.reducer;

import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import MDBox from "components/MDBox";
import Badge from "@mui/material/Badge";

type AvatarUploadProps = {
  selectedImage: string;
  handleImageUpload: (image: string) => void;
};

const AvatarUpload: React.FC<AvatarUploadProps> = (props) => {
  const { selectedImage, handleImageUpload } = props;
  const [image, setAvatarImage] = useState<string>(selectedImage);

  const setImage = (newImage) => {
    setAvatarImage(newImage);
    handleImageUpload(newImage);
  };

  const handleOnChange = (event) => {
    const newImage = event.target?.files?.[0];

    if (newImage) {
      // Convert image into base64
      const reader = new FileReader();
      reader.readAsDataURL(newImage);
      reader.onloadend = function () {
        setImage(reader.result);
      };
    }
  };

  const removeImage = (event) => {
    if (image) {
      event.preventDefault();
      setImage(null);
    }
  };

  return (
    <MDBox display={"flex"} justifyContent={"center"} mb={5}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <DeleteIcon
            color={"primary"}
            sx={{
              width: 30,
              height: 30,
              display: !image ? "none" : "initial",
            }}
            onClick={removeImage}
          />
        }
      >
        <a href={image} target="_blank">
          <Avatar
            sx={{ width: 150, height: 150, border: `1px solid #f0f2f5` }}
            src={image}
          >
            <FileUploadIcon sx={{ width: 70, height: 70 }} />
            <input
              accept="image/*"
              id="avatar-image-upload"
              type="file"
              onChange={handleOnChange}
              style={{
                opacity: 0,
                height: "150px",
                width: "150px",
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            />
          </Avatar>
        </a>
      </Badge>
    </MDBox>
  );
};

export default AvatarUpload;

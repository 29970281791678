import { AxiosResponse } from "axios";
import { getRequest, postRequestWithFormData } from "services/client/request";
import { AxiosGenericResponse, DateFilter } from "types/common";
import { Journey, JourneyPagination, NewJourney } from "types/Journeys";

const apiVersion = "/api";

export const getAllJourneyWithPagination = async (
  accessToken: string,
  page: number
): Promise<JourneyPagination> => {
  //&page=3
  const response: AxiosResponse<AxiosGenericResponse<JourneyPagination>> =
    await getRequest(
      `${apiVersion}/journeys?paginate=1&page=${page}`,
      accessToken
    );
  const journeys: JourneyPagination = response.data.success
    ? response.data.data
    : ({} as JourneyPagination);
  return journeys;
};

export const getAllJourneys = async (
  accessToken: string,
  dateFilter: DateFilter
): Promise<Journey[]> => {
  const { from, to } = dateFilter;
  const params =
    from === "" && to === "" ? `` : `&start_date=${from}&end_date=${to}`;
  var alljourney: Journey[] = [];
  const response: AxiosResponse<AxiosGenericResponse<JourneyPagination>> =
    await getRequest(
      `${apiVersion}/journeys?paginate=1&per_page=1000&page=1${params}`,
      accessToken
    );
  const result = response.data.data;
  if (response.data.success && result.last_page > 1) {
    alljourney = result.data;
    for (var i = 2; i < result.last_page; i++) {
      const responsePerpage: AxiosResponse<
        AxiosGenericResponse<JourneyPagination>
      > = await getRequest(
        `${apiVersion}/journeys?paginate=1&per_page=1000&page=${i}${params}`,
        accessToken
      );
      alljourney = alljourney.concat(responsePerpage.data.data.data);
    }
  } else {
    alljourney = result.data;
  }

  return alljourney ?? [];
};

export const createNewJourney = async (
  request: NewJourney,
  accessToken: string
): Promise<boolean> => {
  const { driver_id, campaign_id, datetime, distance, gpx_file, points } =
    request;

  const formData = new FormData();
  if (gpx_file) formData.append("gpx_file", gpx_file);
  formData.append("datetime", datetime);
  formData.append("driver_id", `${driver_id}`);
  formData.append("campaign_id", `${campaign_id}`);
  formData.append("distance", `${distance}`);
  formData.append("points", `${points}`);

  const response: AxiosResponse = await postRequestWithFormData(
    `${apiVersion}/journeys/add-system`,
    accessToken,
    formData
  );
  return response.data.success;
};

export const updateExistingJourney = async (
  request: NewJourney,
  accessToken: string,
  journey_id: number
): Promise<boolean> => {
  const { driver_id, campaign_id, datetime, distance, gpx_file, points } =
    request;

  const formData = new FormData();
  if (gpx_file) formData.append("gpx_file", gpx_file);
  formData.append("datetime", datetime);
  formData.append("driver_id", `${driver_id}`);
  formData.append("campaign_id", `${campaign_id}`);
  formData.append("distance", `${distance}`);
  formData.append("points", `${points}`);

  const response: AxiosResponse = await postRequestWithFormData(
    `${apiVersion}/journeys/${journey_id}`,
    accessToken,
    formData
  );
  return response.data.success;
};

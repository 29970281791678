import LoadingComponent from "components/LoadingComponent";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  filterDriversThunk,
  getDriverStatusThunk
} from "redux/slices/driverSlice";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import CarefreeHomeOwnerModal from "./HomeOwnerModal";
import {
  editHomeOwnersThunk,
  getAllHomeOwnersThunk,
  getAllPendingHomeOwnersThunk,
  getHomeOwners
} from "redux/slices/carefreeSlice";
import { CareFreeHomeOwner } from "types/Carefree";
import { Tab, Tabs } from "@mui/material";
import HomeOwnerTable from "./table";

const header = [
  { id: "id", name: "Driver Id" },
  { id: "homeUrl", name: "Home Url" },
  { id: "fullName", name: "Full Name" },
  { id: "icNumber", name: "IC Number" },
  { id: "status", name: "Status" },
  { id: "action", name: "Action" }
];
const CarefreeHomeOwnerPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const homeOwners = useSelector(getHomeOwners);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedHomeOwner, setSelectedHomeOwner] =
    useState<CareFreeHomeOwner>();
  const [activeTab, setActiveTab] = React.useState(0);

  useEffect(() => {
    if (!!accessToken && !!homeOwners && !homeOwners.length) {
      getAllHomeOwnersTableList();
      dispatch(getDriverStatusThunk(accessToken));
    }
  }, [accessToken]);

  const getAllHomeOwnersTableList = () => {
    if (!!accessToken) dispatch(getAllHomeOwnersThunk(accessToken));
  };
  const handleFilter = (header: string, value: string) => {
    if (accessToken) {
      !!value
        ? dispatch(filterDriversThunk({ header, value, accessToken }))
        : getAllHomeOwnersTableList();
    }
  };

  const updateHomeOwner = (homeOwner: CareFreeHomeOwner) => {
    if (!!accessToken)
      dispatch(editHomeOwnersThunk({ accessToken, editRequest: homeOwner }));
    setModalOpen(false);
    getAllHomeOwnersTableList();
  };

  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
    newValue === 0
      ? getAllHomeOwnersTableList()
      : dispatch(getAllPendingHomeOwnersThunk(accessToken));
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Tabs
        value={activeTab}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="All" />
        <Tab label="Pending" value={1} />
      </Tabs>

      {!!homeOwners && homeOwners.length >= 0 ? (
        <HomeOwnerTable
          header={header}
          rows={homeOwners}
          onPageChanged={(newPage: number) => {}}
          handleUserSearch={(searchType: string, searchInput: string) =>
            handleFilter(searchType, searchInput)
          }
          onReload={() => getAllHomeOwnersTableList()}
          addNewOrEditDriver={(
            isEdit: boolean,
            selectedHomeOwner?: CareFreeHomeOwner
          ) => {
            setSelectedHomeOwner(selectedHomeOwner);
            setIsEdit(isEdit);
            setModalOpen(!isModalOpen);
          }}
        />
      ) : (
        <LoadingComponent />
      )}

      {isModalOpen && (
        <CarefreeHomeOwnerModal
          open={isModalOpen}
          isEdit={isEdit}
          handleClose={() => setModalOpen(false)}
          addEditUser={() => {}}
          addEditHomeOwner={updateHomeOwner}
          selectedHomeOwner={selectedHomeOwner}
        />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(CarefreeHomeOwnerPage);

export enum TransactionActionsEnum {
  Approve = "Approve",
  Reject = "Reject",
  Transferred = "Transferr",
  ApproveByRange = "Approve by Range",
}

export enum TransactionStatusIdEnum {
  Processing = 24,
  Approve = 25,
  Reject = 26,
  Transferred = 27,
}

export enum LedgerEnum {
  MAIN_LEDGER = "Main Ledger",
  FLOAT_LEDGER = "Float Ledger",
}

import React, { useEffect } from "react";
// react-router-dom components
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import brandWhite from "assets/images/logo.png";
import MDPhoneIput from "components/MDInput/MDPhoneInput";
import { Formik, Form as FormikForm } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { validateForm } from "./validateForm";
import { NewUser } from "types/Authentication";
import {
  getIsAuthenticatedStatus,
  signUpNewAccount,
  getLoadingStatus,
} from "redux/slices/authenticationSlice";
import LoadingComponent from "components/LoadingComponent";

const SignUpComponent: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(getIsAuthenticatedStatus);
  const isLoading = useSelector(getLoadingStatus);

  const initialValues = {
    fname: "",
    email: "",
    password: "",
    countryCode: "",
    phoneNumber: "",
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const onSubmit = (values) => {
    const { fname, email, password, countryCode, phoneNumber } = values;
    if (!!fname && !!email && !!password && !!countryCode && !!phoneNumber) {
      const request: NewUser = {
        fname,
        lname: "",
        email,
        password,
        password_confirmation: password,
        country_code: countryCode,
        phone: phoneNumber,
        role_id: 4,
      };
      dispatch(signUpNewAccount(request));
    }
  };

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>

        <MDBox
          component="img"
          src={brandWhite}
          alt="Brand"
          width="5rem"
          height="5rem"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
          margin="auto"
        />
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={initialValues}
            validate={validateForm}
            onSubmit={onSubmit}
          >
            {({ values, errors, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Company Name"
                    name="fname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fname}
                    fullWidth
                    error={!!errors.fname}
                    helperText={errors.fname}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    helperText={errors.email}
                    fullWidth
                    error={!!errors.email}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={!!errors.password}
                    helperText={errors.password}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDPhoneIput
                    error={!!errors.phoneNumber}
                    onChangeCountryCode={(value) => {
                      setFieldValue("countryCode", value);
                    }}
                    onChangePhoneNumber={(value) => {
                      setFieldValue("phoneNumber", value);
                    }}
                  />
                </MDBox>
                <MDBox display="flex" alignItems="center" ml={-1}>
                  <Checkbox
                    name="isChecked"
                    checked={true}
                    color="primary"
                    disabled
                  />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;I agree the&nbsp;
                  </MDTypography>
                  <MDTypography
                    component="a"
                    href="#"
                    variant="button"
                    fontWeight="bold"
                    color="primary"
                    textGradient
                  >
                    Terms and Conditions
                  </MDTypography>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    sign up
                  </MDButton>
                </MDBox>
              </FormikForm>
            )}
          </Formik>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Already have an account?{" "}
              <MDTypography
                component={Link}
                to="/sign-in"
                variant="button"
                color="primary"
                fontWeight="medium"
                textGradient
              >
                Sign In
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
        {isLoading && <LoadingComponent />}
      </Card>
    </BasicLayout>
  );
};

export default SignUpComponent;

import { AxiosResponse } from "axios";
import { getRequest } from "services/client/request";
import { Bank } from "types/AllUsers";
import { AxiosGenericResponse, State, Status } from "types/common";

const apiVersion = "/api";

export const getStatuses = async (
  accessToken: string,
  model: string
): Promise<Status[]> => {
  const response: AxiosResponse<AxiosGenericResponse<Status[]>> =
    await getRequest(`${apiVersion}/statuses?model=${model}`, accessToken);
  const statuses: Status[] = response.data.success
    ? response.data.data
    : ([] as Status[]);
  return statuses;
};

export const getCountryStates = async (
  accessToken: string
): Promise<State[]> => {
  const response: AxiosResponse<AxiosGenericResponse<State[]>> =
    await getRequest(`${apiVersion}/states`, accessToken);
  const states: State[] = response.data.success
    ? response.data.data
    : ([] as State[]);
  return states;
};

export const getBanksList = async (accessToken: string): Promise<Bank[]> => {
  const response: AxiosResponse<AxiosGenericResponse<Bank[]>> =
    await getRequest(`${apiVersion}/banks/all`, accessToken);
  const states: Bank[] = response.data.success
    ? response.data.data
    : ([] as Bank[]);
  return states;
};

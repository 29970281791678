import React, { useEffect, useState } from "react";
import "./style.scss";
import MDBox from "components/MDBox";
import { Campaign } from "types/Campaign";
import DesignForm from "./form";
import Paper from "@mui/material/Paper";
import { PhoneSize } from "types/enum/Common";
import { getFontColor } from "./helper";
import AccordionDefault from "features/Accordion";

type Props = {
  isEdit: boolean;
  campaign: Campaign;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
};

const CampaignDesign: React.FC<Props> = (props) => {
  const { campaign, handleChange, handleBlur, setFieldValue, isEdit } = props;

  const [phoneStyle, setPhoneStyle] = useState({
    phoneWrapper: {},
    ptsPerMo: {},
  });
  useEffect(() => {
    setPhoneStyle({
      ...phoneStyle,
      phoneWrapper: {
        backgroundColor: isEdit ? `#${campaign.color_code}` : `#FFFFFF`,
        color: isEdit ? `#${campaign.font_color_code}` : `#000000`,
      },
      ptsPerMo: {
        backgroundColor: isEdit ? `#${campaign.color_code}` : `#FFFFFF`,
        color: isEdit ? `#${campaign.font_color_code}` : `#000000`,
      },
    });
  }, []);
  useEffect(() => {
    autoSetFontColor();
  }, [campaign.color_code]);

  const autoSetFontColor = () => {
    if (campaign.color_code) {
      setPhoneStyle({
        ...phoneStyle,
        phoneWrapper: {
          backgroundColor: `#${campaign.color_code}`,
          color: getFontColor(`#${campaign.color_code}`),
        },
        ptsPerMo: {
          backgroundColor: getFontColor(`#${campaign.color_code}`),
          color: `#${campaign.color_code}`,
        },
      });
    }
  };

  return (
    <AccordionDefault title={"Campaign Design"} panel="" onChange={() => {}}>
      <Paper sx={{ width: "100%", display: "flex" }}>
        <MDBox width={"50%"}>
          <DesignForm
            values={campaign}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </MDBox>
        <MDBox width={"50%"}>
          <MDBox className="phone-emulator">
            <MDBox
              className={`phone ${
                campaign.phonePreview || PhoneSize.IPHONE_10
              }`}
              style={phoneStyle.phoneWrapper}
            >
              <p className="ending-in">Ending in 23 hours 13 mins</p>
              <MDBox
                className="phone-logo"
                style={{
                  backgroundImage: `url(${campaign.logo_url})`,
                }}
              >
                {!campaign.logo_url && (
                  <MDBox className="place-holder-background">
                    <p>Logo</p>
                  </MDBox>
                )}
              </MDBox>
              <MDBox
                className="phone-banner"
                style={{
                  ...phoneStyle.phoneWrapper,
                  backgroundImage: `url(${campaign.banner_url})`,
                }}
              >
                <div className="phone-data">
                  <MDBox className="title">
                    <p className="title-name">Coca-Cola</p>
                    <p>3 months</p>
                  </MDBox>
                  <MDBox className="waitlist">
                    <p>
                      <span className="large">0</span>/
                      {campaign.max_participants}
                    </p>
                    <p className="waiting">0 waiting</p>
                  </MDBox>
                </div>
                <MDBox className="start-date">
                  <p>Starts 7 Jan 22</p>
                </MDBox>
                <MDBox className="pts-per-mo" style={phoneStyle.ptsPerMo}>
                  <p>up to</p>
                  <p>3,000</p>
                  <p>pts/mth</p>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Paper>
    </AccordionDefault>
  );
};
export default CampaignDesign;

import { AxiosResponse } from "axios";
import { getRequest } from "services/client/request";
import { UserResponse, User } from "types/User";
import { postRequestWithoutToken } from "../client/request";
import { LoginRequest } from "types/User";
import { NewUser } from "types/Authentication";

const apiVersion = "/api";

export const login = async (
  loginCredentials: LoginRequest
): Promise<UserResponse> => {
  try {
    const response: AxiosResponse<UserResponse> = await postRequestWithoutToken(
      `${apiVersion}/login?from=web`,
      loginCredentials
    );
    const userResponse: UserResponse = response.data
      ? response.data
      : ({} as UserResponse);
    return userResponse;
  } catch (e) {
    console.error(e);
    return {} as UserResponse;
  }
};

export const getUserDetail = async (
  accessToken: string
): Promise<User | undefined> => {
  try {
    const response: AxiosResponse<User> = await getRequest(
      `${apiVersion}/user`,
      accessToken
    );
    const userDetails: User | undefined = response.data
      ? response.data
      : undefined;
    return userDetails;
  } catch (e) {
    console.error(e);
    return;
  }
};

export const signUp = async (signUpRequest: NewUser): Promise<UserResponse> => {
  const response: AxiosResponse<UserResponse> = await postRequestWithoutToken(
    `${apiVersion}/register`,
    signUpRequest
  );
  const userResponse: UserResponse = response.data
    ? response.data
    : ({} as UserResponse);
  return userResponse;
};

export const forgetPassword = async (email: string): Promise<void> => {
  await postRequestWithoutToken(`${apiVersion}/forgot`, email);
};

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "components/MDTable/tableToolbar";
import TableHeader from "components/MDTable/tableHeader";
import { Order } from "components/MDTable/helper";
import { ITableHeader } from "types/Table";
import TablePaginationActions from "components/MDTable/tablePagination";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton } from "@mui/material";
import { PaginationCommonObject } from "types/common";
import { Partner } from "types/Partner";
import { BooleaEnum, StatusEnum } from "types/enum/Common";
import SelectInput from "components/MDInput/SelectInput";
import { getLoadingStatus } from "redux/slices/partnerSlice";

type TableType = {
  header: ITableHeader[];
  rows: PaginationCommonObject<Partner>;
  onPageChanged: (newPage: number) => void;
  handlePartnerSearch: (searchInput: string) => void;
  onReload: () => void;
  addNewOrEditPartner: (isEdit: boolean, selectedPartner?: Partner) => void;
};
const PartnerTable: React.FC<TableType> = (props) => {
  const {
    header,
    rows,
    onReload,
    handlePartnerSearch,
    addNewOrEditPartner,
    onPageChanged,
  } = props;
  const { data } = rows;
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const isLoading = useSelector(getLoadingStatus);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onPageChanged(newPage);
    setPage(newPage);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          numSelected={0}
          tableName={"Partners"}
          onReload={onReload}
        />
        <MDBox
          display={"inline-flex"}
          justifyContent={"space-between"}
          width={"100%"}
          padding={"10px"}
        >
          <MDBox>
            <SelectInput
              title="Select Status"
              items={[
                { id: "32", name: StatusEnum.ENABLED },
                { id: "33", name: StatusEnum.DISABLED },
              ]}
              onSelected={(searchValue) => handlePartnerSearch(searchValue)}
              width={"60ch"}
            />
          </MDBox>
          <MDBox display={"flex"} justifyContent={"end"}>
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => addNewOrEditPartner(false)}
              style={{ marginRight: 8 }}
            >
              Create Partner
            </MDButton>
          </MDBox>
        </MDBox>

        {isLoading ? (
          <MDBox display={"flex"} justifyContent={"center"} mt={3}>
            <CircularProgress color={"primary"} />
          </MDBox>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeader
                numSelected={0}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={() => {}}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                headCells={header}
                disableCheckbox={true}
              />
              <TableBody>
                {data.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell align="right">{row.id}</TableCell>
                      <TableCell align="right">{row.title}</TableCell>
                      <TableCell align="right">{row.desc}</TableCell>
                      <TableCell align="right">
                        {row.is_webview ? BooleaEnum.YES : BooleaEnum.No}
                      </TableCell>
                      <TableCell align="right">
                        {row.status_id === 32
                          ? StatusEnum.ENABLED
                          : StatusEnum.DISABLED}
                      </TableCell>
                      <TableCell align="right">{`${new Date(
                        row.created_at
                      ).toLocaleDateString()}`}</TableCell>

                      <TableCell align="right">
                        <IconButton
                          onClick={(e) => {
                            //Prevent whole  row is being selected
                            e.stopPropagation();
                            e.preventDefault();
                            addNewOrEditPartner(true, row);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={rows.total}
          rowsPerPage={rows.per_page}
          page={rows.total <= 0 ? 0 : page}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </Box>
  );
};
export default PartnerTable;

import React from "react";
import LoadingComponent from "components/LoadingComponent";
import MDBox from "components/MDBox";
import { useSelector } from "react-redux";
import { getCampaignSummaryReducer } from "redux/slices/campaignSlice";
import { Grid } from "@mui/material";
import ComplexStatisticsCard from "features/Cards/StatisticsCards";
import ProgressBarCard from "features/Cards/ProgressBarCard";

const CampaignSummaryTab: React.FC = () => {
  const summary = useSelector(getCampaignSummaryReducer);

  return (
    <MDBox p={2}>
      {!!summary ? (
        <MDBox>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title="Campaign Status"
                    count={summary.campaign_status}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title="Start Date"
                    count={summary.start_date}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title="End Date"
                    count={summary.end_date}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title="Current Month"
                    count={summary.current_month}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ProgressBarCard
                    title="Participant Completion"
                    completed={Number(
                      (
                        (summary.number_of_participants /
                          summary.target_participants) *
                        100
                      ).toFixed(2)
                    )}
                    subLabel={"Number of particiapnt"}
                    subLabelValue={`${summary.number_of_participants}/${summary.target_participants}`}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ProgressBarCard
                    title="Installation Booked"
                    completed={Number(
                      (
                        (summary.installation_appointment /
                          summary.target_participants) *
                        100
                      ).toFixed(2)
                    )}
                    subLabel={"Number of installation appoinment"}
                    subLabelValue={`${summary.installation_appointment}/${summary.target_participants}`}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ProgressBarCard
                    title="Installation Done"
                    completed={Number(
                      (
                        (summary.completed_installation_appointment /
                          summary.target_participants) *
                        100
                      ).toFixed(2)
                    )}
                    subLabel={"Number of installation appoinment done"}
                    subLabelValue={`${summary.completed_installation_appointment}/${summary.target_participants}`}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ProgressBarCard
                    title="Total (Actual) Distance Completed"
                    completed={Number(
                      (
                        (summary.sum_of_actual_distance_total /
                          summary.target_distance_total) *
                        100
                      ).toFixed(2)
                    )}
                    subLabel={"Number of total distance completed"}
                    subLabelValue={`${summary.sum_of_actual_distance_total}/${summary.target_distance_total}`}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ProgressBarCard
                    title="Total (Points) Distance Completed"
                    completed={Number(
                      (
                        (summary.sum_of_distance_total /
                          summary.target_distance_total) *
                        100
                      ).toFixed(2)
                    )}
                    subLabel={"Number of total distance completed"}
                    subLabelValue={`${summary.sum_of_distance_total}/${summary.target_distance_total}`}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ProgressBarCard
                    title="Monthly (Actual) Distance Completed"
                    completed={Number(
                      (
                        (summary.sum_of_actual_distance_monthly /
                          summary.target_distance_monthly) *
                        100
                      ).toFixed(2)
                    )}
                    subLabel={"Number of monthly distance completed"}
                    subLabelValue={`${summary.sum_of_actual_distance_monthly}/${summary.target_distance_monthly}`}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ProgressBarCard
                    title="Monthly (Points) Distance Completed"
                    completed={Number(
                      (
                        (summary.sum_of_distance_monthly /
                          summary.target_distance_monthly) *
                        100
                      ).toFixed(2)
                    )}
                    subLabel={"Number of monthly distance completed"}
                    subLabelValue={`${summary.sum_of_distance_monthly}/${summary.target_distance_monthly}`}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      ) : (
        <LoadingComponent />
      )}
    </MDBox>
  );
};
export default CampaignSummaryTab;

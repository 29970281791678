import React, { useEffect } from "react";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "@mui/material";
import {
  getAllTransactionsThunk,
  getTransactions,
} from "redux/slices/transactionSlice";
import LoadingComponent from "components/LoadingComponent";
import TransactionTable from "./table";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import { TransactionStatusIdEnum } from "types/enum/transactions";

const header = [
  { id: "id", name: "Transaction Id" },
  { id: "date", name: "Date" },
  { id: "points", name: "Points" },
  { id: "username", name: "Username" },
  { id: "campaign", name: "Campaign" },
  { id: "status", name: "Status" },
  { id: "ledger_type", name: "Ledger Type" },
  { id: "action", name: "Action" },
];

const TransactionPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const transactions = useSelector(getTransactions);
  const [activeTab, setActiveTab] = React.useState(0);

  useEffect(() => {
    if (!!accessToken && !!transactions && !transactions.data) {
      getAllTransaction(1);
    }
  }, []);

  const getAllTransaction = (page: number, status_id?: number) => {
    if (!!accessToken) {
      dispatch(
        getAllTransactionsThunk({
          accessToken,
          page,
          status_id,
        })
      );
    }
  };
  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
    newValue === 0 ? getAllTransaction(1) : getAllTransaction(1, newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Tabs
        value={activeTab}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="All" />
        <Tab label="Processing" value={TransactionStatusIdEnum.Processing} />
        <Tab label="Approved" value={TransactionStatusIdEnum.Approve} />
        <Tab label="Rejected" value={TransactionStatusIdEnum.Reject} />
        <Tab label="Transferred" value={TransactionStatusIdEnum.Transferred} />
      </Tabs>
      {!!transactions && transactions.data ? (
        <TransactionTable
          header={header}
          rows={transactions}
          onPageChanged={(newPage: number) => getAllTransaction(newPage + 1)}
          downloadIntoCsv={() => {}}
          handleUserSearch={() => getAllTransaction(1)}
          onReload={(statusId?: number) => getAllTransaction(1, statusId)}
          addNewOrEditUser={() => {}}
        />
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(TransactionPage);

import React, { useEffect } from "react";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import {
  getDriverCarBrand,
  getDriverCarColor,
  getDriverCarModel,
  getDriverCarsOptionsThunk,
} from "redux/slices/driverSlice";
import { CareFreeHomeOwner } from "types/Carefree";

type Props = {
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  values: CareFreeHomeOwner;
};

const HomeOwnerCarDetails: React.FC<Props> = (props) => {
  const { values, handleChange, handleBlur, setFieldValue } = props;
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();

  const carBrands = useSelector(getDriverCarBrand);
  const carColors = useSelector(getDriverCarColor);
  const carModels = useSelector(getDriverCarModel);

  useEffect(() => {
    if (!!accessToken) dispatch(getDriverCarsOptionsThunk(accessToken));
  }, [accessToken, dispatch]);

  return (
    <div>
      <MDBox mb={2} display={"flex"}>
        <MDBox width={"50%"} mr={5}>
          <MDInput
            type="text"
            label="Unit No"
            name="unit_no"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.unit_no}
            fullWidth
          />
        </MDBox>
        <MDBox width={"50%"}>
          <MDInput
            type="text"
            label="Address"
            name="address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address}
            fullWidth
          />
        </MDBox>
      </MDBox>
      <MDBox mb={2} display={"flex"}>
        <MDBox width={"50%"} mr={5}>
          <MDInput
            type="text"
            label="Postal Code"
            name="postal_code"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.postal_code}
            fullWidth
          />
        </MDBox>
        <MDBox width={"50%"}>
          <MDInput
            type="text"
            label="District"
            name="district"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.district}
            fullWidth
          />
        </MDBox>
      </MDBox>
      <MDBox mb={2} ml={1} display={"flex"}>
        <MDBox width={"50%"} mt={1} mr={1.7}>
          <MDInput
            type="text"
            label="Latitude"
            name="latitude"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.latitude}
            fullWidth
          />
        </MDBox>
        <MDBox width={"50%"}>
          <MDInput
            type="text"
            label="Longitdue"
            name="longitude"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.longitude}
            fullWidth
          />
        </MDBox>
      </MDBox>
    </div>
  );
};
export default HomeOwnerCarDetails;

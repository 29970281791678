import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import {
  MonthlySubmission,
  NewMonthlySubmission,
} from "types/MonthlySubmission";
import ImageUpload from "components/ImageUpload";

const initialValues = {
  id: 0,
  car_front_url: "",
  car_left_url: "",
  car_right_url: "",
  car_back_url: "",
  odometer_url: "",
};
type AddEditUserModalProp = {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  selectedRow?: MonthlySubmission;
  addEditMonthlySubmission: (updatedUser: NewMonthlySubmission) => void;
};
const AddEditMonthlySubmissionModal: React.FC<AddEditUserModalProp> = (
  props
) => {
  const { open, handleClose, isEdit, selectedRow, addEditMonthlySubmission } =
    props;
  const formikRef = useRef<any>(null);

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = (values) => {
    if (
      values.id === 0 ||
      !values.car_front_url ||
      !values.car_left_url ||
      !values.car_right_url ||
      !values.car_back_url ||
      !values.odometer_url
    ) {
      alert("All Fiedls are required");
    } else {
      const request: NewMonthlySubmission = {
        driver_id: values.id,
        car_front: values.car_front_url,
        car_back: values.car_back_url,
        car_left: values.car_left_url,
        car_right: values.car_right_url,
        odometer: values.odometer_url,
      };

      addEditMonthlySubmission(request);
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`${isEdit ? "Edit" : "New"} Monthly Submissions`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Formik
            initialValues={
              isEdit && !!selectedRow ? selectedRow : initialValues
            }
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, errors, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <MDBox mb={5} mt={5} width={"60%"}>
                  <MDInput
                    fullWidth
                    type="number"
                    label="Driver Id"
                    disabled={isEdit}
                    name="id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.id}
                    error={!!errors.id}
                    helperText={errors.id}
                  />
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Car Front"
                      selectedImage={values.car_front_url}
                      handleImageUpload={(image) =>
                        setFieldValue("car_front_url", image)
                      }
                    />
                  </MDBox>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Car Left"
                      selectedImage={values.car_left_url}
                      handleImageUpload={(image) =>
                        setFieldValue("car_left_url", image)
                      }
                    />
                  </MDBox>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Car Right"
                      selectedImage={values.car_right_url}
                      handleImageUpload={(image) =>
                        setFieldValue("car_right_url", image)
                      }
                    />
                  </MDBox>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Car Back"
                      selectedImage={values.car_back_url}
                      handleImageUpload={(image) =>
                        setFieldValue("car_back_url", image)
                      }
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Odometer"
                      selectedImage={values.odometer_url}
                      handleImageUpload={(image) =>
                        setFieldValue("odometer_url", image)
                      }
                    />
                  </MDBox>
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {`${isEdit ? "Edit" : "Create"}`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddEditMonthlySubmissionModal;

import { AxiosResponse } from "axios";
import { getRequest, postRequest, putRequest } from "services/client/request";
import { AxiosGenericResponse, PaginationCommonObject } from "types/common";
import { NewPartner, Partner } from "types/Partner";

const apiVersion = "/api";

export const getAllPartners = async (
  accessToken: string,
  page: number,
  name?: string
): Promise<PaginationCommonObject<Partner>> => {
  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<Partner>>
  > = await getRequest(
    `${apiVersion}/partners?page=${page}&status_id=${name}`,
    accessToken
  );
  const brands: PaginationCommonObject<Partner> = response.data.success
    ? response.data.data
    : ({} as PaginationCommonObject<Partner>);
  return brands;
};

export const createPartners = async (
  request: NewPartner,
  accessToken: string
): Promise<void> => {
  await postRequest(`${apiVersion}/partners`, accessToken, request);
};

export const updatePartners = async (
  request: NewPartner,
  accessToken: string
): Promise<void> => {
  await putRequest(
    `${apiVersion}/partners/${request.id}`,
    accessToken,
    request
  );
};

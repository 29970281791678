import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "components/MDTable/tableToolbar";
import TableHeader from "components/MDTable/tableHeader";
import { Order } from "components/MDTable/helper";
import { ITableHeader } from "types/Table";
import TablePaginationActions from "components/MDTable/tablePagination";
import MDBox from "components/MDBox";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { getLoadingStatus } from "redux/slices/driverSlice";
import { Report } from "types/Report";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import ProgressBar from "components/ProgressBar";
import TrafficIcon from "@mui/icons-material/Traffic";
import AddRoad from "@mui/icons-material/AddRoad";
import CalendarMonthIcon from "@mui/icons-material/Schedule";
import RouteIcon from "@mui/icons-material/Route";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from "@mui/material";

type TableType = {
  header: ITableHeader[];
  rows: Report[];
  onReload: () => void;
};

const BookingTable: React.FC<TableType> = (props) => {
  const { header, rows, onReload } = props;
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState<number>(0);
  const isLoading = useSelector(getLoadingStatus);
  const navigate = useNavigate();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          numSelected={selected.length}
          tableName={"Report"}
          onReload={onReload}
        />
        <MDBox display={"flex"} justifyContent={"end"}>
          <MDButton
            variant="gradient"
            color="secondary"
            onClick={() => navigate("/report/new")}
            style={{ marginRight: 8 }}
          >
            Generate Report
          </MDButton>
        </MDBox>
        <MDButton variant="outline">
          Road Type: &nbsp;
          <AddRoad fontSize="medium" />
        </MDButton>
        <MDButton variant="outline">
          Traffic Condition: &nbsp;
          <TrafficIcon fontSize="medium" />
        </MDButton>
        <MDButton variant="outline">
          Journeys Days: &nbsp;
          <RouteIcon fontSize="medium" />
        </MDButton>
        <MDButton variant="outline">
          Week Difference: &nbsp;
          <CalendarMonthIcon fontSize="medium" />
        </MDButton>

        {isLoading ? (
          <MDBox display={"flex"} justifyContent={"center"} mt={3}>
            <CircularProgress color={"primary"} />
          </MDBox>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeader
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={() => {}}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={header}
                disableCheckbox={true}
              />
              <TableBody>
                {rows.slice(page * 20, page * 20 + 20).map((row, index) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell align="right">{row.id}</TableCell>
                      <TableCell align="right">{row.title}</TableCell>
                      <TableCell align="right">{`${row.campaign_id}`}</TableCell>
                      <TableCell align="right">
                        <ProgressBar value={row.progress} />
                      </TableCell>

                      <TableCell>
                        {row.flags && (
                          <>
                            <Tooltip title="Road Type">
                              <AddRoad
                                fontSize="medium"
                                style={{
                                  color:
                                    row.flags["road_type"] === 1 ? "red" : ""
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Traffic Condition">
                              <TrafficIcon
                                fontSize="medium"
                                style={{
                                  color:
                                    row.flags["traffic_condition"] === 1
                                      ? "red"
                                      : ""
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Journey Days">
                              <RouteIcon
                                fontSize="medium"
                                style={{
                                  color:
                                    row.flags["journeys_days"] === 1
                                      ? "red"
                                      : ""
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Week difference">
                              <CalendarMonthIcon
                                fontSize="medium"
                                style={{
                                  color:
                                    row.flags["week_difference"] === 1
                                      ? "red"
                                      : ""
                                }}
                              />
                            </Tooltip>
                          </>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {row.status ? row.status.name : row.status_id}
                      </TableCell>
                      <TableCell align="right">
                        <a href={row.url} target="_blank">
                          {`Click to download`}
                        </a>
                      </TableCell>
                      <TableCell align="right">
                        {`${new Date(row.created_at).toLocaleDateString()}`}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={rows.length}
          rowsPerPage={20}
          page={rows.length <= 0 ? 0 : page}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </Box>
  );
};
export default BookingTable;

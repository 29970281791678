import { IconButton, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import SelectInput from "components/MDInput/SelectInput";
import React, { useEffect, useState } from "react";
import { InstallationEnum } from "types/enum/Common";
import DeleteIcon from "@mui/icons-material/Delete";
import { CampaignWorkshops, WorkshopSchedules } from "types/Campaign";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Workshop } from "types/Workshop";

type Props = {
  schedules?: WorkshopSchedules[];
  onChangeWorkshops: (updatedWorkshop: CampaignWorkshops[]) => void;
  workshopIndex: number;
  workshops: CampaignWorkshops[];
  currentWorkshop: Workshop;
};
const WorkshopChild: React.FC<Props> = (props) => {
  const {
    schedules,
    workshopIndex,
    onChangeWorkshops,
    workshops,
    currentWorkshop,
  } = props;

  const [formValues, setFormValues] = useState<WorkshopSchedules[]>(
    schedules ?? []
  );
  useEffect(() => {
    if (!formValues || formValues.length === 0) {
      addFormFields();
    }
  }, []);

  const addFormFields = () => {
    const initialiseValue: WorkshopSchedules = {
      id: 0,
      campaign_workshop_id: 0,
      installation_date: new Date().toISOString().split("T")[0],
      closed_at: currentWorkshop.closed_at,
      type: InstallationEnum.INSTALLATION,
      opened_at: currentWorkshop.opened_at,
      time_gap: currentWorkshop.time_gap,
      appointment_per_slot: currentWorkshop.appointment_per_slot,
    };
    setFormValues([...formValues, initialiseValue]);
  };
  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };
  const handleOnChange = (index: number, e, field: string) => {
    const newFormValues = [...formValues];
    const updatedLocation = {
      ...newFormValues[index],
      [field]: field === "type" ? e : e.target.value,
    };
    newFormValues[index] = updatedLocation;
    setFormValues(newFormValues);
    const cloneCampaignWorkshop = JSON.parse(JSON.stringify(workshops));
    cloneCampaignWorkshop[workshopIndex].schedules = newFormValues;
    onChangeWorkshops(cloneCampaignWorkshop);
  };

  return (
    <MDBox>
      {formValues.map((schedule, index) => (
        <MDBox display="flex" width={"100%"} key={index}>
          <MDBox width={"20%"} ml={2} mt={1}>
            <TextField
              label={"Date"}
              type="date"
              name={"installation_date"}
              onChange={(e) => handleOnChange(index, e, "installation_date")}
              value={schedule ? schedule.installation_date : ""}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </MDBox>

          <MDBox width={"20%"} ml={2} mt={1}>
            <TextField
              label={"Open at"}
              type="time"
              name={"opened_at"}
              onChange={(e) => handleOnChange(index, e, "opened_at")}
              value={schedule ? schedule.opened_at : ""}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </MDBox>
          <MDBox width={"20%"} ml={2} mt={1}>
            <TextField
              label={"Close At"}
              type="time"
              name={"closed_at"}
              onChange={(e) => handleOnChange(index, e, "closed_at")}
              value={schedule ? schedule.closed_at : ""}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </MDBox>
          <MDBox width={"20%"} ml={2} mr={2}>
            <SelectInput
              title="Select Type"
              items={[
                { id: "20", name: InstallationEnum.INSTALLATION },
                { id: "21", name: InstallationEnum.REMOVAL },
              ]}
              defaultValue={
                schedule.type === InstallationEnum.INSTALLATION ? "20" : "21"
              }
              onSelected={(searchValue) => {
                handleOnChange(
                  index,
                  searchValue === "20"
                    ? InstallationEnum.INSTALLATION
                    : InstallationEnum.REMOVAL,
                  "type"
                );
              }}
              width={"100%"}
            />
          </MDBox>
          <MDBox width={"20%"} ml={1} mr={2} mt={1}>
            <TextField
              label={"Time Gap"}
              type="number"
              name={"time_gap"}
              onChange={(e) => handleOnChange(index, e, "time_gap")}
              value={schedule ? schedule.time_gap : ""}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </MDBox>
          <MDBox width={"20%"} mr={2} mt={1}>
            <TextField
              label={"Appointment Per Slot"}
              type="number"
              name={"appointment_per_slot"}
              onChange={(e) => handleOnChange(index, e, "appointment_per_slot")}
              value={schedule ? schedule.appointment_per_slot : ""}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </MDBox>
          <MDBox width={"10%"} mt={1} mr={1} display="inline-flex">
            {index ? (
              <IconButton onClick={() => removeFormFields(index)}>
                <DeleteIcon />
              </IconButton>
            ) : (
              <IconButton onClick={addFormFields}>
                <AddCircleIcon color={"primary"} />
              </IconButton>
            )}
          </MDBox>
        </MDBox>
      ))}
    </MDBox>
  );
};
export default WorkshopChild;

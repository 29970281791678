import LedgerComponent from "components/Ledgers";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllLedgersThunk, getLedgers } from "redux/slices/transactionSlice";
import WithAuthWrapper from "withWrapper/withAuthWrapper";

const LedgerPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const ledgers = useSelector(getLedgers);

  useEffect(() => {
    if (!!accessToken && !!ledgers) {
      getAllLedgers(0);
    }
  }, [accessToken]);

  const getAllLedgers = useCallback(
    (page: number, name?: string) => {
      if (!!accessToken) {
        dispatch(
          getAllLedgersThunk({
            accessToken,
            page,
            name,
          })
        );
      }
    },
    [dispatch, accessToken]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!!ledgers && !!ledgers.float_ledger && (
        <LedgerComponent
          rows={ledgers}
          onDateChange={(date: string) => getAllLedgers(0, date)}
        />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(LedgerPage);

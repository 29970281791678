import React, { useRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import { NewUser } from "types/Authentication";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import { Drivers } from "types/AllUsers";
import { Tab, Tabs } from "@mui/material";
import DriverOverview from "./overview";
import DriverAccount from "./driverAccount";
import MDBox from "components/MDBox";
import AdTechModal from "./adtech";
import { CarefreeDrivers } from "types/Carefree";

type AddEditUserModalProp = {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  selectedDriver?: CarefreeDrivers;
  addEditUser: (updatedUser: NewUser) => void;
  addEditDriver: (updatedDriver: CarefreeDrivers) => void;
};
const CarefreeDriverModal: React.FC<AddEditUserModalProp> = (props) => {
  const {
    open,
    handleClose,
    isEdit,
    selectedDriver,
    addEditUser,
    addEditDriver,
  } = props;
  const formikRef = useRef<any>(null);
  const [activeTab, setActiveTab] = useState<number>(1);

  const driver: CarefreeDrivers = {} as CarefreeDrivers;

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = (values) => {
    if (activeTab === 1) {
      addEditUser(values);
    } else if (activeTab === 2) {
      addEditDriver(values);
    }
  };
  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`${isEdit ? "Edit" : "New"} Driver`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
          >
            {/* <Tab label="Overview" value={0} /> */}
            <Tab label="Account" value={1} />
            <Tab label="AdTech" value={2} />
          </Tabs>

          <Formik
            initialValues={isEdit && !!selectedDriver ? selectedDriver : driver}
            // validate={(values) => {
            //   if (!isEdit) validateForm(values);
            // }}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <MDBox mt={3}>
                  {activeTab === 0 && <DriverOverview />}
                  {activeTab === 1 && selectedDriver && (
                    <DriverAccount
                      isEdit={isEdit}
                      selectedDriver={selectedDriver.driver}
                      addEditUser={onSubmit}
                    />
                  )}
                  {activeTab === 2 && selectedDriver && (
                    <AdTechModal
                      isEdit={isEdit}
                      selectedDriver={selectedDriver}
                      addEditUser={() => {}}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  )}
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          {activeTab === 2 && (
            <Button
              onClick={() => {
                //Trigger formik button as outside the form
                if (formikRef.current) {
                  formikRef.current.handleSubmit();
                }
              }}
            >
              {isEdit ? "Edit Driver" : "Add Driver"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CarefreeDriverModal;

import { AxiosResponse } from "axios";
import {
  getRequest,
  postRequestWithFormData,
  putRequest,
} from "services/client/request";
import {
  AddEditAppointmentSubmissions,
  AppointmentSubmissions,
  CampaignWorkshopAppointment,
} from "types/AppointmentsSubmission";
import { AxiosGenericResponse, PaginationCommonObject } from "types/common";

const apiVersion = "/api";

export const getAppointmentsByType = async (
  accessToken: string,
  page: number,
  type: string
): Promise<PaginationCommonObject<AppointmentSubmissions>> => {
  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<AppointmentSubmissions>>
  > = await getRequest(
    `${apiVersion}/appointments?type=${type}&page=${page}`,
    accessToken
  );
  const appointments: PaginationCommonObject<AppointmentSubmissions> = response
    .data.success
    ? response.data.data
    : ({} as PaginationCommonObject<AppointmentSubmissions>);
  return appointments;
};

export const createNewAppointmentSubmissions = async (
  request: AddEditAppointmentSubmissions,
  accessToken: string
): Promise<boolean> => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(request)) {
    if (value) {
      formData.append(key, value);
    }
  }
  const response: AxiosResponse = await postRequestWithFormData(
    `${apiVersion}/appointment_submissions`,
    accessToken,
    formData
  );
  return response.data.success;
};

export const updateAppointmentSubmissions = async (
  request: AddEditAppointmentSubmissions,
  accessToken: string
): Promise<boolean> => {
  const response: AxiosResponse = await putRequest(
    `${apiVersion}/appointment_submissions/${request.appointment_id}`,
    accessToken,
    request
  );
  return response.data.success;
};
export const getAppointmentsByCampaignId = async (
  accessToken: string,
  page: number,
  campaign_id: number
): Promise<PaginationCommonObject<CampaignWorkshopAppointment>> => {
  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<CampaignWorkshopAppointment>>
  > = await getRequest(
    `${apiVersion}/appointments?campaign_id=${campaign_id}&page=${page}`,
    accessToken
  );
  const appointments: PaginationCommonObject<CampaignWorkshopAppointment> =
    response.data.success
      ? response.data.data
      : ({} as PaginationCommonObject<CampaignWorkshopAppointment>);
  return appointments;
};
export const getAllAppointmentCsv = async (
  accessToken: string,
  type: string
): Promise<AppointmentSubmissions[]> => {
  var appointments: AppointmentSubmissions[] = [];

  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<AppointmentSubmissions>>
  > = await getRequest(
    `${apiVersion}/appointments?type=${type}&paginate=1&per_page=1000&page=1`,
    accessToken
  );
  const result = response.data.data;
  if (response.data.success && result.last_page > 1) {
    appointments = result.data;
    for (var i = 2; i < result.last_page; i++) {
      const responsePerpage: AxiosResponse<
        AxiosGenericResponse<PaginationCommonObject<AppointmentSubmissions>>
      > = await getRequest(
        `${apiVersion}/monthly_submissions?paginate=1&per_page=1000&page=${i}`,
        accessToken
      );
      appointments = appointments.concat(responsePerpage.data.data.data);
    }
  } else {
    appointments = result.data;
  }

  return appointments ?? [];
};

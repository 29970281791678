import ImageUpload from "components/ImageUpload";
import MDBox from "components/MDBox";
import React from "react";
import { Drivers } from "types/AllUsers";

type Props = {
  setFieldValue: (field: string, value: any) => void;
  values: Drivers;
};

const DriverImages: React.FC<Props> = (props) => {
  const { values, setFieldValue } = props;

  return (
    <MDBox>
      <MDBox>
        <MDBox mb={2} display={"flex"} width={"100%"}>
          <MDBox width={"20%"}>
            <ImageUpload
              title="Selfie"
              selectedImage={values.selfie_url}
              handleImageUpload={(image) => setFieldValue("selfie_url", image)}
            />
          </MDBox>
          <MDBox width={"20%"} mr={1}>
            <ImageUpload
              title="IC Front"
              selectedImage={values.ic_front_url}
              handleImageUpload={(image) =>
                setFieldValue("ic_front_url", image)
              }
            />
          </MDBox>
          <MDBox width={"20%"} mr={1}>
            <ImageUpload
              title="IC Back"
              selectedImage={values.ic_back_url}
              handleImageUpload={(image) => setFieldValue("ic_back_url", image)}
            />
          </MDBox>
          <MDBox width={"20%"} mr={1}>
            <ImageUpload
              title="Driver License Front"
              selectedImage={values.driver_license_front_url}
              handleImageUpload={(image) =>
                setFieldValue("driver_license_front_url", image)
              }
            />
          </MDBox>
          <MDBox width={"20%"} mr={1}>
            <ImageUpload
              title="Driver License Back"
              selectedImage={values.driver_license_back_url}
              handleImageUpload={(image) =>
                setFieldValue("driver_license_back_url", image)
              }
            />
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox display={"flex"}>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="Road Tax"
            selectedImage={values.road_tax_sticker_url}
            handleImageUpload={(image) =>
              setFieldValue("road_tax_sticker_url", image)
            }
          />
        </MDBox>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="Car Grant"
            selectedImage={values.car_grant_url}
            handleImageUpload={(image) => setFieldValue("car_grant_url", image)}
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default DriverImages;

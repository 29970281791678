import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "components/LoadingComponent";
import WithAuthWrapper from "withWrapper/withAuthWrapper";

import AddEditBannerModal from "./Modal";
import BannerTable from "./table";
import {
  createOrEditBannerThunk,
  getBanners,
  getBannersThunk,
} from "redux/slices/bannerSlice";
import { Banner, NewBanner } from "types/Banner";

const header = [
  { id: "id", name: "Banner Id" },
  { id: "title", name: "Title" },
  { id: "desc", name: "Desc" },
  { id: "link", name: "Link" },
  { id: "is_webview", name: "Is Webview" },
  { id: "is_hero", name: "Is Hero" },
  { id: "created_at", name: "Created At" },
  { id: "action", name: "Action" },
];

const BannerPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const banners = useSelector(getBanners);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedBanner, setSelectedBanner] = useState<Banner>();

  const getAllBanners = useCallback(
    (page: number, name?: string) => {
      if (!!accessToken) {
        dispatch(
          getBannersThunk({
            accessToken,
            page,
            name,
          })
        );
      }
    },
    [dispatch, accessToken]
  );

  useEffect(() => {
    if (!!accessToken && !!banners && !banners.data) {
      getAllBanners(1);
    }
  }, [accessToken, banners, getAllBanners]);

  const createOrEditBrand = (banner: NewBanner) => {
    if (!!accessToken)
      dispatch(
        createOrEditBannerThunk({
          accessToken,
          banner,
          isEdit,
        })
      );
    setModalOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddEditBannerModal
        open={isModalOpen}
        isEdit={isEdit}
        selectedBanner={selectedBanner}
        handleClose={() => setModalOpen(false)}
        addEditBanner={createOrEditBrand}
      />
      {!!banners && banners.data ? (
        <BannerTable
          header={header}
          rows={banners}
          onReload={() => getAllBanners(1)}
          addNewOrEditBanner={(isEdit: boolean, selectedBrand?: Banner) => {
            setSelectedBanner(selectedBrand);
            setIsEdit(isEdit);
            setModalOpen(!isModalOpen);
          }}
          onPageChanged={(newPage: number) => getAllBanners(newPage + 1)}
        />
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(BannerPage);

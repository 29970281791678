import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "types/User";
import { forgetPassword, getUserDetail } from "services/api/authenticationApi";
import { StoreState, AuthenticationState } from "../../types/state/StoreState";
import { login, signUp } from "../../services/api/authenticationApi";
import { LoginRequest } from "../../types/User";
import { NewUser } from "../../types/Authentication";

const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    isLoading: false,
    isAuthenticated: false,
    accessToken: "",
    user: {},
    loginFailed: false,
  } as AuthenticationState,
  reducers: {
    setUserDetails(state, action) {
      state.user = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setLoginFailed(state, action) {
      state.loginFailed = action.payload;
    },
  },
});

// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.authentication.isLoading;
export const getIsAuthenticatedStatus = (state: StoreState): boolean =>
  state.authentication.isAuthenticated;
export const getUserDetails = (state: StoreState): User =>
  state.authentication.user;
export const getAccessToken = (state: StoreState): string =>
  state.authentication.accessToken;
export const getLoginFailed = (state: StoreState): boolean =>
  state.authentication.loginFailed;

// Actions
export const validateAuthentication = createAsyncThunk(
  "authentication/validateAuth",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(authenticationSlice.actions.setIsLoading(true));
    const userDetails = await getUserDetail(accessToken);
    if (!!userDetails) {
      dispatch(authenticationSlice.actions.setIsAuthenticated(true));
      dispatch(authenticationSlice.actions.setUserDetails(userDetails));
    } else {
      dispatch(authenticationSlice.actions.setLoginFailed(true));
      dispatch(authenticationSlice.actions.setIsAuthenticated(false));
      dispatch(authenticationSlice.actions.setUserDetails(undefined));
    }
    dispatch(authenticationSlice.actions.setIsLoading(false));
  }
);

export const loginRequest = createAsyncThunk(
  "authentication/login",
  async (credentials: LoginRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(authenticationSlice.actions.setIsLoading(true));
    const loginResponse = await login(credentials);
    if (!!loginResponse.success) {
      const { token, user } = loginResponse;
      dispatch(authenticationSlice.actions.setAccessToken(token));
      dispatch(authenticationSlice.actions.setUserDetails(user));
      dispatch(authenticationSlice.actions.setIsAuthenticated(true));
      dispatch(authenticationSlice.actions.setLoginFailed(false));
      localStorage.setItem("carching-token", token);
    } else {
      alert("User not found or password mismatched.");
      dispatch(authenticationSlice.actions.setLoginFailed(true));
      dispatch(authenticationSlice.actions.setIsAuthenticated(false));
      dispatch(authenticationSlice.actions.setAccessToken(undefined));
    }
    dispatch(authenticationSlice.actions.setIsLoading(false));
  }
);

export const signUpNewAccount = createAsyncThunk(
  "authentication/signUp",
  async (credentials: NewUser, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(authenticationSlice.actions.setIsLoading(true));
    const loginResponse = await signUp(credentials);
    if (loginResponse.success) {
      const { token, user } = loginResponse;
      dispatch(authenticationSlice.actions.setAccessToken(token));
      dispatch(authenticationSlice.actions.setUserDetails(user));
      dispatch(authenticationSlice.actions.setIsAuthenticated(true));
      localStorage.setItem("carching-token", token);
    } else {
      dispatch(authenticationSlice.actions.setIsAuthenticated(false));
      dispatch(authenticationSlice.actions.setAccessToken(undefined));
    }
    dispatch(authenticationSlice.actions.setIsLoading(false));
  }
);

export const logout = createAsyncThunk(
  "authentication/logout",
  async (_, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(authenticationSlice.actions.setIsAuthenticated(false));
    dispatch(authenticationSlice.actions.setAccessToken(undefined));
    dispatch(authenticationSlice.actions.setUserDetails(undefined));
    localStorage.removeItem("carching-token");
  }
);

export const forgetAccountPassword = createAsyncThunk(
  "authentication/forgotPassword",
  async (email: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(authenticationSlice.actions.setIsLoading(true));
    await forgetPassword(email);
    dispatch(authenticationSlice.actions.setIsLoading(false));
  }
);

export default authenticationSlice.reducer;

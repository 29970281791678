import LoadingComponent from "components/LoadingComponent";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  filterDriversThunk,
  getDriverStatusThunk,
} from "redux/slices/driverSlice";
import DriversTable from "./table";
import WithAuthWrapper from "withWrapper/withAuthWrapper";

import { NewUser } from "types/Authentication";
import { createOrUpdateUser } from "redux/slices/allUsersSlice";
import CarefreeDriverModal from "./DriverModal";
import {
  editDriverThunk,
  getAllCarefreeDriversThunk,
  getAllPendingDriversThunk,
  getCarefreeDrivers,
} from "redux/slices/carefreeSlice";
import { CarefreeDrivers } from "types/Carefree";
import { Tab, Tabs } from "@mui/material";

const header = [
  { id: "id", name: "Driver Id" },
  { id: "carPlate", name: "Car Plate" },
  { id: "fullName", name: "Full Name" },
  { id: "ic", name: "IC Number" },
  { id: "status", name: "Status" },
  { id: "action", name: "Action" },
];
const CarefreeDriverPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const drivers = useSelector(getCarefreeDrivers);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedDriver, setSelectedDriver] = useState<CarefreeDrivers>();
  const [activeTab, setActiveTab] = React.useState(0);

  useEffect(() => {
    if (!!accessToken && !!drivers && !drivers.length) {
      getAllDriversTableList();
      dispatch(getDriverStatusThunk(accessToken));
    }
  }, [accessToken]);

  const getAllDriversTableList = () => {
    if (!!accessToken) dispatch(getAllCarefreeDriversThunk(accessToken));
  };
  const handleFilter = (header: string, value: string) => {
    if (accessToken) {
      !!value
        ? dispatch(filterDriversThunk({ header, value, accessToken }))
        : getAllDriversTableList();
    }
  };

  const updateUser = (user: NewUser) => {
    if (!!accessToken)
      dispatch(
        createOrUpdateUser({
          accessToken,
          isEdit,
          user,
        })
      );
    setModalOpen(false);
    getAllDriversTableList();
  };

  const updateDriver = (driver: CarefreeDrivers) => {
    if (!!accessToken)
      dispatch(editDriverThunk({ accessToken, editRequest: driver }));
    setModalOpen(false);
    getAllDriversTableList();
  };

  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
    newValue === 0
      ? getAllDriversTableList()
      : dispatch(getAllPendingDriversThunk(accessToken));
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Tabs
        value={activeTab}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="All" />
        <Tab label="Pending" value={1} />
      </Tabs>

      {!!drivers && drivers.length > 0 ? (
        <DriversTable
          header={header}
          rows={drivers}
          onPageChanged={(newPage: number) => getAllDriversTableList()}
          handleUserSearch={(searchType: string, searchInput: string) =>
            handleFilter(searchType, searchInput)
          }
          onReload={() => getAllDriversTableList()}
          addNewOrEditDriver={(
            isEdit: boolean,
            selectedDriver?: CarefreeDrivers
          ) => {
            setSelectedDriver(selectedDriver);
            setIsEdit(isEdit);
            setModalOpen(!isModalOpen);
          }}
        />
      ) : (
        <LoadingComponent />
      )}

      {isModalOpen && (
        <CarefreeDriverModal
          open={isModalOpen}
          isEdit={isEdit}
          handleClose={() => setModalOpen(false)}
          addEditUser={updateUser}
          addEditDriver={updateDriver}
          selectedDriver={selectedDriver}
        />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(CarefreeDriverPage);

import React, { useCallback, useState } from "react";
import LoadingComponent from "components/LoadingComponent";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCampaignAssets,
  getCampaignAssetsThunk,
} from "redux/slices/campaignSlice";
import CampaignAssetsTable from "./table";

const header = [
  { id: "id", name: "Asset Id" },
  { id: "front", name: "Front" },
  { id: "back", name: "Back" },
  { id: "left", name: "Left" },
  { id: "right", name: "Right" },
  { id: "type", name: "Type" },
  { id: "theme", name: "Theme" },
  { id: "action", name: "Action" },
];
type Props = {
  campaignId: number;
};
const CampaignAssetsTab: React.FC<Props> = (props) => {
  const { campaignId } = props;
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const campaignAssets = useSelector(getAllCampaignAssets);

  const getAllCampaignsAseets = useCallback(
    (page: number) => {
      if (!!accessToken) {
        dispatch(
          getCampaignAssetsThunk({
            accessToken,
            page,
            campaignId,
          })
        );
      }
    },
    [dispatch, accessToken]
  );

  return (
    <MDBox p={2}>
      {!!campaignAssets && campaignAssets.data ? (
        <CampaignAssetsTable
          header={header}
          rows={campaignAssets}
          onReload={() => getAllCampaignsAseets(1)}
          onPageChanged={(newPage: number) => getAllCampaignsAseets(newPage)}
          campaignId={campaignId}
        />
      ) : (
        <LoadingComponent />
      )}
    </MDBox>
  );
};
export default CampaignAssetsTab;

import React, { useEffect } from "react";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import { useLocation } from "react-router-dom";
import EditCampaign from "../editCampaign";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignAllDetails,
  getLoadingStatus,
  getZonesAndBrandsThunk,
} from "redux/slices/campaignSlice";
import CampaignDefaultSetting from "./campaignSettings";
import LoadingComponent from "components/LoadingComponent";

const CampaignDetails: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const route = useLocation().pathname.split("/").slice(1);
  const isEdit = route[route.length - 1] !== "create";
  const campaignId = parseInt(route[route.length - 1]);
  const isLoading = useSelector(getLoadingStatus);

  useEffect(() => {
    if (isEdit) {
      dispatch(getCampaignAllDetails({ campaignId, accessToken }));
    }
    dispatch(getZonesAndBrandsThunk(accessToken));
  }, [accessToken, dispatch, campaignId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          {isEdit ? (
            <EditCampaign campaignId={campaignId} />
          ) : (
            <CampaignDefaultSetting isEdit={isEdit} />
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default WithAuthWrapper(CampaignDetails);

import { Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getCampaignParticipants } from "redux/slices/campaignSlice";
import { CampaignParticipatns } from "types/Campaign";
import { CampaignParticipantsStatusEnum } from "types/enum/Common";
import CampaignParticipantsTable from "./table";

const header = [
  { id: "id", name: "Registration Id" },
  { id: "driver_name", name: "Driver Name" },
  { id: "car_plate", name: "Car Plate" },
  { id: "registered_at", name: "Registered at" },
  { id: "status", name: "Status" },
  { id: "action", name: "Action" },
];

type Props = {
  campaignId: number;
};
const CampaignParticipants: React.FC<Props> = (props) => {
  const { campaignId } = props;
  const [activeTab, setActiveTab] = useState<string>(
    CampaignParticipantsStatusEnum.ALL
  );
  const participants = useSelector(getCampaignParticipants);

  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const renderParticipantList = (
    currentTab: string
  ): CampaignParticipatns[] => {
    return currentTab === CampaignParticipantsStatusEnum.ALL
      ? participants
      : participants.filter(
          (participant) => participant.status.name === currentTab
        );
  };
  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
      >
        {Object.values(CampaignParticipantsStatusEnum).map((values) => (
          <Tab label={values} value={values} key={values} />
        ))}
      </Tabs>
      <MDBox pt={2}>
        <CampaignParticipantsTable
          header={header}
          campaignId={campaignId}
          rows={renderParticipantList(activeTab)}
        />
      </MDBox>
    </>
  );
};

export default CampaignParticipants;

import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "components/LoadingComponent";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import {
  createOrEditBrandThunk,
  getBrands,
  getBrandsThunk,
} from "redux/slices/brandSlice";
import BrandTable from "./table";
import { Brands, NewBrand } from "types/Brand";
import AddEditBrandModal from "./Modal";

const header = [
  { id: "id", name: "Brand Id" },
  { id: "brand_name", name: "Brand Name" },
  { id: "contact_name", name: "Contact Name" },
  { id: "contact_no", name: "Contact No" },
  { id: "campaigns", name: "Campaigns" },
  { id: "created_at", name: "Created At" },
  { id: "action", name: "Action" },
];

const BrandPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const brands = useSelector(getBrands);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedBrand, setSelectedBrand] = useState<Brands>();

  const getAllBrands = useCallback(
    (page: number, name?: string) => {
      if (!!accessToken) {
        dispatch(
          getBrandsThunk({
            accessToken,
            page,
            name,
          })
        );
      }
    },
    [dispatch, accessToken]
  );

  useEffect(() => {
    if (!!accessToken && !!brands && !brands.data) {
      getAllBrands(1);
    }
  }, [accessToken, brands, getAllBrands]);

  const createOrEditBrand = (brands: NewBrand) => {
    if (!!accessToken)
      dispatch(
        createOrEditBrandThunk({
          accessToken,
          brands,
          isEdit,
        })
      );
    setModalOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddEditBrandModal
        open={isModalOpen}
        isEdit={isEdit}
        selectedBrand={selectedBrand}
        handleClose={() => setModalOpen(false)}
        addEditBrand={createOrEditBrand}
      />
      {!!brands && brands.data ? (
        <BrandTable
          header={header}
          rows={brands}
          onReload={() => getAllBrands(1)}
          addNewOrEditBrand={(isEdit: boolean, selectedBrand?: Brands) => {
            setSelectedBrand(selectedBrand);
            setIsEdit(isEdit);
            setModalOpen(!isModalOpen);
          }}
          onPageChanged={(newPage: number) => getAllBrands(newPage)}
          handleBrandSearch={(name: string) => getAllBrands(1, name)}
        />
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(BrandPage);

import { AxiosResponse } from "axios";
import { getRequest, postRequest, putRequest } from "services/client/request";
import { Brands, NewBrand } from "types/Brand";
import { AxiosGenericResponse, PaginationCommonObject } from "types/common";

const apiVersion = "/api";

export const getAllBrandsWithoutPagination = async (
  accessToken: string,
  name?: string
): Promise<Brands[]> => {
  const response: AxiosResponse<AxiosGenericResponse<Brands[]>> =
    await getRequest(
      `${apiVersion}/brands?all=true&brand_name=${!!name ? name : ""}`,
      accessToken
    );
  return response.data.data;
};

export const getAllBrands = async (
  accessToken: string,
  page: number,
  name?: string
): Promise<PaginationCommonObject<Brands>> => {
  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<Brands>>
  > = await getRequest(
    `${apiVersion}/brands?page=${page}&brand_name=${!!name ? name : ""}`,
    accessToken
  );
  const brands: PaginationCommonObject<Brands> = response.data.success
    ? response.data.data
    : ({} as PaginationCommonObject<Brands>);
  return brands;
};

export const createBrands = async (
  request: NewBrand,
  accessToken: string
): Promise<void> => {
  await postRequest(`${apiVersion}/brands`, accessToken, request);
};

export const updateBrands = async (
  request: NewBrand,
  accessToken: string
): Promise<void> => {
  await putRequest(`${apiVersion}/brands/${request.id}`, accessToken, request);
};

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import LedgerComponent from "components/Ledgers";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserLedgersDetails,
  getUserLedgerThunk,
} from "redux/slices/transactionSlice";

type Props = {
  userId: number;
  open: boolean;
  handleClose: () => void;
};
const UserLedgerModal: React.FC<Props> = (props) => {
  const { userId, open, handleClose } = props;
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const userLedgers = useSelector(getUserLedgersDetails);

  useEffect(() => {
    if (!!accessToken) {
      getUserLedgers();
    }
  }, [accessToken]);

  const getUserLedgers = useCallback(
    (name?: string) => {
      if (!!accessToken) {
        dispatch(
          getUserLedgerThunk({
            accessToken,
            userId,
            date: name,
          })
        );
      }
    },
    [dispatch, accessToken]
  );
  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  return (
    <Dialog
      onClose={handleOnClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      disableEscapeKeyDown={true}
      onBackdropClick={(event) => {
        event.stopPropagation();
        return false;
      }}
    >
      <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
        {"User Ledger"}
      </ModalHeaderTitle>
      <DialogContent dividers>
        {!!userLedgers && !!userLedgers.float_ledger && (
          <LedgerComponent
            rows={userLedgers}
            onDateChange={(date: string) => getUserLedgers(date)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UserLedgerModal;

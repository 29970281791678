import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "@mui/material";
import LoadingComponent from "components/LoadingComponent";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import {
  getAppointmentSubmissions,
  getAppointmentSubmissionsThunk,
} from "redux/slices/appointmentSubmissionsSlice";
import { AppointmentsEnum } from "types/enum/Appointments";
import AppointmentSubmissionsTable from "./table";
import {
  AddEditAppointmentSubmissions,
  AppointmentSubmissions,
} from "types/AppointmentsSubmission";
import AddEditAppointmentSubmissionModal from "./AddEditModal";
import {
  createNewAppointmentSubmissions,
  getAllAppointmentCsv,
  updateAppointmentSubmissions,
} from "services/api/appointmentsApi";
import { CSVLink } from "react-csv";

const header = [
  { id: "id", name: "Appointment Id" },
  { id: "campaign_id", name: "Campaign Id" },
  { id: "campaign_title", name: "Campaign Title" },
  { id: "username", name: "Username" },
  { id: "userId", name: "User id" },
  { id: "pre_car_front_url", name: "Pre Front Car" },
  { id: "pre_car_back_url", name: "Pre Back Car" },
  { id: "pre_car_left_url", name: "Pre Left Car" },
  { id: "pre_car_right_url", name: "Pre Right Car" },
  { id: "odometer_url", name: "Odometer" },
  { id: "created_at", name: "Created at" },
  { id: "action", name: "Action" },
];

const AppointmentSubmissionsPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const appointmentSubmissions = useSelector(getAppointmentSubmissions);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<AppointmentSubmissions>();
  const [activeTab, setActiveTab] = React.useState<string>(
    AppointmentsEnum.Installation
  );
  const [csvData, setCsvData] = useState<any>([]);
  const csvButtonLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  useEffect(() => {
    if (
      !!accessToken &&
      !!appointmentSubmissions &&
      !appointmentSubmissions.data
    ) {
      getAllAppointmentSubmissions(1, AppointmentsEnum.Installation);
    }
  }, []);

  const getAllAppointmentSubmissions = (page: number, type: string) => {
    if (!!accessToken) {
      dispatch(
        getAppointmentSubmissionsThunk({
          accessToken,
          page,
          type,
        })
      );
    }
  };
  const handleChange = (_, type: string) => {
    setActiveTab(type);
    getAllAppointmentSubmissions(1, type);
  };

  const createOreditAppointmentSubmission = async (
    appointmentSubmission: AddEditAppointmentSubmissions
  ): Promise<void> => {
    if (accessToken) {
      isEdit
        ? await updateAppointmentSubmissions(appointmentSubmission, accessToken)
        : await createNewAppointmentSubmissions(
            appointmentSubmission,
            accessToken
          );
      setModalOpen(false);
      getAllAppointmentSubmissions(1, activeTab);
    } else {
      setModalOpen(false);
    }
  };

  const downloadAllAppointmentIntoCsv = async (): Promise<void> => {
    const allCsv = await getAllAppointmentCsv(accessToken, activeTab);
    const formattedCsv = allCsv.map((data) => {
      return {
        id: data.id,
        type: data.type,
        email: data.user ? data.user.email : "",
        userId: data.user ? data.user.id : "",
        campaign: data.campaign ? data.campaign.title : "",
        appointment_id: data.submission ? data.submission.appointment_id : "",
        pre_car_front_url: data.submission
          ? data.submission.pre_car_front_url
          : "",
        pre_car_back_url: data.submission
          ? data.submission.pre_car_back_url
          : "",
        pre_car_left_url: data.submission
          ? data.submission.pre_car_left_url
          : "",
        pre_car_right_url: data.submission
          ? data.submission.pre_car_right_url
          : "",
        odometer_url: data.submission ? data.submission.odometer_url : "",
        post_car_front_url: data.submission
          ? data.submission.post_car_front_url
          : "",
        post_car_back_url: data.submission
          ? data.submission.post_car_back_url
          : "",
        post_car_right_url: data.submission
          ? data.submission.post_car_right_url
          : "",
        post_car_left_url: data.submission
          ? data.submission.post_car_left_url
          : "",
        created_at: data.created_at,
        workshop: data.workshop,
      };
    });
    setCsvData(formattedCsv);
    setTimeout(() => {
      csvButtonLink?.current?.link.click();
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddEditAppointmentSubmissionModal
        open={isModalOpen}
        isEdit={isEdit}
        selectedRow={selectedRow}
        handleClose={() => setModalOpen(false)}
        addNewOrEditAppointmentSubmissions={createOreditAppointmentSubmission}
      />
      <Tabs
        value={activeTab}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Installation" value={AppointmentsEnum.Installation} />
        <Tab label="Removal" value={AppointmentsEnum.Removal} />
      </Tabs>
      {!!appointmentSubmissions && appointmentSubmissions.data ? (
        <>
          <CSVLink
            headers={[
              "id",
              "type",
              "email",
              "userId",
              "campaign",
              "appointment_id",
              "pre_car_front_url",
              "pre_car_back_url",
              "pre_car_left_url",
              "pre_car_right_url",
              "odometer_url",
              "post_car_front_url",
              "post_car_back_url",
              "post_car_right_url",
              "post_car_left_url",
              "created_at",
              "workshop",
            ]}
            filename="Appointment_Submission.csv"
            data={csvData}
            ref={csvButtonLink}
          />
          <AppointmentSubmissionsTable
            header={header}
            rows={appointmentSubmissions}
            downloadIntoCsv={downloadAllAppointmentIntoCsv}
            onPageChanged={(newPage: number) =>
              getAllAppointmentSubmissions(newPage + 1, activeTab)
            }
            onReload={() => getAllAppointmentSubmissions(1, activeTab)}
            addNewOrEditAppointmentSubmissions={(
              isEdit: boolean,
              selectedRow?: AppointmentSubmissions
            ) => {
              setSelectedRow(selectedRow);
              setIsEdit(isEdit);
              setModalOpen(true);
            }}
          />
        </>
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(AppointmentSubmissionsPage);

import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import ImageUpload from "components/ImageUpload";
import {
  AddEditAppointmentSubmissions,
  AppointmentSubmissions,
} from "types/AppointmentsSubmission";

type AddEditUserModalProp = {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  selectedRow?: AppointmentSubmissions;
  addNewOrEditAppointmentSubmissions: (
    updatedUser: AddEditAppointmentSubmissions
  ) => void;
};
const AddEditAppointmentSubmissionModal: React.FC<AddEditUserModalProp> = (
  props
) => {
  const {
    open,
    handleClose,
    isEdit,
    selectedRow,
    addNewOrEditAppointmentSubmissions,
  } = props;
  const formikRef = useRef<any>(null);

  const initialValues = {
    appointment_id: selectedRow ? selectedRow.id : 0,
    pre_car_front:
      selectedRow && !!selectedRow.submission
        ? selectedRow.submission.pre_car_front_url
        : "",
    pre_car_back:
      selectedRow && !!selectedRow.submission
        ? selectedRow.submission.pre_car_back_url
        : "",
    pre_car_left:
      selectedRow && !!selectedRow.submission
        ? selectedRow.submission.pre_car_left_url
        : "",
    pre_car_right:
      selectedRow && !!selectedRow.submission
        ? selectedRow.submission.pre_car_right_url
        : "",
    odometer:
      selectedRow && !!selectedRow.submission
        ? selectedRow.submission.odometer_url
        : "",
    post_car_front:
      selectedRow && !!selectedRow.submission
        ? selectedRow.submission.post_car_front_url
        : "",
    post_car_back:
      selectedRow && !!selectedRow.submission
        ? selectedRow.submission.post_car_back_url
        : "",
    post_car_left:
      selectedRow && !!selectedRow.submission
        ? selectedRow.submission.post_car_left_url
        : "",
    post_car_right:
      selectedRow && !!selectedRow.submission
        ? selectedRow.submission.post_car_right_url
        : "",
  };
  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = (values: AddEditAppointmentSubmissions) => {
    addNewOrEditAppointmentSubmissions(values);
  };

  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`${isEdit ? "Edit" : "New"} Appointment Submissions`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, errors, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <MDBox mb={5} mt={5} width={"60%"}>
                  <MDInput
                    fullWidth
                    type="number"
                    label="Appointment Id"
                    disabled={isEdit}
                    name="appointment_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.appointment_id}
                    error={!!errors.appointment_id}
                    helperText={errors.appointment_id}
                  />
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Pre Car Front"
                      selectedImage={values.pre_car_front}
                      handleImageUpload={(image) =>
                        setFieldValue("pre_car_front", image)
                      }
                    />
                  </MDBox>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Pre Car Left"
                      selectedImage={values.pre_car_left}
                      handleImageUpload={(image) =>
                        setFieldValue("pre_car_left", image)
                      }
                    />
                  </MDBox>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Pre Car Right"
                      selectedImage={values.pre_car_right}
                      handleImageUpload={(image) =>
                        setFieldValue("pre_car_right", image)
                      }
                    />
                  </MDBox>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Pre Car Back"
                      selectedImage={values.pre_car_back}
                      handleImageUpload={(image) =>
                        setFieldValue("pre_car_back", image)
                      }
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Post Car Front"
                      selectedImage={values.post_car_front}
                      handleImageUpload={(image) =>
                        setFieldValue("post_car_front", image)
                      }
                    />
                  </MDBox>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Post Car Left"
                      selectedImage={values.post_car_left}
                      handleImageUpload={(image) =>
                        setFieldValue("post_car_left", image)
                      }
                    />
                  </MDBox>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Post Car Right"
                      selectedImage={values.post_car_right}
                      handleImageUpload={(image) =>
                        setFieldValue("post_car_right", image)
                      }
                    />
                  </MDBox>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Post Car Back"
                      selectedImage={values.post_car_back}
                      handleImageUpload={(image) =>
                        setFieldValue("post_car_back", image)
                      }
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Odometer"
                      selectedImage={values.odometer}
                      handleImageUpload={(image) =>
                        setFieldValue("odometer", image)
                      }
                    />
                  </MDBox>
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {`${isEdit ? "Edit" : "Create"}`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddEditAppointmentSubmissionModal;

import LoadingComponent from "components/LoadingComponent";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import { CsvDrivers, DriverFilter, Drivers } from "types/AllUsers";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateDriver,
  filterDriversThunk,
  getAllDriversThunk,
  getDrivers,
  getDriverStatusThunk,
} from "redux/slices/driverSlice";
import { CSVLink } from "react-csv";
import { getAllDriversWithoutPagination } from "services/api/usersApi";
import DriversTable from "./table";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import { NewUser } from "types/Authentication";
import { createOrUpdateUser } from "redux/slices/allUsersSlice";
import DriverModal from "./DriverModal";
import { useLocation } from "react-router-dom";

const header = [
  { id: "id", name: "Driver Id" },
  { id: "userid", name: "User Id" },
  { id: "carPlate", name: "Car Plate" },
  { id: "fullName", name: "Full Name" },
  { id: "email", name: "Email" },
  { id: "ehailing", name: "e-hailing" },
  { id: "status", name: "Status" },
  { id: "car_brand", name: "Car Brand" },
  { id: "referrer", name: "Referrer" },
  { id: "action", name: "Action" },
];
const DriverPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") ?? "";
  const dispatch = useDispatch();
  const drivers = useSelector(getDrivers);
  const [csvDrivers, setCsvDrivers] = useState<CsvDrivers[]>([]);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedDriver, setSelectedDriver] = useState<Drivers>();
  const [selectedTab, setSelecteTab] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [driverId, setDriverId] = useState<number | undefined>();
  const [currentFilter, setCurrentFilter] = useState<{
    header: string;
    value: string;
  }>();
  const csvButtonLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const { state } = useLocation();

  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (!!accessToken && !!drivers && !drivers.data && !state) {
      getAllDriversTableList(1);
      dispatch(getDriverStatusThunk(accessToken));
    } else if (state) {
      const { driverStatus } = state as { driverStatus: number };
      // all drivers
      if (driverStatus === 0) {
        getAllDriversTableList(1);
      } else {
        handleFilter("status_id", driverStatus.toString());
      }
    }
  }, [accessToken]);

  useEffect(() => {
    if (urlParams.has("id")) {
      const driverId = urlParams.get("id");
      setIsEdit(true);
      setModalOpen(true);
      setDriverId(Number(driverId));
    }
  }, []);

  const getAllDriversTableList = (page: number, username?: string) => {
    if (!!currentFilter) {
      dispatch(
        filterDriversThunk({
          header: currentFilter.header,
          value: currentFilter.value,
          accessToken,
          page,
        })
      );
    } else {
      dispatch(
        getAllDriversThunk({
          accessToken,
          page: page,
          filter: {} as DriverFilter,
        })
      );
    }
  };
  const onReload = (page: number) => {
    if (!!currentFilter) {
      dispatch(
        filterDriversThunk({
          header: currentFilter.header,
          value: currentFilter.value,
          accessToken,
        })
      );
    } else {
      getAllDriversTableList(page);
    }
  };
  const handleFilter = (header: string, value: string) => {
    if (!!value) {
      setCurrentFilter({ header, value });
      dispatch(filterDriversThunk({ header, value, accessToken }));
    } else {
      setCurrentFilter(undefined);
      dispatch(
        getAllDriversThunk({
          accessToken,
          page: 1,
          filter: {} as DriverFilter,
        })
      );
    }
  };

  const downloadIntoCsv = async (): Promise<void> => {
    if (accessToken) {
      const allCsvUsers = await getAllDriversWithoutPagination(accessToken);
      const csvAllDrivers = allCsvUsers.map((driver) => {
        return {
          ...driver,
          car_brand_name: driver.car_brand ? driver.car_brand.name : "",
          car_color_name: driver.car_color ? driver.car_color.name : "",
          car_model_name: driver.car_model ? driver.car_model.name : "",
          status_name: driver.status ? driver.status.name : "",
          username: driver.user ? driver.user.username : "",
          email: driver.user ? driver.user.email : "",
          whatsapp: `https://wa.me/6${driver.user ? driver.user.phone : ""}`,
        };
      });
      setCsvDrivers(csvAllDrivers);
      setTimeout(() => {
        csvButtonLink?.current?.link.click();
      });
    }
  };

  const updateUser = (user: NewUser) => {
    if (!!accessToken)
      dispatch(
        createOrUpdateUser({
          accessToken,
          isEdit,
          user,
        })
      );
    setModalOpen(false);
    setSelecteTab(undefined);
    getAllDriversTableList(currentPage);
  };

  const updateDriver = async (driver: Drivers) => {
    if (!!accessToken) {
      const cloneDriver = JSON.parse(JSON.stringify(driver));
      const driverNew: Drivers = {
        ...driver,
        frequent_locations:
          typeof cloneDriver.frequent_locations === "string"
            ? cloneDriver.frequent_locations.split(",")
            : cloneDriver.frequent_locations,
      };

      await dispatch(
        createOrUpdateDriver({
          accessToken,
          isEdit,
          driver: driverNew,
        })
      );
      onReload(currentPage);
      setModalOpen(false);
      setSelecteTab(undefined);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      {!!drivers && drivers.data ? (
        <DriversTable
          header={header}
          rows={drivers}
          onPageChanged={(newPage: number) => {
            getAllDriversTableList(newPage + 1);
            setCurrentPage(newPage + 1);
          }}
          downloadIntoCsv={downloadIntoCsv}
          handleUserSearch={(searchType: string, searchInput: string) =>
            handleFilter(searchType, searchInput)
          }
          onReload={(newPage: number) => onReload(newPage + 1)}
          addNewOrEditDriver={(
            isEdit: boolean,
            selectedDriver?: Drivers,
            selectedTab?: number
          ) => {
            setSelectedDriver(selectedDriver);
            setIsEdit(isEdit);
            setSelecteTab(selectedTab);
            setModalOpen(!isModalOpen);
          }}
        />
      ) : (
        <LoadingComponent />
      )}
      {!!csvDrivers && (
        <CSVLink
          headers={[
            "address",
            "car_accident",
            "car_back_url",
            "car_brand_id",
            "car_color_id",
            "country_code",
            "car_front_url",
            "car_grant_url",
            "car_left_url",
            "car_right_url",
            "car_model_id",
            "car_plate",
            "car_repaint",
            "car_year",
            "citizenship",
            "created_at",
            "driver_license_back_url",
            "driver_license_front_url",
            "e_hailing",
            "gender",
            "home_address_proof",
            "ic_back_url",
            "ic_front_url",
            "ic_number",
            "id",
            "insurance_url",
            "is_owner",
            "level",
            "postcode",
            "road_tax_sticker_url",
            "selfie_url",
            "user_id",
            "work_address",
            "car_brand_name",
            "car_color_name",
            "car_model_name",
            "status_name",
            "username",
            "email",
            "whatsapp",
          ]}
          filename="All_Drivers.csv"
          data={csvDrivers}
          ref={csvButtonLink}
        />
      )}
      {isModalOpen && (
        <DriverModal
          open={isModalOpen}
          isEdit={isEdit}
          handleClose={() => {
            setModalOpen(false);
            if (urlParams.has("id")) {
              window.history.pushState({}, document.title, "/drivers");
            }
          }}
          addEditUser={updateUser}
          addEditDriver={updateDriver}
          selectedDriver={selectedDriver}
          selectedTab={selectedTab}
          driverId={driverId}
        />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(DriverPage);

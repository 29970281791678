import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BannerState, StoreState } from "types/state/StoreState";
import {
  PaginationCommonObject,
  PaginationRequest,
  Status,
} from "types/common";
import {
  createBanner,
  getAllBanners,
  updateBanner,
} from "services/api/bannerApi";
import { Banner, NewBanner } from "types/Banner";
import { getStatuses } from "services/api/commonApi";

const bannerSlice = createSlice({
  name: "banners",
  initialState: {
    isLoading: false,
    banners: {},
    bannerStatuses: [] as Status[],
  } as BannerState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setBanners(state, action) {
      state.banners = action.payload;
    },
    setBannerStatuses(state, action) {
      state.bannerStatuses = action.payload;
    },
  },
});

// Thunk Actions
export const getBannersThunk = createAsyncThunk(
  "banner/getBannersThunk",
  async (request: PaginationRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, page } = request;
    dispatch(bannerSlice.actions.setIsLoading(true));
    const banners = await getAllBanners(accessToken, page);
    dispatch(bannerSlice.actions.setBanners(banners));
    dispatch(bannerSlice.actions.setIsLoading(false));
  }
);

export const getBannerStatusesThunk = createAsyncThunk(
  "banner/getBannerStatuses",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(bannerSlice.actions.setIsLoading(true));
    const statuses = await getStatuses(accessToken, "Banner");
    dispatch(bannerSlice.actions.setBannerStatuses(statuses));
    dispatch(bannerSlice.actions.setIsLoading(false));
  }
);

type NewBannerRequest = {
  accessToken: string;
  banner: NewBanner;
  isEdit: boolean;
};
export const createOrEditBannerThunk = createAsyncThunk(
  "banner/createOrEditBannerThunk",
  async (request: NewBannerRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { banner, accessToken, isEdit } = request;
    dispatch(bannerSlice.actions.setIsLoading(true));
    isEdit
      ? await updateBanner(banner, accessToken)
      : await createBanner(banner, accessToken);

    dispatch(
      getBannersThunk({
        accessToken,
        page: 1,
      })
    );
    dispatch(bannerSlice.actions.setIsLoading(false));
  }
);

// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.banners.isLoading;
export const getBanners = (state: StoreState): PaginationCommonObject<Banner> =>
  state.banners.banners;
export const getBannerStatuses = (state: StoreState): Status[] =>
  state.banners.bannerStatuses;

export const { setBanners, setIsLoading } = bannerSlice.actions;
export default bannerSlice.reducer;

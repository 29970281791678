import React, { useRef } from "react";
import { Drivers } from "types/AllUsers";
import HomeOwnerImages from "./child/homeownerImages";
import HomeOwnerCarImages from "./child/homeownerCarImages";
import HomeOwnerCarDetails from "./child/homeownerCarDetails";
import HomeOwnerDetails from "./child/homeownerDetails";
import { CareFreeHomeOwner } from "types/Carefree";

type Props = {
  isEdit: boolean;
  selectedHomeOwner: CareFreeHomeOwner;
  addEditUser: (updatedUser: Drivers) => void;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
};

const AdTechModal: React.FC<Props> = (props) => {
  const { selectedHomeOwner, handleChange, handleBlur, setFieldValue } = props;

  return (
    <>
      <HomeOwnerDetails
        values={selectedHomeOwner.home_owner}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      />
      {/* <AddBanks
        userBanks={selectedHomeOwner.user}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      /> */}
      <HomeOwnerCarImages
        values={selectedHomeOwner}
        setFieldValue={setFieldValue}
      />
      <HomeOwnerCarDetails
        values={selectedHomeOwner}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      />
      <HomeOwnerImages
        values={selectedHomeOwner.home_owner}
        setFieldValue={setFieldValue}
      />
    </>
  );
};
export default AdTechModal;

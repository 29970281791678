import React, { useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import AvatarUpload from "components/AvatarUpload";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import { useDispatch, useSelector } from "react-redux";
import { Banner, NewBanner } from "types/Banner";
import SelectInput from "components/MDInput/SelectInput";
import {
  getBannerStatuses,
  getBannerStatusesThunk,
} from "redux/slices/bannerSlice";

const initialValues = {
  title: "",
  desc: "",
  link: "",
  is_webview: 0,
  is_hero: 0,
  status_id: 0,
  picture_url: "",
};
type Props = {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  selectedBanner?: Banner;
  addEditBanner: (banner: NewBanner) => void;
};
const AddEditBannerModal: React.FC<Props> = (props) => {
  const { open, handleClose, isEdit, selectedBanner, addEditBanner } = props;
  const formikRef = useRef<any>(null);
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const statuses = useSelector(getBannerStatuses);

  useEffect(() => {
    if (!!accessToken) dispatch(getBannerStatusesThunk(accessToken));
  }, [accessToken, dispatch]);

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = (values) => {
    const request: NewBanner = {
      ...values,
      picture: values.picture_url,
    };
    if (isEdit) request.id = selectedBanner?.id;
    addEditBanner(request);
  };

  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`${isEdit ? "Edit" : "New"} Banner`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Formik
            initialValues={
              isEdit && !!selectedBanner ? selectedBanner : initialValues
            }
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <AvatarUpload
                  selectedImage={values.picture_url}
                  handleImageUpload={(image) =>
                    setFieldValue("picture_url", image)
                  }
                />
                <MDBox mb={2} ml={1} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="text"
                      label="Title"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="text"
                      label="Description"
                      name="desc"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.desc}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} ml={1} display={"flex"}>
                  <MDBox width={"50%"} mr={3.5} mt={1}>
                    <MDInput
                      type="text"
                      label="Link"
                      name="link"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.link}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <SelectInput
                      title="Status"
                      items={statuses}
                      onSelected={(searchValue) =>
                        setFieldValue("status_id", searchValue)
                      }
                      defaultValue={
                        isEdit
                          ? selectedBanner?.status_id.toString()
                          : undefined
                      }
                      width={"38ch"}
                      margin={0}
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={3.5}>
                    <SelectInput
                      title="Is Hero"
                      items={[
                        { id: "0", name: "No" },
                        { id: "1", name: "Yes" },
                      ]}
                      onSelected={(searchValue) =>
                        setFieldValue("is_hero", searchValue)
                      }
                      defaultValue={
                        isEdit ? selectedBanner?.is_hero.toString() : undefined
                      }
                      width={"38ch"}
                      margin={0}
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <SelectInput
                      title="Is Webview"
                      items={[
                        { id: "0", name: "No" },
                        { id: "1", name: "Yes" },
                      ]}
                      onSelected={(searchValue) =>
                        setFieldValue("is_webview", searchValue)
                      }
                      defaultValue={
                        isEdit
                          ? selectedBanner?.is_webview.toString()
                          : undefined
                      }
                      width={"38ch"}
                      margin={0}
                    />
                  </MDBox>
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {isEdit ? "Edit Brand" : "Add Banner"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddEditBannerModal;

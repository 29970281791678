import { createContext } from "react";
import { User } from "types/User";

export interface UserContextInterface {
  user: User;
  token?: string;
}
const UserContext = createContext<UserContextInterface>(
  {} as UserContextInterface
);

export default UserContext;

import { AxiosResponse } from "axios";
import { getRequest, postRequest, putRequest } from "services/client/request";
import {
  Campaign,
  CampaignAppointment,
  CampaignAssets,
  CampaignDetailResponses,
  CampaignRequestFilter,
  CampaignSummary,
  Segregation
} from "types/Campaign";
import {
  AxiosGenericResponse,
  PaginationCommonObject,
  Status
} from "types/common";

const apiVersion = "/api";

export const getCampaigns = async (
  request: CampaignRequestFilter
): Promise<PaginationCommonObject<Campaign>> => {
  const {
    accessToken,
    page,
    title = "",
    status_id = "",
    start_date = "",
    end_date = "",
    crowdsource_end_date = "",
    crowdsource_start_date = ""
  } = request;
  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<Campaign>>
  > = await getRequest(
    `${apiVersion}/campaigns?title=${title}&status_id=${status_id}&start_date=${start_date}&end_date=${end_date}&crowdsource_start_date=${crowdsource_start_date}&crowdsource_end_date=${crowdsource_end_date}&page=${page}`,
    accessToken
  );
  const campaigns: PaginationCommonObject<Campaign> = response.data.success
    ? response.data.data
    : ({} as PaginationCommonObject<Campaign>);
  return campaigns;
};

export const getSpecificCampaign = async (
  accessToken: string,
  campaignId: number
): Promise<Campaign> => {
  const response: AxiosResponse<CampaignDetailResponses> = await getRequest(
    `${apiVersion}/campaigns/${campaignId}`,
    accessToken
  );
  const campaigns: Campaign = response.data.data
    ? response.data.data
    : ({} as Campaign);

  return campaigns;
};

export const getCampaignAssets = async (
  accessToken: string,
  page: number,
  campaignId: number
): Promise<PaginationCommonObject<CampaignAssets>> => {
  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<CampaignAssets>>
  > = await getRequest(
    `${apiVersion}/campaign_assets?page=${page}&campaign_id=${campaignId}`,
    accessToken
  );
  const campaignAssets: PaginationCommonObject<CampaignAssets> = response.data
    .success
    ? response.data.data
    : ({} as PaginationCommonObject<CampaignAssets>);
  return campaignAssets;
};

export const getAvailablesZones = async (
  accessToken: string
): Promise<PaginationCommonObject<Status>> => {
  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<Status>>
  > = await getRequest(`${apiVersion}/zones`, accessToken);
  const zones: PaginationCommonObject<Status> = response.data.success
    ? response.data.data
    : ({} as PaginationCommonObject<Status>);
  return zones;
};

export const createCampaigns = async (
  request: Campaign,
  accessToken: string
): Promise<boolean> => {
  try {
    await postRequest(`${apiVersion}/campaigns`, accessToken, request);
  } catch (error: any) {
    const message = error.response
      ? error.response.data.message
      : "Creation Failed";

    alert(message);
    return false;
  }
  return true;
};

export const updateCampaigns = async (
  request: Campaign,
  accessToken: string
): Promise<boolean> => {
  try {
    await putRequest(
      `${apiVersion}/campaigns/${request.id}`,
      accessToken,
      request
    );
  } catch (error: any) {
    const message = error.response
      ? error.response.data.message
      : "Update Failed";

    alert(message);
    return false;
  }
  return true;
};

export const markAppointmentAsReady = async (
  accessToken: string,
  appoinmentId: number
): Promise<void> => {
  await postRequest(
    `${apiVersion}/appointments/${appoinmentId}/ready`,
    accessToken
  );
};
export const approveCampaignDrivers = async (
  id: number,
  accessToken: string
): Promise<void> => {
  await postRequest(
    `${apiVersion}/campaigns/drivers/${id}/approve`,
    accessToken
  );
};

export const rejectCampaignDrivers = async (
  id: number,
  accessToken: string
): Promise<void> => {
  await postRequest(
    `${apiVersion}/campaigns/drivers/${id}/reject`,
    accessToken
  );
};

export const markAsReadyCampaignDrivers = async (
  id: number,
  accessToken: string
): Promise<void> => {
  await postRequest(`${apiVersion}/driver_campaigns/${id}/ready`, accessToken);
};

export const removeCampaignDrivers = async (
  id: number,
  status: { status_id: number },
  accessToken: string
): Promise<void> => {
  await putRequest(`${apiVersion}/driver_campaigns/${id}`, accessToken, status);
};

export const createAndUpdateCampaignWorkshops = async (
  request: any,
  accessToken: string
): Promise<boolean> => {
  try {
    await postRequest(
      `${apiVersion}/campaign_workshops/create`,
      accessToken,
      request
    );
    alert("Updated successfully! Reload the page to see the changes");
  } catch (error: any) {
    const message = error.response
      ? error.response.data.message
      : "Creation Failed";

    alert(message);
    return false;
  }
  return true;
};

export const createCampaignParticipant = async (
  request: { driver_id: number; campaign_id: number; status_id: number },
  accessToken: string
): Promise<boolean> => {
  try {
    const response = await postRequest(
      `${apiVersion}/driver_campaigns`,
      accessToken,
      request
    );
  } catch (error: any) {
    const message = error.response
      ? error.response.data.error
      : "Creation Failed";
    alert(message);
    return false;
  }
  return true;
};
export const createCampaignAsset = async (
  request: any,
  accessToken: string
): Promise<boolean> => {
  try {
    await postRequest(`${apiVersion}/campaign_assets`, accessToken, request);
  } catch (error: any) {
    const message = error.response
      ? error.response.data.message
      : "Creation Failed";

    alert(message);
    return false;
  }
  return true;
};

export const updateCampaignAsset = async (
  request: any,
  accessToken: string
): Promise<boolean> => {
  try {
    await putRequest(
      `${apiVersion}/campaign_assets/${request.id}`,
      accessToken,
      request
    );
  } catch (error: any) {
    const message = error.response
      ? error.response.data.message
      : "Update Failed";

    alert(message);
    return false;
  }
  return true;
};

export const getCampaignSummary = async (
  accessToken: string,
  campaignId: number
): Promise<CampaignSummary> => {
  const response: AxiosResponse<AxiosGenericResponse<CampaignSummary>> =
    await getRequest(
      `${apiVersion}/campaigns/${campaignId}/summary`,
      accessToken
    );
  const campaigns: CampaignSummary = response.data.data
    ? response.data.data
    : ({} as CampaignSummary);

  return campaigns;
};

export const createCampaignAppointment = async (
  request: CampaignAppointment,
  accessToken: string
): Promise<boolean> => {
  try {
    await postRequest(`${apiVersion}/appointments`, accessToken, request);
  } catch (error: any) {
    const message = error.response
      ? error.response.data.message
      : "Creation Failed";

    alert(message);
    return false;
  }
  return true;
};

export const getSegregations = async (
  accessToken: string
): Promise<Segregation[]> => {
  const response: AxiosResponse<AxiosGenericResponse<Segregation[]>> =
    await getRequest(`${apiVersion}/segregations`, accessToken);
  const segregations: Segregation[] = response.data.data
    ? response.data.data
    : [];

  return segregations;
};

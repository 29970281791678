import Grid from "@mui/material/Grid";
import LoadingComponent from "components/LoadingComponent";
import MDBox from "components/MDBox";
import React from "react";
import { useSelector } from "react-redux";
import { getCurrentDriver } from "redux/slices/driverSlice";
import OverviewChild from "./overviewChild";

const DriverOverview: React.FC = () => {
  const driver = useSelector(getCurrentDriver);

  return (
    <MDBox>
      {Object.keys(driver).length > 0 ? (
        <>
          <Grid
            container
            rowSpacing={4}
            columnSpacing={{ xs: 1, sm: 4, md: 4 }}
          >
            <Grid item xs={4}>
              <OverviewChild title={"User Id"} value={driver.id} />
            </Grid>
            <Grid item xs={4}>
              <OverviewChild
                title={"Full Name"}
                value={`${driver.user.fname} ${driver.user.lname}`}
              />
            </Grid>
            <Grid item xs={4}>
              <OverviewChild title={"Email"} value={driver.user.email} />
            </Grid>
            <Grid item xs={4}>
              <OverviewChild title={"Gender"} value={driver.gender} />
            </Grid>
            <Grid item xs={4}>
              <OverviewChild title={"Phone"} value={driver.user.phone} />
            </Grid>
            <Grid item xs={4}>
              <OverviewChild
                title={"Total Points"}
                value={driver.points.total_points}
              />
            </Grid>
            <Grid item xs={4}>
              <OverviewChild
                title={"Total Campaigns"}
                value={driver.total_campaigns}
              />
            </Grid>
            <Grid item xs={4}>
              <OverviewChild
                title={"Total Journeys"}
                value={driver.total_journeys}
              />
            </Grid>
            <Grid item xs={4}>
              <OverviewChild
                title={"Joined since"}
                value={`${new Date(driver.created_at).toLocaleDateString()}`}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <LoadingComponent />
      )}
    </MDBox>
  );
};

export default DriverOverview;

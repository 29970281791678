import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import AvatarUpload from "components/AvatarUpload";
import { NewUser } from "types/Authentication";
import { Drivers } from "types/AllUsers";
import DriverImages from "./child/driverImages";
import DriverCarImages from "./child/driverCarImages";
import DriverCarDetails from "./child/driverCarDetails";
import DriverDetails from "./child/driverDetails";
import AddBanks from "./child/addBanks";
import { CarefreeDrivers } from "types/Carefree";

type Props = {
  isEdit: boolean;
  selectedDriver: CarefreeDrivers;
  addEditUser: (updatedUser: Drivers) => void;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
};

const AdTechModal: React.FC<Props> = (props) => {
  const { selectedDriver, handleChange, handleBlur, setFieldValue } = props;

  return (
    <>
      <DriverDetails
        values={selectedDriver.driver}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      />
      {/* <AddBanks
        userBanks={selectedDriver.user}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      /> */}
      <DriverCarImages values={selectedDriver} setFieldValue={setFieldValue} />
      <DriverCarDetails
        values={selectedDriver}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      />
      <DriverImages
        values={selectedDriver.driver}
        setFieldValue={setFieldValue}
      />
    </>
  );
};
export default AdTechModal;

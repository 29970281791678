import React, { useCallback, useState } from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

type Props = {
  onChangeDatesRange: (from: string, to: string) => void;
  fromTitile?: string;
  toTitile?: string;
  hideResetButton?: boolean;
  type?: string;
};
const DatePickerRange: React.FC<Props> = (prop) => {
  const {
    onChangeDatesRange,
    fromTitile = "From",
    toTitile = "To",
    hideResetButton,
    type = "date",
  } = prop;

  const [fromvalue, setfromValue] = useState<string>("");
  const [toValue, setToValue] = useState<string>("");

  const resetDateRange = () => {
    setfromValue("");
    setToValue("");
    onChangeDatesRange("", "");
  };
  const updateDateInput = useCallback(
    (key: string, newValues: string) => {
      key === "From"
        ? onChangeDatesRange(newValues, toValue ? toValue : "")
        : onChangeDatesRange(fromvalue ? fromvalue : "", newValues);
    },
    [fromvalue, toValue, onChangeDatesRange]
  );

  return (
    <MDBox
      sx={{
        display: "flex",
      }}
      mt={1}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MDBox ml={1} mr={1}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            Filter:
          </MDTypography>
        </MDBox>
        <TextField
          label={fromTitile}
          type={type}
          name={"From"}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setfromValue(e.target.value);
            updateDateInput("From", e.target.value);
          }}
          value={fromvalue}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
        <MDBox ml={1} mr={1}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            to
          </MDTypography>
        </MDBox>

        <TextField
          label={toTitile}
          type={type}
          name={"To"}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setToValue(e.target.value);
            updateDateInput("To", e.target.value);
          }}
          value={toValue}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
        {!hideResetButton && (
          <MDBox ml={2} mr={2}>
            <MDTypography
              variant="button"
              color="primary"
              fontWeight="medium"
              sx={{ cursor: "pointer", userSelect: "none" }}
              textGradient
              onClick={resetDateRange}
            >
              Reset
            </MDTypography>
          </MDBox>
        )}
      </LocalizationProvider>
    </MDBox>
  );
};

export default DatePickerRange;

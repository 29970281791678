import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateJourneyThunk,
  getAllJourneysThunk,
  getJourneys,
  setIsDownloading,
} from "redux/slices/journeySlice";
import JourneyTable from "./table";
import LoadingComponent from "components/LoadingComponent";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import AddEditJourneyModal from "./AddEditJourney";
import { NewJourney } from "types/Journeys";
import { getAllJourneys } from "services/api/journeysApi";
import DateFilterModal from "./DateFilterModel";
import { DateFilter } from "types/common";

const header = [
  { id: "id", name: "id" },
  { id: "driver_id", name: "Driver Id" },
  { id: "campaign_id", name: "Campaign Id" },
  { id: "campaign", name: "Campaign Name" },
  { id: "username", name: "username" },
  { id: "point", name: "Point" },
  { id: "total_distance", name: "total distance" },
  { id: "actual_distance", name: "actual distance" },
  { id: "started_at", name: "started at" },
  { id: "ended_at", name: "ended_at" },
  { id: "action", name: "Action" },
];
const JourneyPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const journeys = useSelector(getJourneys);
  const [csvJourneys, setCsvJourneys] = useState<any>([]);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDateFilterOpen, setDateFilterOpen] = useState<boolean>(false);
  const [selectedJourney, setSelectedJourney] = useState<NewJourney>();
  const csvButtonLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const getAllJourneysList = (page: number) => {
    if (!!accessToken)
      dispatch(
        getAllJourneysThunk({
          accessToken,
          page,
        })
      );
  };

  useEffect(() => {
    if (!!accessToken && !!journeys && !journeys.data) {
      getAllJourneysList(1);
    }
  }, [accessToken, journeys]);

  const downloadIntoCsv = async (dateFilter: DateFilter): Promise<void> => {
    if (accessToken) {
      dispatch(setIsDownloading(true));
      const allCsvJourneys = await getAllJourneys(accessToken, dateFilter);
      dispatch(setIsDownloading(false));
      const formattedJourney = allCsvJourneys.map((journey) => {
        return {
          id: journey.id,
          driver_id: journey.driver_id,
          driver_campaign_id: journey.driver_campaign_id,
          campaign: journey.campaign ? journey.campaign.title : "",
          email: journey.user ? journey.user.email : "",
          userId: journey.user ? journey.user.id : "",
          total_distance: journey.total_distance,
          point: journey.points,
          actual_distance: journey.actual_distance,
          latitude_start: journey.latitude_start,
          longitude_start: journey.longitude_start,
          latitude_end: journey.latitude_end,
          longitude_end: journey.longitude_end,
          started_at: journey.started_at,
          ended_at: journey.ended_at,
          gpx_url: journey.gpx_url,
        };
      });
      setCsvJourneys(formattedJourney);
      setTimeout(() => {
        csvButtonLink?.current?.link.click();
      });
    }
  };

  const createOrEditJourney = (journey: NewJourney) => {
    if (!!accessToken) {
      dispatch(
        createOrUpdateJourneyThunk({
          accessToken,
          isEdit,
          journey,
        })
      );
      setModalOpen(false);
      getAllJourneysList(1);
    } else {
      setModalOpen(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isDateFilterOpen && (
        <DateFilterModal
          open={isDateFilterOpen}
          handleClose={() => setDateFilterOpen(false)}
          filterDate={async (dateFilter: DateFilter) =>
            await downloadIntoCsv(dateFilter)
          }
        />
      )}

      {!!journeys && !!journeys.data ? (
        <>
          <CSVLink
            headers={[
              "id",
              "driver_id",
              "driver_campaign_id",
              "campaign",
              "email",
              "userId",
              "total_distance",
              "points",
              "actual_distance",
              "latitude_start",
              "longitude_start",
              "latitude_end",
              "longitude_end",
              "started_at",
              "ended_at",
              "gpx_url",
            ]}
            filename="All_Journeys.csv"
            data={csvJourneys}
            ref={csvButtonLink}
          />

          <AddEditJourneyModal
            open={isModalOpen}
            isEdit={isEdit}
            selectedJourney={selectedJourney}
            handleClose={() => setModalOpen(false)}
            addEditJourney={createOrEditJourney}
          />
          <JourneyTable
            header={header}
            rows={journeys}
            onReload={(currentPage: number) =>
              getAllJourneysList(currentPage + 1)
            }
            downloadIntoCsv={() => {
              setDateFilterOpen(true);
            }}
            onPageChanged={(newPage: number) => getAllJourneysList(newPage + 1)}
            addNewOrEditJourney={(
              isEditAction: boolean,
              selectedJourney?: NewJourney
            ) => {
              setSelectedJourney(selectedJourney);
              setIsEdit(isEditAction);
              setModalOpen(!isModalOpen);
            }}
          />
        </>
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(JourneyPage);

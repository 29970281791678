export const car_type = [
  {
    id: "Sedan",
    name: "Sedan",
  },
  {
    id: "Hatchback",
    name: "Hatchback",
  },
  {
    id: "MPV",
    name: "MPV",
  },
  {
    id: "SUV",
    name: "SUV",
  },
  {
    id: "Truck",
    name: "Truck",
  },
  {
    id: "Wagon",
    name: "Wagon",
  },
  {
    id: "Coupe",
    name: "Coupe",
  },
  {
    id: "Van",
    name: "Van",
  },
  {
    id: "Convertible",
    name: "Convertible",
  },
];

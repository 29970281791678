import ImageUpload from "components/ImageUpload";
import MDBox from "components/MDBox";
import React from "react";
import { CarefreeDriver } from "types/Carefree";

type Props = {
  setFieldValue: (field: string, value: any) => void;
  values: CarefreeDriver;
};

const DriverImages: React.FC<Props> = (props) => {
  const { values, setFieldValue } = props;

  return (
    <MDBox>
      <MDBox mb={2} display={"flex"} width={"100%"}>
        <MDBox width={"20%"}>
          <ImageUpload
            title="Selfie"
            selectedImage={values.selfie_picture_url}
            handleImageUpload={(image) =>
              setFieldValue("selfie_picture_url", image)
            }
          />
        </MDBox>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="IC Front"
            selectedImage={values.ic_front_picture_url}
            handleImageUpload={(image) =>
              setFieldValue("ic_front_picture_url", image)
            }
          />
        </MDBox>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="IC Back"
            selectedImage={values.ic_back_picture_url}
            handleImageUpload={(image) =>
              setFieldValue("ic_back_picture_url", image)
            }
          />
        </MDBox>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="Driver License Front"
            selectedImage={values.driver_license_front_picture_url}
            handleImageUpload={(image) =>
              setFieldValue("driver_license_front_picture_url", image)
            }
          />
        </MDBox>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="Driver License Back"
            selectedImage={values.driver_license_back_picture_url}
            handleImageUpload={(image) =>
              setFieldValue("driver_license_back_picture_url", image)
            }
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default DriverImages;

import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "components/LoadingComponent";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import PartnerTable from "./table";
import AddEditPartnerModal from "./Modal";
import {
  createOrEditPartnerThunk,
  getPartners,
  getPartnersThunk,
} from "redux/slices/partnerSlice";
import { NewPartner, Partner } from "types/Partner";

const header = [
  { id: "id", name: "Partner Id" },
  { id: "title", name: "Title" },
  { id: "desc", name: "Desc" },
  { id: "is_webview", name: "Is webview" },
  { id: "status_id", name: "Status" },
  { id: "created_at", name: "Created At" },
  { id: "action", name: "Action" },
];

const PartnerPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const partners = useSelector(getPartners);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedPartner, setSelectedPartner] = useState<Partner>();

  const getAllPartners = useCallback(
    (page: number, status?: string) => {
      if (!!accessToken) {
        dispatch(
          getPartnersThunk({
            accessToken,
            page,
            name: status,
          })
        );
      }
    },
    [dispatch, accessToken]
  );

  useEffect(() => {
    if (!!accessToken && !!partners && !partners.data) {
      getAllPartners(1);
    }
  }, [accessToken, partners, getAllPartners]);

  const createOrEditPartner = (partner: NewPartner) => {
    if (!!accessToken)
      dispatch(
        createOrEditPartnerThunk({
          accessToken,
          partner,
          isEdit,
        })
      );
    setModalOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddEditPartnerModal
        open={isModalOpen}
        isEdit={isEdit}
        selectedPartner={selectedPartner}
        handleClose={() => setModalOpen(false)}
        addEditPartner={createOrEditPartner}
      />
      {!!partners && partners.data ? (
        <PartnerTable
          header={header}
          rows={partners}
          onReload={() => getAllPartners(1)}
          addNewOrEditPartner={(isEdit: boolean, selectedPartner?: Partner) => {
            setSelectedPartner(selectedPartner);
            setIsEdit(isEdit);
            setModalOpen(!isModalOpen);
          }}
          onPageChanged={(newPage: number) => getAllPartners(newPage)}
          handlePartnerSearch={(status: string) => getAllPartners(1, status)}
        />
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(PartnerPage);

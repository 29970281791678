import React, { useRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import { NewUser } from "types/Authentication";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import { Drivers } from "types/AllUsers";
import { Tab, Tabs } from "@mui/material";
import DriverOverview from "./overview";
import {
  getCurrentDriver,
  getCurrentDriverThunk,
} from "redux/slices/driverSlice";
import { useDispatch, useSelector } from "react-redux";
import DriverAccount from "./driverAccount";
import MDBox from "components/MDBox";
import AdTechModal from "./adtech";
import RefereeTab from "./referee";
import RefererTab from "./referral";
import MDTypography from "components/MDTypography";

type AddEditUserModalProp = {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  selectedDriver?: Drivers;
  addEditUser: (updatedUser: NewUser) => void;
  addEditDriver: (updatedDriver: Drivers) => void;
  selectedTab?: number;
  driverId?: number;
};
const DriverModal: React.FC<AddEditUserModalProp> = (props) => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const {
    open,
    handleClose,
    isEdit,
    selectedDriver,
    addEditUser,
    addEditDriver,
    selectedTab,
    driverId,
  } = props;
  const formikRef = useRef<any>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const currentDriver = useSelector(getCurrentDriver);
  const dispatch = useDispatch();
  const driver: Drivers = {} as Drivers;

  useEffect(() => {
    if (!!selectedTab && selectedTab === 3) {
      setActiveTab(3);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (driverId && !selectedDriver) {
      dispatch(getCurrentDriverThunk({ accessToken, driverId: driverId }));
    }
  }, []);

  useEffect(() => {
    if (!!currentDriver && selectedDriver) {
      dispatch(
        getCurrentDriverThunk({ accessToken, driverId: selectedDriver?.id })
      );
    }
  }, [selectedDriver]);

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = (values) => {
    if (activeTab === 1) {
      addEditUser(values);
    } else if (activeTab === 2) {
      addEditDriver(values);
    }
  };
  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`${isEdit ? "Edit" : "New"} User`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Overview" value={0} />
            <Tab label="Account" value={1} />
            <Tab label="AdTech" value={2} />
            <Tab label="Referral" value={3} />
          </Tabs>

          <Formik
            initialValues={isEdit && !!selectedDriver ? selectedDriver : driver}
            // validate={(values) => {
            //   if (!isEdit) validateForm(values);
            // }}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({
              values,
              handleBlur,
              handleChange,
              setFieldValue,
              setValues,
            }) => (
              <FormikForm>
                <MDBox mt={3}>
                  {activeTab === 0 && <DriverOverview />}
                  {activeTab === 1 && (
                    <DriverAccount
                      isEdit={isEdit}
                      selectedDriver={currentDriver.user}
                      addEditUser={onSubmit}
                    />
                  )}
                  {activeTab === 2 && (
                    <AdTechModal
                      isEdit={isEdit}
                      selectedDriver={values}
                      addEditUser={() => {}}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      setValues={setValues}
                    />
                  )}
                  {activeTab === 3 && (
                    <>
                      <MDTypography variant="button" fontWeight="medium">
                        {"Referrer"}
                      </MDTypography>
                      <RefererTab referrer={currentDriver.referrer || {}} />
                      <br />
                      <MDTypography variant="button" fontWeight="medium">
                        {"Referee"}
                      </MDTypography>
                      <RefereeTab referee={currentDriver.referee || []} />
                    </>
                  )}
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          {activeTab === 2 && (
            <Button
              onClick={() => {
                //Trigger formik button as outside the form
                if (formikRef.current) {
                  formikRef.current.handleSubmit();
                }
              }}
            >
              {isEdit ? "Edit user" : "Add user"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default DriverModal;

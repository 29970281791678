import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import TableHeader from "components/MDTable/tableHeader";
import React from "react";
import { Referral } from "types/AllUsers";
import { ITableHeader } from "types/Table";
import { IconButton, Link } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const tableHeader: ITableHeader[] = [
  { id: "id", name: "Id" },
  { id: "driverId", name: "Driver Id" },
  { id: "fname", name: "Name" },
  { id: "status", name: "status" },
  { id: "phone", name: "Phone" },
  { id: "action", name: "Action" },
];
type Prop = {
  referee: Referral[];
};
const RefereeTab: React.FC<Prop> = (props) => {
  const { referee } = props;
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 600 }}
        aria-labelledby="tableTitle"
        size={"medium"}
      >
        <TableHeader headCells={tableHeader} disableCheckbox={true} />
        <TableBody>
          {referee.map((row, index) => {
            return (
              <TableRow key={row.id}>
                <TableCell align="right">{row.id}</TableCell>
                <TableCell align="right">{row.driver_id}</TableCell>
                <TableCell align="right">{`${row.fname} ${row.lname}`}</TableCell>
                <TableCell align="right">{row.status}</TableCell>
                <TableCell align="right">{row.phone}</TableCell>
                <TableCell align="right">
                  <Link href={`https://wa.me/6${row.phone}`} target="_blank">
                    <IconButton>
                      <WhatsAppIcon sx={{ color: "#25d366" }} />
                    </IconButton>
                  </Link>
                  <Link href={`/drivers?id=${row.driver_id}`} target="_blank">
                    <IconButton>
                      <OpenInNewIcon />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RefereeTab;

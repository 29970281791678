import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ReportState, StoreState } from "types/state/StoreState";
import {
  CampaignDrivers,
  DriverMultiplier,
  Report,
  ReportCampaign,
  ReportCampaignMonth,
  ReportDriverInfo,
  ReportGenerate
} from "types/Report";
import {
  generateReportApi,
  getAllCampaignsWithoutPagination,
  getAllReportApi,
  getCampaignDriverInfosApi,
  getCampaignDriversApi,
  getCampaignMonthsApi,
  recalculateDriverMultiplierApi
} from "services/api/reportApi";

const reportSlice = createSlice({
  name: "reports",
  initialState: {
    isLoading: false,
    reports: [],
    campaignMonths: [],
    campaignDrivers: [],
    currentReport: {} as Report,
    campaigns: [],
    selectedDrivers: [],
    reportDriversInfo: []
  } as ReportState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setAllReports(state, action) {
      state.reports = action.payload;
    },
    setCampaignMonths(state, action) {
      state.campaignMonths = action.payload;
    },
    setCampaignDrivers(state, action) {
      state.campaignDrivers = action.payload;
    },
    setCurrentReport(state, action) {
      state.currentReport = action.payload;
    },
    setCampaigns(state, action) {
      state.campaigns = action.payload;
    },
    setSelectedDrivers(state, action) {
      state.selectedDrivers = action.payload;
    },
    setReportDriverInfo(state, action) {
      state.reportDriversInfo = action.payload;
    }
  }
});

// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.report.isLoading;
export const getAllReports = (state: StoreState): Report[] =>
  state.report.reports;
export const getCampaingMonths = (state: StoreState): ReportCampaignMonth[] =>
  state.report.campaignMonths;
export const getCampaignDrivers = (state: StoreState): CampaignDrivers[] =>
  state.report.campaignDrivers;
export const getCurrentReport = (state: StoreState): Report =>
  state.report.currentReport;
export const getAllCampaignFromReport = (state: StoreState): ReportCampaign[] =>
  state.report.campaigns;
export const getAllSelectedDriversFromCampaigns = (
  state: StoreState
): CampaignDrivers[] => state.report.selectedDrivers;
export const getDriversInfoReportSelector = (
  state: StoreState
): ReportDriverInfo[] => state.report.reportDriversInfo;
// Thunk Actions
export const getAllReportsThunk = createAsyncThunk(
  "reports/getAllReportsThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;

    dispatch(reportSlice.actions.setIsLoading(true));
    const reports = await getAllReportApi(accessToken);
    dispatch(reportSlice.actions.setAllReports(reports));
    dispatch(reportSlice.actions.setIsLoading(false));
  }
);

export const getAllCampaignForReportThunk = createAsyncThunk(
  "reports/getAllCampaignForReportThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;

    dispatch(reportSlice.actions.setIsLoading(true));
    const campaign = await getAllCampaignsWithoutPagination(accessToken);
    dispatch(reportSlice.actions.setCampaigns(campaign));
    dispatch(reportSlice.actions.setIsLoading(false));
  }
);
type ReportById = {
  accessToken: string;
  id: number;
};
export const getReportCampaignMonthThunk = createAsyncThunk(
  "reports/getReportCampaignMonthThunk",
  async (request: ReportById, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, id } = request;
    dispatch(reportSlice.actions.setIsLoading(true));
    dispatch(reportSlice.actions.setCampaignMonths([]));
    const months = await getCampaignMonthsApi(accessToken, id);
    dispatch(reportSlice.actions.setCampaignMonths(months));
    dispatch(reportSlice.actions.setIsLoading(false));
  }
);
export const getReporDriversInfoThunk = createAsyncThunk(
  "reports/getReporDriversInfoThunk",
  async (request: ReportById, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, id } = request;
    dispatch(reportSlice.actions.setIsLoading(true));
    const data = await getCampaignDriverInfosApi(accessToken, id);
    dispatch(reportSlice.actions.setReportDriverInfo(data));
    dispatch(reportSlice.actions.setIsLoading(false));
  }
);
type ReportDrivers = {
  accessToken: string;
  id: number;
  month: string;
};
export const getCampaignDriversThunk = createAsyncThunk(
  "reports/getCampaignDriversThunk",
  async (request: ReportDrivers, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, id, month } = request;
    dispatch(reportSlice.actions.setIsLoading(true));
    dispatch(reportSlice.actions.setCampaignDrivers([]));
    const drivers = await getCampaignDriversApi(accessToken, id, month);
    dispatch(reportSlice.actions.setCampaignDrivers(drivers));
    dispatch(reportSlice.actions.setIsLoading(false));
  }
);
type RecalculateMultiplier = {
  accessToken: string;
  newRequest: DriverMultiplier;
};
export const recalculateDriverMultiplierThunk = createAsyncThunk(
  "reports/recalculateDriverMultiplierThunk",
  async (request: RecalculateMultiplier, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, newRequest } = request;
    dispatch(reportSlice.actions.setIsLoading(true));
    try {
      await recalculateDriverMultiplierApi(accessToken, newRequest);
    } catch (e) {
      alert(e);
      console.error(e);
    }

    dispatch(reportSlice.actions.setIsLoading(false));
  }
);

type ReportGenerateRequest = {
  accessToken: string;
  newRequest: ReportGenerate;
};
export const generateReportThunk = createAsyncThunk(
  "reports/generateReportThunk",
  async (request: ReportGenerateRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, newRequest } = request;
    dispatch(reportSlice.actions.setIsLoading(true));
    try {
      await generateReportApi(accessToken, newRequest);
    } catch (e) {
      alert(e);
      console.error(e);
    }
    dispatch(reportSlice.actions.setIsLoading(false));
  }
);

export const { setCampaignDrivers, setAllReports, setSelectedDrivers } =
  reportSlice.actions;

export default reportSlice.reducer;

import LoadingComponent from "components/LoadingComponent";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  createOrEditCarSettingsThunk,
  getCarBrands,
  getCarColors,
  getCarModels,
  getCarSettingThunk,
} from "redux/slices/settingSlice";
import { CarBrand, CarSettings } from "types/Cars";
import { CarRoutesEnum } from "types/enum/Common";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import CarSettingModal from "./modal";
import CarSettingsTable from "./table";

const CarSettingsPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const location = useLocation();
  const carBrands = useSelector(getCarBrands);
  const carModels = useSelector(getCarModels);
  const carColors = useSelector(getCarColors);
  const carType = location.pathname.slice(1);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedCarSetting, setSelectedCarSetting] = useState<CarSettings>();

  const carSettings = useMemo(() => {
    switch (carType) {
      case CarRoutesEnum.CARBRAND: {
        return carBrands;
      }
      case CarRoutesEnum.CARMODELS: {
        return carModels;
      }
      case CarRoutesEnum.CARCOLORS: {
        return carColors;
      }
      default: {
        return [] as CarBrand[];
      }
    }
  }, [carType, carBrands, carModels, carColors]);

  useEffect(() => {
    if (!!accessToken) {
      getCarSetting();
    }
  }, [carType]);

  const getCarSetting = () => {
    if (!!accessToken) {
      dispatch(getCarSettingThunk({ accessToken, page: 0, name: carType }));
    }
  };

  const createOrEdtiCarSetting = (setting: CarSettings) => {
    if (!!accessToken) {
      dispatch(
        createOrEditCarSettingsThunk({
          accessToken,
          setting,
          isEdit,
          carType,
        })
      );
    }
    setModalOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CarSettingModal
        open={isModalOpen}
        isEdit={isEdit}
        selectedCarSetting={selectedCarSetting}
        handleClose={() => setModalOpen(false)}
        addEditCarSetting={createOrEdtiCarSetting}
        carType={carType}
      />

      {!!carSettings ? (
        <CarSettingsTable
          header={[
            { id: "id", name: "Id" },
            { id: "name", name: "Name" },
            { id: "created_at", name: "Created At" },
            { id: "action", name: "Action" },
          ]}
          rows={carSettings}
          onReload={() => getCarSetting()}
          addNewOrEditCarSetting={(
            isEdit: boolean,
            selectedCarSetting?: any
          ) => {
            if (!!selectedCarSetting) {
              setSelectedCarSetting({
                id: selectedCarSetting.id,
                name: selectedCarSetting.name,
                car_brand_id: selectedCarSetting.car_brand_id || undefined,
              });
            }

            setIsEdit(isEdit);
            setModalOpen(!isModalOpen);
          }}
          carType={carType}
        />
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};

export default WithAuthWrapper(CarSettingsPage);

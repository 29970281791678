import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
// @mui icons
import Icon from "@mui/material/Icon";
import ForgetPassword from "layouts/authentication/reset-password";
import AllUsers from "layouts/users";
import JourneyPage from "layouts/journey";
import DriverPage from "layouts/drivers";
import TransactionPage from "layouts/transactions";
import MonthlySubmissionsPage from "layouts/monthlySubmissions";
import AppointmentSubmissionsPage from "layouts/appointmentSubmissions";
import NotificationPage from "layouts/notifications";
import WorkshopPage from "layouts/workshops";
import WithdrawalPage from "layouts/withdrawal";
import BrandPage from "layouts/brand";
import BannerPage from "layouts/banner";
import AdminPage from "layouts/admin";
import PartnerPage from "layouts/partner";
import VariablesPage from "layouts/settings";
import CarSettingsPage from "layouts/settings/CarSettings";
import OverviewPage from "layouts/overview";
import LedgerPage from "layouts/ledger";
import CampaignPage from "layouts/campaign";
import CampaignDetails from "layouts/campaign/campaignDetails";
import CarefreeDriverPage from "layouts/carefree/drivers";
import CarefreeHomeOwnerPage from "layouts/carefree/homeowner";
import CarefreeBookingPage from "layouts/carefree/bookings";
import CarefreePostingsPage from "layouts/carefree/postings";
import CarefreeUser from "layouts/carefree/users";
import ReportPage from "layouts/report";
import ReportDetails from "layouts/report/details";
import GenerteReport from "layouts/generateReport";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />
  },
  {
    type: "child",
    name: "Users",
    key: "users-main",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/users",
    component: <AllUsers />,
    children: [
      {
        type: "child",
        name: "All Users",
        key: "users",
        icon: <Icon fontSize="small">person</Icon>,
        route: "/users",
        component: <AllUsers />
      },
      {
        type: "child",
        name: "Drivers",
        key: "drivers",
        icon: <Icon fontSize="small">drive_eta</Icon>,
        route: "/drivers",
        component: <DriverPage />
      }
    ]
  },
  {
    type: "collapse",
    name: "Campaigns",
    key: "campaigns",
    icon: <Icon fontSize="small">campaign</Icon>,
    route: "/campaigns",
    component: <CampaignPage />
  },

  {
    type: "details",
    name: "Campaigns Details",
    key: "campaigns",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/campaigns/:id",
    component: <CampaignDetails />
  },
  {
    type: "collapse",
    name: "Brands",
    key: "clients",
    icon: <Icon fontSize="small">face</Icon>,
    route: "/clients",
    component: <BrandPage />
  },
  {
    type: "collapse",
    name: "Admin",
    key: "admins",
    icon: <Icon fontSize="small">manage_accounts_icon</Icon>,
    route: "/admins",
    component: <AdminPage />
  },
  {
    type: "collapse",
    name: "Report",
    key: "report",
    icon: <Icon fontSize="small">manage_accounts_icon</Icon>,
    route: "/report",
    component: <ReportPage />
  },
  {
    type: "collapse",
    name: "CSV Generate Report",
    key: "csv-generate",
    icon: <Icon fontSize="small">manage_accounts_icon</Icon>,
    route: "/csv-generate",
    component: <GenerteReport />
  },
  {
    type: "details",
    name: "Report Details",
    key: "report-details",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/report/:id",
    component: <ReportDetails />
  },
  {
    type: "collapse",
    name: "Journeys",
    key: "journeys",
    icon: <Icon fontSize="small">directions_run</Icon>,
    route: "/journeys",
    component: <JourneyPage />
  },
  {
    type: "child",
    name: "Submissions",
    key: "submission-main",
    icon: <Icon fontSize="small">drive_folder_upload</Icon>,
    route: "/submission",
    component: <MonthlySubmissionsPage />,
    children: [
      {
        type: "child",
        name: "Monthly Submissions",
        key: "monthly-submissions",
        icon: <Icon fontSize="small">hail</Icon>,
        route: "/monthly-submissions",
        component: <MonthlySubmissionsPage />
      },
      {
        type: "child",
        name: "Appointment Sub",
        key: "appointment-submissions",
        icon: <Icon fontSize="small">calendar_month</Icon>,
        route: "/appointment-submissions",
        component: <AppointmentSubmissionsPage />
      }
    ]
  },
  {
    type: "collapse",
    name: "Notfications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <NotificationPage />
  },
  {
    type: "collapse",
    name: "Workshops",
    key: "workshops",
    icon: <Icon fontSize="small">home_repair_service_icon</Icon>,
    route: "/workshops",
    component: <WorkshopPage />
  },
  {
    type: "collapse",
    name: "Banners",
    key: "banners",
    icon: <Icon fontSize="small">image</Icon>,
    route: "/banners",
    component: <BannerPage />
  },
  {
    type: "child",
    name: "Rewards",
    key: "rewards-main",
    icon: <Icon fontSize="small">emoji_events</Icon>,
    route: "/rewards",
    component: <TransactionPage />,
    children: [
      {
        type: "child",
        name: "Transactions",
        key: "transactions",
        icon: <Icon fontSize="small">account_balance_wallet</Icon>,
        route: "/transactions",
        component: <TransactionPage />
      },
      {
        type: "child",
        name: "Withdrawals",
        key: "withdrawals",
        icon: <Icon fontSize="small">local_atm</Icon>,
        route: "/withdrawals",
        component: <WithdrawalPage />
      },
      {
        type: "child",
        name: "Users Overview",
        key: "users_overview",
        icon: <Icon fontSize="small">contact_page</Icon>,
        route: "/users_overview",
        component: <OverviewPage />
      },
      {
        type: "child",
        name: "Ledger",
        key: "ledger",
        icon: <Icon fontSize="small">savings</Icon>,
        route: "/ledger",
        component: <LedgerPage />
      }
    ]
  },

  {
    type: "collapse",
    name: "Partners",
    key: "partners",
    icon: <Icon fontSize="small">wc</Icon>,
    route: "/partners",
    component: <PartnerPage />
  },
  {
    type: "child",
    name: "Settings",
    key: "settings-main",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings/variables",
    component: <VariablesPage />,
    children: [
      {
        type: "child",
        name: "Variables",
        key: "variables",
        icon: <Icon fontSize="small">settings_suggest</Icon>,
        route: "/settings/variables",
        component: <VariablesPage />
      },
      {
        type: "child",
        name: "Car Brands",
        key: "car_brands",
        icon: <Icon fontSize="small">directions_car</Icon>,
        route: "/car_brands",
        component: <CarSettingsPage />
      },
      {
        type: "child",
        name: "Car Models",
        key: "car_models",
        icon: <Icon fontSize="small">toys</Icon>,
        route: "/car_models",
        component: <CarSettingsPage />
      },
      {
        type: "child",
        name: "Car Colors",
        key: "car_colors",
        icon: <Icon fontSize="small">colorize</Icon>,
        route: "/car_colors",
        component: <CarSettingsPage />
      }
    ]
  },
  {
    type: "child",
    name: "Carefree",
    key: "carefree-main",
    icon: <Icon fontSize="small">local_parking</Icon>,
    route: "/carefree",
    component: <TransactionPage />,
    children: [
      {
        type: "child",
        name: "Users",
        key: "carefree-users",
        icon: <Icon fontSize="small">directions_car</Icon>,
        route: "/carefree-users",
        component: <CarefreeUser />
      },
      {
        type: "child",
        name: "Drivers",
        key: "carefree-drivers",
        icon: <Icon fontSize="small">directions_car</Icon>,
        route: "/carefree-drivers",
        component: <CarefreeDriverPage />
      },
      {
        type: "child",
        name: "Homeowners",
        key: "carefree-homeowner",
        icon: <Icon fontSize="small">house</Icon>,
        route: "/carefree-homeowner",
        component: <CarefreeHomeOwnerPage />
      },
      {
        type: "child",
        name: "Bookings",
        key: "carefree-bookings",
        icon: <Icon fontSize="small">local_parking</Icon>,
        route: "/carefree-bookings",
        component: <CarefreeBookingPage />
      },
      {
        type: "child",
        name: "Listings",
        key: "carefree-postings",
        icon: <Icon fontSize="small">house</Icon>,
        route: "/carefree-postings",
        component: <CarefreePostingsPage />
      }
    ]
  },
  {
    type: "details",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />
  },
  {
    type: "details",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/sign-up",
    component: <SignUp />
  },
  {
    type: "details",
    name: "Forget Password",
    key: "forget-password",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/forget-password",
    component: <ForgetPassword />
  }
];

export default routes;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StoreState, WorkshopState } from "types/state/StoreState";

import { PaginationCommonObject, PaginationRequest } from "types/common";
import {
  createWorkshops,
  getWorkshops,
  updateWorkshops
} from "services/api/workshopsApi";
import { NewWorkshop, Workshop } from "types/Workshop";

const workshopSlice = createSlice({
  name: "workshops",
  initialState: {
    isLoading: false,
    workshops: {}
  } as WorkshopState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setWorkshops(state, action) {
      state.workshops = action.payload;
    }
  }
});
// Thunk Actions
export const getWorkshopsThunk = createAsyncThunk(
  "workshop/getWorkshopsThunk",
  async (request: PaginationRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, page, name } = request;
    dispatch(workshopSlice.actions.setIsLoading(true));
    try {
      const workshops = await getWorkshops(accessToken, page, name);
      dispatch(workshopSlice.actions.setWorkshops(workshops));
    } catch (e) {
      alert(e);
    }

    dispatch(workshopSlice.actions.setIsLoading(false));
  }
);

type NewWorshopRequest = {
  accessToken: string;
  workshop: NewWorkshop;
  isEdit: boolean;
};
export const createWorkshopsThunk = createAsyncThunk(
  "workshop/createWorkshopsThunk",
  async (request: NewWorshopRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { workshop, accessToken, isEdit } = request;
    dispatch(workshopSlice.actions.setIsLoading(true));
    try {
      isEdit
        ? await updateWorkshops(workshop, accessToken)
        : await createWorkshops(workshop, accessToken);

      dispatch(
        getWorkshopsThunk({
          accessToken,
          page: 1
        })
      );
    } catch (e) {
      alert(e);
    }

    dispatch(workshopSlice.actions.setIsLoading(false));
  }
);

// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.workshops.isLoading;
export const getAllWorkshops = (
  state: StoreState
): PaginationCommonObject<Workshop> => state.workshops.workshops;
export const { setWorkshops, setIsLoading } = workshopSlice.actions;
export default workshopSlice.reducer;

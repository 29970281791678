import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "components/MDTable/tableToolbar";
import TableHeader from "components/MDTable/tableHeader";
import { Order } from "components/MDTable/helper";
import { ITableHeader } from "types/Table";
import TablePaginationActions from "components/MDTable/tablePagination";
import MDBox from "components/MDBox";
import { useSelector } from "react-redux";
import { getLoadingStatus } from "redux/slices/monthySubmissionsSlice";
import CircularProgress from "@mui/material/CircularProgress";
import MDButton from "components/MDButton";
import { MonthlySubmission } from "types/MonthlySubmission";
import ConfirmationDialog from "components/ConfirmationDialog";
import { approveMonthlySubmissions } from "services/api/monthlysubmissionApi";
import IconButton from "@mui/material/IconButton/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Tooltip from "@mui/material/Tooltip";
import { PaginationCommonObject } from "types/common";
import InputDebounce from "components/MDInput/inputDebounce";
import { Link } from "@mui/material";

type TableType = {
  header: ITableHeader[];
  rows: PaginationCommonObject<MonthlySubmission>;
  onReload: () => void;
  onCreateOrEdit: (isEdit: boolean, selectedRow?: MonthlySubmission) => void;
  onPageChanged: (newPage: number) => void;
  downloadIntoCsv: () => void;
};
const MonthlySubmissionTable: React.FC<TableType> = (props) => {
  const {
    header,
    rows,
    onReload,
    onCreateOrEdit,
    onPageChanged,
    downloadIntoCsv,
  } = props;
  const accessToken = localStorage.getItem("carching-token");
  const { data } = rows;
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const isLoading = useSelector(getLoadingStatus);
  const [isDialogOpenWithAction, openDialogConfirmation] = useState<
    string | undefined
  >();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const approveDriverMonthlySubmissions = async (
    id: string | undefined
  ): Promise<void> => {
    if (accessToken && !!id) {
      await approveMonthlySubmissions(accessToken, parseInt(id));
      onReload();
    }
    openDialogConfirmation(undefined);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onPageChanged(newPage);
    setPage(newPage);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {!!isDialogOpenWithAction && (
        <ConfirmationDialog
          title={`Are you sure you want to approve this driver?`}
          open={!!isDialogOpenWithAction}
          onClose={(action?: string) => approveDriverMonthlySubmissions(action)}
          action={isDialogOpenWithAction}
          submitButtonTitle={"Approve"}
        />
      )}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          numSelected={0}
          tableName={"Monthly Submission"}
          onReload={onReload}
        />

        <MDBox display={"flex"} justifyContent={"end"}>
          <MDButton
            variant="gradient"
            color="secondary"
            onClick={() => onCreateOrEdit(false)}
            style={{ marginRight: 8 }}
          >
            Add new monthly submissions
          </MDButton>
          <MDButton
            variant="gradient"
            color="primary"
            onClick={downloadIntoCsv}
          >
            Download as CSV
          </MDButton>
        </MDBox>

        {isLoading ? (
          <MDBox display={"flex"} justifyContent={"center"} mt={3}>
            <CircularProgress color={"primary"} />
          </MDBox>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeader
                numSelected={0}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={() => {}}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                headCells={header}
                disableCheckbox={true}
              />
              <TableBody>
                {data.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell align="right">{row.id}</TableCell>
                      <TableCell align="right">
                        {row.driver_campaign_id}
                      </TableCell>
                      <TableCell align="right">{`${row.user.fname} ${row.user.lname}`}</TableCell>
                      <TableCell align="right">
                        <Link
                          href={`/drivers?id=${row.driver_id}`}
                          target="_blank"
                        >
                          {row.driver_id}
                        </Link>
                      </TableCell>
                      <TableCell align="right">{row.status.name}</TableCell>
                      <TableCell align="right">
                        {row.submission_month}
                      </TableCell>
                      <TableCell align="right">
                        <img
                          alt="car front url"
                          src={row.car_front_url}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <img
                          alt="car right url"
                          src={row.car_left_url}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <img
                          alt="car right url"
                          src={row.car_right_url}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <img
                          alt="car back url"
                          src={row.car_back_url}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <img
                          alt="car odometer url"
                          src={row.odometer_url}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <img
                          alt="car pre odometer installation url"
                          src={row.odometer_installation_url}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </TableCell>
                      <TableCell align="right">{`${new Date(
                        row.created_at
                      ).toLocaleDateString()}`}</TableCell>

                      <TableCell align="right" style={{ display: "flex" }}>
                        <IconButton
                          onClick={(e) => {
                            //Prevent whole  row is being selected
                            e.stopPropagation();
                            e.preventDefault();
                            onCreateOrEdit(true, row);
                          }}
                        >
                          <EditIcon color={"secondary"} />
                        </IconButton>
                        {row.status.name !== "Approved" && (
                          <Tooltip title="Approve">
                            <IconButton
                              onClick={() =>
                                openDialogConfirmation(row.id.toString())
                              }
                            >
                              <CheckCircleIcon color={"primary"} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <MDBox display={"inline-flex"} width={"100%"} justifyContent={"end"}>
          <InputDebounce
            type="number"
            name="search page"
            placeholder="search specify page"
            onChange={(value: string) => {
              const page = Number(value);
              handleChangePage(null, page === 0 ? 1 : page - 1);
            }}
            width={"10%"}
          />
          <TablePagination
            rowsPerPageOptions={[15]}
            component="div"
            count={rows.total}
            rowsPerPage={rows.per_page}
            page={rows.total <= 0 ? 0 : page}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        </MDBox>
      </Paper>
    </Box>
  );
};
export default MonthlySubmissionTable;

import LoadingComponent from "components/LoadingComponent";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import ReportTable from "./table";
import { getAllReports, getAllReportsThunk } from "redux/slices/reportSlice";

const header = [
  { id: "id", name: "Report Id" },
  { id: "title", name: "Title" },
  { id: "campaign_id", name: "Campaign Id" },
  { id: "progress", name: "Progress" },
  { id: "flag", name: "Flags" },
  { id: "status", name: "Status" },
  { id: "url", name: "URL" },
  { id: "created_at", name: "Created At" }
];
const ReportPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const reports = useSelector(getAllReports);

  useEffect(() => {
    if (!!accessToken && !!reports && !reports.length) {
      getAllReportData();
    }
  }, [accessToken]);

  const getAllReportData = () => {
    dispatch(getAllReportsThunk(accessToken));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {!!reports ? (
        <ReportTable
          header={header}
          rows={reports}
          onReload={() => getAllReportData()}
        />
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(ReportPage);

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SettingState, StoreState } from "types/state/StoreState";
import { Variables } from "types/Setting";
import {
  createCarSettings,
  createSettingVariables,
  getAllCarBrands,
  getAllCarColors,
  getAllCarModels,
  getAllSettings,
  updateCarSettings,
} from "services/api/settingsApi";
import { CarBrand, CarColor, CarModel, CarSettings } from "types/Cars";
import { PaginationRequest } from "types/common";
import { CarRoutesEnum } from "types/enum/Common";

const settingSlice = createSlice({
  name: "settings",
  initialState: {
    isLoading: false,
    variables: [] as Variables[],
    carBrands: [] as CarBrand[],
    carColors: [] as CarColor[],
    carModels: [] as CarModel[],
  } as SettingState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setVariables(state, action) {
      state.variables = action.payload;
    },
    setCarModels(state, action) {
      state.carModels = action.payload;
    },
    setCarBrands(state, action) {
      state.carBrands = action.payload;
    },
    setCarColors(state, action) {
      state.carColors = action.payload;
    },
  },
});

// Thunk Actions
export const getVariablesThunk = createAsyncThunk(
  "settings/getVariablesThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(settingSlice.actions.setIsLoading(true));
    const variables = await getAllSettings(accessToken);
    dispatch(settingSlice.actions.setVariables(variables));
    dispatch(settingSlice.actions.setIsLoading(false));
  }
);
export const getCarSettingThunk = createAsyncThunk(
  "settings/getCarSettingThunk",
  async (request: PaginationRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, name } = request;
    dispatch(settingSlice.actions.setIsLoading(true));

    if (!!name) {
      switch (name) {
        case CarRoutesEnum.CARBRAND: {
          const carBrands = await getAllCarBrands(accessToken);
          dispatch(settingSlice.actions.setCarBrands(carBrands));
          break;
        }
        case CarRoutesEnum.CARMODELS: {
          const carModels = await getAllCarModels(accessToken);
          dispatch(settingSlice.actions.setCarModels(carModels));
          break;
        }
        case CarRoutesEnum.CARCOLORS: {
          const carColors = await getAllCarColors(accessToken);
          dispatch(settingSlice.actions.setCarColors(carColors));
          break;
        }
        default: {
          console.warn("No car type is available");
        }
      }
    }
    dispatch(settingSlice.actions.setIsLoading(false));
  }
);

type NewVariablesRequest = {
  accessToken: string;
  variables: any;
};
export const createOrEditVariablesThunk = createAsyncThunk(
  "settings/createOrEditPartnerThunk",
  async (request: NewVariablesRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { variables, accessToken } = request;
    dispatch(settingSlice.actions.setIsLoading(true));
    await createSettingVariables(variables, accessToken);
    dispatch(getVariablesThunk(accessToken));
  }
);

type NewCarSettingsRequest = {
  accessToken: string;
  setting: CarSettings;
  carType: string;
  isEdit: boolean;
};
export const createOrEditCarSettingsThunk = createAsyncThunk(
  "settings/createOrEditCarSettingsThunk",
  async (request: NewCarSettingsRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { setting, accessToken, carType, isEdit } = request;
    dispatch(settingSlice.actions.setIsLoading(true));
    isEdit
      ? await updateCarSettings(setting, carType, accessToken)
      : await createCarSettings(setting, carType, accessToken);
    dispatch(getCarSettingThunk({ accessToken, page: 0, name: carType }));
  }
);
// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.settings.isLoading;
export const getVariables = (state: StoreState): Variables[] =>
  state.settings.variables;
export const getCarBrands = (state: StoreState): CarBrand[] =>
  state.settings.carBrands;
export const getCarModels = (state: StoreState): CarModel[] =>
  state.settings.carModels;
export const getCarColors = (state: StoreState): CarColor[] =>
  state.settings.carColors;

export default settingSlice.reducer;

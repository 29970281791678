import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  MonthlySubmission,
  NewMonthlySubmission,
} from "types/MonthlySubmission";
import { MonthlySubmissionState, StoreState } from "types/state/StoreState";
import {
  createMonthlySubmissions,
  getAllMonthlySubmissions,
  updateMonthlySubmissions,
} from "services/api/monthlysubmissionApi";
import { PaginationCommonObject, PaginationRequest } from "types/common";

const monthlySubmissionsSlice = createSlice({
  name: "monthlySubmissions",
  initialState: {
    isLoading: false,
    monthlySubmissions: {},
  } as MonthlySubmissionState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setMonthlySubmissions(state, action) {
      state.monthlySubmissions = action.payload;
    },
  },
});

// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.monthlySubmissions.isLoading;

export const getMonthlySubmissions = (
  state: StoreState
): PaginationCommonObject<MonthlySubmission> =>
  state.monthlySubmissions.monthlySubmissions;

// Thunk Actions
export const getMonthlySubmissionsThunk = createAsyncThunk(
  "monthlySubmissions/getMonthlySubmissionsThunk",
  async (request: PaginationRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, page } = request;
    dispatch(monthlySubmissionsSlice.actions.setIsLoading(true));
    const monthlySubmissions = await getAllMonthlySubmissions(
      accessToken,
      page
    );

    dispatch(
      monthlySubmissionsSlice.actions.setMonthlySubmissions(monthlySubmissions)
    );
    dispatch(monthlySubmissionsSlice.actions.setIsLoading(false));
  }
);
interface MonthlySubmissionRequest {
  accessToken: string;
  monthlySubmission: NewMonthlySubmission;
  isEdit: boolean;
  currentPage: number;
}
export const createOrEditMonthlySubmissionsThunk = createAsyncThunk(
  "monthlySubmissions/createMonthlySubmissionsThunk",
  async (request: MonthlySubmissionRequest, thunkApi) => {
    const { isEdit, accessToken, monthlySubmission, currentPage } = request;
    const { dispatch } = thunkApi;
    dispatch(monthlySubmissionsSlice.actions.setIsLoading(true));
    isEdit
      ? await updateMonthlySubmissions(accessToken, monthlySubmission)
      : await createMonthlySubmissions(accessToken, monthlySubmission);
    dispatch(monthlySubmissionsSlice.actions.setIsLoading(false));

    dispatch(getMonthlySubmissionsThunk({ accessToken, page: currentPage }));
  }
);

export default monthlySubmissionsSlice.reducer;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableToolbar from "components/MDTable/tableToolbar";
import TableHeader from "components/MDTable/tableHeader";
import { Order } from "components/MDTable/helper";
import { ITableHeader } from "types/Table";
import TablePaginationActions from "components/MDTable/tablePagination";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import LaunchIcon from "@mui/icons-material/Launch";
import CircularProgress from "@mui/material/CircularProgress";
import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { PaginationCommonObject } from "types/common";
import {
  createCampaignAppointmentThunk,
  getLoadingStatus,
} from "redux/slices/campaignSlice";
import { CampaignWorkshopAppointment } from "types/AppointmentsSubmission";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ConfirmationDialog from "components/ConfirmationDialog";
import { markAppointmentAsReady } from "services/api/campaignApi";
import MDButton from "components/MDButton";
import AddAppointmentModal from "./Modal";
import { CampaignAppointment } from "types/Campaign";

type TableType = {
  header: ITableHeader[];
  rows: PaginationCommonObject<CampaignWorkshopAppointment>;
  onPageChanged: (newPage: number) => void;
  onReload: () => void;
  campaignId: number;
};
const CampaignAppointmentTable: React.FC<TableType> = (props) => {
  const { header, rows, onReload, onPageChanged, campaignId } = props;
  const { data } = rows;
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const isLoading = useSelector(getLoadingStatus);
  const [isDialogOpenWithAction, openDialogConfirmation] = useState<
    string | undefined
  >();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onPageChanged(newPage);
    setPage(newPage);
  };

  const markSelectedAppointmentAsReady = async (
    id: string | undefined
  ): Promise<void> => {
    if (accessToken && !!id) {
      await markAppointmentAsReady(accessToken, parseInt(id));
      onReload();
    }
    openDialogConfirmation(undefined);
  };

  const createCampaignAppointment = async (request: CampaignAppointment) => {
    dispatch(
      createCampaignAppointmentThunk({
        accessToken,
        currentPage: page,
        appointments: request,
      })
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      {!!isDialogOpenWithAction && (
        <ConfirmationDialog
          title={`Are you sure you want to mark this driver as ready?`}
          open={!!isDialogOpenWithAction}
          onClose={(action?: string) => markSelectedAppointmentAsReady(action)}
          action={isDialogOpenWithAction}
          submitButtonTitle={"Confirm"}
        />
      )}
      {isModalOpen && (
        <AddAppointmentModal
          open={isModalOpen}
          handleClose={() => {
            setModalOpen(false);
          }}
          campaignId={campaignId}
          addAppointment={createCampaignAppointment}
        />
      )}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          numSelected={0}
          tableName={"Campaign"}
          onReload={onReload}
        />
        <MDBox display={"flex"} justifyContent={"end"}>
          <MDButton
            variant="gradient"
            color="secondary"
            component={Link}
            onClick={() => {
              setModalOpen(true);
            }}
            style={{ marginRight: 8 }}
          >
            Create appointment
          </MDButton>
        </MDBox>
        {isLoading ? (
          <MDBox display={"flex"} justifyContent={"center"} mt={3}>
            <CircularProgress color={"primary"} />
          </MDBox>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeader
                numSelected={0}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={() => {}}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                headCells={header}
                disableCheckbox={true}
              />
              <TableBody>
                {data.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell align="right">{row.id}</TableCell>
                      <TableCell align="right">
                        {row.user ? `${row.user.fname} ${row.user.lname}` : ""}
                      </TableCell>
                      <TableCell align="right">{row.workshop_id}</TableCell>
                      <TableCell align="right">{row.status.name}</TableCell>
                      <TableCell align="right">{`${row.date} ${row.time}`}</TableCell>

                      <TableCell align="right">
                        <Link href={""} target="_blank">
                          <LaunchIcon />
                        </Link>
                        {row.status.name !== "Ready" && (
                          <Tooltip title="Mark as ready">
                            <IconButton
                              onClick={() =>
                                openDialogConfirmation(row.id.toString())
                              }
                            >
                              <CheckCircleIcon color={"primary"} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={rows.total}
          rowsPerPage={rows.per_page}
          page={rows.total <= 0 ? 0 : page}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </Box>
  );
};
export default CampaignAppointmentTable;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NotificationState, StoreState } from "types/state/StoreState";
import { NewNotification, Notification } from "types/Notification";
import {
  createNotification,
  getNotifications,
} from "services/api/notificationApi";
import { PaginationCommonObject, PaginationRequest } from "types/common";

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    isLoading: false,
    notifications: {},
  } as NotificationState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
  },
});
// Thunk Actions
export const getNotificationThunk = createAsyncThunk(
  "notification/getNotificationThunk",
  async (request: PaginationRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, page } = request;
    dispatch(notificationSlice.actions.setIsLoading(true));
    const notification = await getNotifications(accessToken, page);
    dispatch(notificationSlice.actions.setNotifications(notification));
    dispatch(notificationSlice.actions.setIsLoading(false));
  }
);

type NewNotificationrRequest = {
  accessToken: string;
  newNotification: NewNotification;
};
export const createNotificationThunk = createAsyncThunk(
  "notification/createNotificationThunk",
  async (request: NewNotificationrRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { newNotification, accessToken } = request;
    dispatch(notificationSlice.actions.setIsLoading(true));
    await createNotification(newNotification, accessToken);
    dispatch(notificationSlice.actions.setIsLoading(false));
  }
);

// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.notifications.isLoading;
export const getAllNotifications = (
  state: StoreState
): PaginationCommonObject<Notification> => state.notifications.notifications;

export const { setNotifications, setIsLoading } = notificationSlice.actions;
export default notificationSlice.reducer;

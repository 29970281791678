import { Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useState } from "react";
import CampaignDefaultSetting from "../campaignDetails/campaignSettings";
import CampaignAppointment from "./campaignAppointment";
import CampaignAssets from "./campaignAssets";
import CampaignParticipants from "./campaignParticipants";
import CampaignWorkshop from "./campaignWorkshops";
import CampaignSummaryTab from "./campaignSummary";

type Props = {
  campaignId: number;
};
const EditCampaign: React.FC<Props> = (props) => {
  const { campaignId } = props;
  const [activeTab, setActiveTab] = useState<number>(0);
  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Campaign Summary" value={0} />
        <Tab label="Edit Campaign" value={1} />
        <Tab label="Campaign Participant" value={2} />
        <Tab label="Campaign Worshops" value={3} />
        <Tab label="Campaign Workshop Appointments" value={4} />
        <Tab label="Campaign Assets" value={5} />
      </Tabs>
      <MDBox pt={2}>
        {activeTab === 0 && <CampaignSummaryTab />}
        {activeTab === 1 && <CampaignDefaultSetting isEdit={true} />}
        {activeTab === 2 && <CampaignParticipants campaignId={campaignId} />}
        {activeTab === 3 && <CampaignWorkshop />}
        {activeTab === 4 && <CampaignAppointment />}
        {activeTab === 5 && <CampaignAssets campaignId={campaignId} />}
      </MDBox>
    </>
  );
};
export default EditCampaign;

import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import TextField from "@mui/material/TextField/TextField";
import { CampaignAppointment } from "types/Campaign";
import SelectInput from "components/MDInput/SelectInput";

const initialValues: CampaignAppointment = {
  workshop_id: 0,
  date: "",
  time: "",
  campaign_id: 0,
  type: "",
};
type AddAppointmentModalProp = {
  open: boolean;
  handleClose: () => void;
  addAppointment: (request: CampaignAppointment) => void;
  campaignId: number;
};
const AddAppointmentModal: React.FC<AddAppointmentModalProp> = (props) => {
  const { open, handleClose, addAppointment, campaignId } = props;
  const formikRef = useRef<any>(null);

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = (values) => {
    const newValues = {
      ...values,
      campaign_id: campaignId,
    };
    addAppointment(newValues);
  };

  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`New Appointment`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="number"
                      label="Workshop Id"
                      name="workshop_id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.workshop_id}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="text"
                      label="Campaign Id"
                      name="campaign_id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={campaignId}
                      disabled
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="time"
                      label="Date at"
                      name="time"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.time}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <TextField
                      label="Date"
                      type="date"
                      name={"date"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.date}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox width={"50%"} mr={3.5}>
                  <SelectInput
                    title="Type"
                    items={[
                      { id: "removal", name: "Removal" },
                      { id: "installation", name: "Installation" },
                    ]}
                    onSelected={(searchValue) =>
                      setFieldValue("type", searchValue)
                    }
                    width={"38ch"}
                    margin={0}
                  />
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {"Add Appointment"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddAppointmentModal;

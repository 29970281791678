import { Box, Paper } from "@mui/material";
import MDBox from "components/MDBox";
import SelectInput from "components/MDInput/SelectInput";
import AccordionDefault from "features/Accordion";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  getCampaignDetails,
  getCampaignWorkshops,
} from "redux/slices/campaignSlice";
import { CampaignWorkshops } from "types/Campaign";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import WorkshopChild from "./child";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { createAndUpdateCampaignWorkshops } from "services/api/campaignApi";

const CampaignWorkshop: React.FC = () => {
  const workshops = useSelector(getCampaignWorkshops);
  const campaign = useSelector(getCampaignDetails);
  const accessToken = localStorage.getItem("carching-token") || "";
  const [formValues, setFormValues] = useState<CampaignWorkshops[]>(
    campaign.workshops ?? []
  );

  const addFormFields = (addedWorkshopId: string) => {
    const addedWorkshop = workshops.data.find(
      (workshop) => workshop.id === Number(addedWorkshopId)
    );
    const isExistedWorkshop = formValues.find(
      (workshop) => workshop.workshop_id === Number(addedWorkshopId)
    );
    if (addedWorkshop && !isExistedWorkshop) {
      const campaignWorkshop: CampaignWorkshops = {
        campaign_id: campaign.id,
        created_at: "string",
        id: 0,
        schedules: [],
        status_id: 0,
        workshop: addedWorkshop,
        workshop_id: addedWorkshop.id,
      };
      setFormValues([...formValues, campaignWorkshop]);
    }
  };
  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const updateWorkshopCampaign = async (): Promise<void> => {
    const createOrUpdate = {
      campaign_id: campaign.id,
      workshops: JSON.stringify(
        formValues.map((workshop) => {
          return {
            workshop_id: workshop.workshop_id,
            schedules: workshop.schedules.reduce(
              (newSchedule, currentSchedule) => {
                const { installation_date } = currentSchedule;
                newSchedule[installation_date] = currentSchedule;
                return newSchedule;
              },
              {}
            ),
          };
        })
      ),
    };
    await createAndUpdateCampaignWorkshops(createOrUpdate, accessToken);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <MDBox sx={{ width: "100%", mb: 2 }}>
        <MDBox>
          <SelectInput
            title="Select Workshop"
            items={workshops.data || []}
            onSelected={(searchValue) => {
              addFormFields(searchValue);
            }}
            width={"30ch"}
          />
        </MDBox>
        <MDBox>
          {campaign &&
            campaign.workshops &&
            formValues.map((workshop, index) => {
              const { schedules } = workshop;
              return (
                <Paper key={index} style={{ marginBottom: 10 }}>
                  <AccordionDefault
                    title={workshop.workshop ? workshop.workshop.name : ""}
                    panel=""
                    onChange={() => {}}
                  >
                    <IconButton
                      onClick={() => removeFormFields(index)}
                      style={{ float: "right" }}
                    >
                      <MDTypography color="primary">remove</MDTypography>
                      <DeleteIcon color="primary" />
                    </IconButton>
                    {schedules && (
                      <WorkshopChild
                        currentWorkshop={workshop.workshop}
                        schedules={schedules}
                        workshopIndex={index}
                        workshops={formValues}
                        onChangeWorkshops={(campaignWorkshop) =>
                          setFormValues(campaignWorkshop)
                        }
                      />
                    )}
                  </AccordionDefault>
                </Paper>
              );
            })}
        </MDBox>
      </MDBox>
      <MDButton
        variant="gradient"
        color="primary"
        onClick={updateWorkshopCampaign}
      >
        update workshop
      </MDButton>
    </Box>
  );
};
export default CampaignWorkshop;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CareFreeState, StoreState } from "types/state/StoreState";
import {
  getAllCareFreeBookings,
  getAllCareFreeDrivers,
  getAllCareFreePostings,
  getAllHomeOwners,
  getCarefreeUsers,
  getCarefreeUsersWithoutPaginationApi,
  getPendingCareFreeDrivers,
  getPendingHomeOwners,
  updateCareFreeDriversrById,
  updateHomeOwnerById,
} from "services/api/carefreeApi";
import {
  CareFreeBooking,
  CarefreeDrivers,
  CareFreeHomeOwner,
  CareFreePosting,
} from "types/Carefree";
import { AllUsers } from "types/AllUsers";
import { User } from "types/User";

const carefreeSlice = createSlice({
  name: "carefree",
  initialState: {
    homeowners: [],
    drivers: [],
    pendingOwners: {},
    pendingDrivers: {},
    isLoading: true,
    bookings: [],
    postings: [],
    carefreUsers: {} as AllUsers,
    allUsersWithoutPagination: [],
  } as CareFreeState,
  reducers: {
    setAllOwners(state, action) {
      state.homeowners = action.payload;
    },
    setAllDrivers(state, action) {
      state.drivers = action.payload;
    },
    setLoadingStatus(state, action) {
      state.isLoading = action.payload;
    },
    setPendingOwners(state, action) {
      state.pendingOwners = action.payload;
    },
    setPendingDrivers(state, action) {
      state.pendingDrivers = action.payload;
    },
    setAllBooking(state, action) {
      state.bookings = action.payload;
    },
    setAllPosting(state, action) {
      state.postings = action.payload;
    },
    setAllUsers(state, action) {
      state.carefreUsers = action.payload;
    },
    setAllUsersWithouthPagination(state, action) {
      state.allUsersWithoutPagination = action.payload;
    },
  },
});
export const getLoadingStatus = (state: StoreState): boolean =>
  state.carefree.isLoading;
export const getHomeOwners = (state: StoreState): CareFreeHomeOwner[] =>
  state.carefree.homeowners;

export const getCarefreeDrivers = (state: StoreState): CarefreeDrivers[] =>
  state.carefree.drivers;

export const getAllPendingHomeOwners = (state: StoreState): any =>
  state.carefree.pendingOwners;

export const getAllPendingDriver = (state: StoreState): any =>
  state.carefree.pendingDrivers;

export const getAllBooking = (state: StoreState): CareFreeBooking[] =>
  state.carefree.bookings;
export const getAllPostings = (state: StoreState): CareFreePosting[] =>
  state.carefree.postings;

export const getAllCarefreeUsers = (state: StoreState): AllUsers =>
  state.carefree.carefreUsers;

export const getAllCarefreeUsersWithoutPagination = (
  state: StoreState
): User[] => state.carefree.allUsersWithoutPagination;

export const getAllHomeOwnersThunk = createAsyncThunk(
  "carefree/getAllHomeOwnersThunk",
  async (request: any, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(carefreeSlice.actions.setLoadingStatus(true));
    const owners = await getAllHomeOwners(request);
    dispatch(carefreeSlice.actions.setAllOwners(owners));
    dispatch(carefreeSlice.actions.setLoadingStatus(false));
  }
);
export const getAllPendingHomeOwnersThunk = createAsyncThunk(
  "carefree/getAllPendingHomeOwnersThunk",
  async (request: any, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(carefreeSlice.actions.setLoadingStatus(true));
    const owners = await getPendingHomeOwners(request);
    dispatch(carefreeSlice.actions.setAllOwners(owners));
    dispatch(carefreeSlice.actions.setLoadingStatus(false));
  }
);
// Driver
export const getAllCarefreeDriversThunk = createAsyncThunk(
  "carefree/getAllDriversThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(carefreeSlice.actions.setLoadingStatus(true));
    const drivers = await getAllCareFreeDrivers(accessToken);
    dispatch(carefreeSlice.actions.setAllDrivers(drivers));
    dispatch(carefreeSlice.actions.setLoadingStatus(false));
  }
);
export const getAllPendingDriversThunk = createAsyncThunk(
  "carefree/getAllPendingDriversThunk",
  async (request: any, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(carefreeSlice.actions.setLoadingStatus(true));
    const drivers = await getPendingCareFreeDrivers(request);
    dispatch(carefreeSlice.actions.setAllDrivers(drivers));
    dispatch(carefreeSlice.actions.setLoadingStatus(false));
  }
);
export interface EditRequest {
  editRequest: any;
  accessToken: string;
}
export const editHomeOwnersThunk = createAsyncThunk(
  "carefree/editHomeOwnersThunk",
  async (request: EditRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { editRequest, accessToken } = request;
    dispatch(carefreeSlice.actions.setLoadingStatus(true));
    await updateHomeOwnerById(editRequest, accessToken);
    dispatch(getAllHomeOwnersThunk(""));
  }
);

export const editDriverThunk = createAsyncThunk(
  "carefree/editDriverThunk",
  async (request: EditRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { editRequest, accessToken } = request;
    dispatch(carefreeSlice.actions.setLoadingStatus(true));
    await updateCareFreeDriversrById(editRequest, accessToken);
    dispatch(getAllHomeOwnersThunk(""));
  }
);

export const getAllBookingsThunk = createAsyncThunk(
  "carefree/getAllBookingsThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(carefreeSlice.actions.setLoadingStatus(true));
    const booking = await getAllCareFreeBookings(accessToken);
    dispatch(carefreeSlice.actions.setAllBooking(booking));
    dispatch(carefreeSlice.actions.setLoadingStatus(false));
  }
);

export const getAllPostingsThunk = createAsyncThunk(
  "carefree/getAllPostingsThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(carefreeSlice.actions.setLoadingStatus(true));
    const postings = await getAllCareFreePostings(accessToken);
    dispatch(carefreeSlice.actions.setAllPosting(postings));
    dispatch(carefreeSlice.actions.setLoadingStatus(false));
  }
);

export const getAllCarefreeUsersThunk = createAsyncThunk(
  "carefree/getAllCarefreeUsersThunk",
  async (request: any, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(carefreeSlice.actions.setLoadingStatus(true));
    const users = await getCarefreeUsers(request);
    dispatch(carefreeSlice.actions.setAllUsers(users));
    dispatch(carefreeSlice.actions.setLoadingStatus(false));
  }
);
export const getAllCarefreeUsersWithoutPaginationThunk = createAsyncThunk(
  "carefree/getAllCarefreeUsersWithoutPaginationThunk",
  async (request: any, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(carefreeSlice.actions.setLoadingStatus(true));
    const users = await getCarefreeUsersWithoutPaginationApi(request);
    dispatch(carefreeSlice.actions.setAllUsersWithouthPagination(users));
    dispatch(carefreeSlice.actions.setLoadingStatus(false));
  }
);
export const { setAllOwners } = carefreeSlice.actions;

export default carefreeSlice.reducer;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "components/MDTable/tableToolbar";
import TableHeader from "components/MDTable/tableHeader";
import { Order } from "components/MDTable/helper";
import { ITableHeader } from "types/Table";
import TablePaginationActions from "components/MDTable/tablePagination";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSelector } from "react-redux";
import { getLoadingStatus } from "redux/slices/journeySlice";
import CircularProgress from "@mui/material/CircularProgress";
import { JourneyPagination, NewJourney } from "types/Journeys";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import InputDebounce from "components/MDInput/inputDebounce";
import { Link } from "@mui/material";

type TableType = {
  header: ITableHeader[];
  rows: JourneyPagination;
  downloadIntoCsv: () => void;
  onReload: (currentPage: number) => void;
  addNewOrEditJourney: (isEdit: boolean, selectedJourney?: NewJourney) => void;
  onPageChanged: (newPage: number) => void;
};
const JourneyTable: React.FC<TableType> = (props) => {
  const {
    header,
    rows,
    downloadIntoCsv,
    onReload,
    addNewOrEditJourney,
    onPageChanged,
  } = props;
  const { data } = rows;
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const isLoading = useSelector(getLoadingStatus);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onPageChanged(newPage);
    setPage(newPage);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          numSelected={0}
          tableName={"Journeys"}
          onReload={() => onReload(page)}
        />

        <MDBox display={"flex"} justifyContent={"end"}>
          <MDButton
            variant="gradient"
            color="secondary"
            onClick={() => addNewOrEditJourney(false)}
            style={{ marginRight: 8 }}
          >
            Add journey
          </MDButton>
          <MDButton
            variant="gradient"
            color="primary"
            onClick={downloadIntoCsv}
          >
            Download as CSV
          </MDButton>
        </MDBox>

        {isLoading ? (
          <MDBox display={"flex"} justifyContent={"center"} mt={3}>
            <CircularProgress color={"primary"} />
          </MDBox>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeader
                numSelected={0}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={() => {}}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                headCells={header}
                disableCheckbox={true}
              />
              <TableBody>
                {data.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell align="right">{row.id}</TableCell>

                      <TableCell align="right">
                        <Link
                          href={`/drivers?id=${row.driver_id}`}
                          target="_blank"
                        >
                          {row.driver_id}
                        </Link>
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          href={`/campaigns/${
                            row.campaign ? row.campaign.id : ""
                          }`}
                          target="_blank"
                        >
                          {row.campaign ? row.campaign.id : ""}
                        </Link>
                      </TableCell>
                      <TableCell align="right">
                        {row.campaign ? row.campaign.title : ""}
                      </TableCell>
                      <TableCell align="right">{`${
                        row.user ? `${row.user.fname} ${row.user.lname}` : ""
                      }`}</TableCell>
                      <TableCell align="right">{row.points}</TableCell>
                      <TableCell align="right">{row.total_distance}</TableCell>
                      <TableCell align="right">{row.actual_distance}</TableCell>
                      <TableCell align="right">{row.started_at}</TableCell>
                      <TableCell align="right">{row.ended_at}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={(e) => {
                            //Prevent whole  row is being selected
                            e.stopPropagation();
                            e.preventDefault();
                            addNewOrEditJourney(true, {
                              driver_id: row.driver_id,
                              campaign_id: row.campaign.id,
                              datetime: row.started_at,
                              distance: row.total_distance,
                              points: row.points,
                              journey_id: row.id,
                            } as NewJourney);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <MDBox display={"inline-flex"} width={"100%"} justifyContent={"end"}>
          <InputDebounce
            type="number"
            name="search page"
            placeholder="search specify page"
            onChange={(value: string) => {
              const page = Number(value);
              handleChangePage(null, page === 0 ? 1 : page - 1);
            }}
            width={"10%"}
          />
          <TablePagination
            rowsPerPageOptions={[15]}
            component="div"
            count={rows.total}
            rowsPerPage={rows.per_page}
            page={rows.total <= 0 ? 0 : page}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        </MDBox>
      </Paper>
    </Box>
  );
};
export default JourneyTable;

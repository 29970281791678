import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "components/MDTable/tableToolbar";
import TableHeader from "components/MDTable/tableHeader";
import { Order } from "components/MDTable/helper";
import { ITableHeader } from "types/Table";
import TablePaginationActions from "components/MDTable/tablePagination";
import MDBox from "components/MDBox";
import InputDebounce from "components/MDInput/inputDebounce";
import { useSelector } from "react-redux";

import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";
import { Icon, IconButton, Tooltip } from "@mui/material";
import { getDriverStatus, getLoadingStatus } from "redux/slices/driverSlice";
import SelectInput from "components/MDInput/SelectInput";
import { CarefreeDrivers } from "types/Carefree";
import ConfirmationDialog from "components/ConfirmationDialog";
import {
  approveCareFreeDrivers,
  rejectCareFreeDrivers,
} from "services/api/carefreeApi";
import MoreActionsMenu from "components/MoreActionsMenu";
import { TransactionActionsEnum } from "types/enum/transactions";

type TableType = {
  header: ITableHeader[];
  rows: CarefreeDrivers[];
  onPageChanged: (newPage: number) => void;
  handleUserSearch: (searchType: string, searchInput: string) => void;
  onReload: () => void;
  addNewOrEditDriver: (isEdit: boolean, selectedUser?: CarefreeDrivers) => void;
};
const moreActions = [
  {
    name: TransactionActionsEnum.Approve,
    icon: <Icon fontSize="small">check_circle</Icon>,
  },
  {
    name: TransactionActionsEnum.Reject,
    icon: <Icon fontSize="small">unpublished</Icon>,
  },
];
const DriversTable: React.FC<TableType> = (props) => {
  const {
    header,
    rows,
    onPageChanged,
    handleUserSearch,
    onReload,
    addNewOrEditDriver,
  } = props;
  const accessToken = localStorage.getItem("carching-token");
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState<number>(0);
  const isLoading = useSelector(getLoadingStatus);
  const driverStatuses = useSelector(getDriverStatus);
  const [selectedRow, setSelectedRow] = useState<CarefreeDrivers | undefined>();
  const [isDialogOpenWithAction, openDialogConfirmation] = useState<
    string | undefined
  >();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleActionButtons = async (action?: string): Promise<void> => {
    if (accessToken && !!selectedRow) {
      if (action === TransactionActionsEnum.Approve) {
        await approveCareFreeDrivers(selectedRow.id, accessToken);
      } else if (action === TransactionActionsEnum.Reject) {
        await rejectCareFreeDrivers(selectedRow.id, accessToken);
      } else if (action === "Edit") {
        addNewOrEditDriver(true, selectedRow);
      }
    }
    openDialogConfirmation(undefined);
    setSelectedRow(undefined);
    if (!!action) onReload();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: "100%" }}>
      {!!isDialogOpenWithAction && (
        <ConfirmationDialog
          title={`Are you sure you want to approve this driver?`}
          open={!!isDialogOpenWithAction}
          onClose={(action?: string) => handleActionButtons(action)}
          action={isDialogOpenWithAction}
          submitButtonTitle={"Approve"}
        />
      )}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          numSelected={selected.length}
          tableName={"Carefree Drivers"}
          onReload={onReload}
        />
        <MDBox
          display={"inline-flex"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <MDBox>
            <InputDebounce
              type="text"
              name="id"
              placeholder="Id"
              onChange={(searchValue) => handleUserSearch("id", searchValue)}
              width={"10ch"}
            />
            <InputDebounce
              type="text"
              name="carplate"
              placeholder="Car Plate"
              onChange={(searchValue) =>
                handleUserSearch("car_plate", searchValue)
              }
            />
            <InputDebounce
              type="text"
              name="name"
              placeholder="Name"
              onChange={(searchValue) => handleUserSearch("name", searchValue)}
            />
            {!!driverStatuses && (
              <SelectInput
                title="Filter by status"
                items={driverStatuses}
                onSelected={(searchValue) =>
                  handleUserSearch("status_id", searchValue)
                }
              />
            )}
          </MDBox>
        </MDBox>
        {isLoading ? (
          <MDBox display={"flex"} justifyContent={"center"} mt={3}>
            <CircularProgress color={"primary"} />
          </MDBox>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeader
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={() => {}}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={header}
                disableCheckbox={true}
              />
              <TableBody>
                {rows.slice(page * 10, page * 10 + 10).map((row, index) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell align="right">{row.id}</TableCell>
                      <TableCell align="right">
                        {/*row.car_plate*/ row.plate_number}
                      </TableCell>
                      <TableCell align="right">
                        {row.driver ? `${row.driver.full_name}` : ""}
                      </TableCell>
                      <TableCell align="right">{`${
                        row.driver ? row.driver.ic_number : ""
                      }`}</TableCell>
                      <TableCell align="right">{row.status_id}</TableCell>
                      <TableCell align="right">
                        {row.status_id === 63 ? (
                          <div
                            style={{
                              display: "flex",
                              float: "right",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              onClick={(e) => {
                                //Prevent whole  row is being selected
                                e.stopPropagation();
                                e.preventDefault();
                                addNewOrEditDriver(true, row);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <MoreActionsMenu
                              menuItems={moreActions}
                              onActionSelected={(action: string) => {
                                setSelectedRow(row);
                                openDialogConfirmation(action);
                              }}
                            />
                          </div>
                        ) : (
                          <IconButton
                            onClick={(e) => {
                              //Prevent whole  row is being selected
                              e.stopPropagation();
                              e.preventDefault();
                              addNewOrEditDriver(true, row);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={rows.length}
          rowsPerPage={10}
          page={rows.length <= 0 ? 0 : page}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </Box>
  );
};
export default DriversTable;

import ImageUpload from "components/ImageUpload";
import MDBox from "components/MDBox";
import React from "react";
import { CarefreeDrivers } from "types/Carefree";

type Props = {
  setFieldValue: (field: string, value: any) => void;
  values: CarefreeDrivers;
};
const DriverCarImages: React.FC<Props> = (props) => {
  const { values, setFieldValue } = props;
  return (
    <MDBox>
      <MDBox mb={2} display={"flex"}>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="Post Car Front"
            selectedImage={values.car_front_picture_url}
            handleImageUpload={(image) =>
              setFieldValue("car_front_picture_url", image)
            }
          />
        </MDBox>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="Post Car Left"
            selectedImage={values.car_back_picture_url}
            handleImageUpload={(image) =>
              setFieldValue("car_back_picture_url", image)
            }
          />
        </MDBox>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="Post Car Right"
            selectedImage={values.car_right_picture_url}
            handleImageUpload={(image) =>
              setFieldValue("car_right_picture_url", image)
            }
          />
        </MDBox>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="Post Car Back"
            selectedImage={values.car_left_picture_url}
            handleImageUpload={(image) =>
              setFieldValue("car_left_picture_url", image)
            }
          />
        </MDBox>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="Post Car Back"
            selectedImage={values.roadtax_picture_url}
            handleImageUpload={(image) =>
              setFieldValue("roadtax_picture_url", image)
            }
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default DriverCarImages;

import React, { useEffect } from "react";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "components/MDInput/SelectInput";
import {
  getDriverCarBrand,
  getDriverCarColor,
  getDriverCarModel,
  getDriverCarsOptionsThunk,
} from "redux/slices/driverSlice";
import { Drivers } from "types/AllUsers";
import { CarefreeDrivers } from "types/Carefree";

type Props = {
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  values: CarefreeDrivers;
};

const DriverCarDetails: React.FC<Props> = (props) => {
  const { values, handleChange, handleBlur, setFieldValue } = props;
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();

  const carBrands = useSelector(getDriverCarBrand);
  const carColors = useSelector(getDriverCarColor);
  const carModels = useSelector(getDriverCarModel);

  useEffect(() => {
    if (!!accessToken) dispatch(getDriverCarsOptionsThunk(accessToken));
  }, [accessToken, dispatch]);

  const setSelectFormFieldValue = (field: string, value: string): void => {
    if (field === "car_brand") {
      const carBrand = carBrands.find((brand) => brand.id === Number(value));
      setFieldValue("car_brand", carBrand);
    } else if (field === "car_model") {
      const carModel = carModels.find((model) => model.id === Number(value));
      setFieldValue("car_model", carModel);
    } else {
      const carColor = carColors.find((color) => color.id === Number(value));
      setFieldValue("car_color", carColor);
    }
  };
  return (
    <div>
      <MDBox mb={2} display={"flex"}>
        <MDBox width={"50%"}>
          <SelectInput
            title="Car Models"
            items={carModels}
            onSelected={(searchValue) =>
              setSelectFormFieldValue("car_model_id", searchValue)
            }
            defaultValue={values.car_model_id}
            width={"38ch"}
            margin={0}
          />
        </MDBox>
        <MDBox width={"50%"}>
          <SelectInput
            title="Car Brands"
            items={carBrands}
            onSelected={(searchValue) =>
              setSelectFormFieldValue("car_brand_id", searchValue)
            }
            defaultValue={values.car_brand_id}
            width={"38ch"}
            margin={0}
          />
        </MDBox>
      </MDBox>

      <MDBox mb={2} ml={1} display={"flex"}>
        {/* <MDBox width={"50%"} mt={1} mr={1.7}>
          <MDInput
            type="text"
            label="Car Plate"
            name="car_plate"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.car_plate}
            fullWidth
          />
        </MDBox> */}
        <MDBox width={"50%"}>
          <SelectInput
            title="Car Color"
            items={carColors}
            onSelected={(searchValue) =>
              setSelectFormFieldValue("car_color_id", searchValue)
            }
            defaultValue={values.car_color_id}
            width={"38ch"}
            margin={0}
          />
        </MDBox>
      </MDBox>
    </div>
  );
};
export default DriverCarDetails;

import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import { useDispatch } from "react-redux";
import { CampaignDriverPictures } from "types/Report";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ImageUpload from "components/ImageUpload";

type AddEditBrandModalProp = {
  open: boolean;
  handleClose: () => void;
  start_pictures: CampaignDriverPictures;
  end_pictures: CampaignDriverPictures;
};
const ReportDriverPicturedModal: React.FC<AddEditBrandModalProp> = (props) => {
  const { open, handleClose, start_pictures, end_pictures } = props;
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`Driver Photos`}
        </ModalHeaderTitle>

        <DialogContent dividers>
          <MDBox>
            <MDTypography>Appointment Submmision Photos</MDTypography>
            <MDBox mb={2} display={"flex"}>
              <MDBox width={"25%"} mr={2}>
                <img
                  src={start_pictures["car_front_url"]}
                  style={{ width: 80, height: 80, border: `1px solid #f0f2f5` }}
                />
              </MDBox>
              <MDBox width={"25%"} mr={2}>
                <img
                  src={start_pictures["car_left_url"]}
                  style={{ width: 80, height: 80, border: `1px solid #f0f2f5` }}
                />
              </MDBox>
              <MDBox width={"25%"} mr={2}>
                <img
                  src={start_pictures["car_right_url"]}
                  style={{ width: 80, height: 80, border: `1px solid #f0f2f5` }}
                />
              </MDBox>
              <MDBox width={"25%"} mr={2}>
                <img
                  src={start_pictures["car_back_url"]}
                  style={{ width: 80, height: 80, border: `1px solid #f0f2f5` }}
                />
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox>
            <MDTypography>Monthly Submmision Photos</MDTypography>
            <MDBox mb={2} display={"flex"}>
              <MDBox width={"25%"} mr={2}>
                <img
                  src={end_pictures["car_front_url"]}
                  style={{ width: 80, height: 80, border: `1px solid #f0f2f5` }}
                />
              </MDBox>
              <MDBox width={"25%"} mr={2}>
                <img
                  src={end_pictures["car_left_url"]}
                  style={{ width: 80, height: 80, border: `1px solid #f0f2f5` }}
                />
              </MDBox>
              <MDBox width={"25%"} mr={2}>
                <img
                  src={end_pictures["car_right_url"]}
                  style={{ width: 80, height: 80, border: `1px solid #f0f2f5` }}
                />
              </MDBox>
              <MDBox width={"25%"} mr={2}>
                <img
                  src={end_pictures["car_back_url"]}
                  style={{ width: 80, height: 80, border: `1px solid #f0f2f5` }}
                />
              </MDBox>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ReportDriverPicturedModal;

import ImageUpload from "components/ImageUpload";
import MDBox from "components/MDBox";
import React from "react";
import { Drivers } from "types/AllUsers";

type Props = {
  setFieldValue: (field: string, value: any) => void;
  values: Drivers;
};
const DriverCarImages: React.FC<Props> = (props) => {
  const { values, setFieldValue } = props;
  return (
    <MDBox>
      <MDBox mb={2} display={"flex"}>
        <MDBox width={"25%"} mr={5}>
          <ImageUpload
            title="Post Car Front"
            selectedImage={values.car_front_url}
            handleImageUpload={(image) => setFieldValue("car_front_url", image)}
          />
        </MDBox>
        <MDBox width={"25%"} mr={5}>
          <ImageUpload
            title="Post Car Left"
            selectedImage={values.car_left_url}
            handleImageUpload={(image) => setFieldValue("car_left_url", image)}
          />
        </MDBox>
        <MDBox width={"25%"} mr={5}>
          <ImageUpload
            title="Post Car Right"
            selectedImage={values.car_right_url}
            handleImageUpload={(image) => setFieldValue("car_right_url", image)}
          />
        </MDBox>
        <MDBox width={"25%"} mr={5}>
          <ImageUpload
            title="Post Car Back"
            selectedImage={values.car_back_url}
            handleImageUpload={(image) => setFieldValue("car_back_url", image)}
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default DriverCarImages;

import React, { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MDBox from "components/MDBox";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import DatePickerRange from "components/MDDatePicker";
import { DateFilter } from "types/common";
import { useSelector } from "react-redux";
import { getIsDownloading } from "redux/slices/journeySlice";

type Props = {
  open: boolean;
  handleClose: () => void;
  filterDate: (date: DateFilter) => void;
};

const DateFilterModal: React.FC<Props> = (props) => {
  const { open, handleClose, filterDate } = props;
  const isLoading = useSelector(getIsDownloading);
  const [dateFilter, setDateFilter] = useState<DateFilter>({
    from: "",
    to: "",
  } as DateFilter);

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = useCallback(() => {
    filterDate(dateFilter);
  }, [dateFilter]);

  const handleDateRange = (from: string, to: string) => {
    setDateFilter({
      from,
      to,
    });
  };
  return (
    <>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`Download Journey By Date. (Default 1000 records)`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <MDBox width={"100%"}>
            <DatePickerRange
              onChangeDatesRange={handleDateRange}
              fromTitile="Start Date Time"
              toTitile=" End Date Time"
              type="datetime-local"
              hideResetButton={true}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit}>
            {isLoading ? "Loading...." : "Download"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DateFilterModal;

import React, { useEffect } from "react";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "components/MDInput/SelectInput";
import {
  getDriverCarsOptionsThunk,
  getDriverStatus,
  getStates,
} from "redux/slices/driverSlice";
import { Drivers } from "types/AllUsers";
import { GenderEnum } from "types/enum/Common";
import { CarefreeDriver } from "types/Carefree";

type Props = {
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  values: CarefreeDriver;
};
const DriverDetails: React.FC<Props> = (props) => {
  const { values, handleChange, handleBlur, setFieldValue } = props;
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const statuses = useSelector(getDriverStatus);
  const countryStates = useSelector(getStates);

  useEffect(() => {
    if (!!accessToken) dispatch(getDriverCarsOptionsThunk(accessToken));
  }, [accessToken, dispatch]);

  const setSelectFormFieldValue = (field: string, value: string): void => {
    if (field === "status") {
      const status = statuses.find((status) => status.id === Number(value));
      setFieldValue("status", status);
    } else {
      const states = countryStates.find(
        (states) => states.id === Number(value)
      );
      setFieldValue("state", states);
    }
  };

  return (
    <div>
      <MDBox mb={2} display={"flex"}>
        <MDBox width={"50%"}>
          <SelectInput
            title="Status"
            items={statuses}
            onSelected={(searchValue) =>
              setSelectFormFieldValue("status", searchValue)
            }
            defaultValue={values.status_id ? values.status_id.toString() : ""}
            width={"38ch"}
            margin={0}
          />
        </MDBox>
        <MDBox width={"50%"}>
          <SelectInput
            title="Gender"
            items={[
              { id: GenderEnum.MALE.toLowerCase(), name: GenderEnum.MALE },
              { id: GenderEnum.FEMALE.toLowerCase(), name: GenderEnum.FEMALE },
            ]}
            onSelected={(searchValue) => setFieldValue("gender", searchValue)}
            defaultValue={values.gender.toLowerCase()}
            width={"38ch"}
            margin={0}
          />
        </MDBox>
      </MDBox>

      {/* <MDBox mb={2} ml={1} display={"flex"}>
        <MDBox width={"50%"} mt={1} mr={1.7}>
          <MDInput
            type="text"
            label="Citizenship"
            name="citizenship"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.citizenship}
            fullWidth
          />
        </MDBox>
        <MDBox width={"50%"}>
          <SelectInput
            title="State"
            items={countryStates}
            onSelected={(searchValue) =>
              setSelectFormFieldValue("state", searchValue)
            }
            defaultValue={values.state ? values.state.id.toString() : ""}
            width={"38ch"}
            margin={0}
          />
        </MDBox>
      </MDBox> */}
      <MDBox mb={2} ml={1} display={"flex"}>
        <MDBox width={"50%"} mr={3.5}>
          <MDInput
            type="number"
            label="Ic no."
            name="ic_number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.ic_number}
            fullWidth
          />
        </MDBox>
        {/* <MDBox width={"50%"}>
          <MDInput
            type="date"
            label="Date"
            name="date"
            onChange={(e) => {
              const newUser = Object.assign({}, values.user);
              newUser["birthday"] = e.target.value;
              setFieldValue("user", newUser);
            }}
            onBlur={handleBlur}
            value={values.user.birthday || ""}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </MDBox> */}
      </MDBox>

      {/* <MDBox mb={2} ml={1} display={"flex"}>
        <MDBox width={"50%"} mr={3.5}>
          <MDInput
            type="text"
            label="Address"
            name="address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address}
            fullWidth
          />
        </MDBox>
        <MDBox width={"50%"}>
          <MDInput
            type="text"
            label="Postcode"
            name="postcode"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.postcode}
            fullWidth
          />
        </MDBox>
      </MDBox> */}
      <MDBox mb={2} ml={1} display={"flex"}>
        <MDBox width={"50%"} mr={3.5}>
          <MDInput
            type="text"
            label="Emergency Contact Name"
            name="emergency_contact_name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.emergency_contact_name}
            fullWidth
          />
        </MDBox>
        <MDBox width={"50%"}>
          <MDInput
            type="text"
            label="Emergency Contact Number"
            name="emergency_contact_num"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.emergency_contact_num}
            fullWidth
          />
        </MDBox>
      </MDBox>
    </div>
  );
};
export default DriverDetails;

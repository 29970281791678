export const validateForm = (values) => {
  const errors = {} as any;
  if (values.driver_id === 0) {
    errors.driver_id = "Required";
  }
  if (values.campaign_id === 0) {
    errors.campaign_id = "Required";
  }
  if (values.distance === 0) {
    errors.distance = "Required";
  }
  if (values.points === 0) {
    errors.points = "Required";
  }
  if (!values.gpx_file) {
    errors.gpx_file = "Required";
  }
  if (!values.datetime) {
    errors.datetime = "Required";
  }
  return errors;
};

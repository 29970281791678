import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SidenavCollapse from "features/Sidenav/SidenavCollapse";
import { List } from "@mui/material";
import "./styles/expansionPanel.scss";

type Props = {
  childkey: string;
  icon: string;
  active: boolean;
  children: any;
  title: string;
};
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(() => ({
  "& .MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "48px !important",
    height: "75x !important",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0,
  },
}));

const ExpansionPanel: React.FC<Props> = (props) => {
  const { childkey, icon, children, title } = props;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const renderSubMenu = children.map(
    ({ name, icon, noCollapse, key, href, route }) => {
      return href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={collapseName.includes(key)}
            noCollapse={noCollapse}
            style={{ marginLeft: "20px" }}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse
            name={name}
            icon={icon}
            active={collapseName.includes(key)}
            style={{ marginLeft: "20px" }}
          />
        </NavLink>
      );
    }
  );
  return (
    <>
      <Accordion
        className="expand-container"
        style={{ background: "transparent", padding: 0, boxShadow: "none" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel1a-header"
          sx={{
            padding: 0,
            "& .MuiAccordionSummary-content": { margin: 0 },
          }}
        >
          <SidenavCollapse
            name={title}
            icon={icon}
            active={collapseName.includes(childkey)}
          />
        </AccordionSummary>
        <AccordionDetails>
          <List>{renderSubMenu}</List>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ExpansionPanel;

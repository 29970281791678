import Grid from "@mui/material/Grid";
import LoadingComponent from "components/LoadingComponent";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

type Props = {
  title: string;
  value: string | number;
};
const OverviewChild: React.FC<Props> = (props) => {
  const { title, value } = props;

  return (
    <MDBox display={"grid"}>
      <MDTypography variant="button" color="text" fontWeight="bold" mr={3}>
        {title}:
      </MDTypography>
      <MDTypography variant="button" color="secondary" fontWeight="bold" mr={3}>
        {value}
      </MDTypography>
    </MDBox>
  );
};

export default OverviewChild;

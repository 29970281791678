import { AxiosResponse } from "axios";
import { getRequest, postRequest, putRequest } from "services/client/request";
import { Banner, NewBanner } from "types/Banner";
import { AxiosGenericResponse, PaginationCommonObject } from "types/common";

const apiVersion = "/api";

export const getAllBanners = async (
  accessToken: string,
  page: number
): Promise<PaginationCommonObject<Banner>> => {
  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<Banner>>
  > = await getRequest(`${apiVersion}/banners?page=${page}`, accessToken);
  const banners: PaginationCommonObject<Banner> = response.data.success
    ? response.data.data
    : ({} as PaginationCommonObject<Banner>);
  return banners;
};

export const createBanner = async (
  request: NewBanner,
  accessToken: string
): Promise<void> => {
  await postRequest(`${apiVersion}/banners`, accessToken, request);
};

export const updateBanner = async (
  request: NewBanner,
  accessToken: string
): Promise<void> => {
  await putRequest(`${apiVersion}/banners/${request.id}`, accessToken, request);
};

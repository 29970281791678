import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  DashboardState,
  StoreState,
  WorkshopState,
} from "types/state/StoreState";

import { PaginationCommonObject, PaginationRequest } from "types/common";
import {
  createWorkshops,
  getWorkshops,
  updateWorkshops,
} from "services/api/workshopsApi";
import { NewWorkshop, Workshop } from "types/Workshop";
import { DashboardSummary } from "types/Dashboard";
import { getDashboardSummary } from "services/api/dashboardApi";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: false,
    dashboardSummary: {},
  } as DashboardState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setDashboardSummary(state, action) {
      state.dashboardSummary = action.payload;
    },
  },
});
// Thunk Actions
export const getDashboardSummaryThunk = createAsyncThunk(
  "dashboard/getDashboardSummaryThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;

    dispatch(dashboardSlice.actions.setIsLoading(true));
    const summary = await getDashboardSummary(accessToken);
    dispatch(dashboardSlice.actions.setDashboardSummary(summary));
    dispatch(dashboardSlice.actions.setIsLoading(false));
  }
);

// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.dashboard.isLoading;
export const getDashboard = (state: StoreState): DashboardSummary =>
  state.dashboard.dashboardSummary;

export default dashboardSlice.reducer;

import { Autocomplete, TextField } from "@mui/material";
import LoadingComponent from "components/LoadingComponent";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import SelectInput from "components/MDInput/SelectInput";
import MDTypography from "components/MDTypography";
import AccordionDefault from "features/Accordion";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { getAllBrandsReducer } from "redux/slices/brandSlice";
import {
  getAllSegregationns,
  getLoadingStatus,
  getZones
} from "redux/slices/campaignSlice";
import { Campaign, CampaignZones } from "types/Campaign";
import { CampaignStatusEnum } from "types/enum/Common";
import AddAgreement from "./addAgreement";

type Props = {
  isEdit: boolean;
  campaign: Campaign;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
};
const CampaignSettings: React.FC<Props> = (props) => {
  const selectedCountry = sessionStorage.getItem(`carching_preset_country`);
  const { campaign, handleChange, handleBlur, setFieldValue } = props;
  const brands = useSelector(getAllBrandsReducer);
  const zones = useSelector(getZones);
  const segragations = useSelector(getAllSegregationns);
  const isLoading = useSelector(getLoadingStatus);
  const status = [
    { id: "20", name: CampaignStatusEnum.CROWDSOURCING },
    { id: "21", name: CampaignStatusEnum.STICKER_INSTALLATION },
    { id: "22", name: CampaignStatusEnum.RUNNING },
    { id: "23", name: CampaignStatusEnum.ENDED }
  ];

  const handleBrand = useCallback(
    (selectedBrand: number) => {
      if (brands && brands.length) {
        return brands.find((brand) => brand.id === selectedBrand);
      }
    },
    [brands]
  );
  const handleZones = useCallback(
    (campaignZones: CampaignZones[]) => {
      if (!campaignZones) {
        return [];
      }
      // Temporary fix for zones are required
      if (campaignZones.length === 0 && zones.data && zones.data.length === 1) {
        setFieldValue("zones", zones.data);
        return zones.data;
      }
      if (zones && zones.data) {
        return zones.data.filter((zone) =>
          campaignZones.some((campaignZone) => zone.id === campaignZone.zone_id)
        );
      }
    },
    [zones]
  );

  const handleWaitlistCalculation = (type: string, value) => {
    if (type === "waitlist_percentage") {
      const maxWailtLst = campaign.max_participants * (Number(value) / 100);
      setFieldValue("max_waitlist", Math.ceil(maxWailtLst));
      setFieldValue(type, value);
    } else if (type === "max_participants") {
      const maxWailtLst = Number(value) * (campaign.waitlist_percentage / 100);
      setFieldValue("max_waitlist", Math.ceil(maxWailtLst));
      setFieldValue(type, value);
    }
  };
  const handlePricePerKmCalculation = (type: string, value) => {
    if (type === "price_per_month") {
      const maxPointsPerMonth = Math.ceil((1000 / 7) * Number(value));
      setFieldValue("max_points_per_month", maxPointsPerMonth);

      const pointsPerKm = maxPointsPerMonth / campaign.min_km_per_month;
      setFieldValue("points_per_km", Math.floor(pointsPerKm));
    } else if (type === "min_km_per_month") {
      if (campaign.max_points_per_month) {
        const pointsPerKm = campaign.max_points_per_month / Number(value);
        setFieldValue("points_per_km", Math.floor(pointsPerKm));
      }
    }
    setFieldValue(type, value);
  };

  return (
    <AccordionDefault
      title={"Campaign Settings"}
      panel="Campaigns perday calculations will be divided by days of the month"
      onChange={() => {}}
    >
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <MDBox>
          <MDBox display={"flex"} width={"100%"}>
            <MDBox width={"25%"} display={"flex"} sx={{ alignItems: "center" }}>
              <SelectInput
                title="Campaign Calculation Type"
                items={[
                  { id: "relative", name: "Relative" },
                  { id: "fixed", name: "Fixed" }
                ]}
                defaultValue={campaign.duration_type}
                onSelected={(searchValue) => {
                  setFieldValue("duration_type", searchValue);
                }}
                width={"30ch"}
              />
            </MDBox>

            <MDBox
              width={"25%"}
              mr={1}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <SelectInput
                title="Brands"
                items={brands}
                itemName={"brand_name"}
                defaultValue={
                  campaign && campaign.brand ? campaign.brand.id : ""
                }
                onSelected={(searchValue) => {
                  const brand = handleBrand(Number(searchValue));
                  setFieldValue("brand_id", brand?.id);
                  setFieldValue("brand", brand);
                }}
                width={"30ch"}
              />
            </MDBox>
            <MDBox
              width={"25%"}
              mr={1}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <SelectInput
                title="States"
                items={segragations}
                defaultValue={
                  campaign && campaign.segregation_id
                    ? campaign.segregation_id
                    : ""
                }
                onSelected={(searchValue) => {
                  setFieldValue("segregation_id", Number(searchValue));
                }}
                width={"30ch"}
              />
            </MDBox>
            <MDBox width={"25%"} display={"flex"} sx={{ alignItems: "center" }}>
              <Autocomplete
                id="multiple-limit-tags"
                multiple
                limitTags={1}
                options={zones.data || []}
                getOptionLabel={(option) => option.name}
                defaultValue={handleZones(campaign.zones)}
                onChange={(_, newValue) => {
                  setFieldValue("zones", newValue);
                }}
                sx={{
                  width: "38ch",
                  "& .MuiAutocomplete-tag": {
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                    color: "rgba(0, 0, 0, 0.87)"
                  },
                  "& .MuiAutocomplete-tag .MuiSvgIcon-root": {
                    color: "rgba(0, 0, 0, 0.26)"
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Zones" placeholder="Zones" />
                )}
              />
            </MDBox>
          </MDBox>
          {campaign.duration_type && (
            <>
              {campaign.duration_type === "relative" ? (
                <MDTypography variant="button" color="dark" ml={1}>
                  Campaigns perday calculations will be divided by days of the
                  month
                </MDTypography>
              ) : (
                <MDTypography variant="button" color="dark" ml={1}>
                  Campaigns perday calculations will always be divided by 30
                  days
                </MDTypography>
              )}
            </>
          )}

          <MDBox display={"flex"} width={"100%"} mt={2} ml={1}>
            <MDBox
              width={"25%"}
              mr={1}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <TextField
                label="Crowdsource Start Date"
                type="date"
                name={"crowdsource_start_date"}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setFieldValue("crowdsource_start_date", e.target.value);
                }}
                value={campaign.crowdsource_start_date}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
              />
            </MDBox>
            <MDBox
              width={"25%"}
              mr={1}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <TextField
                label="Crowdsource End Date"
                type="date"
                name={"crowdsource_end_date"}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setFieldValue("crowdsource_end_date", e.target.value);
                }}
                value={campaign.crowdsource_end_date}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
              />
            </MDBox>
            <MDBox
              width={"25%"}
              mr={1}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <TextField
                label="Campaign Start Date"
                type="date"
                name={"start_date"}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setFieldValue("start_date", e.target.value);
                }}
                value={campaign.start_date}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
              />
            </MDBox>
            <MDBox width={"25%"} display={"flex"} sx={{ alignItems: "center" }}>
              <TextField
                label="Campaign End Date"
                type="date"
                name={"end_date"}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setFieldValue("end_date", e.target.value);
                }}
                value={campaign.end_date}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
              />
            </MDBox>
          </MDBox>
          <MDBox display={"flex"} width={"100%"} mt={5}>
            <MDBox
              width={"25%"}
              mr={1}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <SelectInput
                title="Select Status"
                items={status}
                onSelected={(searchValue) => {
                  setFieldValue("status_id", Number(searchValue));
                }}
                defaultValue={campaign.status_id}
                width={"30ch"}
              />
            </MDBox>
            <MDBox
              width={"25%"}
              mr={1}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <MDInput
                type="number"
                label="Install Duration"
                name="install_duration"
                onChange={handleChange}
                onBlur={handleBlur}
                value={campaign.install_duration}
                fullWidth
              />
            </MDBox>
            <MDBox
              width={"25%"}
              mr={1}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <MDInput
                type="number"
                label="Max Participants"
                name="max_participants"
                onChange={(e) =>
                  handleWaitlistCalculation("max_participants", e.target.value)
                }
                onBlur={handleBlur}
                value={campaign.max_participants}
                fullWidth
              />
            </MDBox>
            <MDBox width={"25%"} display={"flex"} sx={{ alignItems: "center" }}>
              <MDInput
                type="number"
                label="Waitlist Percentage"
                name="waitlist_percentage"
                onChange={(e) =>
                  handleWaitlistCalculation(
                    "waitlist_percentage",
                    e.target.value
                  )
                }
                onBlur={handleBlur}
                value={campaign.waitlist_percentage}
                fullWidth
              />
            </MDBox>
          </MDBox>
          <MDBox display={"flex"} width={"100%"} mt={5} ml={1}>
            <MDBox
              width={"25%"}
              mr={1}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <MDInput
                type="number"
                label="Max Waitlist"
                name="max_waitlist"
                onChange={handleChange}
                onBlur={handleBlur}
                value={campaign.max_waitlist}
                disabled
                fullWidth
              />
            </MDBox>
            <MDBox
              width={"25%"}
              mr={1}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <MDInput
                type="number"
                label="Crowdsource Period"
                name="crowdsource_period"
                onChange={handleChange}
                onBlur={handleBlur}
                value={campaign.crowdsource_period}
                fullWidth
              />
            </MDBox>
            <MDBox
              width={"25%"}
              mr={1}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <MDInput
                type="number"
                label="Min km per month"
                name="min_km_per_month"
                onChange={(e) =>
                  handlePricePerKmCalculation(
                    "min_km_per_month",
                    e.target.value
                  )
                }
                onBlur={handleBlur}
                value={campaign.min_km_per_month}
                fullWidth
              />
              <MDTypography variant="button" color="dark" ml={0.5}>
                KM
              </MDTypography>
            </MDBox>
            <MDBox width={"25%"} display={"flex"} sx={{ alignItems: "center" }}>
              <MDTypography variant="button" color="dark" mr={0.5}>
                {selectedCountry === "PH" ? "PHP" : "RM"}
              </MDTypography>
              <MDInput
                type="number"
                label="Price per month"
                name="price_per_month"
                onChange={(e) =>
                  handlePricePerKmCalculation("price_per_month", e.target.value)
                }
                onBlur={handleBlur}
                value={campaign.price_per_month}
                fullWidth
              />
            </MDBox>
          </MDBox>

          <MDBox display={"flex"} width={"100%"} mt={5} ml={1}>
            <MDBox
              width={"25%"}
              mr={1}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <MDInput
                type="number"
                label="Max Points Per Month"
                name="max_points_per_month"
                disabled
                onChange={handleChange}
                onBlur={handleBlur}
                value={campaign.max_points_per_month ?? 0}
                fullWidth
              />
            </MDBox>
            <MDBox
              width={"25%"}
              mr={1}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <MDInput
                type="number"
                label="Points Per KM"
                name="points_per_km"
                disabled
                onChange={handleChange}
                onBlur={handleBlur}
                value={campaign.points_per_km ?? 0}
                fullWidth
              />
            </MDBox>
            <MDBox
              width={"25%"}
              mr={1}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <MDInput
                type="number"
                label="Max Price"
                name="max_price"
                onChange={handleChange}
                onBlur={handleBlur}
                value={campaign.max_price}
                fullWidth
              />
            </MDBox>
          </MDBox>
          <AddAgreement
            isEdit
            campaign={campaign}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
          />
        </MDBox>
      )}
    </AccordionDefault>
  );
};
export default CampaignSettings;

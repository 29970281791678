import React, { useCallback, useEffect, useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCampaings,
  getAllCampaignsThunk,
} from "redux/slices/campaignSlice";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import LoadingComponent from "components/LoadingComponent";
import { CampaignRequestFilter } from "types/Campaign";
import CampaignTable from "./table";
import { useLocation } from "react-router-dom";

const header = [
  { id: "id", name: "Campaign Id" },
  { id: "brand", name: "Brand" },
  { id: "title", name: "Title" },
  { id: "status", name: "Status" },
  { id: "started_at", name: "Started At" },
  { id: "end_at", name: "Ended At" },
  { id: "action", name: "Action" },
];

const CampaignPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [currentFilter, setCurrentFilter] = useState<CampaignRequestFilter>();
  const campaigns = useSelector(getAllCampaings);

  useEffect(() => {
    if (!!accessToken && !!campaigns && !campaigns.data && !state) {
      getAllCampaigns(1);
    } else if (state) {
      const { campaignType } = state as { campaignType: number };
      // all campaigns
      if (campaignType === 0) {
        getAllCampaigns(1);
      } else {
        let filter = {} as CampaignRequestFilter;
        filter.status_id = campaignType;
        getAllCampaigns(1, filter);
      }
    }
  }, [accessToken, dispatch]);

  const getAllCampaigns = useCallback(
    (page: number, request?: CampaignRequestFilter) => {
      let filterRequest = currentFilter;
      if (!filterRequest) {
        filterRequest = {
          accessToken,
          page,
          ...request,
        };
      }

      dispatch(getAllCampaignsThunk(filterRequest));
    },
    [dispatch, accessToken, currentFilter]
  );
  const handleOnSearchInput = (
    field: string,
    value: string | { start_date: string; end_date: string }
  ): void => {
    let filter = {} as CampaignRequestFilter;

    if (field === "date" && typeof value !== "string") {
      filter.start_date = value.start_date;
      filter.end_date = value.end_date;
    } else if (field === "crowdsource_date" && typeof value !== "string") {
      filter.crowdsource_start_date = value.start_date;
      filter.crowdsource_end_date = value.end_date;
    } else {
      filter[field] = value;
    }

    filter.accessToken = accessToken;
    filter.page = 1;
    setCurrentFilter(filter);
    getAllCampaigns(1, filter);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox p={2}>
        {!!campaigns && campaigns.data ? (
          <CampaignTable
            header={header}
            rows={campaigns}
            onReload={(newPage: number) => getAllCampaigns(newPage + 1)}
            handleCampaignSearch={(field: string, value: string) =>
              handleOnSearchInput(field, value)
            }
            handleDateRangeSearch={(field: string, from: string, to: string) =>
              handleOnSearchInput(field, {
                start_date: from,
                end_date: to,
              })
            }
            onPageChanged={(newPage: number) => getAllCampaigns(newPage + 1)}
          />
        ) : (
          <LoadingComponent />
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default WithAuthWrapper(CampaignPage);

import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import AvatarUpload from "components/AvatarUpload";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import { NewWorkshop, Workshop } from "types/Workshop";

const initialValues = {
  name: "",
  contact_name: "",
  contact_email: "",
  location_name: "",
  latitude: "",
  longitude: "",
  google_place_id: "",
  contact_no: "",
  closed_at: "",
  opened_at: "",
  image_url: "",
  time_gap: 60,
  appointment_per_slot: 0,
};
type AddEditWorkshopModalProp = {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  selectedWorkshop?: Workshop;
  addEditWorkshop: (updatedWorkshop: NewWorkshop) => void;
};
const AddEditWorkshopModal: React.FC<AddEditWorkshopModalProp> = (props) => {
  const { open, handleClose, isEdit, selectedWorkshop, addEditWorkshop } =
    props;
  const formikRef = useRef<any>(null);

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = (values) => {
    if (values && values.appointment_per_slot === 0) {
      alert("Appointment per slot cannot be 0");
    } else {
      const request: NewWorkshop = {
        ...values,
        image: values.image_url,
      };
      addEditWorkshop(request);
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`${isEdit ? "Edit" : "New"} Workshop`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Formik
            initialValues={
              isEdit && !!selectedWorkshop ? selectedWorkshop : initialValues
            }
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <AvatarUpload
                  selectedImage={values.image_url}
                  handleImageUpload={(image) =>
                    setFieldValue("image_url", image)
                  }
                />
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="text"
                      label="Workshop Name"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="text"
                      label="Contact Person"
                      name="contact_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contact_name}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="email"
                      label="Contact Email"
                      name="contact_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contact_email}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="number"
                      label="Contact Phone Number (+60)"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      name="contact_no"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contact_no}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="text"
                      label="Location Name"
                      name="location_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.location_name}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="text"
                      label="Google Place Id"
                      name="google_place_id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.google_place_id}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="text"
                      label="Latitude"
                      name="latitude"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.latitude}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="text"
                      label="Longitude"
                      name="longitude"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.longitude}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="number"
                      label="Time Gap"
                      name="time_gap"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.time_gap}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="text"
                      label="Appointment Per Slot"
                      name="appointment_per_slot"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.appointment_per_slot}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="time"
                      label="Open at"
                      name="opened_at"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.opened_at}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="time"
                      label="Close at"
                      name="closed_at"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.closed_at}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {isEdit ? "Edit Workshop" : "Add Workshop"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddEditWorkshopModal;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PartnerState, StoreState } from "types/state/StoreState";
import { PaginationCommonObject, PaginationRequest } from "types/common";
import {
  createPartners,
  getAllPartners,
  updatePartners,
} from "services/api/partnerApi";
import { NewPartner, Partner } from "types/Partner";

const partnersSlice = createSlice({
  name: "partner",
  initialState: {
    isLoading: false,
    partners: {},
  } as PartnerState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setPartners(state, action) {
      state.partners = action.payload;
    },
  },
});

// Thunk Actions
export const getPartnersThunk = createAsyncThunk(
  "partners/getPartnersThunk",
  async (request: PaginationRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, page, name } = request;
    dispatch(partnersSlice.actions.setIsLoading(true));
    const partners = await getAllPartners(accessToken, page, name);
    dispatch(partnersSlice.actions.setPartners(partners));
    dispatch(partnersSlice.actions.setIsLoading(false));
  }
);

type NewPartnerRequest = {
  accessToken: string;
  partner: NewPartner;
  isEdit: boolean;
};
export const createOrEditPartnerThunk = createAsyncThunk(
  "brpartnersands/createOrEditPartnerThunk",
  async (request: NewPartnerRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { partner, accessToken, isEdit } = request;
    dispatch(partnersSlice.actions.setIsLoading(true));
    isEdit
      ? await updatePartners(partner, accessToken)
      : await createPartners(partner, accessToken);

    dispatch(
      getPartnersThunk({
        accessToken,
        page: 1,
      })
    );
    dispatch(partnersSlice.actions.setIsLoading(false));
  }
);
// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.partners.isLoading;
export const getPartners = (
  state: StoreState
): PaginationCommonObject<Partner> => state.partners.partners;

export default partnersSlice.reducer;

import { Box, Typography } from "@mui/material";
import React from "react";
import LinearProgress, {
  LinearProgressProps
} from "@mui/material/LinearProgress";
type Props = {
  value: number;
};

const ProgressBar: React.FC<Props> = (
  props: LinearProgressProps & { value: number }
) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={props.value}
          title={`${props.value}%`}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};
export default ProgressBar;

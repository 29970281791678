import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "components/MDTable/tableToolbar";
import TableHeader from "components/MDTable/tableHeader";
import { ITableHeader } from "types/Table";
import TablePaginationActions from "components/MDTable/tablePagination";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSelector } from "react-redux";
import { getLoadingStatus } from "redux/slices/transactionSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { NewUser } from "types/Authentication";
import { Transaction, Transactions } from "types/Transactions";
import ConfirmationDialog from "components/ConfirmationDialog";
import MoreActionsMenu from "components/MoreActionsMenu";
import { TransactionActionsEnum } from "types/enum/transactions";
import {
  approveTransaction,
  markAsTransferred,
  rejectTransaction,
} from "services/api/transactionsApi";
import Icon from "@mui/material/Icon";
import ApproveByRangeModel from "./ApproveByRangeModel";
import InputDebounce from "components/MDInput/inputDebounce";
import NewTransactionModal from "./NewTransaction";

type TableType = {
  header: ITableHeader[];
  rows: Transactions;
  onPageChanged: (newPage: number) => void;
  downloadIntoCsv: () => void;
  handleUserSearch: (searchInput: string) => void;
  onReload: (statusId?: number) => void;
  addNewOrEditUser: (isEdit: boolean, selectedUser?: NewUser) => void;
};
const moreActions = [
  {
    name: TransactionActionsEnum.Approve,
    icon: <Icon fontSize="small">check_circle</Icon>,
  },
  {
    name: TransactionActionsEnum.Reject,
    icon: <Icon fontSize="small">unpublished</Icon>,
  },
  {
    name: TransactionActionsEnum.ApproveByRange,
    icon: <Icon fontSize="small">grading</Icon>,
  },
];
const TransactionTable: React.FC<TableType> = (props) => {
  const accessToken = localStorage.getItem("carching-token");
  const { header, rows, onPageChanged, onReload } = props;
  const { data } = rows;
  const [page, setPage] = useState<number>(0);
  const isLoading = useSelector(getLoadingStatus);
  const [isDialogOpenWithAction, openDialogConfirmation] = useState<
    string | undefined
  >();
  const [isApprovedByRangeModalOpen, setApprovedByRangeModalOpen] =
    useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Transaction | undefined>();
  const [isCreateTransactionOpen, setCreateTransactionModalOpen] =
    useState<boolean>(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChanged(newPage);
    setPage(newPage);
  };
  const handleActionButtons = async (action?: string): Promise<void> => {
    if (accessToken && !!selectedRow) {
      if (action === TransactionActionsEnum.Approve) {
        await approveTransaction(accessToken, selectedRow.id);
      } else if (action === TransactionActionsEnum.Reject) {
        await rejectTransaction(accessToken, selectedRow.id);
      } else if (action === TransactionActionsEnum.Transferred) {
        await markAsTransferred(
          accessToken,
          selectedRow.id,
          selectedRow.approved_amount
        );
      }
    }
    openDialogConfirmation(undefined);
    setSelectedRow(undefined);
    if (!!action) onReload();
  };

  return (
    <Box sx={{ width: "100%" }}>
      {isCreateTransactionOpen && (
        <NewTransactionModal
          open={isCreateTransactionOpen}
          handleClose={(reload?: boolean) => {
            setCreateTransactionModalOpen(!isCreateTransactionOpen);
            if (reload) onReload(25);
          }}
        />
      )}
      {!!isDialogOpenWithAction && (
        <ConfirmationDialog
          title={`Are you sure you want to ${isDialogOpenWithAction.toLowerCase()} this transaction?`}
          open={!!isDialogOpenWithAction}
          onClose={(action?: string) => handleActionButtons(action)}
          action={isDialogOpenWithAction}
          submitButtonTitle={
            !!isDialogOpenWithAction ? isDialogOpenWithAction : "Submit"
          }
        />
      )}
      {isApprovedByRangeModalOpen && selectedRow && (
        <ApproveByRangeModel
          selectedTransaction={selectedRow}
          open={isApprovedByRangeModalOpen}
          handleClose={(reload?: boolean) => {
            setApprovedByRangeModalOpen(!isApprovedByRangeModalOpen);
            if (reload) onReload();
          }}
        />
      )}

      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          numSelected={0}
          tableName={"Transactions"}
          onReload={onReload}
        />
        <MDBox display={"flex"} justifyContent={"end"}>
          <MDButton
            variant="gradient"
            color="secondary"
            onClick={() => setCreateTransactionModalOpen(true)}
            style={{ marginRight: 8 }}
          >
            Create Transaction
          </MDButton>
        </MDBox>

        {isLoading ? (
          <MDBox display={"flex"} justifyContent={"center"} mt={3}>
            <CircularProgress color={"primary"} />
          </MDBox>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeader
                numSelected={0}
                order={"desc"}
                orderBy={""}
                onSelectAllClick={() => {}}
                onRequestSort={() => {}}
                rowCount={data.length}
                headCells={header}
                disableCheckbox
              />
              <TableBody>
                {data.map((row) => {
                  return (
                    <TableRow tabIndex={-1} key={row.id}>
                      <TableCell align="center">{row.id}</TableCell>
                      <TableCell align="right">{`${new Date(
                        row.created_at
                      ).toLocaleDateString()}`}</TableCell>
                      <TableCell align="right">{row.amount}</TableCell>
                      <TableCell align="right">
                        {row.user ? row.user.username : ""}
                      </TableCell>
                      <TableCell align="right">
                        {row.campaign && row.campaign[0]
                          ? row.campaign[0].title
                          : ""}
                      </TableCell>

                      <TableCell align="right">
                        {row.status ? row.status.name : ""}
                      </TableCell>
                      <TableCell align="right">
                        {row.type ? row.type.name : ""}
                      </TableCell>
                      <TableCell align="right">
                        {row.status && row.status.name === "Processing" && (
                          <MoreActionsMenu
                            menuItems={moreActions}
                            onActionSelected={(action: string) => {
                              setSelectedRow(row);
                              action === TransactionActionsEnum.ApproveByRange
                                ? setApprovedByRangeModalOpen(true)
                                : openDialogConfirmation(action);
                            }}
                          />
                        )}
                        {row.status && row.status.name === "Approved" && (
                          <>
                            <MDButton
                              variant="gradient"
                              color="secondary"
                              onClick={() => {
                                setSelectedRow(row);
                                openDialogConfirmation(
                                  TransactionActionsEnum.Transferred
                                );
                              }}
                            >
                              Mark as transferred
                            </MDButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <MDBox display={"inline-flex"} width={"100%"} justifyContent={"end"}>
          <InputDebounce
            type="number"
            name="search page"
            placeholder="search specify page"
            onChange={(value: string) => {
              const page = Number(value);
              handleChangePage(null, page === 0 ? 1 : page - 1);
            }}
            width={"10%"}
          />
          <TablePagination
            rowsPerPageOptions={[15]}
            component="div"
            count={rows.total}
            rowsPerPage={rows.per_page}
            page={rows.total <= 0 ? 0 : page}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        </MDBox>
      </Paper>
    </Box>
  );
};
export default TransactionTable;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { JourneyPagination, NewJourney } from "types/Journeys";
import { JourneyState, StoreState } from "types/state/StoreState";
import {
  createNewJourney,
  getAllJourneyWithPagination,
  updateExistingJourney
} from "services/api/journeysApi";
import { PaginationRequest } from "types/common";

const journeySlice = createSlice({
  name: "journeys",
  initialState: {
    isLoading: false,
    journeys: {},
    isDownloading: false
  } as JourneyState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setJourneys(state, action) {
      state.journeys = action.payload;
    },
    setIsDownloading(state, action) {
      state.isDownloading = action.payload;
    }
  }
});

// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.journeys.isLoading;
export const getIsDownloading = (state: StoreState): boolean =>
  state.journeys.isDownloading;
export const getJourneys = (state: StoreState): JourneyPagination =>
  state.journeys.journeys;

// Thunk Actions
export const getAllJourneysThunk = createAsyncThunk(
  "journey/getAllJourneysThunk",
  async (request: PaginationRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, page } = request;
    dispatch(journeySlice.actions.setIsLoading(true));
    const journeys = await getAllJourneyWithPagination(accessToken, page);
    dispatch(journeySlice.actions.setJourneys(journeys));
    dispatch(journeySlice.actions.setIsLoading(false));
  }
);
type NewOrEditJourneyRequest = {
  accessToken: string;
  isEdit: boolean;
  journey: NewJourney;
};
export const createOrUpdateJourneyThunk = createAsyncThunk(
  "allUsers/createOrUpdateUser",
  async (journeyRequest: NewOrEditJourneyRequest, thunkApi) => {
    const { accessToken, isEdit, journey } = journeyRequest;
    isEdit
      ? await updateExistingJourney(
          journey,
          accessToken,
          journey.journey_id || 0
        )
      : await createNewJourney(journey, accessToken);
  }
);

export const {
  setJourneys,
  setIsLoading,
  setIsDownloading
} = journeySlice.actions;
export default journeySlice.reducer;

import { AxiosResponse } from "axios";
import { getRequest } from "services/client/request";
import { AxiosGenericResponse } from "types/common";
import { DashboardSummary } from "types/Dashboard";

const apiVersion = "/api";

export const getDashboardSummary = async (
  accessToken: string
): Promise<DashboardSummary> => {
  const response: AxiosResponse<
    AxiosGenericResponse<DashboardSummary>
  > = await getRequest(`${apiVersion}/dashboard`, accessToken);
  const dashboard: DashboardSummary = response.data.data
    ? response.data.data
    : {} as DashboardSummary;

  return dashboard;
};

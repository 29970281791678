import React, { useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import {
  NewNotification,
  Notification,
  NotificationOption,
} from "types/Notification";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import SelectInput from "components/MDInput/SelectInput";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import { StyledEngineProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersWithoutPagination,
  getAllUsersWithoutPaginationThunk,
  getLoadingStatus,
} from "redux/slices/allUsersSlice";
import { User } from "types/User";

type AddViewModalProp = {
  open: boolean;
  handleClose: () => void;
  isView: boolean;
  selectedRow?: Notification;
  createNewNotifcation: (notifcation: NewNotification) => void;
};
const initialValues = {
  title: "",
  subtitle: "",
  user_ids: [],
  target: "",
  type: "*",
  screen: "",
  screenId: "",
  referral: false,
};
const AddViewNotificationsModal: React.FC<AddViewModalProp> = (props) => {
  const { open, handleClose, isView, selectedRow, createNewNotifcation } =
    props;
  const formikRef = useRef<any>(null);
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const allUsers = useSelector(getUsersWithoutPagination);
  const isLoading = useSelector(getLoadingStatus);

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (!!accessToken && !allUsers.length) {
      dispatch(getAllUsersWithoutPaginationThunk(accessToken));
    }
  }, [accessToken, dispatch]);

  const onSubmit = (values) => {
    const {
      title,
      subtitle,
      target,
      type,
      user_ids,
      screen,
      screenId,
      referral,
    } = values;
    // Backend is returning certain user_ids with string and others are array
    const userIds =
      typeof user_ids === "string"
        ? user_ids
        : JSON.stringify(user_ids.map((user) => user.id));

    const options: NotificationOption = {
      screen: screen,
    };
    if (screen === "campaign") {
      options.id = screenId;
    } else if (screen === "account") {
      options.referral = referral;
    }

    const request: NewNotification = {
      title,
      subtitle,
      target,
      user_ids: type === "*" ? "*" : userIds,
      options,
    };
    createNewNotifcation(request);
  };

  const validateFromManualInput = (value: string): User[] => {
    if (value && value !== "") {
      const emailSplit = value.split(" ");
      if (emailSplit.length > 0) {
        const user = allUsers.filter((user) =>
          emailSplit.some((email) => user.email === email)
        );

        return user;
      }
    }
    return [];
  };

  const renderUserIdsInput = (userIds) => {
    if (Array.isArray(userIds)) {
      const isNumberArray =
        userIds.length > 0 &&
        userIds.every((value) => {
          return typeof value === "number";
        });
      if (!isNumberArray) {
        return userIds;
      }
      const users = allUsers.filter((user) => {
        if (!Array.isArray(userIds) && typeof userIds === "string") {
          return JSON.parse(userIds).some((id) => id === user.id);
        } else {
          return userIds.some((id) => id === user.id);
        }
      });
      return users;
    } else if (typeof userIds === "string") {
      const newUsers = validateFromManualInput(userIds);
      return newUsers;
    }
    return [];
  };

  const shoudlDisplayUserInputs = (type: string): boolean => {
    return (isView && selectedRow?.type !== "all") || type === "single_user";
  };

  const shouldShowCampaignId = (screen: string): boolean => {
    return (
      (isView && selectedRow?.options?.screen === "campaign") ||
      screen === "campaign"
    );
  };
  const shouldShowRefferral = (screen: string): boolean => {
    return (
      (isView && selectedRow?.options?.screen === "account") ||
      screen === "account"
    );
  };

  return (
    <StyledEngineProvider injectFirst>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`${isView ? "View" : "New"} Notification`}
        </ModalHeaderTitle>{" "}
        <DialogContent dividers>
          <Formik
            initialValues={
              isView && !!selectedRow ? selectedRow : initialValues
            }
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <MDBox mb={2} ml={1} display={"flex"}>
                  <MDBox mr={5} width={"50%"}>
                    <MDInput
                      fullWidth
                      type="text"
                      label="Title"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      fullWidth
                      type="text"
                      label="Sub Title"
                      name="subtitle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subtitle}
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox mr={3} width={"50%"}>
                    <SelectInput
                      title="Target Platform"
                      items={[
                        { id: "mobile", name: "Mobile" },
                        { id: "Web", name: "Web" },
                      ]}
                      onSelected={(searchValue) =>
                        setFieldValue("target", searchValue)
                      }
                      defaultValue={values.target}
                      width={"38ch"}
                    />
                  </MDBox>

                  <MDBox mt={1} ml={1} width={"50%"}>
                    <SelectInput
                      title="Screen"
                      items={[
                        { id: "home", name: "Home" },
                        { id: "campaign", name: "Campaign" },
                        { id: "go", name: "Go" },
                        { id: "rewards", name: "Rewards" },
                        { id: "account", name: "Account" },
                      ]}
                      onSelected={(searchValue) =>
                        setFieldValue("screen", searchValue)
                      }
                      defaultValue={values.target}
                      width={"38ch"}
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"}>
                    <SelectInput
                      title="Type"
                      items={[
                        { id: "*", name: "All" },
                        { id: "single_user", name: "Single Users" },
                      ]}
                      onSelected={(searchValue) =>
                        setFieldValue("type", searchValue)
                      }
                      defaultValue={
                        isView
                          ? selectedRow?.type === "all"
                            ? "*"
                            : "single_user"
                          : values.type
                      }
                      width={"38ch"}
                    />
                  </MDBox>
                </MDBox>

                <MDBox ml={1} width={"47%"}>
                  {shouldShowCampaignId(values.screen ?? "") && (
                    <MDInput
                      fullWidth
                      type="number"
                      label="Campaign Id"
                      name="screenId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.screenId}
                    />
                  )}
                </MDBox>
                <MDBox width={"50%"}>
                  {shouldShowRefferral(values.screen ?? "") && (
                    <SelectInput
                      title="Show Referral"
                      items={[
                        { id: "true", name: "Show" },
                        { id: "false", name: "No" },
                      ]}
                      onSelected={(searchValue) =>
                        setFieldValue("referral", searchValue)
                      }
                      defaultValue={values.referral}
                      width={"38ch"}
                    />
                  )}
                </MDBox>
                <MDBox ml={1}>
                  {shoudlDisplayUserInputs(values.type) && (
                    <Autocomplete
                      id="multiple-limit-tags"
                      multiple
                      limitTags={1}
                      options={allUsers}
                      getOptionLabel={(option) => option.username}
                      loading={isLoading}
                      filterSelectedOptions
                      defaultValue={
                        renderUserIdsInput(values.user_ids) as User[]
                      }
                      onInputChange={(event, value) => {
                        const users = validateFromManualInput(value);

                        if (users) {
                          if (users.length) {
                            handleChange(event);

                            setFieldValue("user_ids", [
                              ...users,
                              ...renderUserIdsInput(values.user_ids),
                            ]);
                          }
                        }
                      }}
                      onChange={(_, newValue) => {
                        setFieldValue("user_ids", newValue);
                      }}
                      sx={{
                        width: "40ch",
                        "& .MuiAutocomplete-tag": {
                          backgroundColor: "rgba(0, 0, 0, 0.08)",
                          color: "rgba(0, 0, 0, 0.87)",
                        },
                        "& .MuiAutocomplete-tag .MuiSvgIcon-root": {},
                      }}
                      value={renderUserIdsInput(values.user_ids) as User[]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="User Ids"
                          placeholder="User Ids"
                        />
                      )}
                    />
                  )}
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {`${isView ? "Resend" : "Create"}`}
          </Button>
        </DialogActions>
      </Dialog>
    </StyledEngineProvider>
  );
};
export default AddViewNotificationsModal;

import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Formik, Form as FormikForm } from "formik";
import { Transaction, TransactionByRange } from "types/Transactions";
import { approveTransactionByDateRange } from "services/api/transactionsApi";

type Props = {
  open: boolean;
  handleClose: (reload?: boolean) => void;
  selectedTransaction: Transaction;
};
const ApproveByRangeModel: React.FC<Props> = (props) => {
  const { open, handleClose, selectedTransaction } = props;
  const formikRef = useRef<any>(null);
  const accessToken = localStorage.getItem("carching-token");

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = async (transaction: TransactionByRange): Promise<void> => {
    const { start_date, end_date } = transaction;
    if (!start_date || !end_date) {
      alert("All Fields are required");
    } else {
      if (accessToken)
        await approveTransactionByDateRange(accessToken, transaction);
      handleClose(true);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {"Approve driver by date range"}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              user_id: selectedTransaction.user.id,
              start_date: "",
              end_date: "",
            }}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, handleBlur, handleChange }) => (
              <FormikForm>
                <MDBox width={"50%"}>
                  <MDInput
                    type="text"
                    label="User Id"
                    name="driverId"
                    value={values.user_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    disabled
                  />
                </MDBox>
                <MDBox mt={5} display={"flex"}>
                  <MDBox width={"50%"} mr={1}>
                    <TextField
                      label="Start Date"
                      type="date"
                      name={"start_date"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.start_date}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <TextField
                      label="End Date"
                      type="date"
                      name={"end_date"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.end_date}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {"Approve"}
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};
export default ApproveByRangeModel;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StoreState, WithdrawalState } from "types/state/StoreState";
import { PaginationCommonObject } from "types/common";
import { Withdrawal, WithdrawalFilter } from "types/Withdrawal";
import { getAllWithdrawals } from "services/api/withdrawalApi";

const withdrawalSlice = createSlice({
  name: "withdrawals",
  initialState: {
    isLoading: false,
    withdrawals: {},
  } as WithdrawalState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setWithdrawals(state, action) {
      state.withdrawals = action.payload;
    },
  },
});
type WithdrawalRequest = {
  accessToken: string;
  filter: WithdrawalFilter;
};

// Thunk Actions
export const getWithdrawalsThunk = createAsyncThunk(
  "withdrawals/getWithdrawalsThunk",
  async (request: WithdrawalRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, filter } = request;
    dispatch(withdrawalSlice.actions.setIsLoading(true));
    const workshops = await getAllWithdrawals(accessToken, filter);
    dispatch(withdrawalSlice.actions.setWithdrawals(workshops));
    dispatch(withdrawalSlice.actions.setIsLoading(false));
  }
);

// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.withdrawals.isLoading;
export const getWithdrawals = (
  state: StoreState
): PaginationCommonObject<Withdrawal> => state.withdrawals.withdrawals;
export const { setWithdrawals, setIsLoading } = withdrawalSlice.actions;
export default withdrawalSlice.reducer;

import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import ModalHeaderTitle from "./Header";
import { createCampaignParticipant } from "services/api/campaignApi";
import { getDriverDetails } from "services/api/usersApi";
import SelectInput from "components/MDInput/SelectInput";
import { useSelector } from "react-redux";
import { getCmapaignStatuses } from "redux/slices/campaignSlice";

type AddEditUserModalProp = {
  open: boolean;
  campaignId: number;
  handleClose: () => void;
};
const AddParticipantModal: React.FC<AddEditUserModalProp> = (props) => {
  const { open, handleClose, campaignId } = props;
  const formikRef = useRef<any>(null);
  const accessToken = localStorage.getItem("carching-token") || "";
  const statuses = useSelector(getCmapaignStatuses);
  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = async (values) => {
    const { driver_id, status_id } = values;
    if (!driver_id || driver_id === "") {
      alert("Driver id is required!");
      return;
    }
    const driverdDetails = await getDriverDetails(
      accessToken,
      Number(driver_id)
    );
    if (
      window.confirm(
        `You are adding ${driverdDetails.user.email} to this campaign`
      ) == true
    ) {
      try {
        await createCampaignParticipant(
          {
            driver_id: Number(driver_id),
            campaign_id: values.campaign_id,
            status_id,
          },
          accessToken
        );
        handleClose();
      } catch (e: any) {
        alert(e.error ?? e);
      }
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`Add Campaign Participant`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              driver_id: 0,
              campaign_id: campaignId,
              status_id: 0,
            }}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, errors, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="text"
                      label="Driver Id"
                      name="driver_id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.driver_id}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="text"
                      label="Campaign Id"
                      name="campaign_id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      value={values.campaign_id}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox width={"50%"}>
                  <SelectInput
                    title="Status"
                    items={statuses}
                    onSelected={(searchValue) =>
                      setFieldValue("status_id", searchValue)
                    }
                    width={"38ch"}
                    margin={0}
                  />
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {"Add driver to campaign"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddParticipantModal;

import React, { useEffect, useState } from "react";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateUser,
  getAdmins,
  getAdminThunk,
} from "redux/slices/allUsersSlice";
import LoadingComponent from "components/LoadingComponent";
import AddEditUserModal from "./AddEditUserModal";
import { NewUser } from "types/Authentication";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import AdminTable from "./table";

const header = [
  { id: "id", name: "id" },
  { id: "fullName", name: "Full Name" },
  { id: "email", name: "Email" },
  { id: "action", name: "Action" },
];
const AdminPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const admins = useSelector(getAdmins);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<NewUser>();

  useEffect(() => {
    if (!!accessToken && !!admins && !admins.data) {
      getAllUsersTableList("", 1);
    }
  }, []);

  const getAllUsersTableList = (username: string, page: number) => {
    if (!!accessToken)
      dispatch(
        getAdminThunk({
          accessToken,
          page: page,
          name: username,
        })
      );
  };

  const createOrEditUser = (user: NewUser) => {
    if (!!accessToken)
      dispatch(
        createOrUpdateUser({
          accessToken,
          isEdit,
          user,
        })
      );
    setModalOpen(false);
    getAllUsersTableList("", 1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddEditUserModal
        open={isModalOpen}
        isEdit={isEdit}
        selectedUser={selectedUser}
        handleClose={() => setModalOpen(false)}
        addEditUser={createOrEditUser}
      />
      {!!admins && admins.data ? (
        <AdminTable
          header={header}
          rows={admins}
          onPageChanged={(newPage: number) =>
            getAllUsersTableList("", newPage + 1)
          }
          handleUserSearch={(username: string) =>
            getAllUsersTableList(username, 1)
          }
          onReload={() => getAllUsersTableList("", 1)}
          addNewOrEditUser={(isEditAction: boolean, selectedUser?: NewUser) => {
            setSelectedUser(selectedUser);
            setIsEdit(isEditAction);
            setModalOpen(!isModalOpen);
          }}
        />
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};

export default WithAuthWrapper(AdminPage);

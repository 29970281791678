import { AxiosResponse } from "axios";
import { getRequest, postRequest, putRequest } from "services/client/request";
import { AxiosGenericResponse, PaginationCommonObject } from "types/common";
import { NewWorkshop, Workshop } from "types/Workshop";

const apiVersion = "/api";

export const getWorkshops = async (
  accessToken: string,
  page: number,
  name?: string
): Promise<PaginationCommonObject<Workshop>> => {
  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<Workshop>>
  > = await getRequest(
    `${apiVersion}/workshops?page=${page}&name=${!!name ? name : ""}`,
    accessToken
  );
  const workshops: PaginationCommonObject<Workshop> = response.data.success
    ? response.data.data
    : ({} as PaginationCommonObject<Workshop>);
  return workshops;
};

export const createWorkshops = async (
  request: NewWorkshop,
  accessToken: string
): Promise<void> => {
  await postRequest(`${apiVersion}/workshops`, accessToken, request);
};

export const updateWorkshops = async (
  request: NewWorkshop,
  accessToken: string
): Promise<void> => {
  await putRequest(
    `${apiVersion}/workshops/${request.id}`,
    accessToken,
    request
  );
};

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ProgressBar from "@ramonak/react-progress-bar";

type Props = {
  completed: number | string;
  color?: string;
  title: string;
  subLabel?: string;
  subLabelValue?: string | number;
  barHeight?: string;
  barWidth?: string;
  maxCompleted?: number;
};
const ProgressBarCard: React.FC<Props> = (props) => {
  const {
    color,
    completed,
    title,
    barHeight,
    barWidth,
    maxCompleted,
    subLabel,
    subLabelValue,
  } = props;
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox width={"100%"}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {title}
          </MDTypography>
          <ProgressBar
            completed={completed}
            labelAlignment={Number(completed) <= 0 ? "left" : "center"}
          />
        </MDBox>
      </MDBox>
      <Divider />
      {!!subLabel && (
        <MDBox pb={2} px={2}>
          <MDTypography
            component="p"
            variant="button"
            color="text"
            display="flex"
          >
            <MDTypography component="span" variant="button" fontWeight="normal">
              {subLabel}
            </MDTypography>
            :
            <MDTypography component="span" variant="button" fontWeight="bold">
              {subLabelValue}
            </MDTypography>
          </MDTypography>
        </MDBox>
      )}
    </Card>
  );
};

export default ProgressBarCard;

import React, { useEffect, useState } from "react";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import Papa from "papaparse";
import CustomBarChart from "./customBarChart";
import TableComponent from "./table";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCampaignForReportThunk,
  getAllCampaignFromReport,
  getDriversInfoReportSelector,
  getLoadingStatus,
  getReporDriversInfoThunk
} from "redux/slices/reportSlice";
import LoadingComponent from "components/LoadingComponent";
import { ReportCampaign } from "types/Report";
import SelectInput from "components/MDInput/SelectInput";
import MDButton from "components/MDButton";
import { saveAs } from "file-saver";
import { uploadGoogleSheetEndpoint } from "services/api/reportApi";

interface DataRow {
  [key: string]: string | number;
}

interface DataSection {
  title: string;
  rows: DataRow[];
}
type Section = DataSection;

interface ChartData {
  name: string;
  value: number;
}
interface TableRow {
  name: string;
  value: string | number;
  percentage: string;
}

interface DriverDetails {
  id: number;
  name: string;
  car_model: string;
  car_color: string;
  common_areas: string[];
}

const GenerteReport: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [array, setArray] = useState<any[]>();
  const campaigns = useSelector(getAllCampaignFromReport);
  const isLoading = useSelector(getLoadingStatus);
  const campaignsDrivers = useSelector(getDriversInfoReportSelector);
  const [isReportGenerating, setIsReportGenerating] = useState<boolean>(false);
  const [drivers, setDrivers] = useState<DataSection>();
  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (campaigns && campaigns.length < 1) {
      dispatch(getAllCampaignForReportThunk(accessToken));
    }
  }, [accessToken, dispatch]);

  const handleFileUpload = (event) => {
    event.preventDefault();

    if (file) {
      Papa.parse(file, {
        header: false,
        skipEmptyLines: false,
        complete: async (results) => {
          const sections: DataSection[] = [];
          let headers: string[] = [];
          let currentTitle: string | null = null;
          let currentSection: DataSection | null = null;
          let isSpecialImpressionsSection = false;

          for (let i = 0; i < results.data.length; i++) {
            const row = results.data[i];

            if (Object.values(row).every((cell) => cell === "")) {
              // Empty row signifies the end of the current section
              if (currentSection) {
                sections.push(currentSection);
                currentSection = null;
                currentTitle = null;
              }
              continue;
            }

            if (!currentTitle) {
              // Start of a new section
              headers = row;
              currentTitle = headers[0];
              currentSection = { title: currentTitle, rows: [] };
              if (currentTitle === "Impressions") {
                isSpecialImpressionsSection = true;
              }
            } else if (currentSection) {
              // Data row for the current section
              const dataRow: DataRow = {};
              const seenHeaders = new Set<string>();

              if (isSpecialImpressionsSection) {
                headers.slice(0, 3).forEach((header, index) => {
                  if (!seenHeaders.has(header)) {
                    dataRow[header] = row[index];
                    seenHeaders.add(header);
                  }
                });
              } else {
                headers.forEach((header, index) => {
                  if (index > 0 && !seenHeaders.has(header)) {
                    dataRow[header] = row[index];
                    seenHeaders.add(header);
                  }
                });
              }
              currentSection.rows.push(dataRow);
            }

            // Handle transition to next section for "Impressions"
            if (
              isSpecialImpressionsSection &&
              Object.values(row)
                .slice(0, 3)
                .every((cell) => cell === "")
            ) {
              if (currentSection) {
                sections.push(currentSection);
                currentSection = null;
                currentTitle = null;
              }
              isSpecialImpressionsSection = false;
            }
          }

          // Push the last section if it exists
          if (currentSection) {
            sections.push(currentSection);
          }
          setDrivers(sections[6]);

          // Transform and generate CSV
          const allSections = [
            transformRoadTypeData(sections[8]),
            transformAddreessDataForTable(sections[0]),
            convertToTableData(sections[1]),
            convertToDistanceTableData(sections[2]),
            summarizeTrafficConditions(sections[9]),
            transformDaysData(sections[3]),
            transformWeekData(sections[5])
          ];
          setArray(allSections);
          await uploadToGoogleSheet(allSections);
          // let csvContent = "";
          // allSections.forEach((section) => {
          //   csvContent += `${section.title}\n`;
          //   csvContent += Papa.unparse(section.rows) + "\n\n";
          // });

          // // Download the CSV
          // const blob = new Blob([csvContent], {
          //   type: "text/csv;charset=utf-8;"
          // });
          // saveAs(blob, "campaign_report.csv");
        }
      });
    }
  };
  const uploadToGoogleSheet = async (data) => {
    try {
      setIsReportGenerating(true);
      await uploadGoogleSheetEndpoint(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsReportGenerating(false);
      alert("Upload to google sheet successfully");
    }
  };
  // Impression
  const convertToTableData = (section: DataSection) => {
    const tableData = section.rows
      .filter((row) => row.Impressions && row["%"]) // Filter out empty rows
      .map((row) => ({
        name: `${row.Impressions}`.replace(" (Total Campaigns)", ""),
        value: parseFloat(String(row[""])),
        percentage: parseFloat(String(row["%"]))
      }));
    return tableData as DataRow[];
  };

  // Traffic Condition
  const summarizeTrafficConditions = (data: DataSection) => {
    const summary: { [key: string]: { mins: number; percent: number } } = {
      Off: { mins: 0, percent: 0 },
      Normal: { mins: 0, percent: 0 },
      Peak: { mins: 0, percent: 0 }
    };
    let totalRow: DataRow | null = null;

    data.rows.forEach((row) => {
      const durationMins = parseFloat(
        row["Sum of New Road Duration (Mins)"] as string
      );
      const percent = parseFloat((row["%"] as string).replace("%", ""));
      switch (row["Traffic Condition"]) {
        case "Unknown":
        case "Green":
          summary.Off.mins += durationMins;
          summary.Off.percent += percent;
          break;
        case "Orange":
          summary.Normal.mins += durationMins;
          summary.Normal.percent += percent;
          break;
        case "Red":
        case "Maroon":
          summary.Peak.mins += durationMins;
          summary.Peak.percent += percent;
          break;
        case "Total":
          totalRow = row;
          break;
      }
    });

    const newRows: DataRow[] = [
      {
        "Traffic Condition": "Off",
        "Sum of New Road Duration (Mins)": summary.Off.mins,
        "%": summary.Off.percent
      },
      {
        "Traffic Condition": "Normal",
        "Sum of New Road Duration (Mins)": summary.Normal.mins,
        "%": summary.Normal.percent
      },
      {
        "Traffic Condition": "Peak",
        "Sum of New Road Duration (Mins)": summary.Peak.mins,
        "%": summary.Peak.percent
      }
    ];

    if (totalRow) {
      newRows.push(totalRow);
    }
    return newRows;
  };

  // Distance
  const convertToDistanceTableData = (section: DataSection): DataRow[] => {
    const tableData = section.rows
      .filter((row) => row.KM && row["%"]) // Filter out empty rows
      .map((row, index) => ({
        name: index === 0 ? "Target" : "Actual",
        value: parseFloat(`${row.KM}`),
        percentage: parseFloat(String(row["%"]))
      }));
    return tableData;
  };

  // Road types
  const transformRoadTypeData = (data: DataSection) => {
    if (!data || !data.rows) {
      alert("Please upload correct format file");
      return;
    }
    // Calculate new impression values and prepare rows
    const transformedRows: DataRow[] = data.rows.map((row) => {
      const newImpression =
        (parseFloat(row["Sum of Idle Final (Mins)"] as string) +
          parseFloat(row["Impression"] as string)) *
        2;
      return {
        "Road Type": row["Road Type (Excluding Unknown)"],
        "Duration (Mins)": parseFloat(
          row["Sum of New Road Duration (Mins)"] as string
        ),
        "%": parseFloat(String(row["%"])),
        Impression: newImpression
      };
    });

    // Separate the total row and sort the rest by %
    const totalRow = transformedRows.pop();
    transformedRows.sort(
      (a, b) => parseFloat(b["%"] as string) - parseFloat(a["%"] as string)
    );

    // Re-add the total row at the end
    if (totalRow) {
      transformedRows.push(totalRow);
    }
    return transformedRows;
  };

  // Days
  const transformDaysData = (data: DataSection) => {
    // Extract required values for the table
    const transformedRows: DataRow[] = data.rows.map((row) => {
      return {
        Days: `${row["Days"]}`.charAt(0), // First letter of the day
        "Duration (Min)": parseFloat(
          row["Sum of New Road Duration (Mins)"] as string
        ), // Convert to locale string format
        "%": parseFloat(String(row["%"]))
      };
    });

    // Sort rows by percentage in descending order, keeping "Total" at the end
    const totalRow = transformedRows.pop();
    transformedRows.sort(
      (a, b) => parseFloat(b["%"] as string) - parseFloat(a["%"] as string)
    );
    if (totalRow) {
      transformedRows.push(totalRow);
    }
    return transformedRows;
  };

  const transformWeekData = (data: DataSection) => {
    const transformedRows: DataRow[] = [];
    let aggregatedDuration = 0;
    let aggregatedImpressions = 0;

    data.rows.forEach((row, index) => {
      if (row["Cumulative Week"] === "Total") return;

      const week = `Week ${index + 1}`;
      const weeklyDuration = parseFloat(row["Road Duration"] as string);
      const weeklyImpressions = parseFloat(row["Sum of Impression"] as string);

      aggregatedDuration += weeklyDuration;
      aggregatedImpressions += weeklyImpressions;

      transformedRows.push({
        Week: week,
        "Weekly Duration (Mins)": weeklyDuration,
        "Aggregated Duration (Mins)": aggregatedDuration,
        "Weekly Impressions": weeklyImpressions,
        "Aggregated Impressions": aggregatedImpressions
      });
    });
    return transformedRows;
  };

  const transformAddreessDataForTable = (data: DataSection) => {
    const rows = data.rows.slice(0, -1); // Exclude the last row (Grand Total) for processing
    const grandTotalRow = data.rows[data.rows.length - 1]; // Store the last row (Grand Total)
    const transformedRows: DataRow[] = [];
    let othersDuration = 0;
    let othersPercent = 0;
    let othersAdded = false;

    rows.forEach((row, index) => {
      const duration = parseFloat(
        row["Sum of New Road Duration (Mins)"] as string
      );
      const percent = parseFloat(row["%"] as string);
      const address = row["Addresses"] as string;

      if (othersPercent < 25) {
        // Accumulate under "Others" until 25%
        othersDuration += duration;
        othersPercent += percent;
      } else if (!othersAdded) {
        // Add the "Others" row when reaching 25% and reset the flag
        transformedRows.push({
          Addresses: "Others",
          "Duration (Mins)": othersDuration,
          "%": othersPercent
        });
        othersAdded = true;
        // Then add the current row directly
        transformedRows.push({
          Addresses: address,
          "Duration (Mins)": duration,
          "%": percent
        });
      } else {
        // Add the row directly after "Others" has been added
        transformedRows.push({
          Addresses: address,
          "Duration (Mins)": duration,
          "%": percent
        });
      }
    });

    // Add the "Others" row if it was not added yet
    if (!othersAdded) {
      transformedRows.push({
        Addresses: "Others",
        "Duration (Mins)": othersDuration,
        "%": othersPercent
      });
    }

    // Sort the rows by percentage in descending order, except for the "Grand Total" row
    transformedRows.sort((a, b) => {
      return (b["%"] as number) - (a["%"] as number);
    });

    // Add the Grand Total row at the end
    transformedRows.push({
      Addresses: "Grand Total",
      "Duration (Mins)": parseFloat(
        String(grandTotalRow["Sum of New Road Duration (Mins)"])
      ),
      "%": parseFloat(String(grandTotalRow["%"]))
    });
    return transformedRows;
  };

  const transformDriverData = (
    driverDetails: DriverDetails[],
    driversData: DataSection
  ): DataRow[] => {
    // Create a map for driver details
    const driverDetailsMap = new Map<number, DriverDetails>();
    driverDetails.forEach((driver) => driverDetailsMap.set(driver.id, driver));
    if (!driversData) {
      alert(
        "Please choose a correct file and import the csv before generate drivers"
      );
    }
    // Transform the drivers data
    const transformedRows: DataRow[] = driversData.rows
      .map((row) => {
        const driverId = parseInt(row["Drivers"] as string);
        const driverDetail = driverDetailsMap.get(driverId);
        if (!driverDetail) return null;

        const newImpression =
          (parseFloat(row["Sum of Idle Final (Mins)"] as string) +
            parseFloat(row["Impression"] as string)) *
          2;

        return {
          id: driverId,
          "car model": driverDetail.car_model,
          "car colour": driverDetail.car_color,
          Areas: driverDetail.common_areas.join(", "),
          Impressions: newImpression.toLocaleString()
        };
      })
      .filter((row) => row !== null) as DataRow[];
    return transformedRows;
  };
  const selectedCurrentCampaign = (
    campaignId: string,
    allcampaigns: ReportCampaign[]
  ) => {
    const campaign = allcampaigns.find(
      (data) => data.id.toString() === campaignId.toString()
    );

    dispatch(getReporDriversInfoThunk({ accessToken, id: campaign?.id ?? 1 }));
  };
  const generateDriverCsv = () => {
    const driver = transformDriverData(
      campaignsDrivers,
      drivers as DataSection
    );
    // Download the CSV
    const blob = new Blob([Papa.unparse(driver)], {
      type: "text/csv;charset=utf-8;"
    });
    saveAs(blob, "driver_details_report.csv");
    // Genereate csv here
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {}
      {(isLoading && campaigns.length === 0) || isReportGenerating ? (
        <LoadingComponent />
      ) : (
        <>
          <MDBox pt={2}>
            <div>
              <h1>Generate Report </h1>
              <form>
                <input
                  type={"file"}
                  id={"csvFileInput"}
                  accept={".csv"}
                  onChange={handleOnChange}
                />

                <MDButton
                  variant="gradient"
                  color="secondary"
                  onClick={(e) => {
                    handleFileUpload(e);
                  }}
                  style={{ marginLeft: 15 }}
                >
                  IMPORT CSV
                </MDButton>
              </form>

              <br />
              <h1>Generate Driver Table </h1>
              <MDBox mb={2} ml={1} display={"flex"}>
                <MDBox width={"25%"} mr={1} mb={5}>
                  <SelectInput
                    title="Select Campaign"
                    itemName="title"
                    combinationWithItemName="id"
                    items={campaigns}
                    onSelected={(searchValue) => {
                      selectedCurrentCampaign(searchValue, campaigns);
                    }}
                    width={"30ch"}
                  />
                </MDBox>
              </MDBox>
              <MDButton
                variant="gradient"
                color="secondary"
                onClick={generateDriverCsv}
                style={{ marginRight: 8 }}
              >
                Generate Driver
              </MDButton>
              {/* {impressionTable && (
                <TableComponent data={impressionTable} title="Impression" />
              )}
              {distanceTable && (
                <TableComponent data={distanceTable} title="Distance" />
              )} */}
            </div>
          </MDBox>
        </>
      )}
    </DashboardLayout>
  );
};

export default WithAuthWrapper(GenerteReport);

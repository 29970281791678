import React from "react";
import { Drivers } from "types/AllUsers";
import DriverImages from "./child/driverImages";
import DriverCarImages from "./child/driverCarImages";
import DriverCarDetails from "./child/driverCarDetails";
import DriverDetails from "./child/driverDetails";
import AddBanks from "./child/addBanks";
import DriverLocations from "./child/driverLocations";

type Props = {
  isEdit: boolean;
  selectedDriver: Drivers;
  addEditUser: (updatedUser: Drivers) => void;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  setValues?: (
    values: React.SetStateAction<Drivers>,
    shouldValidate?: boolean | undefined
  ) => void;
};

const AdTechModal: React.FC<Props> = (props) => {
  const { selectedDriver, handleChange, handleBlur, setFieldValue } = props;

  return (
    <>
      <DriverDetails
        values={selectedDriver}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      />
      <AddBanks
        userBanks={selectedDriver.user}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      />
      <DriverCarImages values={selectedDriver} setFieldValue={setFieldValue} />
      <DriverCarDetails
        values={selectedDriver}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
      />
      <DriverImages values={selectedDriver} setFieldValue={setFieldValue} />
      {selectedDriver.driver_locations && (
        <DriverLocations
          values={selectedDriver.driver_locations}
          setFieldValue={setFieldValue}
        />
      )}
    </>
  );
};
export default AdTechModal;

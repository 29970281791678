import { createAsyncThunk, createSlice, Store } from "@reduxjs/toolkit";
import {
  AllDrivers,
  Bank,
  DriverFilter,
  Drivers,
  DriversRequest,
} from "types/AllUsers";
import { DriverState, StoreState } from "types/state/StoreState";
import {
  createNewDriver,
  getAllDrivers,
  getAllDriversWithoutPagination,
  getDriverAllStatus,
  getDriverDetails,
  getFilteredDrivers,
  updateExistingDriver,
} from "services/api/usersApi";
import { State, Status } from "types/common";
import { getCarBrands, getCarColors, getCarModels } from "services/api/carApi";
import { CarBrand, CarColor, CarModel } from "types/Cars";
import { getBanksList, getCountryStates } from "services/api/commonApi";

const driverSlice = createSlice({
  name: "drivers",
  initialState: {
    isLoading: false,
    drivers: {} as AllDrivers,
    driverStatus: [],
    selectedDriver: {} as Drivers,
    carBrands: [],
    carColors: [],
    carModels: [],
    countryStates: [],
    banks: [],
    allDrivers: [],
  } as DriverState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setDrivers(state, action) {
      state.drivers = action.payload;
    },
    setDriverStatus(state, action) {
      state.driverStatus = action.payload;
    },
    setSelectedDriver(state, action) {
      state.selectedDriver = action.payload;
    },
    setCarModels(state, action) {
      state.carModels = action.payload;
    },
    setCarColors(state, action) {
      state.carColors = action.payload;
    },
    setCarBrands(state, action) {
      state.carBrands = action.payload;
    },
    setCountryStates(state, action) {
      state.countryStates = action.payload;
    },
    setBanks(state, action) {
      state.banks = action.payload;
    },
    settAllDrivers(state, action) {
      state.allDrivers = action.payload;
    },
  },
});

// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.drivers.isLoading;

export const getDrivers = (state: StoreState): AllDrivers =>
  state.drivers.drivers;

export const getDriverStatus = (state: StoreState): Status[] =>
  state.drivers.driverStatus;

export const getCurrentDriver = (state: StoreState): Drivers =>
  state.drivers.selectedDriver;

export const getDriverCarBrand = (state: StoreState): CarBrand[] =>
  state.drivers.carBrands;
export const getDriverCarColor = (state: StoreState): CarColor[] =>
  state.drivers.carColors;
export const getDriverCarModel = (state: StoreState): CarModel[] =>
  state.drivers.carModels;
export const getStates = (state: StoreState): State[] =>
  state.drivers.countryStates;
export const getBanks = (state: StoreState): Bank[] => state.drivers.banks;
export const getDriversListWithoutPagination = (state: StoreState): Drivers[] =>
  state.drivers.allDrivers;

export const getAllDriversThunk = createAsyncThunk(
  "drivers/getAllDriversThunk",
  async (request: DriversRequest, thunkApi) => {
    const { dispatch } = thunkApi;

    dispatch(driverSlice.actions.setIsLoading(true));
    const drivers = await getAllDrivers(request);
    dispatch(driverSlice.actions.setDrivers(drivers));
    dispatch(driverSlice.actions.setIsLoading(false));
  }
);

export type CurrentDriverType = {
  accessToken: string;
  driverId: number;
};
export const getCurrentDriverThunk = createAsyncThunk(
  "drivers/getCurrentDriverThunk",
  async (request: CurrentDriverType, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, driverId } = request;
    const driver = await getDriverDetails(accessToken, driverId);
    dispatch(driverSlice.actions.setSelectedDriver(driver));
  }
);

export const getAllDriversWithoutPaginationThunk = createAsyncThunk(
  "drivers/getAllDriversWithoutPaginationThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(driverSlice.actions.setIsLoading(true));
    const allDrivers = await getAllDriversWithoutPagination(accessToken);
    dispatch(driverSlice.actions.settAllDrivers(allDrivers));
    dispatch(driverSlice.actions.setIsLoading(false));
  }
);

export const getDriverStatusThunk = createAsyncThunk(
  "drivers/getDriverStatusThink",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;
    const statuses = await getDriverAllStatus(accessToken);
    dispatch(driverSlice.actions.setDriverStatus(statuses));
  }
);

export const getDriverCarsOptionsThunk = createAsyncThunk(
  "drivers/getDriverStatusThink",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;

    const [carColors, carModels, carBrands, states, banks] = await Promise.all([
      getCarColors(accessToken),
      getCarModels(accessToken),
      getCarBrands(accessToken),
      getCountryStates(accessToken),
      getBanksList(accessToken),
    ]);

    dispatch(driverSlice.actions.setCarBrands(carBrands));
    dispatch(driverSlice.actions.setCarColors(carColors));
    dispatch(driverSlice.actions.setCarModels(carModels));
    dispatch(driverSlice.actions.setCountryStates(states));
    dispatch(driverSlice.actions.setBanks(banks));
  }
);

export type FilteredDriverType = {
  header: string;
  value: string;
  accessToken: string;
  page?: number;
};
export const filterDriversThunk = createAsyncThunk(
  "drivers/filterDriversThunk",
  async (request: FilteredDriverType, thunkApi) => {
    const { header, value, accessToken, page } = request;
    const { dispatch } = thunkApi;
    dispatch(driverSlice.actions.setIsLoading(true));
    const drivers = await getFilteredDrivers(header, value, accessToken, page);
    dispatch(driverSlice.actions.setDrivers(drivers));
    dispatch(driverSlice.actions.setIsLoading(false));
  }
);
type NewOrEditDriverRequest = {
  accessToken: string;
  isEdit: boolean;
  driver: Drivers;
};
export const createOrUpdateDriver = createAsyncThunk(
  "drivers/createOrUpdateDriver",
  async (userRequest: NewOrEditDriverRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, isEdit, driver } = userRequest;
    dispatch(driverSlice.actions.setIsLoading(true));
    isEdit
      ? await updateExistingDriver(driver, accessToken)
      : await createNewDriver(driver, accessToken);
    dispatch(driverSlice.actions.setIsLoading(false));
  }
);

export const createNewDriverThunk = createAsyncThunk(
  "drivers/createNewDriverThunk",
  async (userRequest: CurrentDriverType, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(driverSlice.actions.setIsLoading(true));
    const { accessToken, driverId } = userRequest;
    const driver = {
      user_id: driverId,
    };
    const response = await createNewDriver(driver, accessToken);
    if (!Object.keys(response).length) {
      dispatch(driverSlice.actions.setIsLoading(false));
      return;
    }
    const drivers = await getAllDrivers({
      accessToken,
      page: 1,
      filter: {} as DriverFilter,
    });
    dispatch(driverSlice.actions.setDrivers(drivers));
    const selectedDriver = drivers.data.find(
      (driver) => driver.id === response.id
    );
    dispatch(driverSlice.actions.setIsLoading(false));

    return selectedDriver;
  }
);
export default driverSlice.reducer;

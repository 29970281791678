import { AxiosResponse } from "axios";
import { getRequest, postRequest, putRequest } from "services/client/request";
import { CarBrand, CarColor, CarModel, CarSettings } from "types/Cars";
import { AxiosGenericResponse } from "types/common";
import { Variables } from "types/Setting";

const apiVersion = "/api";

export const getAllSettings = async (
  accessToken: string
): Promise<Variables[]> => {
  const response: AxiosResponse<AxiosGenericResponse<Variables[]>> =
    await getRequest(`${apiVersion}/settings`, accessToken);
  const variables: Variables[] = response.data.success
    ? response.data.data
    : ([] as Variables[]);
  return variables;
};

export const createSettingVariables = async (
  request: any,
  accessToken: string
): Promise<void> => {
  await postRequest(`${apiVersion}/settings`, accessToken, request);
};

export const getAllCarBrands = async (
  accessToken: string
): Promise<CarBrand[]> => {
  const response: AxiosResponse<AxiosGenericResponse<CarBrand[]>> =
    await getRequest(`${apiVersion}/car_brands`, accessToken);
  const carbrands: CarBrand[] = response.data.success
    ? response.data.data
    : ([] as CarBrand[]);
  return carbrands;
};

export const getAllCarModels = async (
  accessToken: string
): Promise<CarModel[]> => {
  const response: AxiosResponse<AxiosGenericResponse<CarModel[]>> =
    await getRequest(`${apiVersion}/car_models`, accessToken);
  const carModels: CarModel[] = response.data.success
    ? response.data.data
    : ([] as CarModel[]);
  return carModels;
};

export const getAllCarColors = async (
  accessToken: string
): Promise<CarColor[]> => {
  const response: AxiosResponse<AxiosGenericResponse<CarColor[]>> =
    await getRequest(`${apiVersion}/car_colors`, accessToken);
  const carColors: CarColor[] = response.data.success
    ? response.data.data
    : ([] as CarColor[]);
  return carColors;
};

export const createCarSettings = async (
  request: CarSettings,
  carType: string,
  accessToken: string
): Promise<void> => {
  await postRequest(`${apiVersion}/${carType}`, accessToken, request);
};

export const updateCarSettings = async (
  request: CarSettings,
  carType: string,
  accessToken: string
): Promise<void> => {
  await putRequest(
    `${apiVersion}/${carType}/${request.id}`,
    accessToken,
    request
  );
};

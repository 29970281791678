import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";

type Props = {
  title: string;
  children: React.ReactNode;
  expanded?: boolean;
  panel: string;
  onChange: (panel: string, isExpanded: boolean) => void;
};
const AccordionDefault: React.FC<Props> = (props) => {
  const { title, children, expanded, panel, onChange } = props;
  return (
    <Accordion
      expanded={expanded}
      onChange={(event, isExpanded) => onChange(panel, isExpanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <MDTypography variant="h6" fontWeight="medium">
          {title}
        </MDTypography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionDefault;

import LoadingComponent from "components/LoadingComponent";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import {
  getAllPostings,
  getAllPostingsThunk,
} from "redux/slices/carefreeSlice";
import HomeOwnerTable from "./table";

const header = [
  { id: "id", name: "Listing Id" },
  { id: "homeOwnerId", name: "Home Id" },
  { id: "start_date", name: "Booking Start Date" },
  { id: "end_date", name: "Booking End Date" },
  { id: "month", name: "Month" },
  { id: "duration", name: "Duration" },
  { id: "status", name: "Status" },
  { id: "house_url", name: "House URL" },
  { id: "address", name: "Address" },
  { id: "action", name: "Cancel listing Action" },
];
const CarefreePostingsPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const postings = useSelector(getAllPostings);

  useEffect(() => {
    if (!!accessToken && !!postings && !postings.length) {
      getAllPostingsList();
    }
  }, [accessToken]);

  const getAllPostingsList = () => {
    if (!!accessToken) dispatch(getAllPostingsThunk(accessToken));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {!!postings && postings.length > 0 ? (
        <HomeOwnerTable
          header={header}
          rows={postings}
          onReload={() => getAllPostingsList()}
        />
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(CarefreePostingsPage);

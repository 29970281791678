import ImageUpload from "components/ImageUpload";
import MDBox from "components/MDBox";
import React from "react";
import { CareFreeHomeOwner } from "types/Carefree";

type Props = {
  setFieldValue: (field: string, value: any) => void;
  values: CareFreeHomeOwner;
};
const HomeOwnerCarImages: React.FC<Props> = (props) => {
  const { values, setFieldValue } = props;
  return (
    <MDBox>
      <MDBox mb={2} display={"flex"}>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="Place Id"
            selectedImage={values.place_id}
            handleImageUpload={(image) => setFieldValue("place_id", image)}
          />
        </MDBox>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="Electricity bill"
            selectedImage={values.electricity_bill_picture_url}
            handleImageUpload={(image) =>
              setFieldValue("electricity_bill_picture_url", image)
            }
          />
        </MDBox>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="Home Picture"
            selectedImage={values.home_picture_url}
            handleImageUpload={(image) =>
              setFieldValue("home_picture_url", image)
            }
          />
        </MDBox>
        <MDBox width={"20%"} mr={1}>
          <ImageUpload
            title="Front Gate"
            selectedImage={values.front_gate_picture_url}
            handleImageUpload={(image) =>
              setFieldValue("front_gate_picture_url", image)
            }
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default HomeOwnerCarImages;

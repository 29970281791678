import { AxiosResponse } from "axios";
import { getRequest, postRequest } from "services/client/request";
import { AxiosGenericResponse, PaginationCommonObject } from "types/common";
import { NewNotification, Notification } from "types/Notification";

const apiVersion = "/api";

export const getNotifications = async (
  accessToken: string,
  page: number
): Promise<PaginationCommonObject<Notification>> => {
  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<Notification>>
  > = await getRequest(`${apiVersion}/notifications?page=${page}`, accessToken);
  const notification: PaginationCommonObject<Notification> = response.data
    .success
    ? response.data.data
    : ({} as PaginationCommonObject<Notification>);
  return notification;
};

export const createNotification = async (
  request: NewNotification,
  accessToken: string
): Promise<void> => {
  await postRequest(`${apiVersion}/notifications`, accessToken, request);
};

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableHeader from "components/MDTable/tableHeader";
import React from "react";
import { LedgerEnum } from "types/enum/transactions";
import { FloatLedger, MainLedger } from "types/Overview";
import { ITableHeader } from "types/Table";

type Props = {
  header: ITableHeader[];
  rows: MainLedger[] | FloatLedger[];
  tableTitle: string;
};
const LedgerTable: React.FC<Props> = (props) => {
  const { header, rows, tableTitle } = props;
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeader
                numSelected={0}
                order={"asc"}
                orderBy={""}
                onSelectAllClick={() => {}}
                onRequestSort={() => {}}
                rowCount={rows.length}
                headCells={header}
                disableCheckbox={true}
              />
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell align="right">{row.id}</TableCell>
                      <TableCell align="right">
                        {new Date(row.created_at).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="right">
                        {new Date(row.created_at).toLocaleTimeString()}
                      </TableCell>
                      {tableTitle === LedgerEnum.MAIN_LEDGER && (
                        <>
                          <TableCell align="right">
                            {new Date(row.updated_at).toLocaleDateString()}
                          </TableCell>
                          <TableCell align="right">
                            {new Date(row.updated_at).toLocaleTimeString()}
                          </TableCell>
                        </>
                      )}
                      <TableCell align="right">{row.user_id}</TableCell>
                      <TableCell align="right">{row.type.name}</TableCell>
                      <TableCell align="right">
                        {row.transaction_type}
                      </TableCell>
                      <TableCell align="right">
                        {tableTitle === LedgerEnum.FLOAT_LEDGER
                          ? row.amount
                          : row.approved_amount}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};
export default LedgerTable;

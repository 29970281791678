import { combineReducers } from "redux";

import campaignSlice from "redux/slices/campaignSlice";
import authenticationSlice from "redux/slices/authenticationSlice";
import notificationSlice from "redux/slices/notificationSlice";
import allUsersSlice from "redux/slices/allUsersSlice";
import journeySlice from "redux/slices/journeySlice";
import driverSlice from "redux/slices/driverSlice";
import transactionSlice from "redux/slices/transactionSlice";
import monthySubmissionsSlice from "redux/slices/monthySubmissionsSlice";
import appointmentSubmissionsSlice from "redux/slices/appointmentSubmissionsSlice";
import workshopSlice from "redux/slices/workshopSlice";
import withdrawalSlice from "redux/slices/withdrawalSlice";
import brandSlice from "redux/slices/brandSlice";
import bannerSlice from "redux/slices/bannerSlice";
import partnerSlice from "redux/slices/partnerSlice";
import settingSlice from "redux/slices/settingSlice";
import carefreeSlice from "redux/slices/carefreeSlice";
import dashboardSlice from "redux/slices/dashboardSlice";
import reportSlice from "redux/slices/reportSlice";

const rootReducer = combineReducers({
  campaigns: campaignSlice,
  authentication: authenticationSlice,
  notifications: notificationSlice,
  allUsers: allUsersSlice,
  journeys: journeySlice,
  drivers: driverSlice,
  transactions: transactionSlice,
  monthlySubmissions: monthySubmissionsSlice,
  appointmentSubmissions: appointmentSubmissionsSlice,
  workshops: workshopSlice,
  withdrawals: withdrawalSlice,
  brands: brandSlice,
  banners: bannerSlice,
  partners: partnerSlice,
  settings: settingSlice,
  carefree: carefreeSlice,
  dashboard: dashboardSlice,
  report: reportSlice
});

export default rootReducer;

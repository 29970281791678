import LoadingComponent from "components/LoadingComponent";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createNotificationThunk,
  getAllNotifications,
  getNotificationThunk,
} from "redux/slices/notificationSlice";
import { NewNotification, Notification } from "types/Notification";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import AddViewNotificationsModal from "./Modal";
import NotificationsTable from "./table";

const headers = [
  { id: "id", name: "Id" },
  { id: "title", name: "Title" },
  { id: "subtitle", name: "Subtitle" },
  { id: "target", name: "Target" },
  { id: "type", name: "type" },
  { id: "created_at", name: "Created At" },
  { id: "actions", name: "Actions" },
];
const NotificationPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const notifcations = useSelector(getAllNotifications);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isView, setViewModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Notification>();

  const getNotificationsPage = useCallback(
    (page: number) => {
      if (accessToken) {
        dispatch(getNotificationThunk({ accessToken, page }));
      }
    },
    [accessToken, dispatch]
  );
  useEffect(() => {
    if (accessToken && !!notifcations && !notifcations.data) {
      getNotificationsPage(1);
    }
  }, [accessToken, getNotificationsPage, notifcations]);

  const createNotification = useCallback(
    (newNotification: NewNotification) => {
      if (accessToken) {
        dispatch(createNotificationThunk({ accessToken, newNotification }));
        setModalOpen(false);
        getNotificationsPage(1);
      }
    },
    [accessToken, dispatch]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddViewNotificationsModal
        open={isModalOpen}
        isView={isView}
        selectedRow={selectedRow}
        handleClose={() => setModalOpen(false)}
        createNewNotifcation={createNotification}
      />
      {!!notifcations && notifcations.data ? (
        <NotificationsTable
          header={headers}
          rows={notifcations}
          onPageChanged={(newPage: number) => getNotificationsPage(newPage + 1)}
          onReload={() => getNotificationsPage(1)}
          addNewOrViewNotifications={(
            isView: boolean,
            selectedRow?: Notification
          ) => {
            setSelectedRow(selectedRow);
            setViewModal(isView);
            setModalOpen(true);
          }}
        />
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};

export default WithAuthWrapper(NotificationPage);

import React, { useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import { NewNotification, Notification } from "types/Notification";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import SelectInput from "components/MDInput/SelectInput";
import { StyledEngineProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import {
  createTransactionThunk,
  getTransactionTypeReducers,
} from "redux/slices/transactionSlice";
import { NewTransaction } from "types/Transactions";
import {
  getAllUsersWithoutPaginationThunk,
  getLoadingStatus,
  getUsersWithoutPagination,
} from "redux/slices/allUsersSlice";

type AddViewModalProp = {
  open: boolean;
  handleClose: (reload?: boolean) => void;
};
const initialValues = {
  user_id: 0,
  points: 0,
  transactionType: "",
};
const NewTransactionModal: React.FC<AddViewModalProp> = (props) => {
  const { open, handleClose } = props;
  const formikRef = useRef<any>(null);
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const transactionType = useSelector(getTransactionTypeReducers);
  const allUsers = useSelector(getUsersWithoutPagination);
  const isUserLoading = useSelector(getLoadingStatus);

  useEffect(() => {
    if (!!accessToken) dispatch(getAllUsersWithoutPaginationThunk(accessToken));
  }, [accessToken, dispatch]);

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose(false);
    }
  };

  const onSubmit = (values) => {
    const { user_id, points, transactionType } = values;

    const request: NewTransaction = {
      transaction_type: "Debit",
      type_id: transactionType,
      amount: points,
      user_id: user_id,
      approved_amount: points,
      status_id: 25,
    };
    dispatch(createTransactionThunk({ accessToken, newTransaction: request }));
    handleClose(true);
  };

  return (
    <StyledEngineProvider injectFirst>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`New Transaction`}
        </ModalHeaderTitle>{" "}
        <DialogContent dividers>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <MDBox mb={2} ml={1} display={"flex"}>
                  <MDBox mr={5} width={"50%"}>
                    <MDInput
                      fullWidth
                      type="number"
                      label="Points"
                      name="points"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.points}
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <Autocomplete
                      options={allUsers}
                      getOptionLabel={(option) =>
                        `${option.email} - ${option.id}`
                      }
                      loading={isUserLoading}
                      onChange={(_, newValue) => {
                        setFieldValue("user_id", newValue?.id);
                      }}
                      sx={{
                        width: "39ch",
                        "& .MuiAutocomplete-tag": {
                          backgroundColor: "rgba(0, 0, 0, 0.08)",
                          color: "rgba(0, 0, 0, 0.87)",
                        },
                        "& .MuiAutocomplete-tag .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.26)",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="User Id"
                          placeholder="User Id"
                        />
                      )}
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox mr={3} width={"50%"}>
                    <SelectInput
                      title="Transaction type"
                      items={transactionType.filter(
                        (type) =>
                          type.name !== "Withdrawal" && type.name !== "Journey"
                      )}
                      onSelected={(searchValue) =>
                        setFieldValue("transactionType", searchValue)
                      }
                      width={"38ch"}
                    />
                  </MDBox>
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {"Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </StyledEngineProvider>
  );
};
export default NewTransactionModal;

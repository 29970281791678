export const validateForm = (values) => {
  const errors = {} as any;

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password !== values.password_confirmation) {
    errors.password_confirmation = "Password is not match";
  }
  if (!values.fname) {
    errors.fname = "Required";
  }
  if (!values.lname) {
    errors.fname = "Required";
  }
  if (!values.phone) {
    errors.phone = "Required";
  }

  return errors;
};

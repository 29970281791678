import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrEditMonthlySubmissionsThunk,
  getMonthlySubmissions,
  getMonthlySubmissionsThunk,
} from "redux/slices/monthySubmissionsSlice";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import LoadingComponent from "components/LoadingComponent";
import MonthlySubmissionTable from "./table";
import AddEditMonthlySubmissionModal from "./AddMonthlySubmissionsModal";
import {
  MonthlySubmission,
  NewMonthlySubmission,
} from "types/MonthlySubmission";
import { CSVLink } from "react-csv";
import { getAllMonthlySubmissionsCsv } from "services/api/monthlysubmissionApi";

const header = [
  { id: "id", name: "Submission id" },
  { id: "driver_campaign_id", name: "Driver Campaign Id" },
  { id: "username", name: "Username" },
  { id: "driver_id", name: "Driver Id" },
  { id: "status", name: "Status" },
  { id: "submission_month", name: "Submission Month" },
  { id: "car_front_url", name: "Car Front" },
  { id: "car_left_url", name: "Car left" },
  { id: "car_right_url", name: "Car Right" },
  { id: "car_back_url", name: "Car back" },
  { id: "odometer_url", name: "Odo -Meter" },
  { id: "odometer_installation_url", name: "Pre - Odo Meter" },
  { id: "created_at", name: "Date" },
  { id: "action", name: "Action" },
];
const MonthlySubmissionsPage: React.FC = (props) => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const monthylySubmissions = useSelector(getMonthlySubmissions);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<MonthlySubmission>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [csvData, setCsvData] = useState<any>([]);
  const csvButtonLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  useEffect(() => {
    if (!!accessToken && !!monthylySubmissions && !monthylySubmissions.data) {
      getAllMonthlySubmissions(1);
    }
  }, []);

  const getAllMonthlySubmissions = (page: number) => {
    if (!!accessToken)
      dispatch(
        getMonthlySubmissionsThunk({
          accessToken,
          page,
        })
      );
  };
  const createOrEditMonthlySubmission = (
    monthlySubmission: NewMonthlySubmission
  ) => {
    if (!!accessToken) {
      setModalOpen(false);
      dispatch(
        createOrEditMonthlySubmissionsThunk({
          accessToken,
          monthlySubmission,
          isEdit,
          currentPage,
        })
      );
    }
  };
  const downloadMonthlySubmissionIntoCsv = async (): Promise<void> => {
    const allCsv = await getAllMonthlySubmissionsCsv(accessToken);
    const formattedCsv = allCsv.map((data) => {
      return {
        id: data.id,
        driver_id: data.driver_id,
        driver_campaign_id: data.driver_campaign_id,
        email: data.user ? data.user.email : "",
        userId: data.user ? data.user.id : "",
        submission_month: data.submission_month,
        car_front_url: data.car_front_url,
        car_left_url: data.car_left_url,
        car_right_url: data.car_right_url,
        car_back_url: data.car_back_url,
        odometer_url: data.odometer_url,
        created_at: data.created_at,
        odometer_installation_url: data.odometer_installation_url,
      };
    });
    setCsvData(formattedCsv);
    setTimeout(() => {
      csvButtonLink?.current?.link.click();
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddEditMonthlySubmissionModal
        open={isModalOpen}
        isEdit={isEdit}
        selectedRow={selectedRow}
        handleClose={() => setModalOpen(false)}
        addEditMonthlySubmission={createOrEditMonthlySubmission}
      />
      {!!monthylySubmissions && !!monthylySubmissions.data ? (
        <>
          <CSVLink
            headers={[
              "id",
              "driver_id",
              "driver_campaign_id",
              "email",
              "userId",
              "submission_month",
              "car_front_url",
              "car_left_url",
              "car_right_url",
              "car_back_url",
              "odometer_url",
              "created_at",
              "odometer_installation_url",
            ]}
            filename="Monthly_Submission.csv"
            data={csvData}
            ref={csvButtonLink}
          />

          <MonthlySubmissionTable
            header={header}
            rows={monthylySubmissions}
            downloadIntoCsv={downloadMonthlySubmissionIntoCsv}
            onCreateOrEdit={(
              isEdit: boolean,
              selectedRow?: MonthlySubmission
            ) => {
              setSelectedRow(selectedRow);
              setIsEdit(isEdit);
              setModalOpen(true);
            }}
            onPageChanged={(newPage: number) => {
              getAllMonthlySubmissions(newPage + 1);
              setCurrentPage(newPage + 1);
            }}
            onReload={() => getAllMonthlySubmissions(1)}
          />
        </>
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(MonthlySubmissionsPage);

import { AxiosResponse } from "axios";
import { getRequest, postRequest, putRequest } from "services/client/request";
import { AxiosGenericResponse, PaginationCommonObject } from "types/common";
import {
  MonthlySubmission,
  NewMonthlySubmission,
} from "types/MonthlySubmission";

const apiVersion = "/api";

export const getAllMonthlySubmissions = async (
  accessToken: string,
  page: number
): Promise<PaginationCommonObject<MonthlySubmission>> => {
  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<MonthlySubmission>>
  > = await getRequest(
    `${apiVersion}/monthly_submissions?page=${page}`,
    accessToken
  );
  const monthlySubmissions: PaginationCommonObject<MonthlySubmission> = response
    .data.success
    ? response.data.data
    : ({} as PaginationCommonObject<MonthlySubmission>);
  return monthlySubmissions;
};

export const approveMonthlySubmissions = async (
  accessToken: string,
  monthlySubmissionId: number
): Promise<void> => {
  await postRequest(
    `${apiVersion}/driver_campaigns/monthly_check/${monthlySubmissionId}/approve`,
    accessToken
  );
};

export const createMonthlySubmissions = async (
  accessToken: string,
  request: NewMonthlySubmission
): Promise<void> => {
  await postRequest(`${apiVersion}/monthly_submissions`, accessToken, request);
};

export const updateMonthlySubmissions = async (
  accessToken: string,
  request: NewMonthlySubmission
): Promise<void> => {
  await putRequest(
    `${apiVersion}/monthly_submissions/${request.driver_id}`,
    accessToken,
    request
  );
};
export const getAllMonthlySubmissionsCsv = async (
  accessToken: string
): Promise<MonthlySubmission[]> => {
  var monthlySubmissions: MonthlySubmission[] = [];

  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<MonthlySubmission>>
  > = await getRequest(
    `${apiVersion}/monthly_submissions?paginate=1&per_page=1000&page=1`,
    accessToken
  );
  const result = response.data.data;
  if (response.data.success && result.last_page > 1) {
    monthlySubmissions = result.data;
    for (var i = 2; i < result.last_page; i++) {
      const responsePerpage: AxiosResponse<
        AxiosGenericResponse<PaginationCommonObject<MonthlySubmission>>
      > = await getRequest(
        `${apiVersion}/monthly_submissions?paginate=1&per_page=1000&page=${i}`,
        accessToken
      );
      monthlySubmissions = monthlySubmissions.concat(
        responsePerpage.data.data.data
      );
    }
  } else {
    monthlySubmissions = result.data;
  }

  return monthlySubmissions ?? [];
};

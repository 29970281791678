import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "components/LoadingComponent";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import {
  createWorkshopsThunk,
  getAllWorkshops,
  getWorkshopsThunk,
} from "redux/slices/workshopSlice";
import { NewWorkshop, Workshop } from "types/Workshop";
import WorkshopTable from "./table";
import AddEditWorkshopModal from "./Modal";

const header = [
  { id: "id", name: "id" },
  { id: "name", name: "workshop Name" },
  { id: "contact_name", name: "Contact Name" },
  { id: "contact_no", name: "Contact No." },
  { id: "location_name", name: "Location" },
  { id: "opened_at", name: "Open At" },
  { id: "closed_at", name: "Closed At" },
  { id: "action", name: "Action" },
];
const WorkshopsPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const workshops = useSelector(getAllWorkshops);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedWorkshop, setSelectedWorkshop] = useState<Workshop>();

  const getAllWorkshopList = useCallback(
    (page: number, username?: string) => {
      if (!!accessToken)
        dispatch(
          getWorkshopsThunk({
            accessToken,
            page: page,
            name: username,
          })
        );
    },
    [accessToken, dispatch]
  );

  useEffect(() => {
    if (!!accessToken && !!workshops && !workshops.data) {
      getAllWorkshopList(1);
    }
  }, [accessToken, getAllWorkshopList, workshops]);

  const createOrEditWorkshop = (workshop: NewWorkshop) => {
    if (!!accessToken)
      dispatch(
        createWorkshopsThunk({
          accessToken,
          workshop,
          isEdit,
        })
      );
    setModalOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddEditWorkshopModal
        open={isModalOpen}
        isEdit={isEdit}
        selectedWorkshop={selectedWorkshop}
        handleClose={() => setModalOpen(false)}
        addEditWorkshop={createOrEditWorkshop}
      />
      {!!workshops && workshops.data ? (
        <WorkshopTable
          header={header}
          rows={workshops}
          onReload={() => getAllWorkshopList(1)}
          addNewOrEditWorkshop={(
            isEdit: boolean,
            selectedWorkshop?: Workshop
          ) => {
            setSelectedWorkshop(selectedWorkshop);
            setIsEdit(isEdit);
            setModalOpen(!isModalOpen);
          }}
          onPageChanged={(newPage: number) => getAllWorkshopList(newPage)}
          handleWorkshopSearch={(name: string) => getAllWorkshopList(1, name)}
        />
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};

export default WithAuthWrapper(WorkshopsPage);

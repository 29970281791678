import axios, { AxiosResponse } from "axios";
import { getRequest, postRequest } from "services/client/request";
import { AxiosGenericResponse } from "types/common";
import {
  CampaignDrivers,
  DriverMultiplier,
  Report,
  ReportCampaign,
  ReportCampaignMonth,
  ReportDriverInfo,
  ReportGenerate
} from "types/Report";

const apiVersion = "/api";

export const getAllReportApi = async (
  accessToken: string
): Promise<Report[]> => {
  const response: AxiosResponse<AxiosGenericResponse<Report[]>> =
    await getRequest(`${apiVersion}/reports`, accessToken);
  const report: Report[] = response.data.success
    ? response.data.data
    : ([] as Report[]);
  return report;
};
export const getReportByIdApi = async (
  accessToken: string,
  id: number
): Promise<Report> => {
  const response: AxiosResponse<AxiosGenericResponse<Report>> =
    await getRequest(`${apiVersion}/reports/id/${id}`, accessToken);
  const report: Report = response.data.success
    ? response.data.data
    : ({} as Report);

  return report;
};

export const getCampaignMonthsApi = async (
  accessToken: string,
  campaign_id: number
): Promise<ReportCampaignMonth[]> => {
  const response: AxiosResponse<AxiosGenericResponse<ReportCampaignMonth[]>> =
    await getRequest(
      `${apiVersion}/reports/campaign-months?campaign_id=${campaign_id}`,
      accessToken
    );
  const months: ReportCampaignMonth[] = response.data.success
    ? response.data.data
    : ([] as ReportCampaignMonth[]);
  return months;
};

export const getCampaignDriversApi = async (
  accessToken: string,
  campaign_id: number,
  month: string
): Promise<CampaignDrivers[]> => {
  const response: AxiosResponse<AxiosGenericResponse<CampaignDrivers[]>> =
    await getRequest(
      `${apiVersion}/reports/campaign-drivers?campaign_id=${campaign_id}&months=${month}`,
      accessToken
    );
  const drivers: CampaignDrivers[] = response.data.success
    ? response.data.data
    : ([] as CampaignDrivers[]);
  return drivers;
};

export const recalculateDriverMultiplierApi = async (
  accessToken: string,
  request: DriverMultiplier
): Promise<AxiosResponse<AxiosGenericResponse<{ multiplier }>>> => {
  return await postRequest(
    `${apiVersion}/reports/recalculate`,
    accessToken,
    request
  );
};

export const generateReportApi = async (
  accessToken: string,
  request: ReportGenerate
): Promise<void> => {
  try {
    const response = await postRequest(
      `${apiVersion}/reports/generate`,
      accessToken,
      request
    );
    alert(response.data.message);
  } catch (e) {
    alert(e);
  }
};
export const getAllCampaignsWithoutPagination = async (
  accessToken: string
): Promise<ReportCampaign[]> => {
  const response: AxiosResponse<AxiosGenericResponse<ReportCampaign[]>> =
    await getRequest(`${apiVersion}/reports/campaigns`, accessToken);
  const campaigns: ReportCampaign[] = response.data.success
    ? response.data.data
    : ([] as ReportCampaign[]);
  return campaigns;
};

export const getCampaignDriverInfosApi = async (
  accessToken: string,
  campaign_id: number
): Promise<ReportDriverInfo[]> => {
  const response: AxiosResponse<ReportDriverInfo[]> = await getRequest(
    `${apiVersion}/reports/campaign-drivers-info?campaign_id=${campaign_id}`,
    accessToken
  );
  return response.data;
};

export const uploadGoogleSheetEndpoint = async (data: any) => {
  return await axios.post(
    `https://i13dhmxymc.execute-api.ap-southeast-1.amazonaws.com/dev/upload-json`,
    data
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AllUsers, AllUsersRequest } from "types/AllUsers";
import { NewUser } from "types/Authentication";
import { AllUsersState, StoreState } from "types/state/StoreState";
import {
  createNewUser,
  getAllUsersAndDrivers,
  getAllUsersAndDriversWithoutPagination,
  updateExistingUser,
} from "services/api/usersApi";
import { User } from "types/User";
import { PaginationCommonObject, PaginationRequest } from "types/common";

const allUsersSlice = createSlice({
  name: "allUsers",
  initialState: {
    isLoading: false,
    allUsers: {} as AllUsers,
    allUsersWithoutPagination: [],
    admins: {} as PaginationCommonObject<User>,
  } as AllUsersState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setAllUsers(state, action) {
      state.allUsers = action.payload;
    },
    setAllUsersWithouthPagination(state, action) {
      state.allUsersWithoutPagination = action.payload;
    },
    setAdmins(state, action) {
      state.admins = action.payload;
    },
  },
});

// Thunk Actions
export const getAllUsersThunk = createAsyncThunk(
  "allUsers/getAllUsersThunk",
  async (allUserRequest: PaginationRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const request: AllUsersRequest = {
      // 3 = users, 4 = drivers
      role_id: "3,4",
      ...allUserRequest,
    };
    dispatch(allUsersSlice.actions.setIsLoading(true));
    const allUsers = await getAllUsersAndDrivers(request);
    dispatch(allUsersSlice.actions.setAllUsers(allUsers));
    dispatch(allUsersSlice.actions.setIsLoading(false));
  }
);

export const getAdminThunk = createAsyncThunk(
  "allUsers/getAdminThunk",
  async (allUserRequest: PaginationRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const request: AllUsersRequest = {
      // 3 = users, 4 = drivers
      role_id: "1,2",
      ...allUserRequest,
    };
    dispatch(allUsersSlice.actions.setIsLoading(true));
    const allUsers = await getAllUsersAndDrivers(request);
    dispatch(allUsersSlice.actions.setAdmins(allUsers));
    dispatch(allUsersSlice.actions.setIsLoading(false));
  }
);

export const getAllUsersWithoutPaginationThunk = createAsyncThunk(
  "allUsers/getAllUsersThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(allUsersSlice.actions.setIsLoading(true));
    dispatch(allUsersSlice.actions.setAllUsersWithouthPagination([]));
    const allUsers = await getAllUsersAndDriversWithoutPagination(
      "3,4",
      accessToken
    );
    dispatch(allUsersSlice.actions.setAllUsersWithouthPagination(allUsers));
    dispatch(allUsersSlice.actions.setIsLoading(false));
  }
);

type NewOrEditUserRequest = {
  accessToken: string;
  isEdit: boolean;
  user: NewUser;
};
export const createOrUpdateUser = createAsyncThunk(
  "allUsers/createOrUpdateUser",
  async (userRequest: NewOrEditUserRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, isEdit, user } = userRequest;

    const response = isEdit
      ? await updateExistingUser(user, accessToken)
      : await createNewUser(user, accessToken);

    if (response) {
      user.role_id === 1
        ? dispatch(
            getAdminThunk({
              accessToken,
              page: 1,
              name: "",
            })
          )
        : dispatch(
            getAllUsersThunk({
              accessToken,
              page: 1,
              name: "",
            })
          );
    }
  }
);
// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.allUsers.isLoading;

export const getAllUsers = (state: StoreState): AllUsers =>
  state.allUsers.allUsers;

export const getUsersWithoutPagination = (state: StoreState): User[] =>
  state.allUsers.allUsersWithoutPagination;

export const getAdmins = (state: StoreState): PaginationCommonObject<User> =>
  state.allUsers.admins;

export default allUsersSlice.reducer;

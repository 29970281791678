import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BrandState, StoreState } from "types/state/StoreState";
import { PaginationCommonObject, PaginationRequest } from "types/common";
import {
  createBrands,
  getAllBrands,
  updateBrands,
} from "services/api/brandsApi";
import { Brands, NewBrand } from "types/Brand";

const brandSlice = createSlice({
  name: "brands",
  initialState: {
    isLoading: false,
    brands: {},
    allBrands: [] as Brands[],
  } as BrandState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setBrands(state, action) {
      state.brands = action.payload;
    },
    setAllBrands(state, action) {
      state.allBrands = action.payload;
    },
  },
});

// Thunk Actions
export const getBrandsThunk = createAsyncThunk(
  "brands/getBrandsThunk",
  async (request: PaginationRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, page, name } = request;
    dispatch(brandSlice.actions.setIsLoading(true));
    const brands = await getAllBrands(accessToken, page, name);
    dispatch(brandSlice.actions.setBrands(brands));
    dispatch(brandSlice.actions.setIsLoading(false));
  }
);

type NewBrandRequest = {
  accessToken: string;
  brands: NewBrand;
  isEdit: boolean;
};
export const createOrEditBrandThunk = createAsyncThunk(
  "brands/createOrEditBrandThunk",
  async (request: NewBrandRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { brands, accessToken, isEdit } = request;
    dispatch(brandSlice.actions.setIsLoading(true));
    isEdit
      ? await updateBrands(brands, accessToken)
      : await createBrands(brands, accessToken);

    dispatch(
      getBrandsThunk({
        accessToken,
        page: 1,
      })
    );
    dispatch(brandSlice.actions.setIsLoading(false));
  }
);
// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.brands.isLoading;
export const getBrands = (state: StoreState): PaginationCommonObject<Brands> =>
  state.brands.brands || [];

export const getAllBrandsReducer = (state: StoreState): Brands[] =>
  state.brands.allBrands;

export const { setBrands, setIsLoading, setAllBrands } = brandSlice.actions;
export default brandSlice.reducer;

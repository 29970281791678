import { useState, useEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import Breadcrumbs from "features/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "features/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";
import { SxProps, Theme } from "@mui/material/styles";
import MDTypography from "components/MDTypography";
import Menu from "@mui/material/Menu";
import MenuItems from "features/MenuItems";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, logout } from "redux/slices/authenticationSlice";
import SelectInput from "components/MDInput/SelectInput";

const DashboardNavbar = ({ absolute, light, isMini }) => {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector(getUserDetails);
  const [navbarType, setNavbarType] = useState<
    "fixed" | "absolute" | "static" | "sticky" | "relative" | undefined
  >("static");
  const [openMenu, setOpenMenu] = useState<Element | null>(null);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const route = useLocation().pathname.split("/").slice(1);
  const selectedCountry = sessionStorage.getItem(`carching_preset_country`);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    const handleTransparentNavbar = () => {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    };

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const logoutAccount = () => {
    reduxDispatch(logout());
    navigate("/sign-in");
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={() => setOpenMenu(null)}
      sx={{ mt: 2 }}
    >
      <MenuItems
        onClick={() => navigate("/profile")}
        icon={<Icon>person</Icon>}
        title="View Profile"
      />
      <MenuItems
        onClick={logoutAccount}
        icon={<Icon>login</Icon>}
        title="Log out"
      />
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini }, "space-between")}
        >
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
          <MDBox>
            <SelectInput
              title="Select Country"
              items={[
                { id: "MY", name: "Malaysia" },
                { id: "PH", name: "Philippines" },
              ]}
              onSelected={(searchValue) => {
                sessionStorage.setItem("carching_preset_country", searchValue);
                window.location.reload();
              }}
              defaultValue={selectedCountry ?? "MY"}
              width={isMini ? "7ch" : "15ch"}
            />
          </MDBox>
        </MDBox>

        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini }, "end")}>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle as SxProps<Theme>} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {!!userDetails && (
                <>
                  {/* <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarIconButton as any}
                    //variant="contained"
                    onClick={onClickedNotificationIcon}
                  >
                    <Badge
                      badgeContent={
                        notifications && hasUnreadNotifications
                          ? notifications.length
                          : 0
                      }
                      color="primary"
                    >
                      <Icon sx={iconsStyle as SxProps<Theme>}>
                        notifications
                      </Icon>
                    </Badge>
                  </IconButton>
                  {!!notifications && renderNotificationItem()} */}

                  <IconButton
                    sx={navbarIconButton as any}
                    size="small"
                    disableRipple
                    onClick={(event) => setOpenMenu(event.currentTarget)}
                  >
                    <Icon sx={iconsStyle as SxProps<Theme>}>
                      account_circle
                    </Icon>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      sx={{ ml: 1 }}
                    >
                      {`Hi ${userDetails.fname} ${userDetails.lname}`}
                    </MDTypography>
                  </IconButton>

                  {renderMenu()}
                </>
              )}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
};

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

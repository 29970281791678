import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrEditCampaignThunk,
  getCampaignDetails,
  getLoadingStatus,
} from "redux/slices/campaignSlice";
import { Campaign } from "types/Campaign";
import CampaignDesign from "../design";
import CampaignRewardSettings from "../reward";
import CampaignSettings from "../settings";
import { Formik, Form as FormikForm } from "formik";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";

type Props = {
  isEdit: boolean;
};
const CampaignDefaultSetting: React.FC<Props> = (props) => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const { isEdit } = props;
  const dispatch = useDispatch();
  const formikRef = useRef<any>(null);
  const navigate = useNavigate();
  const campaign = useSelector(getCampaignDetails);
  const isLoading = useSelector(getLoadingStatus);
  const newCampaign = {
    max_participants: 30,
    install_duration: 5,
    max_waitlist: 6,
    crowdsource_period: 7,
    waitlist_percentage: 20,
    price_base_percentage: 15,
    price_relevant_percentage: 20,
    max_bonus_percentage: 45,
    bronze_bonus_percentage: 3,
    silver_percentage: 20,
    silver_bonus_percentage: 8,
    gold_percentage: 25,
    gold_bonus_percentage: 12,
    platinum_percentage: 50,
    platinum_bonus_percentage: 15,
  } as Campaign;

  const calculateWaitlist = (percentage, participants) => {
    var max = (participants / 100) * percentage;
    return max < 1 ? 1 : Math.floor(max);
  };

  const validateAgreements = (
    values
  ): { termsAndConditions: string[] | undefined; hasError: boolean } => {
    if (!values || values.length === 0) {
      return { termsAndConditions: undefined, hasError: false };
    }
    let agreements: string[];

    if (typeof values === "string") {
      agreements = values.replaceAll("\n", "").split("*");
    } else {
      agreements = values.map((x) => x.content);
    }

    const updatedAgreement = agreements.filter((x) => x !== "");
    if (updatedAgreement.length > 0) {
      if (updatedAgreement.length > 7) {
        alert(
          `Maximum of 6 agreements per campaign are allowed. Please remove the extra T&C`
        );
        return { termsAndConditions: undefined, hasError: true };
      }
      const exceedWordCount = updatedAgreement.findIndex(
        (agreement) => agreement.length > 200
      );
      if (exceedWordCount != -1) {
        alert(
          `Your agreement (${
            exceedWordCount + 1
          }) has more than 200 words count`
        );
        return { termsAndConditions: undefined, hasError: true };
      } else {
        return { termsAndConditions: updatedAgreement, hasError: false };
      }
    }
    return { termsAndConditions: undefined, hasError: false };
  };
  const onSubmit = async (values: Campaign) => {
    const { termsAndConditions, hasError } = validateAgreements(
      values.agreements ?? ""
    );

    if (!hasError) {
      const request: Campaign = {
        ...values,
        agreements: termsAndConditions ?? [],
        max_waitlist: calculateWaitlist(
          values.waitlist_percentage,
          values.max_participants
        ),
        logo: values.logo_url,
        banner: values.banner_url,
        color_code: values.color_code ? values.color_code : "ffffff",
        font_color_code: values.font_color_code
          ? values.font_color_code
          : "000000",
      };

      try {
        const response: any = await dispatch(
          createOrEditCampaignThunk({
            accessToken,
            campaigns: request,
            isEdit,
          })
        );
        if (response.payload) {
          navigate("/campaigns");
        }
      } catch (error) {
        console.log("error here", error);
      }
    }
  };
  return (
    <>
      <MDButton
        variant="gradient"
        color="secondary"
        onClick={() => navigate("/campaigns")}
        style={{ marginBottom: 15 }}
      >
        Back to campaign
      </MDButton>
      {!!campaign && !isLoading && (
        <Formik
          initialValues={isEdit ? campaign : newCampaign}
          onSubmit={onSubmit}
          innerRef={formikRef}
        >
          {({ values, handleBlur, handleChange, setFieldValue }) => (
            <FormikForm>
              <CampaignSettings
                isEdit={isEdit}
                campaign={values}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              <CampaignRewardSettings
                isEdit={isEdit}
                campaign={values}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              <CampaignDesign
                isEdit={isEdit}
                campaign={values}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              <MDBox
                mt={5}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <MDButton
                  variant="gradient"
                  color="secondary"
                  onClick={() => {
                    //Trigger formik button as outside the form
                    if (formikRef.current) {
                      formikRef.current.handleSubmit();
                    }
                  }}
                  style={{ marginRight: 8 }}
                >
                  Submit
                </MDButton>
              </MDBox>
            </FormikForm>
          )}
        </Formik>
      )}
    </>
  );
};

export default CampaignDefaultSetting;

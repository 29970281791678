import LoadingComponent from "components/LoadingComponent";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import { getAllBooking, getAllBookingsThunk } from "redux/slices/carefreeSlice";
import HomeOwnerTable from "./table";

const header = [
  { id: "id", name: "Booking Id" },
  { id: "homeOwnerId", name: "Home Owner Id" },
  { id: "start_date", name: "Booking Start Date" },
  { id: "end_date", name: "Booking End Date" },
  { id: "duration", name: "Duration" },
  { id: "status", name: "Status" },
  { id: "user", name: "Booking User" },
  { id: "car", name: "User Car" },
  { id: "action", name: "Cancel Booking Action" },
];
const CarefreeBookingPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const bookings = useSelector(getAllBooking);

  useEffect(() => {
    if (!!accessToken && !!bookings && !bookings.length) {
      getAllBookingsList();
    }
  }, [accessToken]);

  const getAllBookingsList = () => {
    if (!!accessToken) dispatch(getAllBookingsThunk(accessToken));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {!!bookings && bookings.length > 0 ? (
        <HomeOwnerTable
          header={header}
          rows={bookings}
          onReload={() => getAllBookingsList()}
        />
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(CarefreeBookingPage);

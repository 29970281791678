import React, { useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import AvatarUpload from "components/AvatarUpload";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import { Brands, NewBrand } from "types/Brand";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsersWithoutPaginationThunk,
  getUsersWithoutPagination,
  getLoadingStatus,
} from "redux/slices/allUsersSlice";

const initialValues = {
  brand_name: "",
  contact_name: "",
  contact_no: "",
  contact_email: "",
  company_registration_no: "",
  company_address: "",
  relationship_manager_id: 0,
  logo_url: "",
};
type AddEditBrandModalProp = {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  selectedBrand?: Brands;
  addEditBrand: (brand: NewBrand) => void;
};
const AddEditBrandModal: React.FC<AddEditBrandModalProp> = (props) => {
  const { open, handleClose, isEdit, selectedBrand, addEditBrand } = props;
  const formikRef = useRef<any>(null);
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const allUsers = useSelector(getUsersWithoutPagination);
  const isLoading = useSelector(getLoadingStatus);

  useEffect(() => {
    if (!!accessToken) dispatch(getAllUsersWithoutPaginationThunk(accessToken));
  }, [accessToken, dispatch]);

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = (values) => {
    const request: NewBrand = {
      ...values,
      logo: values.logo_url,
    };
    if (isEdit) request.id = selectedBrand?.id;
    addEditBrand(request);
  };

  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`${isEdit ? "Edit" : "New"} Brand`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Formik
            initialValues={
              isEdit && !!selectedBrand ? selectedBrand : initialValues
            }
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <AvatarUpload
                  selectedImage={values.logo_url}
                  handleImageUpload={(image) =>
                    setFieldValue("logo_url", image)
                  }
                />
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="text"
                      label="Brand Name"
                      name="brand_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.brand_name}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="text"
                      label="Contact Person"
                      name="contact_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contact_name}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="email"
                      label="Contact Email"
                      name="contact_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contact_email}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="number"
                      label="Contact Phone Number (+60)"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      name="contact_no"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contact_no}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="text"
                      label="Company Address"
                      name="company_address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company_address}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="text"
                      label="Company Registration No"
                      name="company_registration_no"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company_registration_no}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"}>
                    <Autocomplete
                      options={allUsers}
                      getOptionLabel={(option) => option.email}
                      loading={isLoading}
                      onChange={(_, newValue) => {
                        setFieldValue("relationship_manager_id", newValue?.id);
                      }}
                      sx={{
                        width: "39ch",
                        "& .MuiAutocomplete-tag": {
                          backgroundColor: "rgba(0, 0, 0, 0.08)",
                          color: "rgba(0, 0, 0, 0.87)",
                        },
                        "& .MuiAutocomplete-tag .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.26)",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Relationship Manager Id"
                          placeholder="Relationship Manager Id"
                        />
                      )}
                    />
                  </MDBox>
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {isEdit ? "Edit Brand" : "Add Brand"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddEditBrandModal;

import React, { useCallback, useEffect } from "react";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "@mui/material";
import LoadingComponent from "components/LoadingComponent";
import TransactionTable from "./table";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import { TransactionStatusIdEnum } from "types/enum/transactions";
import {
  getWithdrawals,
  getWithdrawalsThunk,
} from "redux/slices/withdrawalSlice";
import { WithdrawalFilter } from "types/Withdrawal";

const header = [
  { id: "id", name: "Transaction Id" },
  { id: "created_at", name: "Date" },
  { id: "account_no", name: "Account  No." },
  { id: "bankname", name: "Bank Name" },
  { id: "account_holder", name: "Account Holder" },
  { id: "user_id", name: "User Id" },
  { id: "amount", name: "Amount" },
  { id: "approved_amount", name: "Approved Amount" },
  { id: "status", name: "Status" },
  { id: "transaction_type", name: "Transaction Type" },
  { id: "action", name: "Action" },
];

const WithdrawalPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const withdrawal = useSelector(getWithdrawals);
  const [activeTab, setActiveTab] = React.useState(0);

  const getAllWithDrawal = useCallback(
    (page: number, filter?: WithdrawalFilter) => {
      if (!!accessToken) {
        dispatch(
          getWithdrawalsThunk({
            accessToken,
            filter: {
              page: page,
              status_id: !!filter ? filter.status_id : 0,
              id: !!filter ? filter.id : "",
              start_date: !!filter ? filter.start_date : "",
              end_date: !!filter ? filter.end_date : "",
            },
          })
        );
      }
    },
    [dispatch, accessToken]
  );

  useEffect(() => {
    if (!!accessToken && !!withdrawal && !withdrawal.data) {
      getAllWithDrawal(1);
    }
  }, [accessToken, withdrawal, getAllWithDrawal]);

  const handleChange = (_, newValue: number) => {
    setActiveTab(newValue);
    newValue === 0
      ? getAllWithDrawal(1)
      : getAllWithDrawal(1, { status_id: newValue } as WithdrawalFilter);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Tabs
        value={activeTab}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="All" />
        <Tab label="Processing" value={TransactionStatusIdEnum.Processing} />
        <Tab label="Approved" value={TransactionStatusIdEnum.Approve} />
        <Tab label="Rejected" value={TransactionStatusIdEnum.Reject} />
        <Tab label="Transferred" value={TransactionStatusIdEnum.Transferred} />
      </Tabs>
      {!!withdrawal && withdrawal.data ? (
        <TransactionTable
          header={header}
          rows={withdrawal}
          onPageChanged={(newPage: number) => getAllWithDrawal(newPage + 1)}
          downloadIntoCsv={() => {}}
          handleTransactionIdSearch={(searchInput: string) =>
            getAllWithDrawal(1, { id: searchInput } as WithdrawalFilter)
          }
          handleDateRangeSearch={(from: string, to: string) =>
            getAllWithDrawal(1, {
              start_date: from,
              end_date: to,
            } as WithdrawalFilter)
          }
          onReload={() => getAllWithDrawal(1)}
        />
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(WithdrawalPage);

import { AxiosResponse } from "axios";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "services/client/request";
import {
  AllDrivers,
  AllUsers,
  AllUsersRequest,
  Drivers,
  DriversRequest,
} from "types/AllUsers";
import { User } from "types/User";
import { NewUser } from "types/Authentication";
import { AxiosGenericResponse, Status } from "types/common";

const apiVersion = "/api";

export const getAllUsersAndDrivers = async (
  request: AllUsersRequest
): Promise<AllUsers> => {
  const { role_id, name, page, accessToken } = request;
  const response: AxiosResponse<AxiosGenericResponse<AllUsers>> =
    await getRequest(
      `${apiVersion}/users?role_id=${role_id}&name=${name}&page=${page}`,
      accessToken
    );

  const allUsers: AllUsers = response.data.success
    ? response.data.data
    : ({} as AllUsers);
  return allUsers;
};

export const getUserById = async (
  accessToken: string,
  userId: number
): Promise<NewUser> => {
  const response: AxiosResponse<AxiosGenericResponse<NewUser>> =
    await getRequest(`${apiVersion}/users/${userId}`, accessToken);

  const allUsers: NewUser = response.data.success
    ? response.data.data
    : ({} as NewUser);
  return allUsers;
};

export const getAllUsersAndDriversWithoutPagination = async (
  role_id: string,
  accessToken: string
): Promise<User[]> => {
  const response: AxiosResponse = await getRequest(
    `${apiVersion}/users?role_id=${role_id}&all=${true}`,
    accessToken
  );

  const allUsers: User[] = response.data.success ? response.data.data : [];
  return allUsers;
};

export const createNewUser = async (
  request: NewUser,
  accessToken: string
): Promise<boolean> => {
  const response: AxiosResponse = await postRequest(
    `${apiVersion}/users`,
    accessToken,
    request
  );
  return response.data.success;
};

export const updateExistingUser = async (
  request: NewUser,
  accessToken: string
): Promise<boolean> => {
  const response: AxiosResponse = await putRequest(
    `${apiVersion}/users/${request.id}`,
    accessToken,
    request
  );
  return response.data.success;
};

export const removeUsers = async (
  request: NewUser,
  accessToken: string
): Promise<boolean> => {
  const response: AxiosResponse = await deleteRequest(
    `${apiVersion}/users`,
    accessToken,
    request
  );
  return response.data.success;
};

export const getAllDrivers = async (
  request: DriversRequest
): Promise<AllDrivers> => {
  const { accessToken, page, filter } = request;

  const response: AxiosResponse<AxiosGenericResponse<AllDrivers>> =
    await getRequest(`${apiVersion}/drivers?page=${page}`, accessToken, filter);

  return response.data.success ? response.data.data : ({} as AllDrivers);
};

export const getFilteredDrivers = async (
  header: string,
  value: string,
  accessToken: string,
  page?: number
): Promise<AllDrivers> => {
  const response: AxiosResponse<AxiosGenericResponse<AllDrivers>> =
    await getRequest(
      `${apiVersion}/drivers?page=${page ?? 1}&${header}=${value}`,
      accessToken
    );

  return response.data.success ? response.data.data : ({} as AllDrivers);
};

export const getAllDriversWithoutPagination = async (
  accessToken: string
): Promise<Drivers[]> => {
  const response: AxiosResponse = await getRequest(
    `${apiVersion}/drivers?all=true`,
    accessToken
  );

  return response.data.success ? response.data.data : [];
};

export const getDriverAllStatus = async (
  accessToken: string
): Promise<Status[]> => {
  const response: AxiosResponse = await getRequest(
    `${apiVersion}/statuses?model=Driver`,
    accessToken
  );
  return response.data.success ? response.data.data : [];
};

export const getDriverDetails = async (
  accessToken: string,
  driverId: number
): Promise<Drivers> => {
  const response: AxiosResponse = await getRequest(
    `${apiVersion}/drivers/${driverId}`,
    accessToken
  );
  return response.data.success ? response.data.data : [];
};

export const updateExistingDriver = async (
  request: Drivers,
  accessToken: string
): Promise<boolean> => {
  const response: AxiosResponse = await putRequest(
    `${apiVersion}/drivers/${request.id}`,
    accessToken,
    request
  );
  return response.data.success;
};

export const createNewDriver = async (
  request: any,
  accessToken: string
): Promise<Drivers> => {
  try {
    const response: AxiosResponse = await postRequest(
      `${apiVersion}/drivers`,
      accessToken,
      request
    );
    return response.data.data;
  } catch (error: any) {
    const message = error.response
      ? error.response.data.message
      : "Create Failed";

    alert(message);
  }
  return {} as Drivers;
};

export const approveDriver = async (
  id: number,
  accessToken: string
): Promise<void> => {
  await postRequest(`${apiVersion}/drivers/${id}/approve`, accessToken);
};

export const rejectDriver = async (
  id: number,
  accessToken: string
): Promise<void> => {
  await postRequest(`${apiVersion}/drivers/${id}/reject`, accessToken);
};

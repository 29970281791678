import SelectInput from "components/MDInput/SelectInput";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getBanks } from "redux/slices/driverSlice";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { BankDetails, DriverUserDetails } from "types/AllUsers";

type Props = {
  userBanks: DriverUserDetails;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
};
const AddBanks: React.FC<Props> = (props) => {
  const { userBanks, setFieldValue } = props;
  const [formValues, setFormValues] = useState<BankDetails[]>([]);
  const banks = useSelector(getBanks);

  useEffect(() => {
    if (!formValues || formValues.length === 0) {
      addFormFields();
    }
  }, []);

  useEffect(() => {
    if (
      userBanks &&
      userBanks.bank_accounts &&
      userBanks.bank_accounts.length
    ) {
      const { bank_accounts } = userBanks;
      const existingBanks: BankDetails[] = [];
      bank_accounts.forEach((bankAccount) => {
        existingBanks.push({
          bank: bankAccount.bank,
          bank_id: bankAccount.bank_id ? bankAccount.bank_id.toString() : "",
          account_no: bankAccount.account_no,
        });
      });
      setFormValues(existingBanks);
    }
  }, [userBanks]);

  const handleOnChange = (index: number, e, field: string) => {
    let newFormValues = [...formValues];
    if (field === "bank") {
      const selectedBank = banks.find((bank) => bank.id === e);
      if (selectedBank) {
        newFormValues[index]["bank"] = selectedBank;
        newFormValues[index]["bank_id"] = e;
      }
    } else {
      newFormValues[index]["account_no"] = e.target.value;
    }

    setFormValues(newFormValues);

    setFieldValue("bank_accounts", newFormValues);
  };

  const addFormFields = () => {
    const initialiseValue: BankDetails = {
      bank: {
        code: "",
        id: 0,
        logo_url: "",
        name: "",
        created_at: "",
      },
      bank_id: "",
      account_no: "",
    };
    setFormValues([...formValues, initialiseValue]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };
  return (
    <>
      {formValues.map((element, index) => (
        <MDBox mb={2} ml={1} display={"flex"} key={index}>
          <MDBox width={"50%"} mt={1} mr={2}>
            <MDInput
              type="text"
              label="Bank account no 1"
              name="account_no"
              value={element.account_no}
              onChange={(e) => handleOnChange(index, e, "account_no")}
              fullWidth
            />
          </MDBox>
          <MDBox width={"40%"} mr={2}>
            <SelectInput
              title="Bank type 1"
              items={banks}
              onSelected={(searchValue) =>
                handleOnChange(index, searchValue, "bank")
              }
              defaultValue={element.bank.id}
              width={"100%"}
              margin={0}
            />
          </MDBox>
          <MDBox width={"10%"} display="inline-flex">
            {index ? (
              <IconButton onClick={() => removeFormFields(index)}>
                <DeleteIcon />
              </IconButton>
            ) : (
              <IconButton onClick={addFormFields}>
                <AddCircleIcon color={"primary"} />
              </IconButton>
            )}
          </MDBox>
        </MDBox>
      ))}
    </>
  );
};

export default AddBanks;

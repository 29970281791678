import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useState, useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { InputLabel, OutlinedInput } from "@mui/material";

type InputDebounceProps = {
  onChange: (value: string) => void;
  name: string;
  type: string;
  placeholder: string;
  width?: string;
};

const InputDebounce: React.FC<InputDebounceProps> = (props) => {
  const { onChange, name, type, placeholder, width } = props;

  const [inputTimeout, setInputTimeout] = useState<any>();
  const [value, setValue] = useState<string>("");

  useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);

  const inputOnChange = (value) => {
    if (inputTimeout) clearTimeout(inputTimeout);
    setInputTimeout(
      setTimeout(() => {
        onChange(value);
      }, 1000)
    );
  };

  return (
    <>
      <FormControl
        sx={{ m: 1, width: width ? width : "25ch" }}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-searchinput">
          {placeholder}
        </InputLabel>
        <OutlinedInput
          type={type}
          name={name}
          label={placeholder}
          placeholder={placeholder}
          onChange={(e) => {
            inputOnChange(e.target.value);
            setValue(e.target.value);
          }}
          value={value}
          endAdornment={
            <InputAdornment position="end">
              {value !== "" ? (
                <IconButton
                  edge="end"
                  onClick={() => {
                    onChange("");
                    setValue("");
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ) : (
                <></>
              )}
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  );
};

export default InputDebounce;

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Tab, Tabs } from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import Footer from "features/Footer";
import ComplexStatisticsCard from "features/Cards/StatisticsCards";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboard,
  getDashboardSummaryThunk,
  getLoadingStatus
} from "redux/slices/dashboardSlice";
import LoadingComponent from "components/LoadingComponent";
import { Chart } from "react-google-charts";
import { DashboardSummary, KeyValueMap } from "types/Dashboard";

const Dashboard: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const selectedCountry = sessionStorage.getItem(`carching_preset_country`);
  const dispatch = useDispatch();
  const dashboardSummary = useSelector(getDashboard);
  const isLoading = useSelector(getLoadingStatus);

  const [activeTab, setActiveTab] = useState<number>(0);
  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
  };
  useEffect(() => {
    if (accessToken && !Object.keys(dashboardSummary).length) {
      dispatch(getDashboardSummaryThunk(accessToken));
    }
  }, []);

  const convertDriversPieChart = (summary: DashboardSummary) => {
    const {
      total_pending_approval_drivers,
      total_missing_docs_drivers,
      total_step_1_drives,
      total_step_2_drivers
    } = summary;
    return [
      ["Driver Pending", "Number"],
      ["Pending Approval", total_pending_approval_drivers],
      ["Missing Doc", total_missing_docs_drivers],
      ["Step 1", total_step_1_drives],
      ["Step 2", total_step_2_drivers]
    ];
  };
  const convertRejectDrivers = (summary: DashboardSummary) => {
    const { total_rejected_drivers, total_blacklisted_drivers } = summary;
    return [
      ["Driver Pending", "Number"],
      ["Rejected", total_rejected_drivers],
      ["Blacklisted", total_blacklisted_drivers]
    ];
  };
  const calculatePercentage = (value: number, total: number): number => {
    return Math.round((value / total) * 100);
  };

  const convertCarDetailsChart = (carDetails: KeyValueMap, title: string) => {
    const data: any = [[title, "Number"]];
    let others = 0;
    if (carDetails && !!Object.keys(carDetails).length) {
      const sortByDecendingArray = Object.entries(carDetails).sort(
        ([, a], [, b]) => b - a
      );
      sortByDecendingArray.forEach((details, index) => {
        if (index >= 5) {
          others += details[1];
        } else {
          data.push(details);
        }
      });
      data.push(["Others", others]);
    }

    return data;
  };
  const calculatePercentageOfCurrentMonthUser = (
    currentMonth: number,
    pastmonth: number
  ) => {
    const increaseOrDecrease = currentMonth - pastmonth;
    const percentage = calculatePercentage(increaseOrDecrease, pastmonth);
    return {
      amount: Math.abs(percentage),
      label: `of ${
        increaseOrDecrease < 0 ? "decrease" : "increase"
      } over past month(${pastmonth})`
    };
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading && !!Object.keys(dashboardSummary).length ? (
        <LoadingComponent />
      ) : (
        <MDBox py={3}>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Overall" value={0} />
            <Tab label="Current Campaign" value={1} />
            <Tab label="Upcoming Campaign" value={2} />
            <Tab label="Transactions" value={3} />
            <Tab label="Demographic" value={4} />
          </Tabs>
          {activeTab === 0 && (
            <MDBox mt={5}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Users"
                      link={"/users"}
                      count={dashboardSummary.total_users ?? "loading..."}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Drivers"
                      count={dashboardSummary.total_drivers ?? "loading..."}
                      link={"/drivers"}
                      state={{ driverStatus: 0 }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Online Users Current Month"
                      link={"/users"}
                      count={
                        dashboardSummary.total_users_current_month ??
                        "loading..."
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Users Past Month"
                      link={"/users"}
                      count={
                        dashboardSummary.total_users_past_month ?? "loading..."
                      }
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Current Month Users"
                      link={"/users"}
                      count={
                        dashboardSummary.total_users_current_month ??
                        "loading..."
                      }
                      percentage={calculatePercentageOfCurrentMonthUser(
                        dashboardSummary.total_users_current_month,
                        dashboardSummary.total_users_past_month
                      )}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Active Drivers"
                      count={
                        dashboardSummary.total_active_driver_drivers ??
                        "loading..."
                      }
                      percentage={{
                        amount: calculatePercentage(
                          dashboardSummary.total_active_driver_drivers,
                          dashboardSummary.total_active_driver_drivers +
                            dashboardSummary.total_in_campaign_drivers
                        ),
                        label: `over ${
                          dashboardSummary.total_active_driver_drivers +
                          dashboardSummary.total_in_campaign_drivers
                        } active & in campaign drivers`
                      }}
                      link={"/drivers"}
                      state={{ driverStatus: 0 }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Drivers in Campaigns"
                      count={
                        dashboardSummary.total_in_campaign_drivers ??
                        "loading..."
                      }
                      percentage={{
                        amount: calculatePercentage(
                          dashboardSummary.total_in_campaign_drivers,
                          dashboardSummary.total_drivers
                        ),
                        label: `over total ${dashboardSummary.total_drivers} drivers`
                      }}
                      link={"/drivers"}
                      state={{ driverStatus: 2 }}
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={1.5}>
                    <Chart
                      chartType="PieChart"
                      data={convertDriversPieChart(dashboardSummary)}
                      options={{
                        title: `Pending Drivers (${
                          dashboardSummary.total_pending_approval_drivers +
                          dashboardSummary.total_missing_docs_drivers +
                          dashboardSummary.total_step_1_drives +
                          dashboardSummary.total_step_2_drivers
                        })`
                      }}
                      width={"100%"}
                      height={"400px"}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={1.5}>
                    <Chart
                      chartType="PieChart"
                      data={convertRejectDrivers(dashboardSummary)}
                      options={{
                        title: `Rejected Drivers (${
                          dashboardSummary.total_rejected_drivers +
                          dashboardSummary.total_blacklisted_drivers
                        })`
                      }}
                      width={"100%"}
                      height={"400px"}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3} mt={2}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Distance"
                      count={`${new Intl.NumberFormat().format(
                        dashboardSummary.total_distance ?? 0
                      )} km`}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3} mt={2}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Monthly Distance"
                      count={`${new Intl.NumberFormat().format(
                        dashboardSummary.total_monthly_distance ?? 0
                      )} km`}
                      percentage={calculatePercentageOfCurrentMonthUser(
                        dashboardSummary.total_monthly_distance,
                        dashboardSummary.total_past_month_distance
                      )}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          )}

          {activeTab === 1 && (
            <MDBox mt={5}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Campaigns"
                      count={dashboardSummary.total_campaigns ?? 0}
                      link={"/campaigns"}
                      state={{ campaignType: 0 }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Running Campaign"
                      count={dashboardSummary.total_running_campaigns ?? 0}
                      link={"/campaigns"}
                      state={{ campaignType: 22 }}
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Crowdsourcing Campaigns"
                      count={
                        dashboardSummary.total_crowdsourcing_campaigns ?? 0
                      }
                      link={"/campaigns"}
                      state={{ campaignType: 20 }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Pending Installation Campaigns"
                      count={
                        dashboardSummary.total_pending_installation_campaigns ??
                        0
                      }
                      link={"/campaigns"}
                      state={{ campaignType: 21 }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          )}
          {activeTab === 2 && <MDBox mt={5}></MDBox>}
          {activeTab === 3 && (
            <MDBox mt={5}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Completed Widthdrawals"
                      count={`${selectedCountry === "PH" ? "PHP" : "RM"} ${
                        dashboardSummary.total_completed_withdrawals_amount ?? 0
                      } (${
                        dashboardSummary.total_completed_withdrawals_count
                      })`}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total Withdrawals"
                      count={`${selectedCountry === "PH" ? "PHP" : "RM"} ${
                        dashboardSummary.total_withdrawals_amount ?? 0
                      } (${dashboardSummary.total_withdrawals_count})`}
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={1.5}>
                    <Chart
                      chartType="PieChart"
                      data={[
                        ["Appointment", "Number"],
                        ["Booked", dashboardSummary.total_appointments_booked],
                        [
                          "In Progress",
                          dashboardSummary.total_appointments_in_progress
                        ],
                        [
                          "Completed",
                          dashboardSummary.total_appointments_completed
                        ]
                      ]}
                      options={{ title: "Workshop Apppointment Status" }}
                      width={"100%"}
                      height={"400px"}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={1.5}>
                    <Chart
                      chartType="PieChart"
                      data={[
                        ["Appointment", "Number"],
                        [
                          "Booked",
                          dashboardSummary.total_removal_appointments_booked
                        ],
                        [
                          "In Progress",
                          dashboardSummary.total_removal_appointments_completed
                        ],
                        [
                          "Completed",
                          dashboardSummary.total_removal_appointments_in_progress
                        ]
                      ]}
                      options={{ title: "Removal Apppointment Status" }}
                      width={"100%"}
                      height={"400px"}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          )}
          {activeTab === 4 && (
            <MDBox mt={5}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <Chart
                      chartType="PieChart"
                      data={convertCarDetailsChart(
                        dashboardSummary.car_brands_with_drivers_count,
                        "Car Brands"
                      )}
                      options={{
                        title: "Car Brands"
                      }}
                      width={"100%"}
                      height={"400px"}
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <Chart
                      chartType="PieChart"
                      data={convertCarDetailsChart(
                        dashboardSummary.car_models_with_drivers_count,
                        "Car Models"
                      )}
                      options={{
                        title: "Car Models"
                      }}
                      width={"100%"}
                      height={"400px"}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <Chart
                      chartType="PieChart"
                      data={convertCarDetailsChart(
                        dashboardSummary.car_colors_with_drivers_count,
                        "Car Colors"
                      )}
                      options={{
                        title: "Car Colours"
                      }}
                      width={"100%"}
                      height={"400px"}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          )}
        </MDBox>
      )}

      <Footer />
    </DashboardLayout>
  );
};

export default WithAuthWrapper(Dashboard);

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "components/MDTable/tableToolbar";
import TableHeader from "components/MDTable/tableHeader";
import { ITableHeader } from "types/Table";
import TablePaginationActions from "components/MDTable/tablePagination";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSelector } from "react-redux";
import { getLoadingStatus } from "redux/slices/withdrawalSlice";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmationDialog from "components/ConfirmationDialog";
import MoreActionsMenu from "components/MoreActionsMenu";
import { TransactionActionsEnum } from "types/enum/transactions";
import {
  approveTransaction,
  markAsTransferred,
  rejectTransaction,
} from "services/api/transactionsApi";
import Icon from "@mui/material/Icon";
import { Withdrawal } from "types/Withdrawal";
import { PaginationCommonObject } from "types/common";
import InputDebounce from "components/MDInput/inputDebounce";
import DatePickerRange from "components/MDDatePicker";

type TableType = {
  header: ITableHeader[];
  rows: PaginationCommonObject<Withdrawal>;
  onPageChanged: (newPage: number) => void;
  downloadIntoCsv: () => void;
  handleTransactionIdSearch: (searchInput: string) => void;
  handleDateRangeSearch: (from: string, to: string) => void;
  onReload: () => void;
};
const moreActions = [
  {
    name: TransactionActionsEnum.Approve,
    icon: <Icon fontSize="small">check_circle</Icon>,
  },
  {
    name: TransactionActionsEnum.Reject,
    icon: <Icon fontSize="small">unpublished</Icon>,
  },
];
const WithdrawalTable: React.FC<TableType> = (props) => {
  const accessToken = localStorage.getItem("carching-token");
  const {
    header,
    rows,
    onPageChanged,
    onReload,
    handleTransactionIdSearch,
    handleDateRangeSearch,
  } = props;

  const { data } = rows;
  const [page, setPage] = useState<number>(0);
  const isLoading = useSelector(getLoadingStatus);
  const [isDialogOpenWithAction, openDialogConfirmation] = useState<
    string | undefined
  >();
  const [selectedRow, setSelectedRow] = useState<Withdrawal | undefined>();

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChanged(newPage);
    setPage(newPage);
  };
  const handleActionButtons = async (action?: string): Promise<void> => {
    if (accessToken && !!selectedRow) {
      if (action === TransactionActionsEnum.Approve) {
        await approveTransaction(accessToken, selectedRow.id);
      } else if (action === TransactionActionsEnum.Reject) {
        await rejectTransaction(accessToken, selectedRow.id);
      } else if (action === TransactionActionsEnum.Transferred) {
        await markAsTransferred(
          accessToken,
          selectedRow.id,
          selectedRow.approved_amount
        );
      }
    }
    openDialogConfirmation(undefined);
    setSelectedRow(undefined);
    if (!!action) onReload();
  };
  const handleDateRangeUpdate = (from: string, to: string) => {
    handleDateRangeSearch(from, to);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {!!isDialogOpenWithAction && (
        <ConfirmationDialog
          title={`Are you sure you want to ${isDialogOpenWithAction.toLowerCase()} this transaction?`}
          open={!!isDialogOpenWithAction}
          onClose={(action?: string) => handleActionButtons(action)}
          action={isDialogOpenWithAction}
          submitButtonTitle={
            !!isDialogOpenWithAction ? isDialogOpenWithAction : "Submit"
          }
        />
      )}

      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          numSelected={0}
          tableName={"Withdrawal"}
          onReload={onReload}
        />
        <MDBox
          display={"inline-flex"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <MDBox>
            <DatePickerRange onChangeDatesRange={handleDateRangeUpdate} />
          </MDBox>
          <MDBox>
            <InputDebounce
              type="text"
              name="id"
              placeholder="Transaction Id"
              onChange={(searchValue) => handleTransactionIdSearch(searchValue)}
              width={"40ch"}
            />
          </MDBox>
        </MDBox>

        {isLoading ? (
          <MDBox display={"flex"} justifyContent={"center"} mt={3}>
            <CircularProgress color={"primary"} />
          </MDBox>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeader
                numSelected={0}
                order={"desc"}
                orderBy={""}
                onSelectAllClick={() => {}}
                onRequestSort={() => {}}
                rowCount={data.length}
                headCells={header}
                disableCheckbox
              />
              <TableBody>
                {data.map((row) => {
                  return (
                    <TableRow tabIndex={-1} key={row.id}>
                      <TableCell align="center">{row.id}</TableCell>
                      <TableCell align="right">{`${new Date(
                        row.created_at
                      ).toLocaleDateString()}`}</TableCell>
                      <TableCell align="right">
                        {row.driver_bank_account
                          ? row.driver_bank_account.account_no
                          : ""}
                      </TableCell>
                      <TableCell align="right">
                        {row.driver_bank_account
                          ? row.driver_bank_account.bank.name
                          : ""}
                      </TableCell>
                      <TableCell align="right">
                        {row.driver_bank_account
                          ? row.driver_bank_account.account_holder
                          : ""}
                      </TableCell>
                      <TableCell align="right">{row.user_id}</TableCell>
                      <TableCell align="right">{row.amount}</TableCell>
                      <TableCell align="right">{row.approved_amount}</TableCell>
                      <TableCell align="right">{row.status.name}</TableCell>
                      <TableCell align="right">
                        {row.transaction_type}
                      </TableCell>
                      <TableCell align="right">
                        {row.status.name === "Processing" && (
                          <MoreActionsMenu
                            menuItems={moreActions}
                            onActionSelected={(action: string) => {
                              setSelectedRow(row);
                              openDialogConfirmation(action);
                            }}
                          />
                        )}
                        {row.status.name === "Approved" && (
                          <>
                            <MDButton
                              variant="gradient"
                              color="secondary"
                              onClick={() => {
                                setSelectedRow(row);
                                openDialogConfirmation(
                                  TransactionActionsEnum.Transferred
                                );
                              }}
                            >
                              Mark as transferred
                            </MDButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <MDBox display={"inline-flex"} width={"100%"} justifyContent={"end"}>
          <InputDebounce
            type="number"
            name="search page"
            placeholder="search specify page"
            onChange={(value: string) => {
              const page = Number(value);
              handleChangePage(null, page === 0 ? 1 : page - 1);
            }}
            width={"10%"}
          />
          <TablePagination
            rowsPerPageOptions={[15]}
            component="div"
            count={rows.total}
            rowsPerPage={rows.per_page}
            page={rows.total <= 0 ? 0 : page}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        </MDBox>
      </Paper>
    </Box>
  );
};
export default WithdrawalTable;

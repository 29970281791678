import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrEditVariablesThunk,
  getLoadingStatus,
  getVariables,
  getVariablesThunk,
} from "redux/slices/settingSlice";
import { Formik, Form as FormikForm, FieldArray, Field } from "formik";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import LoadingComponent from "components/LoadingComponent";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import MDTypography from "components/MDTypography";

const VariablesPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const variables = useSelector(getVariables);
  const isLoading = useSelector(getLoadingStatus);

  useEffect(() => {
    if (!!accessToken) {
      dispatch(getVariablesThunk(accessToken));
    }
  }, []);

  const onSubmit = (values) => {
    const settingVariables = values["settings"];
    const objectSetting = settingVariables.reduce(
      (previousObject, currentObject) => {
        return Object.assign(previousObject, {
          [currentObject.name]: currentObject.value,
        });
      },
      {}
    );
    if (!!accessToken) {
      dispatch(
        createOrEditVariablesThunk({
          accessToken,
          variables: { settings: objectSetting },
        })
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!!variables && variables.length && !isLoading ? (
        <MDBox mt={5}>
          <Formik
            initialValues={{
              settings: variables,
            }}
            onSubmit={onSubmit}
          >
            <FormikForm>
              <FieldArray
                name="settings"
                render={({ form: { values }, name }) => (
                  <>
                    {!!values[name].length &&
                      values[name].map((variable, index) => (
                        <MDBox key={variable.name} mb={3}>
                          <MDTypography
                            variant="button"
                            color="dark"
                            fontWeight="medium"
                          >
                            {variable.name}
                          </MDTypography>

                          <Field
                            name={`${name}[${index}].value`}
                            render={RenderInputChild}
                            style={{ width: "100%" }}
                          />
                        </MDBox>
                      ))}
                  </>
                )}
              />

              <MDButton
                variant="gradient"
                color="primary"
                fullWidth
                type="submit"
              >
                Update
              </MDButton>
            </FormikForm>
          </Formik>
        </MDBox>
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};
const RenderInputChild = ({ field, form, ...props }) => {
  const onChange = async (value) => {
    await field.onChange(value);
  };
  return (
    <MDInput
      name={field.name}
      onChange={onChange}
      value={field.value}
      {...props}
      fullWidth
    />
  );
};
export default WithAuthWrapper(VariablesPage);

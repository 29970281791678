import React, { useRef } from "react";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { NewUser } from "types/Authentication";
import { DriverUserDetails } from "types/AllUsers";
import Button from "@mui/material/Button";

type Props = {
  isEdit: boolean;
  selectedDriver: DriverUserDetails;
  addEditUser: (updatedUser: NewUser) => void;
};

const DriverAccount: React.FC<Props> = (props) => {
  const { isEdit, selectedDriver, addEditUser } = props;
  const formikRef = useRef<any>(null);

  const onSubmit = (values) => {
    if (
      isEdit &&
      (values.password === "" || values.password_confirmation === "")
    ) {
      delete values.password_confirmation;
      delete values.password;
    } else {
      const user: NewUser = {
        role_id: 3,
        country_code: "+60",
        ...values,
      };
      addEditUser(user);
    }
  };

  return (
    <Formik
      initialValues={selectedDriver}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ values, errors, handleBlur, handleChange, setFieldValue }) => (
        <FormikForm>
          <MDBox mb={2} display={"flex"}>
            <MDBox width={"50%"} mr={5}>
              <MDInput
                type="text"
                label="First Name"
                name="fname"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.fname}
                fullWidth
                error={!!errors.fname}
                helperText={errors.fname}
              />
            </MDBox>
            <MDBox width={"50%"}>
              <MDInput
                type="text"
                label="Last Name"
                name="lname"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lname}
                fullWidth
                error={!!errors.lname}
                helperText={errors.lname}
              />
            </MDBox>
          </MDBox>
          <MDBox mb={2} display={"flex"}>
            <MDBox width={"50%"} mr={5}>
              <MDInput
                type="email"
                label="Email"
                name="email"
                disabled={isEdit}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                helperText={errors.email}
                fullWidth
                error={!!errors.email}
              />
            </MDBox>
            <MDBox width={"50%"}>
              <MDInput
                type="number"
                label="Phone Number (+60)"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                name="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                helperText={errors.phone}
                fullWidth
                error={!!errors.phone}
              />
            </MDBox>
          </MDBox>
          <MDBox mb={2} display={"flex"}>
            <MDBox width={"50%"} mr={5}>
              <MDInput
                type="password"
                label="Password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={!!errors.password}
                helperText={errors.password}
                fullWidth
              />
            </MDBox>
            <MDBox width={"50%"}>
              <MDInput
                type="password"
                label="Confirm Password"
                name="password_confirmation"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password_confirmation}
                error={!!errors.password_confirmation}
                helperText={errors.password_confirmation}
                fullWidth
              />
            </MDBox>
          </MDBox>
          <Button
            style={{ float: "right" }}
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {isEdit ? "Edit user" : "Add user"}
          </Button>
        </FormikForm>
      )}
    </Formik>
  );
};
export default DriverAccount;

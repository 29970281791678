import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import AccordionDefault from "features/Accordion";
import React from "react";
import { Campaign } from "types/Campaign";

type Props = {
  isEdit: boolean;
  campaign: Campaign;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
};
const CampaignRewardSettings: React.FC<Props> = (props) => {
  const { campaign, handleChange, handleBlur } = props;

  return (
    <AccordionDefault
      title={"Campaign Reward Settings"}
      panel=""
      onChange={() => {}}
    >
      <MDBox>
        <MDBox display={"flex"} width={"100%"}>
          <MDBox
            width={"25%"}
            mr={1}
            display={"flex"}
            sx={{ alignItems: "center" }}
          >
            <MDInput
              type="number"
              label="Price Base Percentage"
              name="price_base_percentage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={campaign.price_base_percentage}
              fullWidth
            />
            %
          </MDBox>
          <MDBox
            width={"25%"}
            mr={1}
            display={"flex"}
            sx={{ alignItems: "center" }}
          >
            <MDInput
              type="number"
              label="Price Relevant"
              name="price_relevant_percentage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={campaign.price_relevant_percentage}
              fullWidth
            />
            %
          </MDBox>
          <MDBox
            width={"25%"}
            mr={1}
            display={"flex"}
            sx={{ alignItems: "center" }}
          >
            <MDInput
              type="number"
              label="Max Bonus Percentage"
              name="max_bonus_percentage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={campaign.max_bonus_percentage}
              fullWidth
            />
            %
          </MDBox>
          <MDBox width={"25%"} display={"flex"} sx={{ alignItems: "center" }}>
            <MDInput
              type="number"
              label="Bronze Bonus Percentage"
              name="bronze_bonus_percentage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={campaign.bronze_bonus_percentage}
              fullWidth
            />
            %
          </MDBox>
        </MDBox>
        <MDBox display={"flex"} width={"100%"} mt={5}>
          <MDBox
            width={"25%"}
            mr={1}
            display={"flex"}
            sx={{ alignItems: "center" }}
          >
            <MDInput
              type="number"
              label="Silver Percentage"
              name="silver_percentage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={campaign.silver_percentage}
              fullWidth
            />
            %
          </MDBox>
          <MDBox
            width={"25%"}
            mr={1}
            display={"flex"}
            sx={{ alignItems: "center" }}
          >
            <MDInput
              type="number"
              label="Silver Bonus Percentage"
              name="silver_bonus_percentage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={campaign.silver_bonus_percentage}
              fullWidth
            />
            %
          </MDBox>
          <MDBox
            width={"25%"}
            mr={1}
            display={"flex"}
            sx={{ alignItems: "center" }}
          >
            <MDInput
              type="number"
              label="Gold Percentage"
              name="gold_percentage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={campaign.gold_percentage}
              fullWidth
            />
            %
          </MDBox>
          <MDBox width={"25%"} display={"flex"} sx={{ alignItems: "center" }}>
            <MDInput
              type="number"
              label="Gold Bonus Percentage"
              name="gold_bonus_percentage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={campaign.gold_bonus_percentage}
              fullWidth
            />
            %
          </MDBox>
        </MDBox>
        <MDBox display={"flex"} width={"100%"} mt={5}>
          <MDBox
            width={"25%"}
            mr={1}
            display={"flex"}
            sx={{ alignItems: "center" }}
          >
            <MDInput
              type="number"
              label="Platinum Percentage"
              name="platinum_percentage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={campaign.platinum_percentage}
              fullWidth
            />
            %
          </MDBox>
          <MDBox
            width={"25%"}
            mr={1}
            display={"flex"}
            sx={{ alignItems: "center" }}
          >
            <MDInput
              type="number"
              label="Platinum Bonus Percentage"
              name="platinum_bonus_percentage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={campaign.platinum_bonus_percentage}
              fullWidth
            />
            %
          </MDBox>
        </MDBox>
      </MDBox>
    </AccordionDefault>
  );
};
export default CampaignRewardSettings;

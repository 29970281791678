import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import AvatarUpload from "components/AvatarUpload";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import { NewPartner, Partner } from "types/Partner";
import SelectInput from "components/MDInput/SelectInput";
import { BooleaEnum, StatusEnum } from "types/enum/Common";

const initialValues = {
  title: "",
  desc: "",
  picture_url: "",
  link: "",
  is_webview: "",
  status_id: "",
};
type Prop = {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  selectedPartner?: Partner;
  addEditPartner: (partner: NewPartner) => void;
};
const AddEditPartnerModal: React.FC<Prop> = (props) => {
  const { open, handleClose, isEdit, selectedPartner, addEditPartner } = props;
  const formikRef = useRef<any>(null);

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = (values) => {
    const request: NewPartner = {
      ...values,
      picture: values.picture_url,
    };
    if (isEdit && selectedPartner?.id) request.id = selectedPartner?.id;
    addEditPartner(request);
  };

  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`${isEdit ? "Edit" : "New"} Partner`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Formik
            initialValues={
              isEdit && !!selectedPartner ? selectedPartner : initialValues
            }
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <AvatarUpload
                  selectedImage={values.picture_url}
                  handleImageUpload={(image) =>
                    setFieldValue("picture_url", image)
                  }
                />
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="text"
                      label="Title"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="text"
                      label="Description"
                      name="desc"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.desc}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={4}>
                    <SelectInput
                      title="Status"
                      items={[
                        { id: "32", name: StatusEnum.ENABLED },
                        { id: "33", name: StatusEnum.DISABLED },
                      ]}
                      onSelected={(searchValue) =>
                        setFieldValue("status_id", searchValue)
                      }
                      defaultValue={values.status_id.toString()}
                      width={"39ch"}
                      margin={0.1}
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <SelectInput
                      title="Is Webview?"
                      items={[
                        { id: 0, name: BooleaEnum.YES },
                        { id: 1, name: BooleaEnum.No },
                      ]}
                      onSelected={(searchValue) =>
                        setFieldValue("is_webview", searchValue)
                      }
                      defaultValue={values.is_webview.toString()}
                      width={"39ch"}
                      margin={0.1}
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"48%"}>
                    <MDInput
                      type="text"
                      label="Link"
                      name="link"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.link}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {isEdit ? "Edit Partner" : "Add Partner"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddEditPartnerModal;

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DriverLocation } from "types/AllUsers";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import SelectInput from "components/MDInput/SelectInput";
import { driverLocations } from "./location.constant";

type Props = {
  setFieldValue: (field: string, value: any) => void;
  values: DriverLocation[];
};

const DriverLocations: React.FC<Props> = (props) => {
  const { values, setFieldValue } = props;
  const [formValues, setFormValues] = useState<DriverLocation[]>(values);

  useEffect(() => {
    if (!formValues || formValues.length === 0) {
      addFormFields();
    }
  }, []);
  const addFormFields = () => {
    const initialiseValue: DriverLocation = {
      driver_id: 0,
      id: 0,
      full_address: "",
      latitude: "",
      longitude: "",
      type: "",
      created_at: new Date().toISOString(),
    };
    setFormValues([...formValues, initialiseValue]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handleOnChange = (index: number, e, field: string) => {
    const newFormValues = [...formValues];
    const updatedLocation = {
      ...newFormValues[index],
      [field]: typeof e === "string" ? e : e.target.value,
    };
    newFormValues[index] = updatedLocation;
    setFormValues(newFormValues);
    setFieldValue("driver_locations", newFormValues);
  };

  return (
    <MDBox>
      <>
        {formValues.map((element, index) => (
          <MDBox ml={1} display={"flex"} key={index}>
            <MDBox width={"25%"} mr={1}>
              <SelectInput
                title="Location Type"
                items={driverLocations}
                onSelected={(searchValue) => {
                  handleOnChange(index, searchValue, "type");
                }}
                defaultValue={element.type ? element.type : ""}
                width={"15ch"}
              />
            </MDBox>
            <MDBox width={"40%"} mt={1} mr={1}>
              <MDInput
                type="text"
                label="Address"
                name="full_address"
                value={element.full_address}
                onChange={(e) => handleOnChange(index, e, "full_address")}
                fullWidth
              />
            </MDBox>
            <MDBox width={"20%"} mt={1} mr={1}>
              <MDInput
                type="text"
                label="Latitude"
                name="latitude"
                value={element.latitude}
                onChange={(e) => handleOnChange(index, e, "latitude")}
                fullWidth
              />
            </MDBox>
            <MDBox width={"20%"} mt={1} mr={1}>
              <MDInput
                type="text"
                label="Longitude"
                name="longitude"
                value={element.longitude}
                onChange={(e) => handleOnChange(index, e, "longitude")}
                fullWidth
              />
            </MDBox>
            <MDBox width={"10%"} mt={1} mr={1} display="inline-flex">
              {index ? (
                <IconButton onClick={() => removeFormFields(index)}>
                  <DeleteIcon />
                </IconButton>
              ) : (
                <IconButton onClick={addFormFields}>
                  <AddCircleIcon color={"primary"} />
                </IconButton>
              )}
            </MDBox>
          </MDBox>
        ))}
      </>
    </MDBox>
  );
};

export default DriverLocations;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "components/MDTable/tableToolbar";
import TableHeader from "components/MDTable/tableHeader";
import { Order } from "components/MDTable/helper";
import { ITableHeader } from "types/Table";
import TablePaginationActions from "components/MDTable/tablePagination";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton, Link } from "@mui/material";
import { PaginationCommonObject } from "types/common";
import {
  createOrEditCampaignAssetsThunk,
  getLoadingStatus,
} from "redux/slices/campaignSlice";
import { CampaignAssets } from "types/Campaign";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AddEditCampaignAssets from "./AddEditModal";

type TableType = {
  header: ITableHeader[];
  rows: PaginationCommonObject<CampaignAssets>;
  onPageChanged: (newPage: number) => void;
  onReload: () => void;
  campaignId: number;
};
const CampaignAssetsTable: React.FC<TableType> = (props) => {
  const { header, rows, onReload, onPageChanged, campaignId } = props;
  const { data } = rows;
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<CampaignAssets>();
  const isLoading = useSelector(getLoadingStatus);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onPageChanged(newPage);
    setPage(newPage);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AddEditCampaignAssets
        open={isModalOpen}
        isEdit={isEdit}
        selectedRow={selectedRow}
        handleClose={() => setModalOpen(false)}
        campaignId={campaignId}
      />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          numSelected={0}
          tableName={"Campaign"}
          onReload={onReload}
        />
        <MDBox display={"flex"} justifyContent={"end"}>
          <MDButton
            variant="gradient"
            color="secondary"
            onClick={() => {
              setIsEdit(false);
              setModalOpen(true);
            }}
          >
            Add campaign assets
          </MDButton>
        </MDBox>
        {isLoading ? (
          <MDBox display={"flex"} justifyContent={"center"} mt={3}>
            <CircularProgress color={"primary"} />
          </MDBox>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeader
                numSelected={0}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={() => {}}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                headCells={header}
                disableCheckbox={true}
              />
              <TableBody>
                {data.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell align="right">{row.id}</TableCell>
                      <TableCell align="right">
                        <Link href={row.front_url} target="_blank">
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="info"
                          >
                            Front
                          </MDTypography>
                        </Link>
                      </TableCell>
                      <TableCell align="right">
                        <Link href={row.back_url} target="_blank">
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="info"
                          >
                            Back
                          </MDTypography>
                        </Link>
                      </TableCell>
                      <TableCell align="right">
                        <Link href={row.left_url} target="_blank">
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="info"
                          >
                            Left
                          </MDTypography>
                        </Link>
                      </TableCell>
                      <TableCell align="right">
                        <Link href={row.right_url} target="_blank">
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="info"
                          >
                            Right
                          </MDTypography>
                        </Link>
                      </TableCell>
                      <TableCell align="right">{row.type}</TableCell>
                      <TableCell align="right">{row.theme}</TableCell>

                      <TableCell align="right">
                        <IconButton
                          onClick={(e) => {
                            //Prevent whole  row is being selected
                            e.stopPropagation();
                            e.preventDefault();
                            setSelectedRow(row);
                            setIsEdit(true);
                            setModalOpen(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={rows.total}
          rowsPerPage={rows.per_page}
          page={rows.total <= 0 ? 0 : page}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </Box>
  );
};
export default CampaignAssetsTable;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StoreState, TransactionState } from "types/state/StoreState";
import {
  NewTransactionRequest,
  TransactionRequest,
  TransactionType,
  Transactions,
} from "types/Transactions";
import {
  createTransaction,
  getAllLedgers,
  getAllOverviews,
  getAllTransactions,
  getTransactionType,
  getUserLedgers,
} from "services/api/transactionsApi";
import { PaginationCommonObject, PaginationRequest } from "types/common";
import { Ledger, Overview } from "types/Overview";

const transactionSlice = createSlice({
  name: "transactions",
  initialState: {
    isLoading: false,
    transations: {} as Transactions,
    overviews: {} as PaginationCommonObject<Overview>,
    userLedger: {} as Ledger,
    allLedgers: {} as Ledger,
    transactionType: [],
  } as TransactionState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setTransactions(state, action) {
      state.transations = action.payload;
    },
    setOverView(state, action) {
      state.overviews = action.payload;
    },
    setUserLedgers(state, action) {
      state.userLedger = action.payload;
    },
    setAllLedgers(state, action) {
      state.allLedgers = action.payload;
    },
    setTransactionTypes(state, action) {
      state.transactionType = action.payload;
    },
  },
});

// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.transactions.isLoading;

export const getTransactions = (state: StoreState): Transactions =>
  state.transactions.transations;

export const getOverview = (
  state: StoreState
): PaginationCommonObject<Overview> => state.transactions.overviews;

export const getUserLedgersDetails = (state: StoreState): Ledger =>
  state.transactions.userLedger;

export const getLedgers = (state: StoreState): Ledger =>
  state.transactions.allLedgers;
export const getTransactionTypeReducers = (
  state: StoreState
): TransactionType[] => state.transactions.transactionType;

export const getAllTransactionsThunk = createAsyncThunk(
  "transaction/getAllTransactionsThunk",
  async (request: TransactionRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, page, status_id } = request;
    dispatch(transactionSlice.actions.setIsLoading(true));
    const transaction = await getAllTransactions(accessToken, page, status_id);
    const response = await getTransactionType(accessToken);
    dispatch(transactionSlice.actions.setTransactionTypes(response));
    dispatch(transactionSlice.actions.setTransactions(transaction));
    dispatch(transactionSlice.actions.setIsLoading(false));
  }
);

export const createTransactionThunk = createAsyncThunk(
  "transaction/createTransactionThunk",
  async (request: NewTransactionRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, newTransaction } = request;
    dispatch(transactionSlice.actions.setIsLoading(true));
    await createTransaction(newTransaction, accessToken);
    dispatch(transactionSlice.actions.setIsLoading(false));
  }
);

export const getAllOverviewThunk = createAsyncThunk(
  "transaction/getAllOverviewThunk",
  async (request: PaginationRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, page, name } = request;
    dispatch(transactionSlice.actions.setIsLoading(true));
    const overviews = await getAllOverviews(accessToken, page, name);
    dispatch(transactionSlice.actions.setOverView(overviews));
    dispatch(transactionSlice.actions.setIsLoading(false));
  }
);

export type UserLedgerRequest = {
  accessToken: string;
  userId: number;
  date?: string;
};
export const getUserLedgerThunk = createAsyncThunk(
  "transaction/getUserLedgerThunk",
  async (request: UserLedgerRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, userId, date } = request;
    dispatch(transactionSlice.actions.setIsLoading(true));
    const currentDate = date ? date : new Date().toLocaleDateString();
    const userLedger = await getUserLedgers(accessToken, userId, currentDate);
    dispatch(transactionSlice.actions.setUserLedgers(userLedger));
    dispatch(transactionSlice.actions.setIsLoading(false));
  }
);

export const getAllLedgersThunk = createAsyncThunk(
  "transaction/getAllLedgersThunk",
  async (request: PaginationRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, name } = request;
    dispatch(transactionSlice.actions.setIsLoading(true));
    const date = name ? name : new Date().toLocaleDateString();
    const ledgers = await getAllLedgers(accessToken, date);
    dispatch(transactionSlice.actions.setAllLedgers(ledgers));
    dispatch(transactionSlice.actions.setIsLoading(false));
  }
);
export default transactionSlice.reducer;

import ImageUpload from "components/ImageUpload";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import SelectInput from "components/MDInput/SelectInput";
import React from "react";
import "../style.scss";
import { Campaign } from "types/Campaign";
import { PhoneSize } from "types/enum/Common";
import { ColorExtractor } from "react-color-extractor";
import { TextareaAutosize } from "@mui/material";
import { getFontColor } from "../helper";

type Props = {
  values: Campaign;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
};
const phoneSize = [
  { id: PhoneSize.IPHONE_6, name: "Iphone 6" },
  { id: PhoneSize.IPHONE_10, name: "Iphone 10" },
  { id: PhoneSize.IPHONE_12_PRO_MAX, name: "Iphone 12 Pro Max" },
];
const DesignForm: React.FC<Props> = (props) => {
  const { values, handleChange, handleBlur, setFieldValue } = props;

  const renderColorCode = (colorCode: string, field: string) => {
    if (!colorCode) {
      return field === "color_code" ? "#ffffff" : "#000000";
    }
    return colorCode.includes("#") ? colorCode : `#${colorCode}`;
  };
  return (
    <MDBox>
      <MDBox mt={2}>
        <SelectInput
          title="Phone Preview"
          items={phoneSize}
          onSelected={(searchValue) =>
            setFieldValue("phonePreview", searchValue)
          }
          defaultValue={PhoneSize.IPHONE_6}
          width={"44ch"}
          margin={0}
        />
      </MDBox>

      <MDBox mt={2} ml={1} width={"75%"}>
        <MDInput
          type="text"
          label="Title"
          name="title"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
          fullWidth
        />
      </MDBox>
      <MDBox mt={3} ml={1} width={"75%"}>
        <TextareaAutosize
          minRows={3}
          name="desc"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.desc}
          placeholder="Description"
          style={{ width: "100%" }}
        />
      </MDBox>
      <MDBox display="flex" mt={3} ml={1}>
        <MDBox mr={5}>
          <ImageUpload
            title="Logo"
            selectedImage={values.logo_url}
            handleImageUpload={(image) => setFieldValue("logo_url", image)}
          />
        </MDBox>
        <MDBox>
          <ImageUpload
            title="Banner"
            selectedImage={values.banner_url}
            handleImageUpload={(image) => setFieldValue("banner_url", image)}
          />
        </MDBox>
      </MDBox>
      <SelectInput
        title="Extract colors from"
        items={[
          { id: "logo", name: "Logo" },
          { id: "banner", name: "Banner" },
        ]}
        onSelected={(searchValue) => {
          setFieldValue("extractedFrom", searchValue);
        }}
        width={"44ch"}
        margin={0}
      />

      {values.extractedFrom && (
        <ColorExtractor
          src={
            values.extractedFrom === "banner"
              ? values.banner_url
              : values.logo_url
          }
          getColors={(colors) => setFieldValue("extractedColors", colors)}
        />
      )}

      <div className="color-row">
        {values.extractedColors &&
          values.extractedColors.map((color, index) => (
            <div
              key={`${color}-${index}`}
              className="color-block"
              style={{
                backgroundColor: color,
              }}
              onClick={() => {
                setFieldValue("color_code", color.substring(1).toUpperCase());
                const fontColor = getFontColor(
                  `#${color.substring(1).toUpperCase()}`
                ).slice(1);
                setFieldValue("font_color_code", fontColor);
              }}
            ></div>
          ))}
      </div>
      <MDBox mt={3} ml={1}>
        <MDInput
          type="color"
          label="Background Color"
          name="color_code"
          onChange={(e) => {
            const color = e.target.value.substring(1).toUpperCase();
            setFieldValue("color_code", color);
          }}
          value={renderColorCode(values.color_code, "color_code")}
          sx={{ width: "15ch" }}
        />
      </MDBox>
      <MDBox mt={3} ml={1}>
        <MDInput
          type="color"
          label="Font Color"
          name="font_color_code"
          onChange={(e) => {
            const color = e.target.value.substring(1).toUpperCase();
            const updatedColor = color.includes("#") ? color.slice(1) : color;
            setFieldValue("font_color_code", updatedColor);
          }}
          value={renderColorCode(values.font_color_code, "font_color_code")}
          sx={{ width: "15ch" }}
        />
      </MDBox>
    </MDBox>
  );
};
export default DesignForm;

import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

type SelectInputProps = {
  title: string;
  items: any[];
  onSelected: (selectedValue: string) => void;
  width?: string;
  margin?: number;
  defaultValue?: any;
  itemName?: string;
  needCheckBox?: boolean;
  combinationWithItemName?: string;
  checkBoxOnChange?: (isChecked: boolean) => void;
};

const SelectInput: React.FC<SelectInputProps> = (props) => {
  const {
    title,
    items,
    onSelected,
    width,
    margin,
    defaultValue,
    itemName = "name",
    needCheckBox,
    combinationWithItemName,
    checkBoxOnChange
  } = props;
  const [value, setValue] = React.useState(defaultValue || "");

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
    onSelected(event.target.value as string);
  };

  return (
    <>
      {needCheckBox && (
        <input
          style={{ float: "left" }}
          className="label-checkbox"
          type="checkbox"
          onChange={(event) => {
            if (checkBoxOnChange) {
              checkBoxOnChange(event.target.checked);
            }
          }}
        />
      )}
      <FormControl
        sx={{
          margin: !!margin ? margin : 1,
          width: width ? width : "25ch"
        }}
      >
        <InputLabel id="demo-simple-select-label">{title}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={title}
          defaultValue={defaultValue}
          onChange={handleChange}
          // Hacky way to override select component padding
          sx={{
            "& .css-103b10f-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
              {
                padding: "12px !important"
              },
            "& .css-1ngdtxc.MuiSelect-select": {
              padding: "12px !important"
            }
          }}
        >
          <MenuItem key={""} value={""}>
            {"None"}
          </MenuItem>
          {items &&
            items.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {combinationWithItemName
                  ? `${option[combinationWithItemName]} - ${option[itemName]}`
                  : option[itemName]}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};
export default SelectInput;

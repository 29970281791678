import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import TableToolbar from "components/MDTable/tableToolbar";
import TableHeader from "components/MDTable/tableHeader";
import { Order } from "components/MDTable/helper";
import { ITableHeader } from "types/Table";
import { AllDrivers, Drivers } from "types/AllUsers";
import TablePaginationActions from "components/MDTable/tablePagination";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import InputDebounce from "../../components/MDInput/inputDebounce";
import { useSelector } from "react-redux";

import EditIcon from "@mui/icons-material/Edit";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CircularProgress from "@mui/material/CircularProgress";
import { Icon, IconButton, Link } from "@mui/material";
import { getDriverStatus, getLoadingStatus } from "redux/slices/driverSlice";
import SelectInput from "components/MDInput/SelectInput";
import { TransactionActionsEnum } from "types/enum/transactions";
import { approveDriver, rejectDriver } from "services/api/usersApi";
import ConfirmationDialog from "components/ConfirmationDialog";
import MoreActionsMenu from "components/MoreActionsMenu";
import AddDriverModal from "./DriverModal/addNew";
import MDTypography from "components/MDTypography";

type TableType = {
  header: ITableHeader[];
  rows: AllDrivers;
  onPageChanged: (newPage: number) => void;
  downloadIntoCsv: () => void;
  handleUserSearch: (searchType: string, searchInput: string) => void;
  onReload: (currentPage: number) => void;
  addNewOrEditDriver: (
    isEdit: boolean,
    selectedUser?: Drivers,
    selectedTab?: number
  ) => void;
};
const moreActions = [
  {
    name: TransactionActionsEnum.Approve,
    icon: <Icon fontSize="small">check_circle</Icon>,
  },
  {
    name: TransactionActionsEnum.Reject,
    icon: <Icon fontSize="small">unpublished</Icon>,
  },
];
const DriversTable: React.FC<TableType> = (props) => {
  const {
    header,
    rows,
    onPageChanged,
    downloadIntoCsv,
    handleUserSearch,
    onReload,
    addNewOrEditDriver,
  } = props;
  const { data } = rows;
  const accessToken = localStorage.getItem("carching-token");
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [isNewDriverModalOpen, setNewDriverModalOpen] =
    useState<boolean>(false);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState<number>(0);
  const isLoading = useSelector(getLoadingStatus);
  const driverStatuses = useSelector(getDriverStatus);
  const [isDialogOpenWithAction, openDialogConfirmation] = useState<
    string | undefined
  >();
  const [selectedRow, setSelectedRow] = useState<Drivers | undefined>();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleActionButtons = async (action?: string): Promise<void> => {
    try {
      if (accessToken && !!selectedRow) {
        if (action === TransactionActionsEnum.Approve) {
          await approveDriver(selectedRow.id, accessToken);
        } else if (action === TransactionActionsEnum.Reject) {
          await rejectDriver(selectedRow.id, accessToken);
        } else if (action === "Edit") {
          addNewOrEditDriver(true, selectedRow);
        }
      }
    } catch (error) {
      alert(`Something went wrong! ${error}`);
    }
    onReload(page);

    openDialogConfirmation(undefined);
    setSelectedRow(undefined);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.user.username);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChanged(newPage);
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: "100%" }}>
      {!!isDialogOpenWithAction && (
        <ConfirmationDialog
          title={`Are you sure you want to ${isDialogOpenWithAction} this driver?`}
          open={!!isDialogOpenWithAction}
          onClose={(action?: string) => handleActionButtons(action)}
          action={isDialogOpenWithAction}
          submitButtonTitle={isDialogOpenWithAction}
        />
      )}
      {isNewDriverModalOpen && (
        <AddDriverModal
          open={isNewDriverModalOpen}
          handleClose={() => setNewDriverModalOpen(false)}
          addedNewDriver={(driver) => {
            setNewDriverModalOpen(false);

            if (driver) addNewOrEditDriver(true, driver);
          }}
        />
      )}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          numSelected={selected.length}
          tableName={
            "Drivers (*Note: Edit and checkbox button is not working yet)"
          }
          onReload={() => onReload(page)}
        />
        <MDBox
          display={"inline-flex"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <MDBox>
            <InputDebounce
              type="text"
              name="id"
              placeholder="Id"
              onChange={(searchValue) => handleUserSearch("id", searchValue)}
              width={"10ch"}
            />
            <InputDebounce
              type="text"
              name="carplate"
              placeholder="Car Plate"
              onChange={(searchValue) =>
                handleUserSearch("car_plate", searchValue)
              }
            />
            <InputDebounce
              type="text"
              name="name"
              placeholder="Name"
              onChange={(searchValue) => handleUserSearch("name", searchValue)}
            />
            {!!driverStatuses && (
              <SelectInput
                title="Filter by status"
                items={driverStatuses}
                onSelected={(searchValue) => {
                  setPage(0);
                  handleUserSearch("status_id", searchValue);
                }}
              />
            )}
          </MDBox>

          <MDBox justifyContent={"end"} margin={"auto"}>
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => setNewDriverModalOpen(true)}
              style={{ marginRight: 8 }}
            >
              Add driver
            </MDButton>
            <MDButton
              variant="gradient"
              color="primary"
              onClick={downloadIntoCsv}
            >
              Download as CSV
            </MDButton>
          </MDBox>
        </MDBox>
        {isLoading ? (
          <MDBox display={"flex"} justifyContent={"center"} mt={3}>
            <CircularProgress color={"primary"} />
          </MDBox>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeader
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                headCells={header}
              />
              <TableBody>
                {data.map((row, index) => {
                  const isItemSelected = isSelected(row.car_plate);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      //onClick={(event) => handleClick(event, row.car_plate)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">{row.id}</TableCell>

                      <TableCell align="right">
                        <Link href={`/users?id=${row.user_id}`} target="_blank">
                          {row.user_id}
                        </Link>
                      </TableCell>
                      <TableCell align="right">{row.car_plate}</TableCell>
                      <TableCell align="right">
                        {row.user ? `${row.user.fname}  ${row.user.lname}` : ""}
                      </TableCell>
                      <TableCell align="right">{`${
                        row.user ? row.user.email : ""
                      }`}</TableCell>
                      <TableCell align="right">
                        {row.e_hailing === 0 ? "No" : "Yes"}
                      </TableCell>

                      <TableCell align="right">
                        {row.driver_status ?? row.status.name}
                      </TableCell>

                      <TableCell align="right">
                        {row.car_brand ? row.car_brand.name : ""}
                      </TableCell>
                      <TableCell align="right">
                        <MDBox>
                          <MDTypography
                            variant="text"
                            color="secondary"
                            fontWeight="bold"
                            onClick={() => addNewOrEditDriver(true, row, 3)}
                          >
                            {row.referrer
                              ? `${row.referrer.fname} ${row.referrer.lname}`
                              : ""}
                          </MDTypography>
                        </MDBox>
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          href={`https://wa.me/6${
                            row.user ? row.user.phone : ""
                          }`}
                          target="_blank"
                        >
                          <IconButton>
                            <WhatsAppIcon sx={{ color: "#25d366" }} />
                          </IconButton>
                        </Link>
                        <IconButton
                          onClick={(e) => {
                            //Prevent whole  row is being selected
                            e.stopPropagation();
                            e.preventDefault();
                            addNewOrEditDriver(true, row);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        {row.status.name === "Pending Approval" && (
                          <div>
                            <MoreActionsMenu
                              menuItems={moreActions}
                              onActionSelected={(action: string) => {
                                setSelectedRow(row);
                                openDialogConfirmation(action);
                              }}
                            />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <MDBox display={"inline-flex"} width={"100%"} justifyContent={"end"}>
          <InputDebounce
            type="number"
            name="search page"
            placeholder="search specify page"
            onChange={(value: string) => {
              const page = Number(value);
              handleChangePage(null, page === 0 ? 1 : page - 1);
            }}
            width={"10%"}
          />
          <TablePagination
            rowsPerPageOptions={[15]}
            component="div"
            count={rows.total}
            rowsPerPage={rows.per_page}
            page={rows.total <= 0 ? 0 : page}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        </MDBox>
      </Paper>
    </Box>
  );
};
export default DriversTable;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "components/MDTable/tableToolbar";
import TableHeader from "components/MDTable/tableHeader";
import { Order } from "components/MDTable/helper";
import { ITableHeader } from "types/Table";
import TablePaginationActions from "components/MDTable/tablePagination";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton } from "@mui/material";
import { PaginationCommonObject } from "types/common";
import { CampaignStatusEnum } from "types/enum/Common";
import SelectInput from "components/MDInput/SelectInput";
import { getLoadingStatus } from "redux/slices/campaignSlice";
import { Campaign } from "types/Campaign";
import InputDebounce from "components/MDInput/inputDebounce";
import DatePickerRange from "components/MDDatePicker";
import { Link } from "react-router-dom";
import MDInput from "components/MDInput";

type TableType = {
  header: ITableHeader[];
  rows: PaginationCommonObject<Campaign>;
  onPageChanged: (newPage: number) => void;
  handleCampaignSearch: (field: string, searchInput: string) => void;
  handleDateRangeSearch: (field: string, from: string, to: string) => void;
  onReload: (currentPage: number) => void;
};
const CampaignTable: React.FC<TableType> = (props) => {
  const {
    header,
    rows,
    onReload,
    handleCampaignSearch,
    onPageChanged,
    handleDateRangeSearch,
  } = props;
  const { data } = rows;
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const isLoading = useSelector(getLoadingStatus);
  const navigate = useNavigate();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onPageChanged(newPage);
    setPage(newPage);
  };
  const handleDateRange = (from: string, to: string) => {
    handleDateRangeSearch("date", from, to);
  };

  const handleCrowsourceDateRange = (from: string, to: string) => {
    handleDateRangeSearch("crowdsource_date", from, to);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          numSelected={0}
          tableName={"Campaign"}
          onReload={() => onReload(page)}
        />
        <MDBox
          display={"inline-flex"}
          justifyContent={"space-between"}
          width={"100%"}
          padding={"10px"}
        >
          <InputDebounce
            type="text"
            name="campaign_title"
            placeholder="Search by Campaign Title"
            onChange={(value: string) => handleCampaignSearch("title", value)}
            width={"50%"}
          />
          <MDBox>
            <SelectInput
              title="Select Status"
              items={[
                { id: "20", name: CampaignStatusEnum.CROWDSOURCING },
                { id: "21", name: CampaignStatusEnum.STICKER_INSTALLATION },
                { id: "22", name: CampaignStatusEnum.RUNNING },
                { id: "23", name: CampaignStatusEnum.ENDED },
              ]}
              onSelected={(searchValue) =>
                handleCampaignSearch("status_id", searchValue)
              }
              width={"30ch"}
            />
          </MDBox>
          <MDBox display={"flex"} justifyContent={"end"}>
            <MDButton
              variant="gradient"
              color="secondary"
              component={Link}
              to={"/campaigns/create"}
              style={{ marginRight: 8 }}
            >
              Create Campaign
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox
          display={"inline-flex"}
          justifyContent={"space-between"}
          width={"100%"}
          padding={"10px"}
        >
          <MDBox width={"50%"}>
            <DatePickerRange
              onChangeDatesRange={handleDateRange}
              fromTitile="Start Date"
              toTitile="End Date"
              hideResetButton={true}
            />
          </MDBox>
          <MDBox width={"50%"}>
            <DatePickerRange
              onChangeDatesRange={handleCrowsourceDateRange}
              fromTitile="Crowdsource Start Date"
              toTitile="Crowdsource End Date"
              hideResetButton={true}
            />
          </MDBox>
        </MDBox>

        {isLoading ? (
          <MDBox display={"flex"} justifyContent={"center"} mt={3}>
            <CircularProgress color={"primary"} />
          </MDBox>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeader
                numSelected={0}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={() => {}}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                headCells={header}
                disableCheckbox={true}
              />
              <TableBody>
                {data.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell align="right">{row.id}</TableCell>
                      <TableCell align="right">
                        {row.brand ? row.brand.brand_name : ""}
                      </TableCell>
                      <TableCell align="right">{row.title}</TableCell>
                      <TableCell align="right">
                        {row.status ? row.status.name : ""}
                      </TableCell>
                      <TableCell align="right">{`${new Date(
                        row.start_date
                      ).toLocaleDateString()}`}</TableCell>
                      <TableCell align="right">{`${new Date(
                        row.end_date
                      ).toLocaleDateString()}`}</TableCell>

                      <TableCell align="right">
                        <IconButton
                          onClick={(e) => {
                            //Prevent whole  row is being selected
                            e.stopPropagation();
                            e.preventDefault();
                            navigate(`/campaigns/${row.id}`);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <MDBox display={"inline-flex"} width={"100%"} justifyContent={"end"}>
          <InputDebounce
            type="number"
            name="search page"
            placeholder="search specify page"
            onChange={(value: string) => {
              const page = Number(value);
              handleChangePage(null, page === 0 ? 1 : page - 1);
            }}
            width={"10%"}
          />
          <TablePagination
            rowsPerPageOptions={[15]}
            component="div"
            count={rows.total}
            rowsPerPage={rows.per_page}
            page={rows.total <= 0 ? 0 : page}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        </MDBox>
      </Paper>
    </Box>
  );
};
export default CampaignTable;

export const driverLocations = [
  {
    id: "gym",
    name: "gym",
  },
  {
    id: "food",
    name: "food",
  },
  {
    id: "personal",
    name: "personal",
  },
  {
    id: "home",
    name: "home",
  },
  {
    id: "work",
    name: "work",
  },
];

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AppointmentSubmissionsState,
  StoreState,
} from "types/state/StoreState";
import { PaginationCommonObject } from "types/common";
import { AppointmentSubmissions } from "types/AppointmentsSubmission";
import { getAppointmentsByType } from "services/api/appointmentsApi";

const appointmentSubmissionsSlice = createSlice({
  name: "journeys",
  initialState: {
    isLoading: false,
    appointmentSubmissions: {},
  } as AppointmentSubmissionsState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setAppointmentSubmissions(state, action) {
      state.appointmentSubmissions = action.payload;
    },
  },
});

// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.appointmentSubmissions.isLoading;

export const getAppointmentSubmissions = (
  state: StoreState
): PaginationCommonObject<AppointmentSubmissions> =>
  state.appointmentSubmissions.appointmentSubmissions;

// Thunk Actions
type AppointmentSubmissioRequest = {
  accessToken: string;
  page: number;
  type: string;
};
export const getAppointmentSubmissionsThunk = createAsyncThunk(
  "appointmentSubmissions/getAppointmentSubmissionsThunk",
  async (request: AppointmentSubmissioRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { accessToken, page, type } = request;
    dispatch(appointmentSubmissionsSlice.actions.setIsLoading(true));
    const appointments = await getAppointmentsByType(accessToken, page, type);

    dispatch(
      appointmentSubmissionsSlice.actions.setAppointmentSubmissions(
        appointments
      )
    );
    dispatch(appointmentSubmissionsSlice.actions.setIsLoading(false));
  }
);

export default appointmentSubmissionsSlice.reducer;

import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import MDBox from "components/MDBox";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import ImageUpload from "components/ImageUpload";
import { CampaignAssets } from "types/Campaign";
import SelectInput from "components/MDInput/SelectInput";
import { car_type } from "../carTypes";
import { useDispatch } from "react-redux";
import { createOrEditCampaignAssetsThunk } from "redux/slices/campaignSlice";

const initialValues = {
  id: 0,
  campaign_id: 0,
  theme: "",
  type: "",
  front_url: "",
  left_url: "",
  right_url: "",
  back_url: "",
};
type AddEditUserModalProp = {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  selectedRow?: CampaignAssets;
  campaignId: number;
};
const AddEditCampaignAssets: React.FC<AddEditUserModalProp> = (props) => {
  const { open, handleClose, isEdit, selectedRow, campaignId } = props;
  const formikRef = useRef<any>(null);
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = (values) => {
    if (
      !values.front_url ||
      !values.left_url ||
      !values.right_url ||
      !values.back_url ||
      !values.theme ||
      !values.type
    ) {
      alert("All Fiedls are required");
    } else {
      const request = {
        id: values.id ?? undefined,
        campaign_id: campaignId,
        front: values.front_url,
        back: values.back_url,
        left: values.left_url,
        right: values.right_url,
        theme: values.theme,
        type: values.type,
      };
      if (!!accessToken) {
        dispatch(
          createOrEditCampaignAssetsThunk({
            accessToken,
            campaignAssets: request,
            isEdit,
          })
        );
        handleClose();
      }
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`${isEdit ? "Edit" : "New"} Campaign Assets`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Formik
            initialValues={
              isEdit && !!selectedRow ? selectedRow : initialValues
            }
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, errors, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={3.5}>
                    <SelectInput
                      title="Theme"
                      items={[
                        { id: "light", name: "Light" },
                        { id: "dark", name: "Dark" },
                      ]}
                      onSelected={(searchValue) =>
                        setFieldValue("theme", searchValue)
                      }
                      defaultValue={isEdit ? selectedRow?.theme : undefined}
                      width={"38ch"}
                      margin={0}
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <SelectInput
                      title="Type"
                      items={car_type}
                      onSelected={(searchValue) => {
                        setFieldValue("type", searchValue);
                      }}
                      defaultValue={values.type ? values.type : ""}
                      width={"38ch"}
                      margin={0}
                    />
                  </MDBox>
                </MDBox>

                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Front"
                      selectedImage={values.front_url}
                      handleImageUpload={(image) =>
                        setFieldValue("front_url", image)
                      }
                    />
                  </MDBox>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Left"
                      selectedImage={values.left_url}
                      handleImageUpload={(image) =>
                        setFieldValue("left_url", image)
                      }
                    />
                  </MDBox>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Right"
                      selectedImage={values.right_url}
                      handleImageUpload={(image) =>
                        setFieldValue("right_url", image)
                      }
                    />
                  </MDBox>
                  <MDBox width={"25%"} mr={5}>
                    <ImageUpload
                      title="Car Back"
                      selectedImage={values.back_url}
                      handleImageUpload={(image) =>
                        setFieldValue("back_url", image)
                      }
                    />
                  </MDBox>
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {`${isEdit ? "Edit" : "Create"}`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddEditCampaignAssets;

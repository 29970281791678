import { AxiosResponse } from "axios";
import { getRequest } from "services/client/request";
import { CarBrand, CarColor, CarModel } from "types/Cars";
import { AxiosGenericResponse } from "types/common";

const apiVersion = "/api";

export const getCarModels = async (
  accessToken: string
): Promise<CarModel[]> => {
  const response: AxiosResponse<AxiosGenericResponse<CarModel[]>> =
    await getRequest(`${apiVersion}/car_models`, accessToken);
  const carModels: CarModel[] = response.data.success ? response.data.data : [];
  return carModels;
};

export const getCarColors = async (
  accessToken: string
): Promise<CarColor[]> => {
  const response: AxiosResponse<AxiosGenericResponse<CarColor[]>> =
    await getRequest(`${apiVersion}/car_colors`, accessToken);
  const carColors: CarColor[] = response.data.success ? response.data.data : [];
  return carColors;
};

export const getCarBrands = async (
  accessToken: string
): Promise<CarBrand[]> => {
  const response: AxiosResponse<AxiosGenericResponse<CarBrand[]>> =
    await getRequest(`${apiVersion}/car_brands`, accessToken);
  const carBrands: CarBrand[] = response.data.success ? response.data.data : [];
  return carBrands;
};

import { AxiosResponse } from "axios";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "services/client/request";
import { AllUsers, AllUsersRequest } from "types/AllUsers";
import {
  CareFreeBooking,
  CarefreeDrivers,
  CareFreeHomeOwner,
  CareFreePosting,
} from "types/Carefree";
import { User } from "types/User";
import { AxiosGenericResponse } from "types/common";

const apiVersion = "/api";

export const getAllHomeOwners = async (
  accessToken: string
): Promise<CareFreeHomeOwner[]> => {
  const response: AxiosResponse<AxiosGenericResponse<CareFreeHomeOwner[]>> =
    await getRequest(`${apiVersion}/carefrees/homeowners`, accessToken);
  const homeowners: CareFreeHomeOwner[] = response.data.success
    ? response.data.data
    : [];
  return homeowners;
};

export const getPendingHomeOwners = async (
  accessToken: string
): Promise<CareFreeHomeOwner[]> => {
  const response: AxiosResponse<AxiosGenericResponse<CareFreeHomeOwner[]>> =
    await getRequest(`${apiVersion}/carefrees/homeowners/pending`, accessToken);
  const homeowners: CareFreeHomeOwner[] = response.data.success
    ? response.data.data
    : [];
  return homeowners;
};

export const updateHomeOwnerById = async (
  request: CareFreeHomeOwner,
  accessToken: string
): Promise<void> => {
  await putRequest(
    `${apiVersion}/carefrees/homeowners/${request.id}`,
    accessToken,
    request
  );
};

export const deleteHomeOwnerById = async (
  id: number,
  accessToken: string
): Promise<void> => {
  await deleteRequest(`${apiVersion}/carefrees/homeowners/${id}`, accessToken);
};

export const approveHomeOwner = async (
  id: number,
  accessToken: string
): Promise<void> => {
  await postRequest(
    `${apiVersion}/carefrees/homeowners/${id}/approve`,
    accessToken
  );
};

export const rejectHomeOwner = async (
  id: number,
  accessToken: string
): Promise<void> => {
  await postRequest(
    `${apiVersion}/carefrees/homeowners/${id}/reject`,
    accessToken
  );
};

// Care free drivers

export const getAllCareFreeDrivers = async (
  accessToken: string
): Promise<CarefreeDrivers[]> => {
  const response: AxiosResponse<AxiosGenericResponse<CarefreeDrivers[]>> =
    await getRequest(`${apiVersion}/carefrees/drivers`, accessToken);
  const drivers: CarefreeDrivers[] = response.data.success
    ? response.data.data
    : [];
  return drivers;
};
export const getPendingCareFreeDrivers = async (
  accessToken: string
): Promise<any[]> => {
  const response: AxiosResponse<AxiosGenericResponse<any[]>> = await getRequest(
    `${apiVersion}/carefrees/drivers/pending`,
    accessToken
  );
  const drivers: any = response.data.success ? response.data.data : [];
  return drivers;
};

export const updateCareFreeDriversrById = async (
  request: CarefreeDrivers,
  accessToken: string
): Promise<void> => {
  await putRequest(
    `${apiVersion}/carefrees/drivers/${request.id}`,
    accessToken,
    request
  );
};

export const deleteCareFreeDriverById = async (
  id: number,
  accessToken: string
): Promise<void> => {
  await deleteRequest(`${apiVersion}/carefrees/drivers/${id}`, accessToken);
};

export const approveCareFreeDrivers = async (
  id: number,
  accessToken: string
): Promise<void> => {
  await postRequest(
    `${apiVersion}/carefrees/drivers/${id}/approve`,
    accessToken
  );
};

export const rejectCareFreeDrivers = async (
  id: number,
  accessToken: string
): Promise<void> => {
  await postRequest(
    `${apiVersion}/carefrees/drivers/${id}/reject`,
    accessToken
  );
};

export const getAllCareFreeBookings = async (
  accessToken: string
): Promise<CareFreeBooking[]> => {
  const response: AxiosResponse<AxiosGenericResponse<CareFreeBooking[]>> =
    await getRequest(`${apiVersion}/carefrees/bookings`, accessToken);
  const booking: CareFreeBooking[] = response.data.success
    ? response.data.data
    : [];
  return booking;
};
export const cancelCareFreeBooking = async (
  id: number,
  accessToken: string
): Promise<void> => {
  await postRequest(
    `${apiVersion}/carefrees/bookings/${id}/cancel`,
    accessToken
  );
};

export const getAllCareFreePostings = async (
  accessToken: string
): Promise<CareFreePosting[]> => {
  const response: AxiosResponse<AxiosGenericResponse<CareFreePosting[]>> =
    await getRequest(
      `${apiVersion}/carefrees/homeowners/all-postings`,
      accessToken
    );
  const postings: CareFreePosting[] = response.data.success
    ? response.data.data
    : [];
  return postings;
};
export const cancelCareFreePosting = async (
  id: number,
  accessToken: string
): Promise<void> => {
  await postRequest(
    `${apiVersion}/carefrees/homeowners/${id}/cancel`,
    accessToken
  );
};

export const getCarefreeUsers = async (
  request: AllUsersRequest
): Promise<AllUsers> => {
  const { name, page, accessToken } = request;
  const response: AxiosResponse<AxiosGenericResponse<AllUsers>> =
    await getRequest(
      `${apiVersion}/users?&name=${name}&page=${page}&source=carefree`,
      accessToken
    );

  const allUsers: AllUsers = response.data.success
    ? response.data.data
    : ({} as AllUsers);
  return allUsers;
};

export const getCarefreeUsersWithoutPaginationApi = async (
  accessToken: string
): Promise<User[]> => {
  const response: AxiosResponse = await getRequest(
    `${apiVersion}/users?source=carefree}&all=${true}`,
    accessToken
  );

  const allUsers: User[] = response.data.success ? response.data.data : [];
  return allUsers;
};

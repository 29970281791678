import React, { useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import SelectInput from "components/MDInput/SelectInput";
import { CarRoutesEnum } from "types/enum/Common";
import { CarSettings } from "types/Cars";
import { useDispatch, useSelector } from "react-redux";
import { getCarBrands, getCarSettingThunk } from "redux/slices/settingSlice";

const initialValues = {
  name: "",
  car_brand_id: 0,
};
type Prop = {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  carType: string;
  selectedCarSetting?: CarSettings;
  addEditCarSetting: (carSettings: CarSettings) => void;
};
const CarSettingModal: React.FC<Prop> = (props) => {
  const {
    open,
    handleClose,
    isEdit,
    selectedCarSetting,
    carType,
    addEditCarSetting,
  } = props;
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const carBrands = useSelector(getCarBrands);
  const formikRef = useRef<any>(null);

  useEffect(() => {
    if (!!accessToken && carType === CarRoutesEnum.CARMODELS) {
      dispatch(
        getCarSettingThunk({
          accessToken,
          page: 0,
          name: CarRoutesEnum.CARBRAND,
        })
      );
    }
  }, [carType]);

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = (values) => {
    const request: CarSettings = {
      ...values,
    };
    if (isEdit && selectedCarSetting?.id) request.id = selectedCarSetting?.id;
    addEditCarSetting(request);
  };

  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`${isEdit ? "Edit" : "New"} ${carType
            .replace("_", " ")
            .toUpperCase()}`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Formik
            initialValues={
              isEdit && !!selectedCarSetting
                ? selectedCarSetting
                : initialValues
            }
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="text"
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                {carType === CarRoutesEnum.CARMODELS && (
                  <MDBox mb={2} display={"flex"}>
                    <MDBox width={"50%"} mr={4}>
                      <SelectInput
                        title="Link Car Brand Id"
                        items={carBrands}
                        onSelected={(searchValue) =>
                          setFieldValue("car_brand_id", searchValue)
                        }
                        defaultValue={values.car_brand_id?.toString()}
                        width={"41ch"}
                        margin={0.1}
                      />
                    </MDBox>
                  </MDBox>
                )}
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {`${isEdit ? "Edit" : "Add"} ${carType
              .replace("_", " ")
              .toUpperCase()}`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CarSettingModal;

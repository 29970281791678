import React, { useEffect, useState } from "react";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "components/LoadingComponent";
import {
  getLoadingStatus,
  getCampaingMonths,
  getCampaignDriversThunk,
  getCampaignDrivers,
  getAllCampaignForReportThunk,
  getAllCampaignFromReport,
  getReportCampaignMonthThunk,
  setCampaignDrivers,
  setAllReports
} from "redux/slices/reportSlice";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { Autocomplete, Button, IconButton, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  CampaignDrivers,
  ReportCampaign,
  ReportCampaignMonth,
  ReportGenerate
} from "types/Report";
import MDButton from "components/MDButton";
import { generateReportApi } from "services/api/reportApi";
import CampaignDriversPage from "./CampaignDriver";
import SelectInput from "components/MDInput/SelectInput";
import MapIcon from "@mui/icons-material/Map";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ReportDetails: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const route = useLocation().pathname.split("/").slice(1);
  const campaignId = route[route.length - 1];
  const isLoading = useSelector(getLoadingStatus);
  const campaignMonth = useSelector(getCampaingMonths);
  const campaignDrivers = useSelector(getCampaignDrivers);
  const campaigns = useSelector(getAllCampaignFromReport);
  const [selectedMonths, setSelectedMonths] = useState<ReportCampaignMonth[]>(
    []
  );
  const [selectedCampaign, setSelectedCampaign] = useState<ReportCampaign>();
  const [updatedCampaignDriver, setUpdatedCampaignDriver] =
    useState<CampaignDrivers[]>(campaignDrivers);
  const navigate = useNavigate();

  useEffect(() => {
    if (campaignId === "new") {
      dispatch(getAllCampaignForReportThunk(accessToken));
    }
  }, [accessToken, dispatch, campaignId]);

  const extractReportDate = (title: string) => {
    if (selectedMonths.length > 0) {
      const sortedMonths = selectedMonths.sort(
        (a, b) => Number(a.month) - Number(b.month)
      );
      if (title === "start_date") {
        return sortedMonths[0].start_date;
      } else if (title === "end_date") {
        return sortedMonths[sortedMonths.length - 1].end_date;
      }
    }

    return "";
  };
  const selectedCurrentCampaign = (
    campaignId: string,
    allcampaigns: ReportCampaign[]
  ) => {
    const campaign = allcampaigns.find(
      (data) => data.id.toString() === campaignId.toString()
    );
    if (selectedMonths.length > 0) {
      setSelectedCampaign(undefined);
      setSelectedMonths([]);
    }

    setSelectedCampaign(campaign);
    dispatch(setCampaignDrivers([]));
    dispatch(
      getReportCampaignMonthThunk({ accessToken, id: campaign?.id ?? 1 })
    );
  };
  const getReportDriverByMonths = () => {
    const onlyMonths = selectedMonths.map((data) => data.month).join(",");
    dispatch(
      getCampaignDriversThunk({
        accessToken,
        id: selectedCampaign?.id ?? 1,
        month: onlyMonths
      })
    );
  };

  const generateReporting = async () => {
    if (selectedMonths.length === 0 || !selectedCampaign) {
      alert("Please select campaign and months in order to generate");
      return;
    }
    if (updatedCampaignDriver.length === 0) {
      alert(
        "You haven't select driver yet. By default we will use all the original drivers"
      );
    }
    const request: ReportGenerate = {
      title: selectedCampaign.title,
      campaign_id: selectedCampaign.id,
      start_date: extractReportDate("start_date"),
      end_date: extractReportDate("end_date"),
      drivers:
        updatedCampaignDriver.length > 0
          ? updatedCampaignDriver
          : campaignDrivers,
      months: selectedMonths.map((data) => data.month).join(",")
    };
    await generateReportApi(accessToken, request);
    dispatch(setCampaignDrivers(null));
    dispatch(setAllReports([]));
    navigate("/report");
  };

  const updateCampaignTitle = (newTitle: string) => {
    const newCampaign = JSON.parse(
      JSON.stringify(selectedCampaign)
    ) as ReportCampaign;
    newCampaign.title = newTitle;
    setSelectedCampaign(newCampaign);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading && campaigns.length === 0 ? (
        <LoadingComponent />
      ) : (
        <>
          <MDBox mb={2} ml={1} display={"flex"}>
            <MDBox width={"25%"} mr={1} mb={5}>
              <SelectInput
                title="Select Campaign"
                itemName="title"
                combinationWithItemName="id"
                items={campaigns}
                onSelected={(searchValue) => {
                  selectedCurrentCampaign(searchValue, campaigns);
                }}
                width={"30ch"}
              />
            </MDBox>
          </MDBox>

          {selectedCampaign && (
            <>
              <MDBox mb={2} ml={1} display={"flex"}>
                <MDBox width={"50%"} mr={1}>
                  <MDInput
                    type="text"
                    label="Title"
                    name="title"
                    value={selectedCampaign.title}
                    onChange={(e) => updateCampaignTitle(e.target.value)}
                    fullWidth
                  />
                </MDBox>
                <MDBox width={"25%"} mr={1}>
                  <MDInput
                    type="text"
                    label="Campaign Start Date"
                    name="start_date"
                    value={selectedCampaign.start_date}
                    disabled
                    fullWidth
                  />
                </MDBox>
                <MDBox width={"25%"} mr={1}>
                  <MDInput
                    type="text"
                    label="Campaign End Date"
                    name="end_date"
                    value={selectedCampaign.end_date}
                    disabled
                    fullWidth
                  />
                </MDBox>
                <MDBox width={"25%"} mr={1}>
                  <MDInput
                    type="text"
                    label="Campaign Period"
                    name="end_date"
                    value={selectedCampaign.campaign_period}
                    disabled
                    fullWidth
                  />
                </MDBox>
              </MDBox>
              <MDBox mb={2} ml={1} display={"flex"}>
                <MDBox width={"25%"} mr={1}>
                  <Autocomplete
                    id="multiple-limit-tags"
                    multiple
                    disableCloseOnSelect
                    onClose={() => {
                      getReportDriverByMonths();
                    }}
                    options={campaignMonth || []}
                    getOptionLabel={(option) => option.month.toString()}
                    value={selectedMonths}
                    onChange={(_, newValue) => {
                      setSelectedMonths(newValue);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.month}
                      </li>
                    )}
                    sx={{
                      width: "38ch",
                      "& .MuiAutocomplete-tag": {
                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                        color: "rgba(0, 0, 0, 0.87)"
                      },
                      "& .MuiAutocomplete-tag .MuiSvgIcon-root": {
                        color: "rgba(0, 0, 0, 0.26)"
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Months"
                        placeholder="months"
                      />
                    )}
                  />
                </MDBox>
              </MDBox>

              {campaignDrivers && campaignDrivers.length > 0 && (
                <>
                  <CampaignDriversPage
                    drivers={campaignDrivers}
                    setFieldValue={(value) => {
                      setUpdatedCampaignDriver(value);
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
      <MDButton
        variant="gradient"
        color="secondary"
        onClick={() => generateReporting()}
        style={{ marginRight: 8 }}
      >
        Generate Report
      </MDButton>
      {selectedCampaign && (
        <MDButton
          variant="gradient"
          color="primary"
          target="blank"
          href={`https://friday.carching.co/heatmap?id=${selectedCampaign?.id}`}
          style={{ marginRight: 8 }}
        >
          View Heat map
        </MDButton>
      )}
    </DashboardLayout>
  );
};

export default WithAuthWrapper(ReportDetails);

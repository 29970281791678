import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDInput
import MDInputRoot from "components/MDInput/MDInputRoot";

const MDInput = forwardRef<any, any>(
  (
    { error, success, disabled, needCheckBox, checkBoxOnChange, ...rest },
    ref
  ) => (
    <>
      {needCheckBox && (
        <input
          style={{ float: "left", marginBottom: 10 }}
          className="label-checkbox"
          type="checkbox"
          onChange={(event) => {
            if (checkBoxOnChange) {
              checkBoxOnChange(event.target.checked);
            }
          }}
        />
      )}
      <MDInputRoot
        {...rest}
        ref={ref}
        ownerState={{ error, success, disabled }}
      />
    </>
  )
);

// Setting default values for the props of MDInput
MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false
};

// Typechecking props for the MDInput
MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  needCheckBox: PropTypes.bool
};

export default MDInput;

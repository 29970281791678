import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import {
  getAllUsersWithoutPaginationThunk,
  getUsersWithoutPagination,
  getLoadingStatus,
} from "redux/slices/allUsersSlice";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  createNewDriverThunk,
  getAllDriversWithoutPaginationThunk,
  getDriversListWithoutPagination,
} from "redux/slices/driverSlice";
import { Drivers } from "types/AllUsers";
import { User } from "types/User";

type Prop = {
  open: boolean;
  handleClose: () => void;
  addedNewDriver: (driver?: Drivers) => void;
};

const AddDriverModal: React.FC<Prop> = (props) => {
  const { open, handleClose, addedNewDriver } = props;
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const allUsers = useSelector(getUsersWithoutPagination);
  const allDrivers = useSelector(getDriversListWithoutPagination);
  const isLoading = useSelector(getLoadingStatus);
  const [userId, setUserId] = useState<number>(0);

  useEffect(() => {
    if (!!accessToken && (!allUsers.length || !allDrivers.length)) {
      dispatch(getAllUsersWithoutPaginationThunk(accessToken));
      dispatch(getAllDriversWithoutPaginationThunk(accessToken));
    }
  }, [accessToken, dispatch]);

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };

  const onSubmit = async (): Promise<void> => {
    if (!userId) {
      alert("user id is required");
    }

    const result = (await dispatch(
      createNewDriverThunk({ accessToken, driverId: userId })
    )) as any;
    if (Object.keys(result.payload).length > 0) {
      addedNewDriver(result.payload);
    }
  };
  const filterOnlyNonDriversList = (
    userList: User[],
    driverList: Drivers[]
  ) => {
    return userList.filter(
      (user) => !driverList.some((driver) => driver.user_id === user.id)
    );
  };
  return (
    <div>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {` New Driver`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Autocomplete
            id="multiple-limit-tags"
            options={filterOnlyNonDriversList(allUsers, allDrivers)}
            getOptionLabel={(option) => `${option.email}-${option.id}`}
            loading={isLoading}
            filterSelectedOptions
            onChange={(_, newValue) => {
              setUserId(newValue?.id || 0);
            }}
            sx={{
              width: "40ch",
              "& .MuiAutocomplete-tag": {
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                color: "rgba(0, 0, 0, 0.87)",
              },
              "& .MuiAutocomplete-tag .MuiSvgIcon-root": {},
            }}
            renderInput={(params) => (
              <TextField {...params} label="User Ids" placeholder="User Ids" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit}>{"Add Driver"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddDriverModal;

export enum GenderEnum {
  MALE = "Male",
  FEMALE = "Female",
}

export enum BooleaEnum {
  YES = "Yes",
  No = "No",
}

export enum StatusEnum {
  ENABLED = "Enabled",
  DISABLED = "Disabled",
}

export enum CarRoutesEnum {
  CARBRAND = "car_brands",
  CARMODELS = "car_models",
  CARCOLORS = "car_colors",
}

export enum CampaignStatusEnum {
  CROWDSOURCING = "Crowdsourcing",
  STICKER_INSTALLATION = "Sticker Installation",
  RUNNING = "Running",
  ENDED = "Ended",
}

export enum PhoneSize {
  IPHONE_6 = "iphone-6",
  IPHONE_10 = "iphone-10",
  IPHONE_12_PRO_MAX = "iphone-12-pro-max",
}

export enum CampaignParticipantsStatusEnum {
  ALL = "All",
  WAITLISTED = "Waitlisted",
  SHORTTLISTED = "Shortlisted",
  REJECTED = "Rejected",
  PENDING_INSTALLATION = "Pending Installation",
  APPROVED = "Approved",
  READY = "Ready",
  FINISHED = "Finished",
  CANCELLED = "Cancelled",
}

export enum InstallationEnum {
  INSTALLATION = "Installation",
  REMOVAL = "Removal",
}

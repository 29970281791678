import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Formik, Form as FormikForm } from "formik";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import ModalHeaderTitle from "layouts/users/AddEditUserModal/Header";
import { validateForm } from "./validateForm";
import { NewJourney } from "types/Journeys";

const initialValues = {
  driver_id: 0,
  campaign_id: 0,
  datetime: "",
  distance: 0,
  gpx_file: null,
  points: 0,
};
type AddEditUserModalProp = {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  selectedJourney?: NewJourney;
  addEditJourney: (updatedJourney: NewJourney) => void;
};
const AddEditJourneyModal: React.FC<AddEditUserModalProp> = (props) => {
  const { open, handleClose, isEdit, selectedJourney, addEditJourney } = props;
  const formikRef = useRef<any>(null);

  const handleOnClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.info("Backdrop is clicked");
    } else {
      handleClose();
    }
  };
  const onSubmit = (values) => {
    var updatedJourney: NewJourney = values;
    if (isEdit) {
      updatedJourney = {
        ...values,
        driver_id: selectedJourney?.driver_id,
      };
    }
    addEditJourney(updatedJourney);
  };

  return (
    <>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        disableEscapeKeyDown={true}
        onBackdropClick={(event) => {
          event.stopPropagation();
          return false;
        }}
      >
        <ModalHeaderTitle id="customized-dialog-title" onClose={handleClose}>
          {`${isEdit ? "Edit" : "New"} Journey`}
        </ModalHeaderTitle>
        <DialogContent dividers>
          <Formik
            initialValues={
              isEdit && !!selectedJourney ? selectedJourney : initialValues
            }
            validate={(values) => {
              if (!isEdit) validateForm(values);
            }}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({ values, errors, handleBlur, handleChange, setFieldValue }) => (
              <FormikForm>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="number"
                      label="Driver Id"
                      name="driver_id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.driver_id}
                      fullWidth
                      error={!!errors.driver_id}
                      helperText={errors.driver_id}
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="number"
                      label="Campaign Id"
                      name="campaign_id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.campaign_id}
                      fullWidth
                      error={!!errors.campaign_id}
                      helperText={errors.campaign_id}
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="datetime-local"
                      label="Date Time"
                      name="datetime"
                      disabled={isEdit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.datetime}
                      helperText={errors.datetime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      error={!!errors.datetime}
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="number"
                      label="Distance"
                      name="distance"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.distance}
                      helperText={errors.distance}
                      fullWidth
                      error={!!errors.distance}
                    />
                  </MDBox>
                </MDBox>
                <MDBox mb={2} display={"flex"}>
                  <MDBox width={"50%"} mr={5}>
                    <MDInput
                      type="number"
                      label="Points"
                      name="points"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.points}
                      error={!!errors.points}
                      helperText={errors.points}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox width={"50%"}>
                    <MDInput
                      type="file"
                      accept=".gpx"
                      label="Gpx File"
                      name="gpx_file"
                      onChange={(event) => {
                        setFieldValue("gpx_file", event.currentTarget.files[0]);
                      }}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors.gpx_file}
                      helperText={errors.gpx_file}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //Trigger formik button as outside the form
              if (formikRef.current) {
                formikRef.current.handleSubmit();
              }
            }}
          >
            {isEdit ? "Edit journey" : "Add journey"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default AddEditJourneyModal;

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import React, { useState, useEffect, useCallback } from "react";
import IconButton from "@mui/material/IconButton";

import {
  CampaignDriverPictures,
  CampaignDrivers,
  DriverMultiplier
} from "types/Report";
import MDTypography from "components/MDTypography";
import { recalculateDriverMultiplierApi } from "services/api/reportApi";
import { Checkbox, Tooltip } from "@mui/material";
import CalculateIcon from "@mui/icons-material/Calculate";
import ImageIcon from "@mui/icons-material/Image";
import ReportDriverPicturedModal from "./Modal";

type Props = {
  drivers: CampaignDrivers[];
  setFieldValue: (value: any) => void;
};
const CampaignDriversPage: React.FC<Props> = (props) => {
  const { drivers, setFieldValue } = props;
  const accessToken = localStorage.getItem("carching-token") || "";
  const [formValues, setFormValues] = useState<CampaignDrivers[]>([]);
  const [isDriverPhotosOpen, openDriverPhotos] = useState<boolean>(false);
  const [driverPhotos, setDriverPhotos] = useState<{
    start_pictures: CampaignDriverPictures;
    end_pictures: CampaignDriverPictures;
  }>();
  // const selectedDrivers = useSelector(getAllSelectedDriversFromCampaigns);
  const [selectedDrivers, setSelectedDrivers] = useState<CampaignDrivers[]>([]);

  useEffect(() => {
    if (drivers && drivers.length) {
      setFormValues(drivers);
    }
  }, [drivers]);

  const handleOnChange = (index: number, e, field: string) => {
    let newFormValues = JSON.parse(
      JSON.stringify(formValues)
    ) as CampaignDrivers[];
    newFormValues[index][field] = e.target ? e.target.value : e;
    setFormValues(newFormValues);
    setFieldValue(newFormValues);

    if (selectedDrivers.length > 0) {
      let newSelectedDrivers = JSON.parse(
        JSON.stringify(selectedDrivers)
      ) as CampaignDrivers[];
      const existingIndex = newSelectedDrivers.findIndex(
        (driver) => driver.id === newFormValues[index]["id"]
      );
      if (existingIndex !== -1) {
        newSelectedDrivers[existingIndex][field] = newFormValues[index][field];
        setSelectedDrivers(newSelectedDrivers);
      }
    }
  };

  const updateDriverMultiplier = async (value: CampaignDrivers, index) => {
    const { start_odo, end_odo, actual_distance } = value;
    const newRequest: DriverMultiplier = {
      start_odo,
      end_odo,
      distance: actual_distance
    };
    try {
      const response = await recalculateDriverMultiplierApi(
        accessToken,
        newRequest
      );
      alert(response.data.status);
      const { multiplier } = response.data.data;
      handleOnChange(index, multiplier, "multiplier");
    } catch (e) {
      alert(e);
      console.error(e);
    }
  };

  const selectedCheckedDriver = (value: boolean, driver: CampaignDrivers) => {
    let result = formValues.some((o1) =>
      selectedDrivers.some((o2) => o1.id === o2.id)
    );
    if (result && !value) {
      const filterdDrivers = selectedDrivers.filter(
        (currentDriver) => currentDriver.id !== driver.id
      );
      setSelectedDrivers(filterdDrivers);
      setFieldValue(filterdDrivers);
    } else {
      const filterdDrivers = JSON.parse(
        JSON.stringify(selectedDrivers)
      ) as CampaignDrivers[];

      filterdDrivers.push(driver);
      setSelectedDrivers(filterdDrivers);
      setFieldValue(filterdDrivers);
    }
  };
  return (
    <>
      {isDriverPhotosOpen && (
        <ReportDriverPicturedModal
          open={isDriverPhotosOpen}
          handleClose={() => openDriverPhotos(false)}
          start_pictures={
            driverPhotos?.start_pictures || ({} as CampaignDriverPictures)
          }
          end_pictures={
            driverPhotos?.end_pictures || ({} as CampaignDriverPictures)
          }
        />
      )}

      <MDTypography variant="p" color="dark" mt={2} mb={2}>
        {
          "Driver Details (* Straight update the value in some of the form fields)"
        }
      </MDTypography>
      {formValues.map((element, index) => (
        <MDBox mt={5} mb={2} ml={1} display={"flex"} key={index}>
          <Checkbox
            color="primary"
            //checked={checkingDriverHasBeenSelected(element)}
            onChange={(e) => selectedCheckedDriver(e.target.checked, element)}
            inputProps={{
              "aria-labelledby": index.toString()
            }}
          />
          <MDBox width={"10%"} mr={2}>
            <MDInput
              type="text"
              label="Id"
              name="id"
              value={element.id}
              disabled
              fullWidth
            />
          </MDBox>
          <MDBox width={"15%"} mr={2}>
            <MDInput
              type="text"
              label="Name"
              name="name"
              value={element.name}
              disabled
              fullWidth
            />
          </MDBox>
          <MDBox width={"10%"} mr={2}>
            <MDInput
              type="text"
              label="Start Date"
              name="start_date"
              value={element.start_date}
              disabled
              fullWidth
            />
          </MDBox>
          <MDBox width={"10%"} mr={2}>
            <MDInput
              type="text"
              label="End Date"
              name="end_date"
              value={element.end_date}
              disabled
              fullWidth
            />
          </MDBox>
          <MDBox width={"13%"} mr={2}>
            <MDInput
              type="text"
              label="Start Odo"
              name="start_odo"
              value={element.start_odo}
              onChange={(e) => handleOnChange(index, e, "start_odo")}
              fullWidth
            />
          </MDBox>
          <MDBox width={"13%"} mr={2}>
            <MDInput
              type="text"
              label="End Odo"
              name="end_odo"
              value={element.end_odo}
              onChange={(e) => handleOnChange(index, e, "end_odo")}
              fullWidth
            />
          </MDBox>
          <MDBox width={"13%"} mr={2}>
            <MDInput
              type="text"
              label="Actual Distance"
              name="actual_distance"
              value={element.actual_distance}
              onChange={(e) => handleOnChange(index, e, "actual_distance")}
              fullWidth
            />
          </MDBox>
          <MDBox width={"13%"} mr={2}>
            <MDInput
              type="text"
              label="Rewarded Distance"
              name="rewarded_distance"
              value={element.rewarded_distance}
              disabled
              fullWidth
            />
          </MDBox>

          <MDBox width={"13%"} mr={2}>
            <MDInput
              type="text"
              label="Multiplier"
              name="multiplier"
              value={element.multiplier}
              onChange={(e) => handleOnChange(index, e, "multiplier")}
              fullWidth
            />
          </MDBox>
          <MDBox align="right">
            <Tooltip title="View Photos">
              <IconButton
                onClick={(e) => {
                  //Prevent whole  row is being selected
                  e.stopPropagation();
                  e.preventDefault();
                  openDriverPhotos(true);
                }}
              >
                <ImageIcon />
              </IconButton>
            </Tooltip>
          </MDBox>
          <MDBox width={"10%"} display="inline-flex">
            <Tooltip title="Recalculate Multiplier">
              <IconButton
                onClick={() => updateDriverMultiplier(element, index)}
              >
                <CalculateIcon color={"primary"} />
              </IconButton>
            </Tooltip>
          </MDBox>
        </MDBox>
      ))}
    </>
  );
};

export default CampaignDriversPage;

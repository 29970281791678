import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DriverPage from "layouts/drivers";
// Material Dashboard 2 React routes
import routes from "routes";

const AppWrapperRoute: React.FC = () => {
  const getRoutes = (allRoutes: any) =>
    allRoutes.map((route: any) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.type === "child" && route.children) {
        return route.children.map((child) => {
          return (
            <Route
              path={child.route}
              element={child.component}
              key={child.key}
            />
          );
        });
      }
      if (route.route) {
        return (
          <Route path={route.route} element={route.component} key={route.key} />
        );
      }

      return null;
    });
  return (
    <Routes>
      {getRoutes(routes)}
      <Route path="*" element={<Navigate to="/dashboard" />} />
      <Route path="/drivers/:id" element={<DriverPage />} />
    </Routes>
  );
};

export default AppWrapperRoute;

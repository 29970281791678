import { AxiosResponse } from "axios";
import { getRequest } from "services/client/request";
import { AxiosGenericResponse, PaginationCommonObject } from "types/common";
import { Withdrawal, WithdrawalFilter } from "types/Withdrawal";

const apiVersion = "/api";

export const getAllWithdrawals = async (
  accessToken: string,
  filterParam: WithdrawalFilter
): Promise<PaginationCommonObject<Withdrawal>> => {
  const param: string[] = [];
  if (!!filterParam) {
    Object.keys(filterParam).forEach((key) => {
      if (!!filterParam[key]) {
        param.push(`${key}=${filterParam[key]}`);
      }
    });
  }
  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<Withdrawal>>
  > = await getRequest(
    `${apiVersion}/transactions/withdrawal?${param.join("&")}`,
    accessToken
  );

  const withdrawal: PaginationCommonObject<Withdrawal> = response.data.success
    ? response.data.data
    : ({} as PaginationCommonObject<Withdrawal>);
  return withdrawal;
};

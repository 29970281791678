import React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import MDTypography from "components/MDTypography";

interface ConfirmationDialogProps {
  title: string;
  open: boolean;
  submitButtonTitle: string;
  onClose: (value?: string) => void;
  action?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
  const { onClose, title, open, submitButtonTitle, action } = props;

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>{submitButtonTitle}</DialogTitle>
      <DialogContent dividers>
        <MDTypography variant="p" color="dark" mt={1}>
          {title}
        </MDTypography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => onClose()}>
          Cancel
        </Button>
        <Button onClick={() => onClose(action)}>{submitButtonTitle}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import SortedTable from "./table";
import { useDispatch, useSelector } from "react-redux";
import { createOrUpdateUser } from "redux/slices/allUsersSlice";
import { CSVLink } from "react-csv";
import { getUserById } from "services/api/usersApi";
import { User } from "types/User";
import LoadingComponent from "components/LoadingComponent";
import AddEditUserModal from "./AddEditUserModal";
import { NewUser } from "types/Authentication";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import {
  getAllCarefreeUsers,
  getAllCarefreeUsersThunk,
} from "redux/slices/carefreeSlice";
import { getCarefreeUsersWithoutPaginationApi } from "services/api/carefreeApi";

const header = [
  { id: "id", name: "id" },
  { id: "fullName", name: "Full Name" },
  { id: "email", name: "Email" },
  { id: "emailVerifiedAt", name: "Email Verified At" },
  { id: "action", name: "Action" },
];
const CarefreeUser: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token") || "";
  const dispatch = useDispatch();
  const allUsers = useSelector(getAllCarefreeUsers);
  const [csvUsers, setCsvUsers] = useState<User[]>([]);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<NewUser>();
  const csvButtonLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (!!accessToken && !!allUsers && !allUsers.data) {
      getAllUsersTableList("", 1);
    }
  }, []);

  useEffect(() => {
    async function getUser() {
      if (urlParams.has("id")) {
        const userId = urlParams.get("id");
        const user = await getUserById(accessToken, Number(userId));
        setSelectedUser(user);
        setIsEdit(true);
        setModalOpen(true);
      }
    }
    getUser();
  }, []);

  const downloadIntoCsv = async (): Promise<void> => {
    if (accessToken) {
      const allCsvUsers = await getCarefreeUsersWithoutPaginationApi(
        accessToken
      );
      setCsvUsers(allCsvUsers);
      setTimeout(() => {
        csvButtonLink?.current?.link.click();
      });
    }
  };

  const getAllUsersTableList = (username: string, page: number) => {
    if (!!accessToken)
      dispatch(
        getAllCarefreeUsersThunk({
          accessToken,
          page: page,
          name: username,
        })
      );
  };

  const createOrEditUser = (user: NewUser) => {
    if (!!accessToken)
      dispatch(
        createOrUpdateUser({
          accessToken,
          isEdit,
          user,
        })
      );
    setModalOpen(false);
    getAllUsersTableList("", 1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!!csvUsers && (
        <CSVLink
          headers={[
            "id",
            "fname",
            "lname",
            "email",
            "email_verified_at",
            "profile_profile_url",
            "phone",
            "country_code",
            "role_id",
            "username",
          ]}
          filename="All_Users.csv"
          data={csvUsers}
          ref={csvButtonLink}
        />
      )}
      <AddEditUserModal
        open={isModalOpen}
        isEdit={isEdit}
        selectedUser={selectedUser}
        handleClose={() => setModalOpen(false)}
        addEditUser={createOrEditUser}
      />
      {!!allUsers && allUsers.data ? (
        <SortedTable
          header={header}
          rows={allUsers}
          onPageChanged={(newPage: number) =>
            getAllUsersTableList("", newPage + 1)
          }
          downloadIntoCsv={downloadIntoCsv}
          handleUserSearch={(username: string) =>
            getAllUsersTableList(username, 1)
          }
          onReload={(currentPage: number) =>
            getAllUsersTableList("", currentPage + 1)
          }
          addNewOrEditUser={(isEditAction: boolean, selectedUser?: NewUser) => {
            setSelectedUser(selectedUser);
            setIsEdit(isEditAction);
            setModalOpen(!isModalOpen);
          }}
        />
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};

export default WithAuthWrapper(CarefreeUser);

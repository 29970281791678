import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { Campaign, CampaignAgreements, CampaignAssets } from "types/Campaign";

type Props = {
  isEdit: boolean;
  campaign: Campaign;
  setFieldValue: (field: string, value: any) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
};
const AddAgreement: React.FC<Props> = (props) => {
  const { isEdit, campaign, setFieldValue, handleBlur } = props;
  const [agreements, setAgreements] = useState<string>();

  useEffect(() => {
    if (isEdit) {
      const values = formatEditedAgreement(campaign);
      setAgreements(values);
    }
  }, [isEdit]);
  const termsAndConditionsOnChange = (text) => {
    setAgreements(text);
    if (text === "") {
      setFieldValue("agreements", undefined);
    } else {
      setFieldValue("agreements", text);
    }
  };
  const formatEditedAgreement = (selectedCampaign: Campaign) => {
    const allagreements = selectedCampaign.agreements;

    if (!allagreements) {
      return "";
    }

    const formatedContentArray: string[] = [];
    for (const eachAgreement of allagreements) {
      const { content } = eachAgreement as CampaignAgreements;
      const formatedContent = `*${content}`;
      formatedContentArray.push(formatedContent);
    }
    return formatedContentArray.join("\n");
  };

  return (
    <MDBox mt={5}>
      <TextField
        id="outlined-multiline-static"
        label="Terms and Condition"
        helperText="Please put * on each of your agreement. (Max of 6 agreements. 200 words per agreement)"
        fullWidth
        multiline
        rows={6}
        value={agreements}
        inputProps={{ maxLength: 1206 }}
        onChange={(e) => termsAndConditionsOnChange(e.target.value)}
        onFocus={(e) => termsAndConditionsOnChange(e.target.value)}
        onBlur={handleBlur}
      />
    </MDBox>
  );
};

export default AddAgreement;

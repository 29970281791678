import { AxiosResponse } from "axios";
import { getRequest, postRequest } from "services/client/request";
import { AxiosGenericResponse, PaginationCommonObject } from "types/common";
import { Ledger, Overview } from "types/Overview";
import {
  NewTransaction,
  TransactionByRange,
  Transactions,
  TransactionType,
} from "types/Transactions";

const apiVersion = "/api";

export const getAllTransactions = async (
  accessToken: string,
  page: number,
  status_id?: number
): Promise<Transactions> => {
  const response: AxiosResponse<AxiosGenericResponse<Transactions>> =
    await getRequest(
      `${apiVersion}/transactions?page=${page}&status_id=${
        status_id ? status_id : ""
      }`,
      accessToken
    );
  const journeys: Transactions = response.data.success
    ? response.data.data
    : ({} as Transactions);
  return journeys;
};

export const approveTransaction = async (
  accessToken: string,
  transactionId: number
): Promise<void> => {
  await postRequest(
    `${apiVersion}/transactions/${transactionId}/approve`,
    accessToken
  );
};

export const rejectTransaction = async (
  accessToken: string,
  transactionId: number
): Promise<void> => {
  await postRequest(
    `${apiVersion}/transactions/${transactionId}/reject`,
    accessToken
  );
};

export const markAsTransferred = async (
  accessToken: string,
  transactionId: number,
  approved_amount: number
): Promise<void> => {
  await postRequest(
    `${apiVersion}/transactions/${transactionId}/transfer`,
    accessToken,
    {
      approved_amount,
      reason: "",
    }
  );
};

export const approveTransactionByDateRange = async (
  accessToken: string,
  transaction: TransactionByRange
): Promise<void> => {
  await postRequest(
    `${apiVersion}/transactions/approve-range`,
    accessToken,
    transaction
  );
};

export const getAllOverviews = async (
  accessToken: string,
  page: number,
  name?: string
): Promise<PaginationCommonObject<Overview>> => {
  const response: AxiosResponse<
    AxiosGenericResponse<PaginationCommonObject<Overview>>
  > = await getRequest(
    `${apiVersion}/transactions/overview?page=${page}&keyword=${
      name ? name : ""
    }`,
    accessToken
  );
  const overview: PaginationCommonObject<Overview> = response.data.success
    ? response.data.data
    : ({} as PaginationCommonObject<Overview>);
  return overview;
};

export const getUserLedgers = async (
  accessToken: string,
  userId: number,
  date: string
): Promise<Ledger> => {
  const response: AxiosResponse<AxiosGenericResponse<Ledger>> =
    await getRequest(
      `${apiVersion}/transactions/users/${userId}/ledgers?date=${date}`,
      accessToken
    );
  const userLedger: Ledger = response.data.success
    ? response.data.data
    : ({} as Ledger);
  return userLedger;
};

export const getAllLedgers = async (
  accessToken: string,
  date: string
): Promise<Ledger> => {
  const response: AxiosResponse<AxiosGenericResponse<Ledger>> =
    await getRequest(
      `${apiVersion}/transactions/ledgers?date=${date}`,
      accessToken
    );
  const ledgers: Ledger = response.data.success
    ? response.data.data
    : ({} as Ledger);
  return ledgers;
};
export const createTransaction = async (
  request: NewTransaction,
  accessToken: string
): Promise<boolean> => {
  try {
    await postRequest(`${apiVersion}/transactions`, accessToken, request);
  } catch (error: any) {
    const message = error.response
      ? error.response.data.message
      : "Creation Failed";

    alert(message);
    return false;
  }
  return true;
};

export const getTransactionType = async (
  accessToken: string
): Promise<TransactionType[]> => {
  const response: AxiosResponse<AxiosGenericResponse<TransactionType[]>> =
    await getRequest(`${apiVersion}/transactions/types`, accessToken);
  const transactionTypes: TransactionType[] = response.data.success
    ? response.data.data
    : [];
  return transactionTypes;
};

import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "components/LoadingComponent";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import {
  getAllOverviewThunk,
  getOverview,
} from "redux/slices/transactionSlice";
import { Overview } from "types/Overview";
import OverviewTable from "./table";
import UserLedgerModal from "./modal";

const header = [
  { id: "id", name: "Partner Id" },
  { id: "fname", name: "Full Name" },
  { id: "total_points", name: "Total Points" },
  { id: "in_points", name: "In Points" },
  { id: "out_points", name: "Out Points" },
  { id: "nett_points", name: "Nett Points" },
  { id: "action", name: "Action" },
];

const OverviewPage: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const overView = useSelector(getOverview);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedOverview, setSelectedOverview] = useState<Overview>();

  const getAllOverview = useCallback(
    (page: number, name?: string) => {
      if (!!accessToken) {
        dispatch(
          getAllOverviewThunk({
            accessToken,
            page,
            name,
          })
        );
      }
    },
    [dispatch, accessToken]
  );

  useEffect(() => {
    if (!!accessToken && !!overView && !overView.data) {
      getAllOverview(1);
    }
  }, [accessToken, overView, getAllOverview]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isModalOpen && (
        <UserLedgerModal
          open={isModalOpen}
          handleClose={() => setModalOpen(false)}
          userId={!!selectedOverview ? selectedOverview.id : 0}
        />
      )}

      {!!overView && overView.data ? (
        <OverviewTable
          header={header}
          rows={overView}
          onReload={() => getAllOverview(1)}
          viewOverview={(selectedOverview: Overview) => {
            setSelectedOverview(selectedOverview);
            setIsEdit(isEdit);
            setModalOpen(!isModalOpen);
          }}
          onPageChanged={(newPage: number) => getAllOverview(newPage)}
          handleOverviewSearch={(name: string) => getAllOverview(1, name)}
        />
      ) : (
        <LoadingComponent />
      )}
    </DashboardLayout>
  );
};
export default WithAuthWrapper(OverviewPage);
